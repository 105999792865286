Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";
exports.deleteApiMethod = 'DELETE'

exports.btnExampleTitle = "CLICK ME";

exports.paymentTokenEndpoint = "https://api.sandbox.checkout.com/tokens";
exports.paymentAPiEndPoint = "bx_block_order_management/orders";
// Customizable Area End