import React from "react";

// Customizable Area Start
import { Container, Box, Typography, Grid } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Chart } from "react-google-charts";
import { Rating } from "@material-ui/lab"
export const dataCard = ({ title, count, rating, total }: { title: string, count?: number, rating?: number, total?: number }) => (
  <Grid item lg={4} md={6} sm={6} xs={12} data-testid="userRecords">
     <div style={webStyle.cardContainer}>
        <Grid container direction="column">
           <Typography style={webStyle.cardTitle}>
              {title}
           </Typography>
           {(count || count === 0)
              ? <Typography style={webStyle.cardCount}>
                 +{count.toLocaleString()}
              </Typography>
              : <Typography style={{
                 ...webStyle.cardCount,
                 display: 'flex',
                 gap: '8px'
              }}
              >
                 <Rating value={0.5} precision={0.1}
                    readOnly size="medium" max={1}
                    style={{ color: "#FE7F2D", margin: 'auto 0' }}
                 />
                 {rating} <sub style={{
                    color: ' #212426',
                    fontFamily: 'Inter',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: 'normal',
                    marginTop: 'auto'
                 }}>({total?.toLocaleString()})</sub>
              </Typography>
           }
        </Grid>
     </div>
  </Grid>
)
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={"sm"}>
          <Box sx={webStyle.mainWrapper}>
            {dataCard({ title: 'Active trips', count: 0 })}
            {dataCard({ title: 'Bookings', count: 1 })}
            {dataCard({ title: 'Rating', total: 2, rating: 2 })}
            <Typography variant="h6">{configJSON.labelTitleText}</Typography>
            <Typography variant="subtitle1" component="div">
              {configJSON.labelBodyText}
            </Typography>
            <Box sx={webStyle.inputStyle}>
              <Chart
                chartType="ColumnChart"
                //@JFR NEED TO CONVERT FORMAT OR ADD CONTROLER???
                data={this.state.googleChartData}
                width="100%"
                height="400px"
                legendToggle
              />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  cardContainer: {
    padding: '24px 24px 32px',
    height: '144px',
    flexShrink: 0,
    borderRadius: '16px',
    background: '#FFF',
 },
 cardTitle: {
  color: 'rgba(33, 36, 38, 0.75)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '150%',
},
cardCount: {
  color: '#212426',
  fontFamily: 'Inter',
  fontSize: '40px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  marginTop: '16px'
},
};
// Customizable Area End
