import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { OptionListItem } from "./Interface.web";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ChangeEvent } from "react";
import { FormikErrors } from "formik";
import { convertDateFormat } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  countriesData: OptionListItem[],
  isOpen: boolean,
  isSuccessOpen: boolean,
  countdown: number,
  otp: string,
  otpErrorMessage: string | null,
  otpHeaderTitle: string | null,
  isEmailVerified: boolean,
  validateErrorMsg: string | null | {},
  setErrorFun: any;
  countryOption: { label:string, value:string }[];
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  createAccountCustomerApiCallId: any;
  validationApiCallId: any = "";
  sendEmailOtpApiCallId: any = "";
  emailOtpVerifyApiCallId: any = "";
  getCountryListapiCallId: any = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  placeHolderEmail: string
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  timer: any
  currentCountryCode: any;
  verifyEmailToken: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: false,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",
      countriesData: [],
      isOpen: false,
      isSuccessOpen: false,
      countdown: 60,
      otp: "",
      otpErrorMessage: null,
      otpHeaderTitle: null,
      isEmailVerified: false,
      validateErrorMsg: null,
      setErrorFun: null,
      countryOption: []
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.placeHolderEmail = configJSON.placeHolderEmail;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    switch (message.id) {
      case getName(MessageEnum.RestAPIResponceMessage):
        await this.handleAPIResponse(message);
        break;

      case getName(MessageEnum.NavigationPayLoadMessage):
        this.handleAuthToken(message);
        break;

      case getName(MessageEnum.CountryCodeMessage):
        this.handleCountryCode(message);
        break;

      default:
        break;
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getCountryListRequest()
  }

  async handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if (apiRequestCallId === this.validationApiCallId) {
      this.updatePasswordValidation(responseJson.data);
    } else if (apiRequestCallId === this.createAccountApiCallId) {
      this.handleCreateAccountResponse(responseJson)
      this.parseApiCatchErrorResponse(errorReponse);
    } else if (apiRequestCallId === this.createAccountCustomerApiCallId) {
      this.handleCreateAccountCustomerResponse(responseJson)
    } else if (apiRequestCallId === this.sendEmailOtpApiCallId) {
      this.handleSendEmailOtpResponse(responseJson)
    } else if (apiRequestCallId === this.emailOtpVerifyApiCallId) {
      this.handleVerifyEmailOtp(responseJson)
    } else if (apiRequestCallId === this.getCountryListapiCallId) {
      this.handleCountryCodeResponse(responseJson)
    }
  }

  updatePasswordValidation(data: any) {
    this.arrayholder = data;

    if (this.arrayholder && this.arrayholder.length !== 0) {
      const regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules
        });
      }

      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleAuthToken(message: Message) {
    const otpAuthTkn = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    if (otpAuthTkn && otpAuthTkn.length > 0) {
      this.setState({ otpAuthToken: otpAuthTkn });
      runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
      runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
    }
  }

  handleCountryCode(message: Message) {
    const selectedCode = message.getData(getName(MessageEnum.CountyCodeDataMessage));
    if (selectedCode !== undefined) {
      this.setState({
        countryCodeSelected:
          selectedCode.indexOf("+") > 0
            ? selectedCode.split("+")[1]
            : selectedCode
      });
    }
  }

  handleCreateAccountResponse(responseJson: any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleSendEmailOtpResponse(responseJson: any) {
    if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.state.setErrorFun('email', `* ${responseJson.errors[0].otp}`)
    } else if (responseJson && responseJson.meta && responseJson.meta.token) {
      if (this.state.otpHeaderTitle) {
        this.setState({
          countdown: 60, otpErrorMessage: null, otp: "",
          otpHeaderTitle: "We sent you the OTP to your email address" 
        });
        this.verifyEmailToken = responseJson.meta.token
        this.startCountdown();
      } else {
        this.verifyEmailToken = responseJson.meta.token
        this.openCloseModal()
        this.startCountdown()
        this.setState({ otpHeaderTitle: `Verification code sent to you on ${this.state.email}` })
      }
    }
  }

  handleCreateAccountCustomerResponse(responseJson: any) {
    if (responseJson && responseJson.errors && responseJson.errors.length) {
      let errorMsg: {} | string = responseJson.errors[0].account
      if (typeof errorMsg === 'object') {
        errorMsg = ([] as string[]).concat(...Object.values(errorMsg as string[])).join(`. `)
      }
      this.setState({ validateErrorMsg: errorMsg })
    } else if (responseJson && responseJson.messages && responseJson.messages.length) {
      this.setState({ validateErrorMsg: null, isSuccessOpen: true })
    }
  }

  handleVerifyEmailOtp(responseJson: any) {
    if (responseJson && responseJson.errors && responseJson.errors.length) {
      this.setState({ otpErrorMessage: `* ${responseJson.errors[0].otp}` })
    } else if (responseJson && responseJson.messages && responseJson.messages.length) {
      this.setState({ isEmailVerified: true })
      this.openCloseModal()
    }
  }

  handleCountryCodeResponse(responseJson: any) {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryCodeList: OptionListItem[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          return { ...attributes, id: attributes.alpha_code };
        }
      );
      const countryList = countryDataArray.map((country:any) => {
        return { label: country.attributes.name, value: country.id } 
      })
      this.setState({
        countriesData: countryCodeList,
        countryOption: countryList
      });
      this.state.countryOption.push(countryList)
    }
  }

  goToPrivacyPolicy = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }
  
  handleCreateAccount = (values: any, setFieldError: (field: string, message: string | undefined) => void) => {
    if (!this.state.isEmailVerified) {
      setFieldError('email', "* Please verify email address")
      return
    }
    if (values.checked) {
      this.setState({ validateErrorMsg: null })

      const header = {};

      const formdata = new FormData()
      formdata.append("first_name", values.first_name);
      formdata.append("last_name", values.last_name);
      formdata.append("email", values.email);
      formdata.append("full_phone_number", values.selectedCountry + values.mobile_number);
      formdata.append("gender", values.gender);
      formdata.append("nationality_id", values.nationality_id);
      formdata.append("country_id", values.country_id);
      formdata.append("password", values.password);
      formdata.append("language", "arabic");
      if (values.profile_picture) {
        formdata.append("profile_image", values.profile_picture);
      }
      if(values.birth_day){
        const date_of_birth = convertDateFormat(new Date(values.birth_day))
        formdata.append("date_of_birth", date_of_birth);
      }
 
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createAccountCustomerApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.customerAccountsAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.setState({ validateErrorMsg: "Please agree on the privacy policy and terms of use before proceeding" })
    }
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getCountryListRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "Accept-Language": 'en'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListapiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountryListEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  } 

  handleFileChange = async(e: any, setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>) => {
    const selectedFile = e.target.files && e.target.files[0];
    if (selectedFile) {
      setFieldValue('profile_picture', selectedFile);
    }
  }

  openCloseModal = () => {
    if (this.state.isOpen) {
      clearInterval(this.timer as number | undefined)
      this.setState({ countdown: 60, otpHeaderTitle: null, otp: '' })
    }
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleSuccessModal = () => {
    if(this.state.isSuccessOpen){
      this.setState({ isSuccessOpen: false})
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }

  handleVerfiyEmail = (email: string, error: any, setFieldError: (field: string, message: string | undefined) => void) => {
    this.setState({ setErrorFun: setFieldError })
    if (email && (!error.email || error.email === "* Please verify email address")) {
      this.handleVerifyEmailApiCall(email)
    }
  }

  handleVerifyEmailApiCall = (email: string):boolean => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      email: email
    };

    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendEmailOtpApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendEmailOtpApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleChangeEmail = (e:ChangeEvent<HTMLInputElement>) => {
    const { target:{ value = ''} = {} } = e
    this.setState({ email: value})
    if (this.state.isEmailVerified) {
      this.setState({ isEmailVerified: false, otpHeaderTitle: null })
    }
  }

  verifyOtp = (e: any) => {
    e.preventDefault();
    if (this.state.otp.length === 4) {
      const header = {
        "Content-Type": configJSON.contentTypeApiAddDetail
      };

      const attrs = {
        otp_code: this.state.otp,
        token: this.verifyEmailToken
      };

      const data = {
        attributes: attrs
      };

      const httpBody = {
        data: data
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.emailOtpVerifyApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.emailOtpVerifyApiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    } else {
      this.setState({ otpErrorMessage: "* Please enter 4 digit OTP" })
    }
  }

  handleOtpChange = (otp: string) => {
    this.setState({ otp: otp });
    if (otp.length === 4) {
      this.setState({ otpErrorMessage: null })
    }
  }

  ResendOtpRequest = () => {
    if (this.state.countdown === 0) {
      this.handleVerifyEmailApiCall(this.state.email)
    }
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
        if (this.state.countdown === 0) {
          clearInterval(this.timer as number | undefined);
          if (this.state.otp.length === 0) {
            this.setState({ otpHeaderTitle: "Please enter the OTP you received, if not please resend" });
          }
        }
      })
    }, 1000);
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  handleShowHidePassword = () => {
    this.setState({ enablePasswordField: !this.state.enablePasswordField })
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Customizable Area End
}
