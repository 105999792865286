import React, { useCallback, useEffect, useState, useRef } from "react";
import { Box } from '@material-ui/core'


const Timer = (props:any) => {
    const {initialMinute = 0,initialSeconds = 0, codeHandler, resendCode, handleTimerZero} = props;
    const [minutes, setMinutes ] = useState(Number(initialMinute));
    const [seconds, setSeconds ] =  useState(Number(initialSeconds));
    const myInterval = useRef<any>(null);

    const resetTimer = useCallback(()  => {
        setMinutes(Number(initialMinute));
        setSeconds(Number(initialSeconds));
    }, [initialMinute, initialSeconds])
    
    useEffect(()=>{
        myInterval.current = setInterval(() => {
            if (minutes === 0 && seconds === 0) {
                codeHandler();
                handleTimerZero(0);
                if (myInterval.current !== null) {
                    clearInterval(myInterval.current); // Clear the interval only if it's not null
                }
            } else {
                if (seconds === 0) {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                    handleTimerZero(59) // Reset seconds to 59 when minutes decrement
                } else {
                    setSeconds(seconds - 1);
                    handleTimerZero(seconds - 1);
                }
            }
        }, 1000);

        // Clear the interval when the component unmounts or dependencies change
        return () => {
            if (myInterval.current !== null) {
                    clearInterval(myInterval.current); // Clear the interval only if it's not null
                }
        };
    }, [minutes, seconds, codeHandler, handleTimerZero, initialMinute, initialSeconds]);
          

    useEffect(()=> {
        if(resendCode)
        {
            resetTimer();
        }
    }, [resendCode, resetTimer]);

    
    return (
        <Box component="span">
        {  
            // minutes === 0 && seconds === 0
            // ? ""
            // : 
            <span style={{ color : '#233D4D', fontFamily: 'Inter', fontSize: '14px', fontWeight: 500}}>{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span> 
        }
        </Box>
    )
}

export default Timer;