import React from "react";
// Customizable Area Start
import { NavigateNext } from '@material-ui/icons';
import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class PaymentMethod extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            <div className="payment-wrapper">
                {/* Customizable Area Start */}
                <div className="payment-item">
                    <div className="payment-title">
                        <h2>Choose payment method</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis malesuada augue eget nulla vestibum
                            varius.
                            Sed tristique finibus augue, vitae congue enim rhoncus eu. Sed ultricies, ante a efficitur rutr</p>
                    </div>
                    <div className="payment-content">
                        <div className="payment-block">
                            <a href="#">
                                <p>Payment method 1</p>
                                <NavigateNext />
                            </a>
                        </div>
                        <div className="payment-block">
                            <a href="#">
                                <p>Payment method 2</p>
                                <NavigateNext />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="payment-button">
                    <button type="button" className="btn button" >Make payment</button>
                </div>
                {/* Customizable Area End */}
            </div>
        );
    }
}

// Customizable Area Start
// Customizable Area End
