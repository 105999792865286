import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { convertToAmPm, getAuthToken, getRelativeDateLabel, getTimeDifference } from "../../../components/src/utils";
import ApiRequest from "../../../components/src/ApiRequest";
import { AuthContext } from "../../user-profile-basic/src/AuthContext.web";
import { MouseEventHandler } from "react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
export interface IChatListItem {
  userId: string;
  userPhoto: string;
  userName: string;
  read?: boolean;
  lastMessageTime?: string;
  lastMessage?: string;
}

export interface IProcessedMessage {
  [key: string]: {
    date: string;
    messages: {
      message: string;
      time: string;
      from: "sender" | "receiver";
    }[]
  }
}

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChatListItem[];
  receiverInfo: IChatListItem,
  chatAllMessage: IProcessedMessage,
  activeTab: any;
  sendMessageData: string;
  message: string;
  isVisibleModal: boolean;
  errorMsg: string;
  isMobile : boolean;
  isChatClicked : boolean,
  currentUserId: number,
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  getChatMessageApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  createSendChatMessageApiCallId = "";
  static contextType:any = AuthContext;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      receiverInfo: {userId: '', userName: '', userPhoto: ''},
      chatAllMessage: {},
      sendMessageData: "",
      message: '',
      isVisibleModal: false,
      activeTab: null,
      errorMsg: '',
      isMobile : false,
      isChatClicked :false,
      currentUserId: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.checkIfMobile();

    // Add resize event listener
    window.addEventListener('resize', this.checkIfMobile);

    const result = await getAuthToken();
    this.setState({token: result})

  }

  async componentDidUpdate(prevProps: {}, prevState: S) {
    console.log("componentDidUpdate Calling")
    const { currentUser } = this.context;
    this.getUserInput(currentUser, prevState.currentUserId)
  }

  initializeData = async (userId: number) => {
      this.setState({ currentUserId: userId }, () => {
        this.getChatList();
      });
  }

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string| null) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async () => {
    const token = await getAuthToken();
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Authorization": `Bearer ${token}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleNavigation = (receiver_id:number) => {
    this.setState({
      isChatClicked : true,
      activeTab: receiver_id
    }, () => {
      this.getChatMessage(this.state.activeTab)
    })
  }

 
  getChatMessage = async (activeTab: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      "Authorization": `Bearer ${this.state.token}`,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsMessageApiEndpoint + `${activeTab}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 

  sendChatMessage = async (activeTab: any) => {
    const header = {
      "Authorization": `Bearer ${this.state.token}`,
    };

    const formData = new FormData();
    if (this.state.sendMessageData !== "") {
      formData.append('message[body]', this.state.sendMessageData);
    }
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.sendMyChatsMessageApiEndpoint + `${activeTab}`,
      payload: formData,
      method: "POST",
    });

    this.createSendChatMessageApiCallId = requestMessage.messageId;

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  

  
  handleChatNameChange = (chatName: string) => {
    this.setState({ chatName });
  };
 
  async receive(from: string, message: Message) {
    const apiRequestCallIds = {
      [this.getChatListApiCallId]: this.handleChatListApiResponse,
      [this.getChatMessageApiCallId]: this.handleChatBoxMessageApiResponse,
      [this.createSendChatMessageApiCallId]: this.handleSendChatMessageResponse,
      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
  }

  // Response funtions
  handleChatListApiResponse = (responseJson: any, errorResponse: any) => {
    const { currentUserId } = this.state;
  
    if (responseJson.data.length > 0 && currentUserId !== undefined) {
      const chatItems: IChatListItem[] = responseJson.data
        .map((chatBox: any) => this.processChatBox(chatBox.attributes, currentUserId))
        .filter((item: IChatListItem | null) => item !== null) as IChatListItem[];
  
      this.setState({ chatList: chatItems }, () => {
        if (this.state.chatList.length > 0) {
          this.getChatMessage(this.state.chatList[0].userId);
        }
      });
    }
  };
  
  processChatBox = (attributes: any, currentUserId: number): IChatListItem | null => {
    const { sender_id, receiver_id, sender_name, receiver_name, sender_image_url, receiver_image_url, messages } = attributes;
  
    const numericSenderId = Number(sender_id);
    const numericCurrentUserId = Number(currentUserId);
  
    const isSender = numericSenderId === numericCurrentUserId;
    const userId = isSender ? receiver_id.toString() : sender_id.toString();
    const userName = isSender ? receiver_name : sender_name;
    const userPhoto = isSender ? receiver_image_url : sender_image_url;
  
    if (messages?.data && messages.data.length > 0) {
      const lastMessage = messages.data[messages.data.length - 1].attributes;
  
      return {
        userId,
        userPhoto,
        userName,
        lastMessageTime: lastMessage ? getTimeDifference(lastMessage.created_at) : "",
        lastMessage: lastMessage?.body,
        read: lastMessage?.read,
      };
    }
  
    return null;
  };
  

  handleChatBoxMessageApiResponse = (responseJson: any, errorResponse: any) => {
    const { currentUserId } = this.state;
    if (responseJson?.chat_box?.data) {
      const { chat_box } = responseJson;
      const { attributes } = chat_box.data;
      const { messages } = attributes;
      const numericCurrentUserId = Number(currentUserId);


      // finding receiver information
      const numericSenderId = Number(attributes.sender_id);
      let receiverInfo: IChatListItem = {userId: '', userName: '', userPhoto: ''};
      const { sender_id, receiver_id, sender_name, receiver_name, sender_image_url, receiver_image_url } = attributes;

      if (numericSenderId === numericCurrentUserId) {
        receiverInfo.userId = receiver_id.toString();
        receiverInfo.userName = receiver_name;
        receiverInfo.userPhoto = receiver_image_url;
      } else {
        receiverInfo.userId = sender_id.toString();
        receiverInfo.userName = sender_name;
        receiverInfo.userPhoto = sender_image_url;
      }

      const processedMessages: IProcessedMessage = {};

      messages?.data.forEach((message: any) => {
        const { attributes: msgAttributes } = message;
        const numericAccountId = Number(msgAttributes.account_id);
        const from = numericAccountId === numericCurrentUserId ? "sender" : "receiver";
        const date = getRelativeDateLabel(msgAttributes.current_date);
        const time = convertToAmPm(msgAttributes.current_time);

        if (!processedMessages[date]) {
          processedMessages[date] = {
            date,
            messages: []
          };
        }

        processedMessages[date].messages.push({
          message: msgAttributes.body,
          time,
          from,
        });
      });

      this.setState({
        receiverInfo,
        chatAllMessage: processedMessages,
        // activeTab: (!this.state.isMobile || this.state.isChatClicked) ? responseJson.chat_box.data.attributes.receiver_id : null
      })
        ;
    } else if(responseJson.errors.length > 0) {
        this.setState({
          errorMsg: responseJson.errors[0]
        })
      }
  }

  handleSendChatMessageResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson) {
      this.getChatMessage(this.state.activeTab)
      this.setState({
        sendMessageData: '',
      });
    }
  }
  
  checkIfMobile = () => {
    const isMobile = window.innerWidth <= 768;
    this.setState({ isMobile });
  }

  handleChatBackIcon = () =>{
    this.setState({
      activeTab : null
    })
  }

  getUserInput = async (currentUser: any, currentUserIdS: number) => {
    if (currentUser && currentUser.id && currentUser.id != currentUserIdS) {
      await this.initializeData(currentUser.id);
    }
  }
  handleNavigationCallBack = (receiver: IChatListItem): MouseEventHandler<HTMLDivElement> | undefined => {
    return () => this.handleNavigation(Number(receiver?.userId))
  }
  // Customizable Area End
}
