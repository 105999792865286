import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import { PersonOutline, Business, AccountBalance } from '@material-ui/icons'; // Import the required Material-UI icons
import { primaryColor } from './theme';

interface CustomStepIconProps extends StepIconProps {
  customIcons?: { [index: string]: string | React.ReactElement };
}

const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#fff',
      border: '1px solid #212426A6',
      zIndex: 1,
      color: '#212426A6',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundColor: primaryColor,
      // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      border: `1px solid ${primaryColor}`,
      color: '#fff',
    },
    completed: {
      backgroundColor: primaryColor,
      border: `1px solid ${primaryColor}`,
      color: '#fff',
    },
  });

function ColorlibStepIcon(props: CustomStepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, customIcons } = props;

  const defaultIcons: { [index: string]: React.ReactElement } = {
    1: <PersonOutline style={{marginBottom: '3px'}} />,
    2: <Business style={{marginBottom: '3px'}} />,
    3: <AccountBalance style={{marginBottom: '3px'}} />,
  };

  const icons = customIcons || defaultIcons;

  const icon =
    typeof icons[String(props.icon)] === 'string' ? (
      <img src={icons[String(props.icon)] as string} alt={`Step ${props.icon}`} />
    ) : (
      icons[String(props.icon)]
    );

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active || completed,
        [classes.completed]: completed,
      })}
    >
      {icon}
    </div>
  );
}

export default ColorlibStepIcon;
