import React from 'react';
import {
    Box,
    Typography,
    makeStyles,
    Modal,
    Button
} from '@material-ui/core';
import { Close } from "@material-ui/icons";
import { primaryColor } from './theme';

const useStyles = makeStyles((theme) => ({

    paperCancelTrip: {
        position: 'absolute',
        width: '40vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        // padding: '8px 10px',
        borderRadius: '16px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'initial'
    },
    modalContent: {
        position: 'relative',
        padding: '32px 40px', // Your desired padding value
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    closeButton: {
        position: 'absolute',
        top: '15px', // Adjust as needed
        right: '15px', // Adjust as needed
        cursor: 'pointer',
    },
    headerText: {
        fontFamily: 'Inter',
        fontSize: '24px',
        fontWeight: 600,
        color: '#212426',
        marginBottom: '16px'
    },
    bodyText: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        color: '#212426',
        marginBottom: '16px'
    },
    cancelBtnStyle: {
        backgroundColor: 'transparent',
        color: primaryColor,
        border: `1px solid ${primaryColor}`,
        textTransform: 'initial',
        borderRadius: '34px',
        // padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 500,
        width: '182px',
        height: '48px',
        whiteSpace: 'nowrap',
        
    },
    noCancelBtnStyle: {
        backgroundColor: primaryColor,
        color: '#ffffff',
        textTransform: 'initial',
        borderRadius: '34px',
        // padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 500,
        width: '182px',
        height: '48px',
        whiteSpace: 'nowrap',
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    [theme.breakpoints.down('md')]: {
        paperCancelTrip: {
            position: 'absolute',
            width: '450px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
            // padding: '8px 10px',
            borderRadius: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial'
        },
        modalContent: {
            position: 'relative',
            padding: '16px 20px', // Your desired padding value
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
        },
        headerText: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontWeight: 600,
            color: '#212426',
            marginBottom: '12px'
        },
        bodyText: {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            color: '#212426',
            marginBottom: '12px'
        },
        cancelBtnStyle: {
            backgroundColor: 'transparent',
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            textTransform: 'initial',
            borderRadius: '30px',
            // padding: '10px 50px',
            fontSize: '14px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '182px',
            height: '48px',
            whiteSpace: 'nowrap'
        },
        noCancelBtnStyle: {
            backgroundColor: primaryColor,
            color: '#ffffff',
            textTransform: 'initial',
            borderRadius: '34px',
            // padding: '10px 50px',
            fontSize: '14px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '182px',
            height: '48px',
            whiteSpace: 'nowrap'
        },
        closeButton: {
            position: 'absolute',
            top: '5px', // Adjust as needed
            right: '10px', // Adjust as needed
            cursor: 'pointer',
        },
       
    },
    [theme.breakpoints.down('sm')]: {
        paperCancelTrip: {
            position: 'absolute',
            width: '350px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
            // padding: '8px 10px',
            borderRadius: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial'
        },
        modalContent: {
            position: 'relative',
            padding: '16px 20px', // Your desired padding value
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
        },
        headerText: {
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 600,
            color: '#212426',
            marginBottom: '18px'
        },
        bodyText: {
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 400,
            color: '#212426',
            marginBottom: '8px'
        },
    }

}));

interface CancelTripModalProps {
    open: boolean;
    onClose: () => void;
    handleCancelTripCreation: () => void;
}

const CancelTripModal: React.FC<CancelTripModalProps> = ({
    open, onClose,
    handleCancelTripCreation
}) => {
    const classes = useStyles();

    return (
        <Modal open={open}>
            <div className={classes.paperCancelTrip}>
                <Box className={classes.modalContent}>
                    <Box className={classes.closeButton} onClick={onClose}>
                        <Close style={{ fontSize: '16px' }} />
                    </Box>
                    <Typography
                        className={classes.headerText}
                    >
                        Are you sure you want to cancel the creation of this trip?
                    </Typography>
                    <Typography
                        className={classes.bodyText}
                    >
                        Please be aware that all progress will be lost if you proceed with the cancellation.
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            gap: '30px',
                            justifyContent: 'flex-end'
                        }}
                    >
                        <Button
                            className={classes.cancelBtnStyle}
                            onClick={onClose}
                        >
                            Continue Editing
                        </Button>
                        <Button
                            className={classes.noCancelBtnStyle}
                            onClick={() => handleCancelTripCreation()}
                        >
                            Cancel Trip
                        </Button>
                    </Box>

                </Box>
            </div>
        </Modal>
    );
};

export default CancelTripModal;
