import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Radio,
    Typography,
    Grid,
    Box,
    Divider,
    makeStyles
} from '@material-ui/core';
import { primaryColor } from './theme';
import CustomTextField from './CustomTextField';
import { IStep3CET } from '../../blocks/ProductDescription/src/Interface.web';
import { FormikErrors, FormikTouched } from 'formik';
import SingleDatePicker from './SingleDatePicker';


interface CustomGroupPreferenceProps {
    values: IStep3CET;
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<IStep3CET>;
    touched: FormikTouched<IStep3CET>;
}



const CustomGroupPreference: React.FC<CustomGroupPreferenceProps> = ({
    values,
    setFieldValue,
    errors,
    touched
}) => {
    const useStyles = makeStyles((theme) => ({
        labelText: { 
            fontFamily: 'Inter', 
            fontSize: '18px', 
            fontWeight: 500, 
            lineHeight: '150%', 
            color: 'rgba(33, 36, 38, 0.90)' 
        },
        [theme.breakpoints.down(1024)]: {
            labelText: { 
                fontFamily: 'Inter', 
                fontSize: '16px', 
                fontWeight: 500, 
                lineHeight: '150%', 
                color: 'rgba(33, 36, 38, 0.90)' 
            },
        },
        [theme.breakpoints.down(768)]: {
            labelText: { 
                fontFamily: 'Inter', 
                fontSize: '14px', 
                fontWeight: 500, 
                lineHeight: '150%', 
                color: 'rgba(33, 36, 38, 0.90)' 
            },
        }
    }));

    const classes = useStyles();

    const handleCheckboxChange = (value: string) => {

        setFieldValue('group_preference', value); // Send the updated array of selected values
    };

    const toggleCheckbox = (currentValue: string) => {
        handleCheckboxChange(currentValue === values.group_preference ? '' : currentValue);
    };

    return (
        <FormControl component="fieldset" fullWidth>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                    // style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'rgba(33, 36, 38, 0.90)' }}
                    key={'1'}
                    control={
                        <Radio
                            size='small'
                            style={{
                                color: values.group_preference === 'private' ? primaryColor : '#212426',
                            }}
                            checked={values.group_preference === 'private'}
                            onChange={() => toggleCheckbox('private')}
                        />
                    }
                    label={
                        <Typography
                           className={classes.labelText}
                        >
                            Private
                        </Typography>
                    }
                />
            </div>
            {
                values.group_preference === 'private'  && (
                    <Grid container style={{marginTop: '16.39px', marginBottom: '20px'}}>
                        <Grid item md={3} sm={2}>
                            {" "}
                        </Grid>
                        <Grid item md={9} sm={10} xs={12}>
                            <Grid container spacing={2}>

                            <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Minimum Seats'}
                                        placeholder={'Enter value'}
                                        type='number'
                                        value={values.minimum_seats as string}
                                        fieldName="minimum_seats"
                                        onChange={setFieldValue}
                                        error={errors.minimum_seats as string}
                                        touched={touched.minimum_seats as boolean}
                                    />

                                </Grid>

                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Maximum Seats'}
                                        placeholder={'Enter value'}
                                        type='number'
                                        value={values.maximum_seats as string}
                                        fieldName="maximum_seats"
                                        onChange={setFieldValue}
                                        error={errors.maximum_seats as string}
                                        touched={touched.maximum_seats as boolean}
                                    />

                                </Grid>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }


            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                    // style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'rgba(33, 36, 38, 0.90)' }}
                    key={'1'}
                    control={
                        <Radio
                        size='small'
                            style={{
                                color: values.group_preference === 'public' ? primaryColor : '#212426',
                            }}
                            checked={values.group_preference === 'public'}
                            onChange={() => toggleCheckbox('public')}
                        />
                    }
                    label={
                        <Typography
                        className={classes.labelText}
                        >
                            Public
                        </Typography>
                    }
                />
            </div>

            {
                values.group_preference === 'public' && (
                    <Grid container style={{marginTop: '16px'}}>
                       <Grid item md={3} sm={2}>
                            {" "}
                        </Grid>
                        <Grid item md={9} sm={10} xs={12}>
                            <Grid item md={6} xs={12}>
                                <CustomTextField
                                        fieldTitle={'Maximum Seats'}
                                        placeholder={'Enter value'}
                                        type='number'
                                        value={values.maximum_seats as string}
                                        fieldName="maximum_seats"
                                        onChange={setFieldValue}
                                        error={errors.maximum_seats as string}
                                        touched={touched.maximum_seats as boolean}
                                    />

                            </Grid>
                        </Grid>
                    </Grid> )
            }

        </FormControl>
    );
};

export default CustomGroupPreference;
