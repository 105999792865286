import React from "react";
import {
    Box,
    Typography,
    Divider,
    Avatar,
    Grid
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { capitalizeFirstLetter, formatMonthDateYear } from "./utils";
import { TripDates } from "../../blocks/ordermanagement/src/BookingListController.web";



interface TripBLCProps {
    tripDates: {
        [key: string]: TripDates
    }
    status: string;
}


// Function to get status color
const getStatusColor = (status: string): string => {
    switch (status) {
        case 'upcoming':
            return '#34B47D';
        case 'confirmed':
            return '#34B47D';
        case 'removed':
            return '#FFAE10';
        case 'completed':
            return '#909090';
        case 'cancelled':
            return '#E71C1F';
        default:
            return '#909090';
    }
};

const TripBookingListContainer: React.FC<TripBLCProps> = ({
    tripDates, status
}) => {
    const useStyles = makeStyles((theme) => ({
        mainBox: {
            borderTop: `4px solid ${getStatusColor(status.toLowerCase())}`, // Change border color based on status
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
            border: '1px solid grey',
            borderRadius: '10px'
        },
        userBox: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        avatar: {
            marginRight: theme.spacing(1),
            height: '25px',
            width: '25px',
        },
        itemText: {
            fontFamily: "Inter",
            fontSize: '16px',
            fontWeight: 500
        },
        titleText: {
            color: `${getStatusColor(status.toLowerCase())}`, // Change border color based on status
            marginBottom: theme.spacing(2),
            fontSize: '18px',
            fontFamily: 'Inter',
            fontWeight: 500
        },
        listHeadingTxt: {
            // marginBottom: theme.spacing(1),
            fontSize: '14px',
            fontFamily: 'Inter',
            fontWeight: 500
        },
        dateBox: {
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            '&::before': {
              content: '""',
              display: 'block',
              height: '1px', // Adjust the height as needed
              backgroundColor: '#ccc', // Adjust the color as needed
              flex: 1,
              marginRight: '10px', // Adjust the spacing before the date
            },
            '&::after': {
              content: '""',
              display: 'block',
              height: '1px', // Adjust the height as needed
              backgroundColor: '#ccc', // Adjust the color as needed
              flex: 1,
              marginLeft: '10px', // Adjust the spacing after the date
            },
          },
        
        [theme.breakpoints.down(1024)]: {
            itemText: {
                fontSize: '14px',
            },
        },
        [theme.breakpoints.down(768)]: {
            itemText: {
                fontSize: '14px',
            },
        }
    }));

    const classes = useStyles();


    return (
        <Box className={classes.mainBox}>
            <Typography className={classes.titleText}>{capitalizeFirstLetter(status)}</Typography>
            <Divider style={{ marginBottom: '10px' }} />
            {
                Object.keys(tripDates)?.length > 0 ?
                Object.keys(tripDates).map((tripDateKey) => {
                    const tripDate = tripDates[tripDateKey]
                    const tripData = Object.keys(tripDate.data);
                    return (
                        <Box key={tripDate.date}>
                            <div className={classes.dateBox}>
                                <Typography className={classes.listHeadingTxt}>{formatMonthDateYear(tripDate.date)}</Typography>
                            </div>
                            <Grid container spacing={1} justifyContent="space-between">
                                {
                                    tripData.map((tripDateKey) => {
                                        const tripStatus = tripDate.data[tripDateKey];
                                        return (
                                            <Grid item md={tripData.length > 1 ? 6 : 12} key={tripDateKey}>
                                                <Box style={{ border: '1px solid grey', padding: '15px', borderRadius: '10px' }}>
                                                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <Typography style={{color: getStatusColor(tripStatus.sub_status.toLowerCase())}} className={classes.listHeadingTxt}>Total {capitalizeFirstLetter(tripStatus.sub_status)}</Typography>
                                                        <Typography style={{color: getStatusColor(tripStatus.sub_status.toLowerCase())}} className={classes.listHeadingTxt}>{tripStatus.count}</Typography>

                                                    </Box>
                                                    <Divider style={{margin: '10px 0'}}/>
                                                    {
                                                        tripStatus.userList.map((user) => {
                                                            return (<Box className={classes.userBox} key={user.name}>
                                                                <Avatar alt={'ab'} src={user.profileImg} className={classes.avatar} />
                                                                <Typography style={{color: '#8B8A8D', fontWeight: 400}} className={classes.itemText}>{user.name}</Typography>
                                                            </Box>)
                                                        })
                                                    }
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                    )
                }) : <Typography className={classes.listHeadingTxt}>No User Found</Typography>
            }
        </Box>
    );
};

export default TripBookingListContainer;
