import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { primaryColor } from './theme';
import { makeStyles } from '@material-ui/core/styles'

export interface OptionCheckboxFilter {
  label: string;
  noOfTrips: number;
  value: string;
  selected: boolean;
}

interface CustomCheckboxFilterPops {
    options: OptionCheckboxFilter[];
    title: string;
    stateName: string;
    handleCheckboxChange: (options: OptionCheckboxFilter[], name: any) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionPadding: {
    boxShadow: 'none',
  },
  accordionContentMargin: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0'
    }
  },

}));


const CustomCheckboxFilter: React.FC<CustomCheckboxFilterPops> = ({options, title, stateName, handleCheckboxChange}) => {
  const classes = useStyles();
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const handleOptionChange = (index: number) => {
    const updatedOptions = options.map((option, i) => {
      if (i === index) {
        return { ...option, selected: !option.selected };
      }
      return option;
    });
    handleCheckboxChange(updatedOptions, stateName);
  };

  const toggleAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };
  return (
    <div>
     <Box maxWidth={"300px"}>
     <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
        <AccordionSummary className={classes.accordionContentMargin}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
            <Typography>{title}</Typography>
            <IconButton>
              {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {options.map((option, index) => (
                <div key={option.label} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          color: option.selected ? primaryColor : '#212426',
                        }}
                        checked={option.selected}
                        onChange={() => handleOptionChange(index)}
                      />
                    }
                    label={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Inter' }}>
                        <div>
                          {option.label}
                        </div>
                      </div>
                    }
                  />
                  <div>
                    <Typography>{option.noOfTrips}</Typography>
                  </div>
                </div>
              ))}
            </div>
        </AccordionDetails>
      </Accordion>
     </Box>
    </div>
  );
};


export default CustomCheckboxFilter;
