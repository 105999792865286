
import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from 'formik'
import { primaryColor } from "../theme";
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import { ErrorOutline, Check } from '@material-ui/icons';
import { TextField, Typography, Button, Box, InputAdornment } from "@material-ui/core";
import { cameraIcon, uploadCloudImg } from "../../../blocks/email-account-registration/src/assets";
import { BuddyEntityDetailsValues } from "../../../blocks/email-account-registration/src/Interface.web";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig, useWindowWidth } from "../utils";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useAuth } from "../../../blocks/user-profile-basic/src/AuthContext.web";


interface EntityDetailsProps {
  initialState: BuddyEntityDetailsValues;
  submitEntityDetails: (values: any) => void;
  handleBack: (values: any) => void;
  isEditClicked : boolean
  traveleAgencyEditRequest
  : (value:any) => void
}

function checkIfFileIsPdfType(files?: any): boolean {
  if (!files) return true;
  return files && files.type === 'application/pdf';
}

const validationSchema = Yup.object().shape({
  buddy_profile_picture: Yup.mixed()
    .test('is-correct-file', '* Please upload a JPG, JPEG, or PNG file', checkIfFilesAreCorrectType)
    .test('is-big-file', '* Please choose a file less than 5 mb', checkIfFilesAreTooBig),
  buddy_name: Yup.string().required("* Please enter your buddy display name"),
  id_number: Yup.string().required("* Please enter ID number"),
  commercialPdf: Yup.mixed()
    .test('is-correct-file', '* Please upload a valid file', checkIfFileIsPdfType)
    .test('is-big-file', '* Please choose a file less than 5 mb', checkIfFilesAreTooBig),
  tourismPdf: Yup.mixed()
    .test('is-correct-file', '* Please upload a valid file', checkIfFileIsPdfType)
    .test('is-big-file', '* Please choose a file less than 5 mb', checkIfFilesAreTooBig),
  permitPdf: Yup.mixed()
    .test('is-correct-file', '* Please upload a valid file', checkIfFileIsPdfType)
    .test('is-big-file', '* Please choose a file less than 5 mb', checkIfFilesAreTooBig)
});

const useStyles = makeStyles((theme) => ({
  customFont: {
    fontFamily: 'Inter',
    fontWeight: 400,
    marginTop: '8px',
    letterSpacing: 0
  },
  toastModal: {
    borderRadius: '8px',
    display: 'inline-flex',
    height: '45px',
    alignItems: 'center',
    gap: '12px',
    flexShrink: 0,
    backgroundColor: '#FFF2F2',
    padding: '8px 16px 8px 12px',
    marginTop: '32px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      background: 'red',
      width: '4px',
      height: '100%',
      top: '0',
      left: '0',
      borderRadius: '8px 0 0 8px !important',
    }
  },
  isVisible: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  }
}));

const theme = createTheme({
  overrides: {
    MuiTypography: {
      body1: {
        letterSpacing: 0,
        fontFamily: 'Inter',
      }
    }
  },
});

function deleteKeysFromObject(
  values: { [key: string]: any },
  errors: { [key: string]: any },
  initialState: BuddyEntityDetailsValues
) {
  for (const key in errors) {
    if (errors.hasOwnProperty(key) && values.hasOwnProperty(key)) {
      delete values[key];
    }
  }
  return { ...initialState, ...values }
}

const FileUploadLabel = ({ label, isMobile }: { label: string, isMobile: boolean }) => {
  return <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
    <Typography
      style={{
        fontSize: "16px",
        color: "rgb(33 36 38 / 65%)",
        marginBottom: "8px",
        fontWeight: 500,
        fontStyle: 'normal',
        lineHeight: '150%',
        flexGrow: 1
      }}
    >
      {label}
    </Typography>
    <Box style={{
      gap: '4px', marginBottom: "0.6rem",
      display: 'flex', flexWrap: 'wrap',
      alignItems: 'end', alignContent: 'end',
      justifyContent: 'end',
    }}>
    <Typography
      style={{
        fontSize: "14px",
        color: "rgb(33 36 38 / 65%)",
        fontStyle: 'normal',
        lineHeight: '150%'
      }}
    >
      (Format : PDF)
    </Typography>
      <Typography
        style={{
          fontSize: "14px",
          color: "rgb(33 36 38 / 65%)",
          fontStyle: 'normal',
          lineHeight: '150%'
        }}
      >
        (Max size : 5 MB)
      </Typography>
    </Box>
  </Box>
}

const EntityDetails = ({ initialState, submitEntityDetails, traveleAgencyEditRequest, handleBack, isEditClicked = false }: EntityDetailsProps) => {
  const classes = useStyles();
  const windowWidth: number = useWindowWidth();
  // Define your responsive logic based on windowWidth
  const isMobile: boolean = windowWidth < 768;

  const normalLine = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23C3CAD9' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  const errorLine = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23FF0000' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
  const { currentUser } = useAuth()
  const [isDocUpload, setDocUpload] = useState(false)

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          // width: isMobile ? '100%' : "77%", // Adjust the width as needed
          // margin: "0 auto", // Center the div horizontally
          padding: "20px",
        }}
      >
        {!isEditClicked &&
          <Box>
            <Typography style={{ fontSize: '32px', marginTop: '30px', fontWeight: 500, color: '#212426' }}>Entity Details</Typography>
          </Box>
        }
        <Formik
          enableReinitialize={true}
          initialValues={initialState}
          test-id="submitButtonId"
          onSubmit={(values: any) => {
            isEditClicked
              ? traveleAgencyEditRequest(values)
              : !isDocUpload
                ? submitEntityDetails(values)
                : undefined
          }}

          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => {
            console.log(values, "123//valuessssssss")
            const isDefaultImg = values.buddy_profile_picture
              && typeof values.buddy_profile_picture === 'object'
              && !errors.buddy_profile_picture

            const buddy_profile_picture = isDefaultImg && values.buddy_profile_picture
              ? URL.createObjectURL(values.buddy_profile_picture)
              : cameraIcon

            return (
              <form onSubmit={(e) => {
                e.preventDefault();
                if (!values.commercialPdf && !values.tourismPdf && !values.permitPdf) {
                  setDocUpload(true);
                }
                handleSubmit(e)
              }}>
                {isEditClicked ?
                  <Box sx={{ display: 'flex', gridGap: '10px' }}>
                    <Box sx={{ display: 'flex', borderRight: '1px dashed', flexDirection: 'column', padding: '0px 40px' }}>

                      <Box style={{ marginTop: '30px', display: 'flex', gap: '7px', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            fontWeight: 500
                          }}
                        >
                          Profile Picture
                        </Typography>

                      </Box>

                      <Box style={{ marginTop: '16px' }}>
                        <Box style={{ display: 'flex', gap: '32px', flexDirection: 'column' }}>
                          <Box>
                            <Typography style={{
                              height: '120px', width: '120px', display: "flex", alignItems: 'center',
                              borderRadius: '50%', background: 'rgb(247, 247, 247)', justifyContent: 'center',
                              border: '1px solid #C3CAD9', padding: '0', overflow: 'hidden', cursor: 'pointer'
                            }} component={"label"} htmlFor="profilePic">
                              <img src={values?.buddy_profile_picture} alt="Profile Image"
                                style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                              <input type="file" id="profilePic" name="buddy_profile_picture" hidden
                                placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                                onChange={(e) => {
                                  const selectedFile = e.target.files && e.target.files[0];
                                  if (selectedFile) {
                                    setFieldValue('buddy_profile_picture', selectedFile);
                                  }
                                }} />
                            </Typography>
                          </Box>
                          <Box style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', gridGap: '10px' }}>
                              <Box>
                                <CloudUploadOutlinedIcon style={{
                                  color: primaryColor,
                                }} />
                              </Box>
                              <Typography style={{
                                color: primaryColor,
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                lineHeight: '150%',
                                cursor: "pointer",
                                marginBottom: "2px"
                              }} component="label" htmlFor="profilePic">
                                Replace
                              </Typography>
                            </Box>
                            <Typography style={{
                              color: '#F00',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                              marginTop: '8px'
                            }}>{errors.buddy_profile_picture}</Typography>
                          </Box>
                        </Box>
                      </Box>

                    </Box>
                    <Box>

                      <Box style={{ marginTop: '32px' }}>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            marginBottom: "8px",
                            fontWeight: 500
                          }}
                        >
                          Buddy Display Name <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter buddy display name"
                            variant="standard"
                            name="buddy_name"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("buddy_name", e.target.value);
                              touched.buddy_name = true;
                            }}
                            error={
                              Boolean(errors.buddy_name) && touched?.buddy_name
                            }
                            helperText={
                              touched?.buddy_name ? errors?.buddy_name : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.buddy_name}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.buddy_name && touched.buddy_name ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>


                      <Box style={{ marginTop: '32px' }}>
                        <Box style={{ marginBottom: '8px', display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              color: "rgb(33 36 38 / 65%)",
                              fontWeight: 500
                            }}
                          >
                            About Me
                          </Typography>
                          <Typography
                            style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '16px',
                              fontStyle: 'normal',
                            }}
                          >
                            (Optional)
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Write something about you"
                            variant="standard"
                            name="about_me"
                            multiline={true}
                            minRows={5}
                            maxRows={10}
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("about_me", e.target.value);
                              touched.about_me = true;
                            }}
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.about_me}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '32px' }}>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            marginBottom: "8px",
                            fontWeight: 500
                          }}
                        >
                          ID Number<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter ID number"
                            variant="standard"
                            name="id_number"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("id_number", e.target.value);
                              touched.id_number = true;
                            }}
                            error={
                              Boolean(errors?.id_number) && touched.id_number
                            }
                            helperText={
                              touched.id_number ? errors?.id_number : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.id_number}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.id_number && touched.id_number ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '32px' }}>
                        <Box style={{ marginBottom: '8px', display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "rgb(33 36 38 / 65%)",
                              fontWeight: 500
                            }}
                          >
                            VAT Number
                          </Typography>
                          <Typography
                            style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '16px',
                              fontStyle: 'normal',
                            }}
                          >
                            (Optional)
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter VAT number"
                            variant="standard"
                            name="vat_number"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("vat_number", e.target.value);
                              touched.vat_number = true;
                            }}
                            error={
                              Boolean(errors?.vat_number) && touched.vat_number
                            }
                            helperText={
                              touched.vat_number ? errors?.vat_number : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.vat_number}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.vat_number && touched.vat_number ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '80px', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            color: 'rgba(33, 36, 38, 0.90)',
                            fontFamily: 'Inter',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '130%'
                          }}
                        >
                          Legal Documents<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Typography
                          style={{
                            color: 'rgba(33, 36, 38, 0.65)',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '150%',
                            display: 'flex',
                            alignItems: 'end'
                          }}
                        >
                          (One or more of the below must be attached)
                        </Typography>
                      </Box>

                      {isDocUpload && <Box className={classes.toastModal}>
                        <Typography style={{ fontSize: '14px', color: '#FF0000' }}>Please upload at least one of the documents below</Typography>
                      </Box>}

                      <Box style={{ marginTop: '32px' }}>
                        <FileUploadLabel label='Commercial Registration Document' isMobile={isMobile} />
                        <Box style={{
                          display: 'flex',
                          ...isMobile
                            ? {
                              gap: '0',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }
                            : {
                              gap: '10px',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            },
                          width: '100%'
                        }}>

                          <TextField
                            style={{ width: "100%", marginTop: "8px", }}
                            placeholder="Commercial Registration Document"
                            variant="standard"
                            name="commercialPdf"
                            autoComplete="off"
                            className="email_input"
                            onDragOver={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              const droppedFiles = e.dataTransfer.files?.[0];
                              if (droppedFiles) {
                                setFieldValue('commercialPdf', droppedFiles);
                                setDocUpload(false)
                                touched.commercialPdf = true;
                              }
                            }}
                            error={
                              Boolean(errors?.commercialPdf) && touched.commercialPdf as boolean
                            }
                            helperText={
                              touched.commercialPdf ? errors?.commercialPdf as string : ""
                            }
                            FormHelperTextProps={{ className: classes.customFont, }}
                            value={!errors.commercialPdf ? values?.commercialPdf?.name : ""}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }
                            }}
                            InputProps={{
                              startAdornment: !values.commercialPdf?.name
                                || values.commercialPdf?.name && errors.commercialPdf ? (
                                <InputAdornment position='start'>
                                  <img src={uploadCloudImg} />
                                </InputAdornment>
                              ) : null,
                              placeholder: !values.commercialPdf?.name
                                || values.commercialPdf?.name && errors.commercialPdf ?
                                'Browse or drag and drop file to upload' : "",
                              endAdornment: values.commercialPdf?.name && !errors?.commercialPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                    <Typography style={{ color: '#6F964C', fontSize: '14px' }} className={classes.isVisible}>Uploaded successfully</Typography>
                                  </div>
                                </InputAdornment>
                              ) : values.commercialPdf?.name && errors?.commercialPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                    <Typography style={{ color: '#FF0000', fontSize: '14px' }} className={classes.isVisible}>Upload failed</Typography>
                                  </div>
                                </InputAdornment>
                              ) : null,
                              style: {
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `none`,
                                height: '56px',
                                width: '100%',
                                ...errors?.commercialPdf && touched.commercialPdf
                                  ? {
                                    backgroundImage: errorLine,
                                    backgroundColor: "#FFF8F8",
                                  }
                                  : {
                                    backgroundImage: normalLine,
                                    backgroundColor: "#F7F7F7",
                                  },
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                          <Button
                            component="label"
                            style={{
                              fontFamily: 'Inter', borderRadius: '8px',
                              fontSize: '16px', fontWeight: 500, marginTop: "8px",
                              width: isMobile ? '50%' : '22%', height: '56px',
                              textTransform: 'initial', border: `1px solid ${primaryColor}`, color: primaryColor
                            }}
                          >
                            Browse file
                            <input
                              type="file"
                              hidden
                              name="commercialPdf"
                              onChange={(e) => {
                                const selectedFile = e.target.files?.[0];
                                if (selectedFile) {
                                  setFieldValue('commercialPdf', selectedFile);
                                  touched.commercialPdf = true;
                                  setDocUpload(false)
                                }
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: '32px' }}>
                        <FileUploadLabel label='Tourism Certificate' isMobile={isMobile} />
                        <Box style={{
                          display: 'flex',
                          ...isMobile
                            ? {
                              gap: '0',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }
                            : {
                              gap: '10px',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            },
                          width: '100%'
                        }}>

                          <TextField
                            style={{ width: "100%", marginTop: "8px", }}
                            placeholder="Certificate File"
                            variant="standard"
                            name="tourismPdf"
                            autoComplete="off"
                            className="email_input"
                            onDragOver={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              const droppedFiles = e.dataTransfer.files?.[0];
                              if (droppedFiles) {
                                setFieldValue('tourismPdf', droppedFiles);
                                setDocUpload(false)
                                touched.tourismPdf = true;
                              }
                            }}
                            error={
                              Boolean(errors?.tourismPdf) && touched.tourismPdf as boolean
                            }
                            helperText={
                              touched.tourismPdf ? errors?.tourismPdf as string : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={!errors.tourismPdf ? values?.tourismPdf?.name : ""}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }
                            }}
                            InputProps={{
                              startAdornment: !values.tourismPdf?.name
                                || values.tourismPdf?.name && errors?.tourismPdf ? (
                                <InputAdornment position='start'>
                                  <img src={uploadCloudImg} />
                                </InputAdornment>
                              ) : null,
                              placeholder: !values.tourismPdf?.name
                                || values.tourismPdf?.name && errors?.tourismPdf
                                ? 'Browse or drag and drop file to upload' : "",
                              endAdornment: values.tourismPdf?.name && !errors?.tourismPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                    <Typography style={{ color: '#6F964C', fontSize: '14px' }} className={classes.isVisible}>Uploaded successfully</Typography>
                                  </div>
                                </InputAdornment>
                              ) : values.tourismPdf?.name && errors?.tourismPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                    <Typography style={{ color: '#FF0000', fontSize: '14px' }} className={classes.isVisible}>Upload failed</Typography>
                                  </div>
                                </InputAdornment>
                              ) : null,
                              style: {
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `none`,
                                height: '56px',
                                width: '100%',
                                ...errors?.tourismPdf && touched.tourismPdf
                                  ? {
                                    backgroundImage: errorLine,
                                    backgroundColor: "#FFF8F8",
                                  }
                                  : {
                                    backgroundImage: normalLine,
                                    backgroundColor: "#F7F7F7",
                                  },
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                          <Button
                            component="label"
                            style={{
                              fontFamily: 'Inter', borderRadius: '8px', fontSize: '16px',
                              fontWeight: 500, width: isMobile ? '50%' : '22%',
                              height: '56px', textTransform: 'initial', marginTop: "8px",
                              border: `1px solid ${primaryColor}`, color: primaryColor
                            }}
                          >
                            Browse file
                            <input
                              type="file"
                              hidden
                              name="tourismPdf"
                              onChange={(e) => {
                                const selectedFile = e.target.files?.[0];
                                if (selectedFile) {
                                  setFieldValue('tourismPdf', selectedFile);
                                  touched.tourismPdf = true;
                                  setDocUpload(false)
                                }
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                     
                      <Box style={{ marginTop: '48px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                        {
                          !isEditClicked ? <Button
                            style={{
                              backgroundColor: primaryColor,
                              color: '#ffffff',
                              textTransform: 'initial',
                              borderRadius: '34px',
                              padding: '10px 50px',
                              fontSize: '16px',
                              fontFamily: "Inter",
                              fontWeight: 500,
                              width: '182px',
                              height: '56px',
                            }}
                            type="submit"
                          >
                            Next
                          </Button> :
                            <Box sx={{ display: 'flex', gridGap: '20px' }}>
                              <Button
                                style={{
                                  backgroundColor: primaryColor,
                                  color: '#ffffff',
                                  textTransform: 'initial',
                                  borderRadius: '34px',
                                  padding: '10px 60px',
                                  fontSize: '16px',
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  width: '182px',
                                  height: '56px',
                                  whiteSpace: 'nowrap'
                                }}
                                type="submit"
                              >
                                Save Changes
                              </Button>
                              <Button
                                style={{
                                  color: primaryColor,
                                  textTransform: 'initial',
                                  borderRadius: '34px',
                                  padding: '10px 50px',
                                  fontSize: '16px',
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  width: '182px',
                                  height: '56px',
                                }}
                                onClick={handleBack}
                              >
                                Cancel
                              </Button>
                            </Box>
                        }
                      </Box>
                    </Box>
                  </Box>
                  :
                  <>
                    <Box>
                      <Box style={{ marginTop: '30px', display: 'flex', gap: '7px', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            fontWeight: 500
                          }}
                        >
                          Profile Picture
                        </Typography>
                        <Typography
                          style={{
                            color: 'rgba(33, 36, 38, 0.65)',
                            fontSize: '16px',
                            fontStyle: 'normal',
                          }}
                        >
                          (Optional)
                        </Typography>
                      </Box>

                      <Box style={{ marginTop: '16px' }}>
                        <Box style={{ display: 'flex', gap: '32px' }}>
                          <Box>
                            <Typography style={{
                              height: '120px', width: '120px', display: "flex", alignItems: 'center',
                              borderRadius: '50%', background: 'rgb(247, 247, 247)', justifyContent: 'center',
                              border: '1px solid #C3CAD9', padding: '0', overflow: 'hidden', cursor: 'pointer'
                            }} component={"label"} htmlFor="profilePic">
                              <img src={buddy_profile_picture} alt="Profile Image"
                                style={{ ...isDefaultImg ? { height: '120px' } : {}, objectFit: 'cover' }} />
                              <input type="file" id="profilePic" name="buddy_profile_picture" hidden
                                placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                                onChange={(e) => {
                                  const selectedFile = e.target.files && e.target.files[0];
                                  if (selectedFile) {
                                    setFieldValue('buddy_profile_picture', selectedFile);
                                  }
                                }} />
                            </Typography>
                          </Box>
                          <Box style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
                            <Typography style={{
                              color: primaryColor,
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              lineHeight: '150%',
                              cursor: "pointer",
                              marginBottom: "2px"
                            }} component="label" htmlFor="profilePic">
                              {isDefaultImg ? `Update` : `Add`} profile picture
                            </Typography>
                            <Typography style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              lineHeight: '150%',
                            }}>(Format : JPG, JPEG and PNG)</Typography>
                            <Typography style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              lineHeight: '150%',
                            }}>(Max size : 5 MB)</Typography>
                            <Typography style={{
                              color: '#F00',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                              marginTop: '8px'
                            }}>{errors.buddy_profile_picture}</Typography>
                          </Box>
                        </Box>
                      </Box>


                      <Box style={{ marginTop: '32px' }}>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            marginBottom: "8px",
                            fontWeight: 500
                          }}
                        >
                          Buddy Display Name <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter buddy display name"
                            variant="standard"
                            name="buddy_name"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("buddy_name", e.target.value);
                              touched.buddy_name = true;
                            }}
                            error={
                              Boolean(errors.buddy_name) && touched?.buddy_name
                            }
                            helperText={
                              touched?.buddy_name ? errors?.buddy_name : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.buddy_name}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.buddy_name && touched.buddy_name ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>


                      <Box style={{ marginTop: '32px' }}>
                        <Box style={{ marginBottom: '8px', display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                          <Typography
                            style={{
                              fontFamily: "Inter",
                              fontSize: "16px",
                              color: "rgb(33 36 38 / 65%)",
                              fontWeight: 500
                            }}
                          >
                            About Me
                          </Typography>
                          <Typography
                            style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '16px',
                              fontStyle: 'normal',
                            }}
                          >
                            (Optional)
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Write something about you"
                            variant="standard"
                            name="about_me"
                            multiline={true}
                            minRows={5}
                            maxRows={10}
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("about_me", e.target.value);
                              touched.about_me = true;
                            }}
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.about_me}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '32px' }}>
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontSize: "16px",
                            color: "rgb(33 36 38 / 65%)",
                            marginBottom: "8px",
                            fontWeight: 500
                          }}
                        >
                          ID Number<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter ID number"
                            variant="standard"
                            name="id_number"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("id_number", e.target.value);
                              touched.id_number = true;
                            }}
                            error={
                              Boolean(errors?.id_number) && touched.id_number
                            }
                            helperText={
                              touched.id_number ? errors?.id_number : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.id_number}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.id_number && touched.id_number ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ marginTop: '32px' }}>
                        <Box style={{ marginBottom: '8px', display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                          <Typography
                            style={{
                              fontSize: "16px",
                              color: "rgb(33 36 38 / 65%)",
                              fontWeight: 500
                            }}
                          >
                            VAT Number
                          </Typography>
                          <Typography
                            style={{
                              color: 'rgba(33, 36, 38, 0.65)',
                              fontSize: '16px',
                              fontStyle: 'normal',
                            }}
                          >
                            (Optional)
                          </Typography>
                        </Box>
                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                          <TextField
                            style={{ width: "100%" }}
                            placeholder="Enter VAT number"
                            variant="standard"
                            name="vat_number"
                            autoComplete="off"
                            className="email_input"
                            onChange={(e) => {
                              setFieldValue("vat_number", e.target.value);
                              touched.vat_number = true;
                            }}
                            error={
                              Boolean(errors?.vat_number) && touched.vat_number
                            }
                            helperText={
                              touched.vat_number ? errors?.vat_number : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={values.vat_number}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%'
                              }
                            }}
                            InputProps={{
                              style: {
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `1px solid`,
                                borderColor: errors?.vat_number && touched.vat_number ? 'red' : '#C3CAD9',
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>
                      </Box>

                      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '80px', flexWrap: 'wrap' }}>
                        <Typography
                          style={{
                            color: 'rgba(33, 36, 38, 0.90)',
                            fontFamily: 'Inter',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '130%'
                          }}
                        >
                          Legal Documents<span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <Typography
                          style={{
                            color: 'rgba(33, 36, 38, 0.65)',
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '150%',
                            display: 'flex',
                            alignItems: 'end'
                          }}
                        >
                          (One or more of the below must be attached)
                        </Typography>
                      </Box>

                      {isDocUpload && <Box className={classes.toastModal}>
                        <Typography style={{ fontSize: '14px', color: '#FF0000' }}>Please upload at least one of the documents below</Typography>
                      </Box>}

                      <Box style={{ marginTop: '32px' }}>
                        <FileUploadLabel label='Commercial Registration Document' isMobile={isMobile} />
                        <Box style={{
                          display: 'flex',
                          ...isMobile
                            ? {
                              gap: '0',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }
                            : {
                              gap: '10px',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            },
                          width: '100%'
                        }}>

                          <TextField
                            style={{ width: "100%", marginTop: "8px", }}
                            placeholder="Commercial Registration Document"
                            variant="standard"
                            name="commercialPdf"
                            autoComplete="off"
                            className="email_input"
                            onDragOver={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              const droppedFiles = e.dataTransfer.files?.[0];
                              if (droppedFiles) {
                                setFieldValue('commercialPdf', droppedFiles);
                                setDocUpload(false)
                                touched.commercialPdf = true;
                              }
                            }}
                            error={
                              Boolean(errors?.commercialPdf) && touched.commercialPdf as boolean
                            }
                            helperText={
                              touched.commercialPdf ? errors?.commercialPdf as string : ""
                            }
                            FormHelperTextProps={{ className: classes.customFont, }}
                            value={!errors.commercialPdf ? values?.commercialPdf?.name : ""}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }
                            }}
                            InputProps={{
                              startAdornment: !values.commercialPdf?.name
                                || values.commercialPdf?.name && errors.commercialPdf ? (
                                <InputAdornment position='start'>
                                  <img src={uploadCloudImg} />
                                </InputAdornment>
                              ) : null,
                              placeholder: !values.commercialPdf?.name
                                || values.commercialPdf?.name && errors.commercialPdf ?
                                'Browse or drag and drop file to upload' : "",
                              endAdornment: values.commercialPdf?.name && !errors?.commercialPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                    <Typography style={{ color: '#6F964C', fontSize: '14px' }} className={classes.isVisible}>Uploaded successfully</Typography>
                                  </div>
                                </InputAdornment>
                              ) : values.commercialPdf?.name && errors?.commercialPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                    <Typography style={{ color: '#FF0000', fontSize: '14px' }} className={classes.isVisible}>Upload failed</Typography>
                                  </div>
                                </InputAdornment>
                              ) : null,
                              style: {
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `none`,
                                height: '56px',
                                width: '100%',
                                ...errors?.commercialPdf && touched.commercialPdf
                                  ? {
                                    backgroundImage: errorLine,
                                    backgroundColor: "#FFF8F8",
                                  }
                                  : {
                                    backgroundImage: normalLine,
                                    backgroundColor: "#F7F7F7",
                                  },
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                          <Button
                            component="label"
                            style={{
                              fontFamily: 'Inter', borderRadius: '8px',
                              fontSize: '16px', fontWeight: 500, marginTop: "8px",
                              width: isMobile ? '50%' : '22%', height: '56px',
                              textTransform: 'initial', border: `1px solid ${primaryColor}`, color: primaryColor
                            }}
                          >
                            Browse file
                            <input
                              type="file"
                              hidden
                              name="commercialPdf"
                              onChange={(e) => {
                                const selectedFile = e.target.files?.[0];
                                if (selectedFile) {
                                  setFieldValue('commercialPdf', selectedFile);
                                  touched.commercialPdf = true;
                                  setDocUpload(false)
                                }
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: '32px' }}>
                        <FileUploadLabel label='Tourism Certificate' isMobile={isMobile} />
                        <Box style={{
                          display: 'flex',
                          ...isMobile
                            ? {
                              gap: '0',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }
                            : {
                              gap: '10px',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            },
                          width: '100%'
                        }}>

                          <TextField
                            style={{ width: "100%", marginTop: "8px", }}
                            placeholder="Certificate File"
                            variant="standard"
                            name="tourismPdf"
                            autoComplete="off"
                            className="email_input"
                            onDragOver={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              const droppedFiles = e.dataTransfer.files?.[0];
                              if (droppedFiles) {
                                setFieldValue('tourismPdf', droppedFiles);
                                setDocUpload(false)
                                touched.tourismPdf = true;
                              }
                            }}
                            error={
                              Boolean(errors?.tourismPdf) && touched.tourismPdf as boolean
                            }
                            helperText={
                              touched.tourismPdf ? errors?.tourismPdf as string : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={!errors.tourismPdf ? values?.tourismPdf?.name : ""}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }
                            }}
                            InputProps={{
                              startAdornment: !values.tourismPdf?.name
                                || values.tourismPdf?.name && errors?.tourismPdf ? (
                                <InputAdornment position='start'>
                                  <img src={uploadCloudImg} />
                                </InputAdornment>
                              ) : null,
                              placeholder: !values.tourismPdf?.name
                                || values.tourismPdf?.name && errors?.tourismPdf
                                ? 'Browse or drag and drop file to upload' : "",
                              endAdornment: values.tourismPdf?.name && !errors?.tourismPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                    <Typography style={{ color: '#6F964C', fontSize: '14px' }} className={classes.isVisible}>Uploaded successfully</Typography>
                                  </div>
                                </InputAdornment>
                              ) : values.tourismPdf?.name && errors?.tourismPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                    <Typography style={{ color: '#FF0000', fontSize: '14px' }} className={classes.isVisible}>Upload failed</Typography>
                                  </div>
                                </InputAdornment>
                              ) : null,
                              style: {
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `none`,
                                height: '56px',
                                width: '100%',
                                ...errors?.tourismPdf && touched.tourismPdf
                                  ? {
                                    backgroundImage: errorLine,
                                    backgroundColor: "#FFF8F8",
                                  }
                                  : {
                                    backgroundImage: normalLine,
                                    backgroundColor: "#F7F7F7",
                                  },
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                          <Button
                            component="label"
                            style={{
                              fontFamily: 'Inter', borderRadius: '8px', fontSize: '16px',
                              fontWeight: 500, width: isMobile ? '50%' : '22%',
                              height: '56px', textTransform: 'initial', marginTop: "8px",
                              border: `1px solid ${primaryColor}`, color: primaryColor
                            }}
                          >
                            Browse file
                            <input
                              type="file"
                              hidden
                              name="tourismPdf"
                              onChange={(e) => {
                                const selectedFile = e.target.files?.[0];
                                if (selectedFile) {
                                  setFieldValue('tourismPdf', selectedFile);
                                  touched.tourismPdf = true;
                                  setDocUpload(false)
                                }
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                      <Box style={{ marginTop: '32px' }}>
                        <FileUploadLabel label='Freelance Permit' isMobile={isMobile} />
                        <Box style={{
                          display: 'flex',
                          ...isMobile
                            ? {
                              gap: '0',
                              flexDirection: 'column',
                              alignItems: 'flex-end',
                            }
                            : {
                              gap: '10px',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                            },
                          width: '100%'
                        }}>

                          <TextField
                            style={{ width: "100%", marginTop: "8px", }}
                            placeholder="Feelance Permit"
                            variant="standard"
                            name="permitPdf"
                            autoComplete="off"
                            className="email_input"
                            onDragOver={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                            }}
                            onDrop={(e) => {
                              e.preventDefault()
                              e.stopPropagation();
                              const droppedFiles = e.dataTransfer.files?.[0];
                              if (droppedFiles) {
                                setFieldValue('permitPdf', droppedFiles);
                                setDocUpload(false)
                                touched.permitPdf = true;
                              }
                            }}
                            error={
                              Boolean(errors?.permitPdf) && touched.permitPdf as boolean
                            }
                            helperText={
                              touched.permitPdf ? errors?.permitPdf as string : ""
                            }
                            FormHelperTextProps={{
                              className: classes.customFont,
                            }}
                            value={!errors.permitPdf ? values?.permitPdf?.name : ""}
                            inputProps={{
                              style: {
                                height: 'auto',
                                padding: 0,
                                lineHeight: '150%',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                              }
                            }}
                            InputProps={{
                              startAdornment: !values.permitPdf?.name
                                || values.permitPdf?.name && errors?.permitPdf ? (
                                <InputAdornment position='start'>
                                  <img src={uploadCloudImg} />
                                </InputAdornment>
                              ) : null,
                              placeholder: !values.permitPdf?.name
                                || values.permitPdf?.name && errors?.permitPdf
                                ? 'Browse or drag and drop file to upload' : "",
                              endAdornment: values.permitPdf?.name && !errors?.permitPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                    <Typography style={{ color: '#6F964C', fontSize: '14px' }} className={classes.isVisible}>Uploaded successfully</Typography>
                                  </div>
                                </InputAdornment>
                              ) : values.permitPdf?.name && errors?.permitPdf ? (
                                <InputAdornment position='end'>
                                  <div style={{ display: 'flex', gap: '5px' }}>
                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                    <Typography style={{ color: '#FF0000', fontSize: '14px' }} className={classes.isVisible}>Upload failed</Typography>
                                  </div>
                                </InputAdornment>
                              ) : null,
                              style: {
                                color: "rgba(33, 36, 38, 0.90)",
                                padding: "15px 20px",
                                border: `none`,
                                height: '56px',
                                width: '100%',
                                ...errors?.permitPdf && touched.permitPdf
                                  ? {
                                    backgroundImage: errorLine,
                                    backgroundColor: "#FFF8F8",
                                  }
                                  : {
                                    backgroundImage: normalLine,
                                    backgroundColor: "#F7F7F7",
                                  },
                                borderRadius: '5px',
                                fontFamily: "Inter",
                                fontSize: '16px',
                                fontWeight: 500
                              },
                              disableUnderline: true,
                              readOnly: true,
                            }}
                          />
                          <Button
                            component="label"
                            style={{
                              fontFamily: 'Inter', borderRadius: '8px', fontSize: '16px',
                              fontWeight: 500, width: isMobile ? '50%' : '22%',
                              height: '56px', textTransform: 'initial', marginTop: "8px",
                              border: `1px solid ${primaryColor}`, color: primaryColor
                            }}
                          >
                            Browse file
                            <input
                              type="file"
                              hidden
                              name="permitPdf"
                              onChange={(e) => {
                                const selectedFile = e.target.files?.[0];
                                if (selectedFile) {
                                  setFieldValue('permitPdf', selectedFile);
                                  touched.permitPdf = true;
                                  setDocUpload(false)
                                }
                              }}
                            />
                          </Button>
                        </Box>
                      </Box>
                    
                      <Box style={{ marginTop: '48px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                        {
                          !isEditClicked ? <Button
                            style={{
                              backgroundColor: primaryColor,
                              color: '#ffffff',
                              textTransform: 'initial',
                              borderRadius: '34px',
                              padding: '10px 50px',
                              fontSize: '16px',
                              fontFamily: "Inter",
                              fontWeight: 500,
                              width: '182px',
                              height: '56px',
                            }}
                            type="submit"
                          >
                            Next
                          </Button> :
                            <Box sx={{ display: 'flex', gridGap: '20px' }}>
                              <Button
                                style={{
                                  backgroundColor: primaryColor,
                                  color: '#ffffff',
                                  textTransform: 'initial',
                                  borderRadius: '34px',
                                  padding: '10px 60px',
                                  fontSize: '16px',
                                  fontFamily: "Inter",
                                  fontWeight: 500,
                                  width: '182px',
                                  height: '56px',
                                  whiteSpace: 'nowrap'
                                }}
                                type="submit"
                              >
                                Save Changes
                              </Button>
                           
                            </Box>
                        }
                      </Box>
                    </Box>
                  </>}
              </form>)
          }}
        </Formik>
      </div >
    </ThemeProvider >
  )
}

export default EntityDetails;
