import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import ApiRequest from "../../../components/src/ApiRequest";
import { getAuthToken } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardNumber: string;
  cardHolderName: string;
  expiration: string;
  securityCode: string;
  paymentToken: string;
  trip_id: string;
  trip_schedule_id: string;
  quantity: string;
  paymentStatus: boolean;
  paymentResponse: any;
  responseModal: boolean;
  responseStatusModal:boolean;
  responseMsg:string;
  isSuccess_updateBooking:boolean;
  existingTrip : any,
  isEdit :boolean
  cardId: number;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdminConsoleController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetPaymentToken: string = "";
  apiCallIdsubmitPayment: string = "";
  updateBookingApiCallId : string = "";
  deleteCartItemApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardNumber: '',
      cardHolderName: '',
      expiration: '',
      securityCode: '',
      paymentToken: '',
      trip_id: '',
      trip_schedule_id: '',
      quantity: '',
      paymentStatus: false,
      paymentResponse: {},
      responseModal: false,
      responseStatusModal:false,
      responseMsg: "",
      isSuccess_updateBooking:false,
      existingTrip : {},
      isEdit :false,
      cardId: 0,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdGetPaymentToken]: this.getPaymentTokenResponse,
      [this.apiCallIdsubmitPayment]: this.getSubmitPaymentResponse,
      [this.updateBookingApiCallId] :this.handleUpdateBookingResponse,
      [this.deleteCartItemApiCallId]: this.deleteCartItem,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  // life-cycle method
  async componentDidMount() {
    const { pathname } = window.location;
    /* istanbul ignore next */
    if (pathname.startsWith('/MakePayment/')) {
      const {trip_id, trip_shcedule_id, noOfPeople , existingTrip , isEdit} = this.getParamValueMP();
      this.setState({
        trip_id: trip_id,
        trip_schedule_id: trip_shcedule_id,
        quantity: noOfPeople,
        existingTrip : existingTrip,
        isEdit : isEdit
      })
    }
  }
// Request function
getPaymentTokenRequest = () => {
  const {cardNumber, expiration, securityCode} = this.state;
  const [expiryMonth, expiryYear] = expiration.split('/');

  // Convert the strings to numbers
  const numericMonth = parseInt(expiryMonth, 10);
  const numericYear = parseInt(expiryYear, 10);

  // Take the last two digits of the year and make it a full year
  const fullYear = new Date().getFullYear();
  const fourDigitYear = parseInt((fullYear + '').slice(0, 2) + numericYear, 10);

  // Remove spaces from the input value
  const unformattedCardNumber = cardNumber.replace(/\s/g, '');

  const payload = { 
    "type": "card", 
    "number": unformattedCardNumber, 
    "expiry_month": numericMonth, 
    "expiry_year": fourDigitYear, 
    "cvv": securityCode, 
    "phone": {}, 
    "preferred_scheme": "", 
    "requestSource": "JS" 
  }
  const authToken = "pk_sbox_gw262xxhmwiv3hi2eglhwnxbjet";
    const header = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.paymentTokenEndpoint,
      payload: JSON.stringify(payload),
      method: "POST",
    });
    this.apiCallIdGetPaymentToken = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

submitPaymentRequest = async () => {
  const {trip_id, trip_shcedule_id, noOfPeople} = this.getParamValueMP()
  const formdata = new FormData();
   // Helper function to append a field and value to formdata if the value exists
   const appendFieldIfValueExists = (field: string, value: any) => {
    if (value) {
      formdata.append(field, value);
    }
  };
  // Append fields with values if they exist
  appendFieldIfValueExists('trip_id', trip_id);
  appendFieldIfValueExists('trip_schedule_id', trip_shcedule_id);
  appendFieldIfValueExists('quantity', noOfPeople);
  appendFieldIfValueExists('type', "booking");
  appendFieldIfValueExists('card_token', this.state.paymentToken);


  const authToken = await getAuthToken();
  const header = {
    Authorization: `Bearer ${authToken}`,
  };
  const endpoint = `${configJSON.paymentAPiEndPoint}`;

  const requestMessage = ApiRequest({
    header,
    endPoint: endpoint,
    payload: formdata,
    method: 'POST',
  });
  this.apiCallIdsubmitPayment = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

 handleDeleteCartItem = async () => {
  const authToken = await getAuthToken();
  const { cardId } = this.getParamValueMP();
  const header = {
    Authorization: `Bearer ${authToken}`,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.deleteCartItemApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.deleteApiMethod
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_order_management/orders/${cardId}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

/* istanbul ignore next */
handleUpdateBooking = async()=>{
  const {trip_id, trip_shcedule_id, noOfPeople ,  existingTrip} = this.getParamValueMP()
  const orderID = existingTrip?.id
  const formdata = new FormData();
   // Helper function to append a field and value to formdata if the value exists
  //  const appendFieldValueExists = (field: string, value: any) => {
  //   if (value) {
  //     formdata.append(field, value);
  //   }
  // };
  // Append fields with values if they exist
  formdata.append('trip_id', trip_id);
  formdata.append('type', "update_booking");
  formdata.append('card_token', this.state?.paymentToken);
  formdata.append('trip_schedule_id', trip_shcedule_id);
  formdata.append('quantity', noOfPeople);

  
  
  const authToken = await getAuthToken();
  const header = {
    Authorization: `Bearer ${authToken}`,
  };
  const endpoint = `bx_block_order_management/orders/${orderID}`;
  
  const requestMessage = ApiRequest({
    header,
    endPoint: endpoint,
    payload: formdata,
    method: 'PUT',
  });
  this.updateBookingApiCallId = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage)
     }

// Response function

getPaymentTokenResponse = (responseJson: any, errorReponse: any) => {
  console.log(responseJson , "errorReponse")
  if(responseJson.token) {
    localStorage.setItem("paymentToken" ,responseJson.token )
    this.setState({paymentToken: responseJson.token ,
 }, () => {
      this.state?.isEdit ?  this.handleUpdateBooking(): this.submitPaymentRequest()
})
  } else {
    this.setState({
      responseModal: true,
    })
  }
}

getSubmitPaymentResponse = (responseJson: any, errorResponse: any) => {
  console.log(responseJson , "reposnejasonnn")
  this.setState({ 
    paymentStatus: !!responseJson?.data?.data, 
    paymentResponse: responseJson 
  }, () => {
    this.gotoPaymentStatusPage()});
    console.log(this.state.paymentResponse, "paymentResponse")

    const { cardId } = this.getParamValueMP();
    if (cardId > 0) {
      this.handleDeleteCartItem();
      setTimeout(() => {
        const msg: Message = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "LandingPage" //route name as per navigation
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
        window.location.reload();
      }, 4000);
    }
  
}

deleteCartItem = (responseJson: any, errorResponse: any) => {};

// Internal function
gotoPaymentStatusPage = () => {
  const payload = {
    responseJson: this.state.paymentResponse,
    paymentStatus: this.state.paymentStatus
  }
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "PaymentStatus"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    payload,
  });

  message.addData(
    getName(MessageEnum.NavigationRaiseMessage),
    raiseMessage
  );
  this.send(message);
}
handleCardNumberChange = (event: any) => {
  const inputCardNumber = event.target.value;
 // Remove non-numeric characters
 const numericValue = inputCardNumber.replace(/\D/g, '');

 // Format the numeric value with spaces every four digits
 const formattedCardNumber = numericValue.replace(/(\d{4})/g, '$1 ').trim();
  this.setState({
    cardNumber: formattedCardNumber
  });
};

handleCardHolderNameChange = (event: any) => {
  const inputCardHolderName = event.target.value;
  this.setState({
    cardHolderName: inputCardHolderName
  });
};

handleExpirationChange = (event: any) => {
  const inputExpiration = event.target.value;
  this.setState({
    expiration: this.formatExpiration(inputExpiration)
  });
};

handleSecurityChange = (event: any) => {
  const inputSecurityCard = event.target.value;
  this.setState({
    securityCode: inputSecurityCard
  });
};

formatExpiration = (inputExpiration: string) => {
  // Remove non-numeric characters
  const numericValue = inputExpiration.replace(/\D/g, '');

  // Take only the first 4 characters (MMYY)
  const formattedValue = numericValue.slice(0, 4);

  // Extract month and year
  const month = formattedValue.slice(0, 2);
  const year = formattedValue.slice(2, 4);

  // Concatenate and return in MM/YY format
  return formattedValue.length >= 3 ? `${month}/${year}` : formattedValue;
};

getParamValueMP = () => {
  const trip_id = this.props.navigation.getParam("trip_id");
  const trip_shcedule_id = this.props.navigation.getParam("trip_schedule_id");
  const noOfPeople = this.props.navigation.getParam("noOfPeople");
  const cardId = this.props.navigation.getParam("cardId");
  const existingTrip = this.props.navigation.history.location?.state?.existingTrip
  const isEdit = this.props.navigation.history.location?.state?.isEdit
  return {trip_id, trip_shcedule_id, noOfPeople ,existingTrip , isEdit , cardId }
}

onClose = () => {
  this.setState({responseModal: false ,
  responseStatusModal:false ,
})
}

/* istanbul ignore next */
handleUpdateBookingResponse= (responseJson: any, errorResponse: any)=>{
  if(responseJson && responseJson?.data)
  {
    this.setState({
      paymentResponse: responseJson,
      paymentStatus: !!responseJson?.data?.data, 
      responseMsg: "your booking has updated succesfully", 
      isSuccess_updateBooking :true        
    } , ()=> {this.gotoPaymentStatusPage()})
  }else{
    this.setState({
      responseStatusModal:true,
      responseMsg: responseJson?.errors, 
      isSuccess_updateBooking :false        
    })
  }
}



  // Customizable Area End
}
