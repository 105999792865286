import React from "react";

// Customizable Area Start
import {
 Avatar, Card, TextField,
  Box, List, Divider, ListItemAvatar,
  Typography, ListItem, ListItemText
} from "@material-ui/core";
import { ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { arrowRightCircle } from "./assets";
import { muiTheme } from "../../ordermanagement/src/MyBookings.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  


  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { chatList, isMobile, activeTab, receiverInfo, chatAllMessage } = this.state
   
    return (
      <ThemeProvider theme={muiTheme}>
        <Box style={{ minHeight: '50vh' }} className={classes.containerSP}>
          <Typography style={{
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: 'normal',
            letterSpacing: '0.1px',
            marginBottom: '32px'
          }}>
            Chats
          </Typography>
          <Box display={'flex'} gridGap={'24px'}>

            {(!isMobile || !activeTab) && <Card  className={classes.chatCardSP} data-testid="chatList">
              <List>
                {chatList?.map((receiver, index) => {

                  return (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider style={{ margin: '24px 0' }} />}
                      <ListItem key={index} button onClick={this.handleNavigationCallBack(receiver)} className={classes.chatContainer}>
                        <ListItemAvatar>
                          <Avatar src={receiver.userPhoto} alt={receiver.userName} classes={{ img: classes.avatarImg, root: classes.avatarImg }} />
                        </ListItemAvatar>
                        <ListItemText
                          classes={{ multiline: classes.chatInfo }}
                          primary={<>
                            <Typography className={classes.username}>{receiver.userName}</Typography>
                            <Typography className={classes.userMsgTime}>{receiver.lastMessageTime}</Typography>
                          </>}
                          secondary={<>
                            <Typography className={`${classes.lastMessage} ${receiver.read ? '' : 'isUnread'}`}>
                              {receiver.lastMessage}</Typography>
                            {receiver.read && <span className={classes.unreadCountText}>{1}</span>}
                          </>}
                          primaryTypographyProps={{ display: undefined, classes: { root: classes.primaryRoot } }}
                          secondaryTypographyProps={{ display: undefined, noWrap: true, classes: { root: classes.secondaryRoot } }}
                        />
                      </ListItem>
                    </React.Fragment>
                  )
                })}
              </List>
            </Card>}


            {
              (!isMobile || activeTab) && <Card className={classes.chatmessageCardSP} data-testid="chatList">
                <Box style={{
                  padding: '24px 48px',
                  borderBottom: '1px solid rgba(33, 36, 38, 0.10)',
                  background: '#FFF',
                  display: 'flex',
                  gap: '16px'
                }} >
                  {isMobile && <span onClick={this.handleChatBackIcon} className={classes.backIconSP} data-testid='backIcon'><ArrowBackIcon /></span>}
                  {/* @ts-ignore */}
                  <Avatar src={receiverInfo.userPhoto} alt={'userName'} styles={{ img: styles.avatarImg, root: styles.avatarImg }} />
                  <Box display={'flex'} gridGap={'6px'} flexDirection={'column'}>
                    <Typography className={classes.ActiveUserName}>
                      {/* @ts-ignore */}
                      {receiverInfo.userName}
                    </Typography>
                    <Typography className={classes.activeStatus}>
                      Active now
                    </Typography>
                  </Box>
                </Box>

                <div className={classes.msgBoxScrollFSP}>
                  <div className={classes.chatMsgContainer}>
                    <Typography className={classes.messageDate}>
                    </Typography>
                    {Object.keys(chatAllMessage).length > 0 &&
                      Object.keys(chatAllMessage).map((dateKey) => {
                        const filteredItem = chatAllMessage[dateKey]
                        const item = filteredItem.messages;
                        return (
                          <React.Fragment>
                            <Typography className={classes.messageDate}>
                              {filteredItem.date}
                            </Typography>
                            {
                              item.map((chat, i) => (
                                chat.from === 'receiver'
                                  ? <div className={`${classes.messageContent} receiver`} key={i}>
                                    <div className={`${classes.messageStyle} ${classes.receiver}`}>
                                      {chat.message}
                                    </div>
                                    <div className={classes.timeStyle}>
                                      {chat.time}
                                    </div>
                                  </div> :
                                  <div className={`${classes.messageContent} sender`} key={i}>
                                    <div className={`${classes.messageStyle} ${classes.sender}`}>
                                      {chat.message}
                                    </div>
                                    <div className={classes.timeStyle} style={{ textAlign: 'end' }}>
                                      {chat.time}
                                    </div>
                                  </div>
                              ))
                            }
                          </React.Fragment>
                        )
                      })}
                  </div>
                </div>
                <Box className={classes.chatFooter}>
                  <TextField
                    placeholder="Write a message..."
                    type="text"
                    name="message"
                    data-test-id={'writeMessageInput'}
                    value={this.state.sendMessageData}
                    onChange={(event) => this.setState({ sendMessageData: event.target.value })}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.sendChatMessage(activeTab);
                      }
                    }}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <img style={{ cursor: 'pointer' }} data-test-id={'sumbitIconBtn'} onClick={() => this.sendChatMessage(activeTab)} src={arrowRightCircle} alt='submit' />
                      ),
                      classes: {
                        input: classes.input,
                        root: classes.inputRoot
                      },
                      disableUnderline: true
                    }}
                  />
                </Box>
              </Card>
            }


          </Box>
        </Box>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  modalContainer: {
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  modalButtonView: {
    marginLeft: 10,
  },
  mutedChat: {
    backgroundColor: "lightgray"
  },
};

const styles = (theme: Theme) => ({
  avatarImg: {
    width: '54px',
    height: '54px',
  },
  username: {
    flex: '1 0 0',
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  userMsgTime: {
    color: 'rgba(33, 36, 38, 0.40)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  lastMessage: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
    flex: '1 0 0',
    '&.isUnread': {
      fontWeight: 500,
      color: 'rgba(33, 36, 38, 0.50)'
    }
  },
  chatContainer: {
    display: 'flex',
    gap: '16px',
    cursor: 'pointer'
  },
  primaryRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px'
  },
  chatInfo: {
    gap: '6px',
    display: 'flex',
    flexDirection: 'column' as const,
    margin: '0',
  },
  unreadCountText: {
    width: '20px',
    height: '20px',
    backgroundColor: '#FE7F2D',
    borderRadius: '50%',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 6px',
  },
  secondaryRoot: {
    display: 'flex'
  },
  ActiveUserName: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  activeStatus: {
    color: 'rgba(33, 36, 38, 0.75)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  chatFooter: {
    display: 'inline-flex',
    height: '120px',
    padding: '24px 48px 56px 48px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '491px',
    borderTop: '1px solid rgba(33, 36, 38, 0.10)',
    background: '#FFF'
  },
  msgBoxScrollFSP: {
    overflowY: 'scroll' as const,
    padding: '0 20px',
    margin: '0',
    gap: '4px',
    height: '500px'
  },
  chatMsgContainer: {
    display: 'flex',
    flexDirection: 'column' as const,
    flex: '1 0 0',
    justifyContent: 'end',
  },
  messageStyle: {
    display: 'inline-flex',
    flexDirection: 'column' as const,
    gap: '8px',
    padding: '12px 16px',
    border: '0.5px solid rgba(33, 36, 38, 0.10)',
    background: '#FFF',
    wordWrap: 'break-word' as const,
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
  },
  sender: {
    borderRadius: '16px 0px 16px 16px',
  },
  receiver: {
    borderRadius: '0px 16px 16px 16px',
  },
  timeStyle: {
    color: 'rgba(33, 36, 38, 0.65)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
  },
  messageContent: {
    display: 'flex',
    flexDirection: 'column' as const,
    gap: '8px',
    maxWidth: '47%',
    marginTop: '8px',
    '&.sender': {
      alignSelf: 'end',
    },
    '&.receiver': {
      alignSelf: 'start'
    },
    [theme.breakpoints.down(768)] : {
      maxWidth : '90%'
    }
  },
  messageDate: {
    color: 'rgba(33, 36, 38, 0.75)',
    fontSize: '14px',
    lineHeight: 'normal',
    margin: '0 auto 16px'
  },
  inputRoot: {
    alignItems: 'flex-start'
  },
  input: {
    padding: 0
  },
  
  subContainer: {
    margin: '69px auto 140px',
    maxWidth: '90%',
    width: '100%',
    
    [theme.breakpoints.down(768)]:{
      marginTop: '-24px',
      width : '100%'
    },
    [theme.breakpoints.down(1024)]:{
      margin: '69px auto 0px',
    },
  },
  containerSP: {
    margin: '49px auto 120px',
    width: '100%' ,
    maxWidth: '90%',
    [theme.breakpoints.down(768)] : {
      marginTop : '20px',
      marginBottom : '15px'
    }
  },
  chatCardSP : {
    borderRadius: '20px',
    boxShadow: 'none',
    background: '#FFF',
    padding: '32px 48px',
    width: '39.08%',
    height: '817px',
    overflowY: 'scroll' as const,
    border: '1px solid rgba(33, 36, 38, 0.1)',

    [theme.breakpoints.down(1024)] : {
      width: '80.08%',
    },
    [theme.breakpoints.down(768)] : {
      width: '100%',
      height : 'auto',
      padding : '0 0',
      overflowX : 'scroll'
    }
  },
  chatmessageCardSP: {
    borderRadius: '20px',
    background: '#FCFAF7',
    boxShadow: 'none',
    width: '766px',
    display: 'flex',
    flexDirection: 'column' as const,
    border: '1px solid rgba(33, 36, 38, 0.1)',
    height: '817px',

    gap: '32px',
    [theme.breakpoints.down(768)] : {
      width : '100%'
    }
  },
  
  backIconSP: {
    cursor : 'pointer',
    margin : '5px 0px 0px -30px' ,
  },
})

const ChatWeb = withStyles(styles)(Chat)
export { ChatWeb }
// Customizable Area End
