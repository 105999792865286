Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";

exports.btnExampleTitle = "CLICK ME";

exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'chat/chats/search_messages';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'bx_block_notifications/chat_boxes';
exports.getMyChatsMessageApiEndpoint = 'bx_block_notifications/chat_boxes/:current_user?participent2=';
exports.sendMyChatsMessageApiEndpoint = 'bx_block_notifications/chat_boxes/:current_user/messages?participent2=';
exports.getTermsAndConditionEngpoint = 'bx_block_content_management/terms_and_conditions';
exports.getPrivacyPolicyEndpoint = 'bx_block_content_management/privacy_policies';
exports.getAboutusEndpoint = 'bx_block_content_management/about_us';

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";
exports.myChatsSubText = "Mange and review your conversations effortlessly."
  
// Customizable Area End