import React, { Dispatch, SetStateAction, useState } from 'react';
import Slider from 'react-slick';
import { Box } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
const shareImg = require("./share-outline.svg");
import './Navbar.css';
import { useWindowWidth } from './utils';

interface Props {
    slides: string[],
    handleShare?: () => void;
}

export default function SliderNavFor(props: Props) {
    const { slides, handleShare } = props;
    const [nav1, setNav1]: [Slider | undefined, Dispatch<SetStateAction<undefined | Slider>>] = useState();
    const [nav2, setNav2]: [Slider | undefined, Dispatch<SetStateAction<undefined | Slider>>] = useState();

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    return (
        <div style={{ display: 'flex', maxWidth: '632px', gap: '31.92px', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
            <Slider
                vertical={!isMobile}
                asNavFor={nav1}
                swipeToSlide={true}
                focusOnSelect={true}
                className='listing-Carousel'
                slidesToScroll={1}
                speed={500}
                slidesToShow={slides.length > 3 ? 4 : slides.length}
                ref={(slider2) => setNav2(slider2 ? slider2 : undefined)}
            >
                {slides.map((item: string, index: number) => {
                    return <span className='list-image'> <img src={item} alt={item} key={index} /> </span>
                })}
            </Slider>
            <Slider
                asNavFor={nav2}
                speed={500}
                className='expandedView-Carousel'
                nextArrow={<Box><KeyboardArrowRight /></Box>}
                prevArrow={<Box><KeyboardArrowLeft /></Box>}
                ref={(slider1) => setNav1(slider1 ? slider1 : undefined)}
            >
                {slides.map((item: string, index: number) => {
                    return <div className='main-image' key={index}>
                        <img src={item} alt={item}
                            style={{ borderRadius: '16px', width: isMobile ? '321px' : '521px', height: isMobile ? '413px' : '613px', border: '1px solid rgba(33, 38, 35, 0.10)' }} />
                        <div className='share-Icon'>
                            <img src={shareImg} style={{cursor: 'pointer'}}  onClick={() => handleShare?.()}/>
                        </div>
                    </div>
                })}
            </Slider>
        </div>
    );
}
