import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
    Modal,
    TextField,
    InputAdornment,
    IconButton,
    Grid
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { VisibilityOutlined, VisibilityOffOutlined } from "@material-ui/icons";
import {
    createTheme,
    ThemeProvider,
    withStyles
} from "@material-ui/core/styles";
export const {
    resetPasswordTitle,
    resetPasswordDescription,
    newPasswordLabel,
    newPasswordPlaceholder,
    cNewPasswordLabel,
    cNewPasswordPlaceholder,
    conrfimPwBtn,
    loginSuccessmsg,
} = require("./config");
import { resetPasswordImg, congats } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import {
    borderColor,
    helperTextValue,
    isErrorInField
} from "../../../components/src/utils";
import {
    close,
} from "../../email-account-registration/src/assets";
import { styles } from "./ForgotPassword.web";

const theme = createTheme({
    overrides: {
        MuiButton: {
            root: {
                "&.Mui-disabled": {
                    color: "#FFF"
                }
            }
        },
        MuiTypography: {
            body1: {
                letterSpacing: 0,
                fontFamily: "Inter, sans-serif"
            }
        },
        MuiInputBase: {
            input: {
                fontFamily: "Inter",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                letterSpacing: 0,
                lineHeight: "150%",
                padding: 0,
                height: "auto"
            }
        },
        MuiFormHelperText: {
            root: {
                color: "#F00",
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginTop: "4px",
                letterSpacing: 0,
                "&.Mui-error": {
                    color: "#F00"
                }
            }
        }
    }
});
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        const validationSchema = Yup.object().shape({
            new_password: Yup.string()
                .min(5, "* Password must be at least 5 characters")
                .required("* Please enter a valid password"),
            confirm_password: Yup.string()
                .oneOf([Yup.ref("new_password"), null], "* Password do not match")
                .required("* Please enter a valid password")
        });

        const BootstrapButton = withStyles({
            root: {
                textTransform: "none",
                backgroundColor: primaryColor,
                color: "#ffffff",
                maxWidth: " 360px",
                width: "100%",
                height: "56px",
                flexShrink: 0,
                textAlign: "center",
                borderRadius: "44px",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
                fontFamily: ["Inter", "sans-serif"].join(","),
                border: `1px solid ${primaryColor}`,
                "&:hover": {
                    backgroundColor: primaryColor
                }
            }
        })(Button);
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Container maxWidth="lg" className={classes.main}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={5}
                        style={{ margin: "100px auto", maxWidth: "1200px" }}
                    >
                        <Grid
                            item
                            xs={10}
                            sm={9}
                            md={6}
                            lg={4}
                            className={classes.formBlock}
                            style={{ flexDirection: "column" }}
                        >
                            <Typography className={classes.title}>
                                {resetPasswordTitle}
                            </Typography>
                            <Typography className={classes.description}>
                                {resetPasswordDescription}
                            </Typography>

                            {this.state.VerifyEmailNote && (
                                <Typography component={"div"}>
                                    <p
                                        className={classes.errorBlock}
                                        style={{ position: `relative`, wordBreak: "break-all" }}
                                    >
                                        <span
                                            className={classes.errorBefore}
                                            style={{ position: "absolute" }}
                                        />
                                        {this.state.VerifyEmailNote}
                                    </p>
                                </Typography>
                            )}

                            <Formik
                                initialValues={{ new_password: "", confirm_password: "" }}
                                validationSchema={validationSchema}
                                onSubmit={values => {
                                    this.resetPasswordCallApi(values);
                                }}
                            >
                                {({ values, touched, errors, handleSubmit, setFieldValue }) => {
                                    return (
                                        <form onSubmit={handleSubmit}>
                                            <Box style={{ marginBottom: "48px" }}>
                                                <Typography className={classes.textlabel}>
                                                    {newPasswordLabel}
                                                </Typography>
                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        width: "100%",
                                                        marginBottom: "32px"
                                                    }}
                                                >
                                                    <TextField
                                                        name="new_password"
                                                        variant="standard"
                                                        style={{ width: "100%" }}
                                                        placeholder={newPasswordPlaceholder}
                                                        type={this.state.showPassword ? "text" : "password"}
                                                        onChange={e => {
                                                            setFieldValue("new_password", e.target.value);
                                                            touched.new_password = true;
                                                        }}
                                                        error={isErrorInField(
                                                            errors,
                                                            touched,
                                                            "new_password"
                                                        )}
                                                        helperText={helperTextValue(
                                                            errors,
                                                            touched,
                                                            "new_password"
                                                        )}
                                                        value={values.new_password}
                                                        InputProps={{
                                                            className: classes.inputField,
                                                            style: {
                                                                borderColor: borderColor(
                                                                    errors,
                                                                    touched,
                                                                    "new_password"
                                                                )
                                                            },
                                                            disableUnderline: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showPassword: !this.state.showPassword
                                                                            })
                                                                        }
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            textDecoration: "none"
                                                                        }}
                                                                    >
                                                                        {this.state.showPassword ? (
                                                                            <VisibilityOutlined
                                                                                style={{
                                                                                    height: "24px",
                                                                                    width: "24px"
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <VisibilityOffOutlined
                                                                                style={{
                                                                                    height: "24px",
                                                                                    width: "24px"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>

                                                <Typography className={classes.textlabel}>
                                                    {cNewPasswordLabel}
                                                </Typography>

                                                <Box
                                                    style={{
                                                        display: "flex",
                                                        gap: "10px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <TextField
                                                        name="confirm_password"
                                                        variant="standard"
                                                        style={{ width: "100%" }}
                                                        type={
                                                            this.state.showCPassword ? "text" : "password"
                                                        }
                                                        placeholder={cNewPasswordPlaceholder}
                                                        onChange={e => {
                                                            setFieldValue("confirm_password", e.target.value);
                                                            touched.confirm_password = true;
                                                        }}
                                                        error={isErrorInField(
                                                            errors,
                                                            touched,
                                                            "confirm_password"
                                                        )}
                                                        helperText={helperTextValue(
                                                            errors,
                                                            touched,
                                                            "confirm_password"
                                                        )}
                                                        value={values.confirm_password}
                                                        InputProps={{
                                                            className: classes.inputField,
                                                            style: {
                                                                borderColor: borderColor(
                                                                    errors,
                                                                    touched,
                                                                    "confirm_password"
                                                                )
                                                            },
                                                            disableUnderline: true,
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                showCPassword: !this.state.showCPassword
                                                                            })
                                                                        }
                                                                        style={{
                                                                            fontSize: "15px",
                                                                            textDecoration: "none"
                                                                        }}
                                                                    >
                                                                        {this.state.showCPassword ? (
                                                                            <VisibilityOutlined
                                                                                style={{
                                                                                    height: "24px",
                                                                                    width: "24px"
                                                                                }}
                                                                            />
                                                                        ) : (
                                                                            <VisibilityOffOutlined
                                                                                style={{
                                                                                    height: "24px",
                                                                                    width: "24px"
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box>
                                                <BootstrapButton type="submit">
                                                    {conrfimPwBtn}
                                                </BootstrapButton>
                                            </Box>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Grid>
                        <Grid item lg={7} className="login-image-block hidden-sm">
                            <img src={resetPasswordImg} alt="Reset Password Image" />
                        </Grid>
                    </Grid>

                    <Modal
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        open={this.state.isSuccessOpen}
                        onClose={this.handleSuccessModal}
                        aria-labelledby="simple-modal-title"
                        aria-describedby="simple-modal-description"
                    >
                        <div className="congratulations-modal">
                            <span
                                className="close-btn"
                                data-test-id="closeSuccessModal"
                                onClick={this.handleSuccessModal}
                            >
                                <img src={close} alt="close" />
                            </span>
                            <img src={congats} alt="" />
                            <p style={{ marginTop: '16px' }} className={classes.successNoteText}>{loginSuccessmsg}</p>
                        </div>
                    </Modal>
                </Container>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const NewPasswordWeb = withStyles(styles)(NewPassword);
export { NewPasswordWeb };
// Customizable Area End
