import React from "react";

// Customizable Area Start

import {
     ThemeProvider
} from "@material-ui/core/styles";
import { muiTheme } from "./MyBookings.web";
import TripPaymentSuccess from "../../../components/src/TripPaymentSuccess";

// Customizable Area End

import OrdermanagementController, {
    Props
} from "./OrdermanagementController";

export default class PaymentStatus extends OrdermanagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    

    
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { paymentResponse } = this.state;
        
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={muiTheme}>
                <TripPaymentSuccess
                    navigation={this.props.navigation}
                    responseJson={paymentResponse}
                />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
