
import {
     Grid, Typography, Box, Button, Divider, createTheme, ThemeProvider
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Check from "@material-ui/icons/Check"
import React, { useState, useEffect } from "react";
import { parsePhoneNumber } from 'libphonenumber-js'
import PhoneInput from "./PhoneInput";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { primaryColor } from "./theme";
import { CityData, CountryData, PersonalDetailsValues } from "../../blocks/email-account-registration/src/Interface.web";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { Categories, IStep1CET } from "../../blocks/ProductDescription/src/Interface.web";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomTextField from "./CustomTextField";
import CountrySelect from "./CountrySelect";
import CitySelect from "./CitySelect";
import CustomCheckboxGroup from "./CustomCheckBoxGroup";
import CustomPhotoUpload from "./CustomPhotoUpload";
import MultiSelectCalender from "./MultiSelectCalender"
interface Step1CETProps {
    initialState: IStep1CET;
    submitStep1CET: (values: IStep1CET) => void;
    cancelTrip: () => void;
    countries: CountryData[];
    cities: CityData[];
    getCityRequest: (country: string) => void;
    // handleDateChange: (selectedDate: string[]) => void;
    mode: 'create' | 'edit';
    tripType: Categories[];
    vibeList: Categories[];
    timeList: Categories[];
}

function checkIfFilesAreTooBig(files: FileList | File[] | null): boolean {
    if (files && files.length > 0) {
        for (const file of files) {
            const size = file.size / 1024 / 1024;
            if (size > 5) {
                return false; // Return false if any file is larger than 5 MB
            }
        }
    }
    return true; // Return true if all files are 5 MB or less, or no files are provided
}



const validationSchema = Yup.object().shape({
    title: Yup.string().required("* Please enter title for the trip"),
    description: Yup.string().required("* Please enter Description for the trip"),
    trip_type: Yup.string().required("* Please select trip type"),
    country: Yup.string().required("* Please select country for the trip").nullable(),
    city: Yup.string().required("* Please select city for the trip").nullable(),
    area: Yup.string().required("* Please enter area for the trip"),
    vibes: Yup.string().required("* Please select at least 1 option"),
    main_photo: Yup.mixed()
        .required("* Please upload main photo for the trip")
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig),
    photos: Yup.mixed().notRequired()
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig),
    time: Yup.string().required("* Please select time for the trip"),
    duration: Yup.string().notRequired(),
    trip_calendar: Yup.string().required("* Please select dates for trip"),
})



const options = [
    { label: 'Culture', value: 'culture' },
    { label: 'Nature', value: 'nature' },
    { label: 'Adventure', value: 'adventure' },
    { label: 'Experience', value: 'experience' },
];

const optionsCheckBox = [
    { label: 'Relaxed', value: 'Relaxed' },
    { label: 'Dance and Chill', value: 'Dance and Chill' },
    { label: 'Arts and Creativity', value: 'Arts and Creativity' },
    { label: 'Hiking', value: 'Hiking' },
    { label: 'Extreme Adventure', value: 'Extreme Adventure' },
    { label: 'Sun and Sea', value: 'Sun and Sea' },
    { label: 'Exploring', value: 'Exploring' },
    { label: 'Trying something different', value: 'Trying something different' },
    { label: 'Sports Activities', value: 'Sports Activities' },
    { label: 'Events', value: 'Events' },

];

const optionsTime = [
    { label: 'Full day', value: 'full_day' },
    { label: 'Day trip', value: 'day_trip' },
    { label: 'Night trip', value: 'night_trip' },
];

export const fileterOption = (value: any, options: Array<{ label: string; value: string }>) => {
    return options.filter((option) => option.value?.toString() === value?.toString());
}

// Create a custom theme
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024, // Change the default md width to 1024
            lg: 1280,
            xl: 1920,
        },
    },
});



const Step1CET = ({
    initialState,
    submitStep1CET,
    cancelTrip,
    countries,
    cities,
    getCityRequest,
    mode,
    tripType,
    vibeList,
    timeList
    // handleDateChange
}: Step1CETProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const useStyles = makeStyles((theme) => ({
        customFont: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 400,
        },
        buttonSelectStyle: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 500,
            color: 'rgba(33, 36, 38, 0.90)',
            fontSize: '16px',
            marginTop: '-22px'
        },
        toastModal: {
            borderRadius: '8px',
            display: 'inline-flex',
            height: '45px',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            backgroundColor: '#FFF2F2',
            borderLeft: '8px solid #FF0000',
            padding: '8px 16px 8px 12px',
            margin: '30px 0'
        },
        formHelperText: {
            color: 'rgb(244, 67, 54)',
            fontSize: '12px',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '8px' : '24px'
        },
        headingText: {
            fontSize: '32px',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontStyle: 'normal',
            color: '#212426',
            lineHeight: 'normal'
        },
        stepText: {
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: 600,
            color: "#FE7F2D",
            lineHeight: '150%',
            marginBottom: '2px'
        },
        fieldTitleText: {
            fontFamily: 'Inter',
            fontSize: '18px',
            color: 'rgba(33, 36, 38, 0.90)',
            marginTop: '8px',
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal'
        },
        whiteContainer: {
            padding: "47px 71px 71px 71px",
            borderRadius: '20px',
        },
        formatDescription: {
            fontFamily: 'Inter',
            fontSize: '14px',
            color: 'rgba(33, 36, 38, 0.75)',
            fontWeight: 400,
            lineHeight: '150%',
            fontStyle: 'normal'
        },
        optionalStyle: { 
            fontWeight: 400, 
            color: 'rgba(33, 36, 38, 0.65)', 
            fontSize: '16px' 
        }, 
        cancelBtn: {
            color: primaryColor,
            textTransform: 'initial',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal',
            paddingLeft: 0,
        },
        nxtBtn: {
            backgroundColor: primaryColor,
            color: '#ffffff',
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: primaryColor,
                color: '#ffffff',
            }
        },
        distanceBtwnTxt: {
            marginTop: '32px'
        },
        distanceBtwnRdo: {
            marginTop: '48px'
        },
        btnStyle: {
            marginTop: '48px',
            display: 'flex',
            justifyContent: 'space-between'
        },
        planToTitleDistance: {
            marginTop: '32px'
        },
        [theme.breakpoints.down(1024)]: {
            whiteContainer: {
                padding: "36px 47px 31px 47px",
                borderRadius: '20px',
                // margin: '48px'
            },
            headingText: {
                fontSize: '26px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '16px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            formatDescription: {
                fontFamily: 'Inter',
                fontSize: '12px',
                color: 'rgba(33, 36, 38, 0.75)',
                fontWeight: 400,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            optionalStyle: { 
                fontWeight: 400, 
                color: 'rgba(33, 36, 38, 0.65)', 
                fontSize: '14px' 
            }, 
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            distanceBtwnTxt: {
                marginTop: '24px'
            },
            distanceBtwnRdo: {
                marginTop: '40px'
            },
            btnStyle: {
                marginTop: '32px',
                display: 'flex',
                justifyContent: 'space-between'
            },

        },
        [theme.breakpoints.down(768)]: {
            whiteContainer: {
                padding: "15px 15px 23px 15px",
                borderRadius: '12px',
                // margin: '16px'
            },
            headingText: {
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            stepText: {
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: 500,
                color: "#FE7F2D",
                lineHeight: '150%',
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '14px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            formatDescription: {
                fontFamily: 'Inter',
                fontSize: '10px',
                color: 'rgba(33, 36, 38, 0.75)',
                fontWeight: 400,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            optionalStyle: { 
                fontWeight: 400, 
                color: 'rgba(33, 36, 38, 0.65)', 
                fontSize: '12px' 
            }, 
            cancelBtn: {
                color: primaryColor,
                textTransform: 'initial',
                fontSize: '14px',
                fontFamily: "Inter",
                fontWeight: 500,
                paddingLeft: 0,
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '179px',
                height: '44px'
            },
            distanceBtwnTxt: {
                marginTop: '16px'
            },
            distanceBtwnRdo: {
                marginTop: '24px'
            },
            btnStyle: {
                marginTop: '24px',
                display: 'flex',
                justifyContent: 'space-between'
            },
            planToTitleDistance: {
                marginTop: '24px'
            },
        },

    }));
    const classes = useStyles();

    return (

        <div
            className={classes.whiteContainer}
            style={{
                maxWidth: "1082px",
                minWidth: "328px",
                backgroundColor: '#fff',
                border: '0.5px solid rgba(33, 36, 38, 0.20)',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
                margin: isMobile ? '0 auto' : '0'
            }}
        >
            <ThemeProvider theme={theme}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography className={classes.headingText}>{mode === "create" ? 'Plan new trip' : 'Edit trip details'}</Typography>
                    <Typography className={classes.stepText}>Step 1 - 4</Typography>
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialState}
                    test-id="submitButtonId"
                    onSubmit={submitStep1CET}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue, errors, touched }) => {
                        // console.log("main photos", values.main_photo)
                        // console.log("step1Values", values)
                        // console.log("mode", mode)
                        return (
                            <Form style={{marginBottom: '0px'}}>
                                <Box>
                                    <Grid container className={classes.planToTitleDistance}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Title'}
                                                placeholder={'Title'}
                                                value={values.title}
                                                fieldName="title"
                                                onChange={setFieldValue}
                                                error={errors.title as string}
                                                touched={touched.title as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Description'}
                                                placeholder={'Description'}
                                                value={values.description}
                                                fieldName="description"
                                                onChange={setFieldValue}
                                                error={errors.description as string}
                                                touched={touched.description as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={3}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Trip '} <span style={{whiteSpace: 'nowrap'}}>type <span style={{ color: 'red' }}> *</span></span>
                                            </Typography>

                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="trip_type"
                                                options={tripType}
                                                error={errors.trip_type as string}
                                                value={values.trip_type}
                                                onChange={setFieldValue}
                                                touched={touched.trip_type as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginBottom: '-2px', color: '#FF0000' }}>
                                        {touched.trip_type ? errors.trip_type : ""}
                                    </Typography>
                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={6} xs={12}>
                                            <CountrySelect
                                                countries={countries}
                                                countryTouched={touched.country as boolean}
                                                selectedCountry={values.country}
                                                setFieldValue={setFieldValue}
                                                countryError={errors?.country as string}
                                                getCityListRequest={getCityRequest}
                                                isDashboard={true}
                                                isDisable={mode === "edit"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={6} xs={12}>
                                            <CitySelect
                                                cities={cities}
                                                cityTouched={touched.city as boolean}
                                                selectedCity={values.city}
                                                setFieldValue={setFieldValue}
                                                cityError={errors?.city as string}
                                                isDashboard={true}
                                                isDisable={mode === "edit"}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={6} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Area'}
                                                placeholder={'Enter area'}
                                                value={values.area}
                                                isDisable={mode === "edit"}
                                                fieldName="area"
                                                onChange={setFieldValue}
                                                error={errors.area as string}
                                                touched={touched.area as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={3}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Vibes'} <span style={{ color: 'red' }}> *</span>
                                                {
                                                    errors.vibes && touched.vibes ?
                                                        <span style={{ fontSize: '12px', fontWeight: 400, color: '#FF0000' }}><br /> {errors.vibes}</span>
                                                        :
                                                        <span style={{ fontSize: '14px', fontWeight: 400, whiteSpace: 'nowrap' }}><br /> {"Select at least 1"}</span>
                                                }
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomCheckboxGroup
                                                fieldName="vibes"
                                                options={vibeList}
                                                error={errors.vibes as string}
                                                selectedValues={values.vibes}
                                                onChange={setFieldValue}
                                                touched={touched.vibes as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={3}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {"Main "} <span style={{whiteSpace: 'nowrap'}}>photo<span style={{ color: 'red' }}> *</span></span> 
                                            </Typography>
                                            <Typography
                                                className={classes.formatDescription}
                                            >
                                                <span>(Format : JPG, JPEG and PNG)</span>
                                                <span><br />Max size : 5MB</span>

                                            </Typography>

                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomPhotoUpload
                                                key={"1"}
                                                onMainPhotoChange={setFieldValue}
                                                photos={values.main_photo ? [values.main_photo] : false}
                                                multiple={false}
                                                paramName="main_photo"
                                                error={errors.main_photo as string}
                                                touch={touched.main_photo as boolean}
                                                mode={mode}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={3}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                                style={{whiteSpace: 'nowrap'}}
                                            >
                                                {"Photos"} <span className={classes.optionalStyle}>(Optional)</span>
                                            </Typography>
                                            <Typography
                                               className={classes.formatDescription}
                                            >
                                                <span>(Format : JPG, JPEG and PNG)</span>
                                                <span><br />Max size : 5MB</span>

                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomPhotoUpload
                                                key={"2"}
                                                onMainPhotoChange={setFieldValue}
                                                photos={values.photos}
                                                multiple={true}
                                                paramName="photos"
                                                error={errors.photos as string}
                                                touch={touched.photos as boolean}
                                                mode={mode}

                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={3}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Time'}
                                                <span style={{ color: 'red' }}> *</span>
                                            </Typography>

                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="time"
                                                options={mode === 'edit' ? fileterOption(values.time, timeList) : timeList}
                                                disabled={mode === 'edit'}
                                                error={errors.time as string}
                                                value={values.time.toString()}
                                                onChange={setFieldValue}
                                                touched={touched.time as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginBottom: '-2px', color: '#FF0000' }}>
                                        {touched.time ? errors.time : ""}
                                    </Typography>
                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Duration'}
                                                placeholder={'Enter duration'}
                                                value={values.duration}
                                                fieldName="duration"
                                                onChange={setFieldValue}
                                                error={errors.duration as string}
                                                touched={touched.duration as boolean}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={3} sm={4} xs={12}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                                style={{ marginBottom: isMobile ? '12px' : 'none', }}
                                            >
                                                {'Trip '}  <span style={{whiteSpace: 'nowrap'}}>Calendar<span style={{ color: 'red' }}> *</span></span> 
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={8} xs={12}>
                                            <MultiSelectCalender
                                                dateValues={values.trip_calendar}
                                                originDateValues={values.original_trip_calendar}
                                                onChange={setFieldValue}
                                                error={errors.trip_calendar as string}
                                                touch={touched.trip_calendar as boolean}
                                                mode={mode}
                                            // handleDateChange={handleDateChange}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.distanceBtwnRdo} />
                                </Box>
                                <Box className={classes.btnStyle}>
                                    <Button
                                        className={classes.cancelBtn}
                                        onClick={() => cancelTrip()}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={classes.nxtBtn}
                                        type="submit"
                                    >
                                        Next
                                    </Button>
                                </Box>
                            </Form>)
                    }}
                </Formik>
            </ThemeProvider>
        </div>
    )
}

export default Step1CET;