
import {
    Grid, Typography, Box, Button, Divider, createTheme, ThemeProvider
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Check from "@material-ui/icons/Check"
import React, { useState, useEffect } from "react";
import { parsePhoneNumber } from 'libphonenumber-js'
import PhoneInput from "./PhoneInput";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { primaryColor } from "./theme";
import { CityData, CountryData, PersonalDetailsValues } from "../../blocks/email-account-registration/src/Interface.web";
import { makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { Categories, IStep3CET } from "../../blocks/ProductDescription/src/Interface.web";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomTextField from "./CustomTextField";
import CustomCheckboxGroup from "./CustomCheckBoxGroup";
import CustomSelectField from "./CustomSelectField";
import { fileterOption } from "./Step1CET";
import CustomGroupPreference from "./CustomGroupPreference";
interface Step3CETProps {
    initialState: IStep3CET;
    submitStep3CET: (values: IStep3CET) => void;
    backStep3CET: (values: IStep3CET) => void;
    cancelTrip: () => void;
    mode: 'create' | 'edit';
    travelerType: Categories[]
}


const validationSchema = Yup.object().shape({
    traveler_type: Yup.string().required("* Please select the traveler type for the trip."),
    group_preference: Yup.string().required("* Please select the group preference for the trip."),
    maximum_seats: Yup.string()
        .test('conditional-validation', '* Please enter maximum number of seats', function (value) {
            const groupPreference = this.resolve(Yup.ref('group_preference'));

            if (groupPreference === 'private' || groupPreference === 'public') {
                return !!value;
            }

            return true; // Return true if groupPreference is not 'private'
        })
        .nullable(),

    minimum_seats: Yup.string()
        .test('conditional-validation', '* Please enter minimum number of seats', function (value) {
            const groupPreference = this.resolve(Yup.ref('group_preference'));

            if (groupPreference === 'private') {
                return !!value;
            }

            return true; // Return true if groupPreference is not 'private'
        })
        .test('conditional-validation', "* Amount of minimum seats can't be more than the maximum seats", function (valueStr) {
            const groupPreference = this.resolve(Yup.ref('group_preference'));
            const maximumSeatsStr = this.resolve(Yup.ref('maximum_seats'));

            const value = parseInt(valueStr, 10);
            const maximumSeats = parseInt(maximumSeatsStr, 10);



            if (groupPreference === 'private') {
                if (maximumSeats && value) {
                    return value <= maximumSeats; // Return true if minimum_seats <= maximum_seats
                }
            }

            return true; // Return true if groupPreference is not 'private'
        })
        .nullable(),

    dress_code: Yup.string().notRequired(),
    language: Yup.string().required("* Please select language for the trip."),
    kids_friendly: Yup.string().required("* Please select whether your service is kids friendly or not."),
    female_only: Yup.string().required("* Please select whether your service is female only or not."),
    wheelchair_access: Yup.string().required("* Please select whether your service has wheelchair access or not.")
})

export const validationSchemaForPlanItinerary = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    country_code: Yup.string().required('Country Code is required'),
    onlyPhone: Yup.string().required('Mobile Number is required'),
    type_of_help: Yup.string().required('Type of Help is required'),
    have_date: Yup.string().required('Have Date is required'),
    have_buget: Yup.string().required('Have Budget is required'),
    persons: Yup.string().required('Number of Persons is required'),
    know_destination: Yup.string().required('Know Destination is required'),
    vibes: Yup.array().min(1, 'Select at least 1'),
    from_date: Yup.string()
        .test('conditional-validation', "* Please select start date.", function (value) {
            const have_date = this.resolve(Yup.ref('have_date'));

            if (have_date === "true") {
                return !!value;
            }

            return true;
        }),
    to_date: Yup.string()
        .test('conditional-validation', "* Please select end date.", function (value) {
            const have_date = this.resolve(Yup.ref('have_date'));

            if (have_date === "true") {
                return !!value;
            }

            return true;
        }),
    country: Yup.string()
        .test('conditional-validation', "* Please select country.", function (value) {
            const have_date = this.resolve(Yup.ref('know_destination'));

            if (have_date === "true") {
                return !!value;
            }

            return true;
        }),
    city: Yup.string()
        .test('conditional-validation', "* Please select city.", function (value) {
            const have_date = this.resolve(Yup.ref('know_destination'));

            if (have_date === "true") {
                return !!value;
            }

            return true;
        }).nullable(),
    budget_amount: Yup.string()
    .test('conditional-validation', "* Please enter your budget.", function (value) {
        const have_date = this.resolve(Yup.ref('have_buget'));

        if (have_date === "approximately") {
            return !!value;
        }

        return true;
    }),
});



const optionsYesNo = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },

];

const optionsTravelerType = [
    { label: 'Small Group', value: 'small_group' },
    { label: 'Big Group', value: 'big_group' },
    { label: 'Couples', value: 'couples' },
    { label: 'Solo', value: 'solo' },

]


const optionsCheckBox = [
    { label: 'English', value: 'english' },
    { label: 'Arabic', value: 'arabic' },

];

const optionsGroupPreference = [
    { label: 'Private', value: 'private' },
    { label: 'Public', value: 'public' }
]

const arr = Array.from({ length: 99 }, (_, index) => (index + 1).toString());

const fileterCheckboxOption = (value: string[], options: Array<{ label: string; value: string }>) => {
    return options.filter((option) => value.includes(option.value));
}

// Create a custom theme
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024, // Change the default md width to 1024
            lg: 1280,
            xl: 1920,
        },
    },
});


const Step3CET = ({
    initialState,
    submitStep3CET,
    backStep3CET,
    cancelTrip,
    mode,
    travelerType
}: Step3CETProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;
    const isTab = windowWidth < 1024

    const useStyles = makeStyles((theme) => ({
        customFont: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 400,
        },
        buttonSelectStyle: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 500,
            color: 'rgba(33, 36, 38, 0.90)',
            fontSize: '16px',
            marginTop: '-22px'
        },
        toastModal: {
            borderRadius: '8px',
            display: 'inline-flex',
            height: '45px',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            backgroundColor: '#FFF2F2',
            borderLeft: '8px solid #FF0000',
            padding: '8px 16px 8px 12px',
            margin: '30px 0'
        },
        formHelperText: {
            color: 'rgb(244, 67, 54)',
            fontSize: '12px',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '8px' : '24px'
        },
        headingText: {
            fontSize: '32px',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontStyle: 'normal',
            color: '#212426',
            lineHeight: 'normal'
        },
        stepText: {
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: 600,
            color: "#FE7F2D",
            lineHeight: '150%',
            marginBottom: '2px'
        },
        fieldTitleText: {
            fontFamily: 'Inter',
            fontSize: '18px',
            color: 'rgba(33, 36, 38, 0.90)',
            marginTop: '8px',
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal'
        },
        whiteContainer: {
            padding: "47px 71px 71px 71px",
            borderRadius: '20px',
        },
        cancelBtn: {
            color: primaryColor,
            textTransform: 'initial',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal',
            paddingLeft: 0,
        },
        nxtBtn: {
            backgroundColor: primaryColor,
            color: '#ffffff',
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: primaryColor,
                color: '#ffffff',
            }
        },
        bckBtn: {
            backgroundColor: 'transparent',
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: 'transparent',
                color: primaryColor,
            }
        },
        distanceBtwnRdo: {
            marginTop: '48px'
        },
        distanceBtnDBtn: {
            marginTop: '48px'
        },
        BtwnBtn: {
            display: 'flex',
            gap: '30px',
            justifyContent: "center"
        },
        [theme.breakpoints.down(1024)]: {
            whiteContainer: {
                padding: "36px 47px 31px 47px",
                borderRadius: '20px',
                margin: '48px'
            },
            headingText: {
                fontSize: '26px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '16px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                textTransform: 'initial',
                borderRadius: '34px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            distanceBtwnRdo: {
                marginTop: '40px'
            },
            distanceBtnDBtn: {
                marginTop: '32px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '16px',
                justifyContent: "center"
            },

        },
        [theme.breakpoints.down(768)]: {
            whiteContainer: {
                padding: "15px 15px 17px 15px",
                borderRadius: '12px',
                margin: '16px'
            },
            headingText: {
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            stepText: {
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: 500,
                color: "#FE7F2D",
                lineHeight: '150%',
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '14px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            cancelBtn: {
                color: primaryColor,
                textTransform: 'initial',
                fontSize: '14px',
                fontFamily: "Inter",
                fontWeight: 500,
                paddingLeft: 0,
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            distanceBtwnRdo: {
                marginTop: '24px'
            },
            distanceBtnDBtn: {
                marginTop: '24px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '30px',
                justifyContent: "space-between" 
            },

        },
    }));
    const classes = useStyles();

    return (
        <div
            className={classes.whiteContainer}
            style={{
                maxWidth: "1082px",
                minWidth: "328px",
                backgroundColor: '#fff',
                border: '0.5px solid rgba(33, 36, 38, 0.20)',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
                margin: isMobile ? '0 auto' : '0'
            }}
        >
            <ThemeProvider theme={theme}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography className={classes.headingText}>{mode === "create" ? 'Plan new trip' : 'Edit trip details'}</Typography>
                    <Typography className={classes.stepText}>Step 3 - 4</Typography>
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialState}
                    test-id="submitButtonId"
                    onSubmit={submitStep3CET}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue, errors, touched }) => {
                        return (
                            <Form style={{marginBottom: '0px'}}>
                                <Box>
                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Traveler Type'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="traveler_type"
                                                options={mode === 'edit' ? fileterOption(values.traveler_type, travelerType) : travelerType}
                                                disabled={mode === 'edit'}
                                                error={errors.traveler_type as string}
                                                value={values.traveler_type?.toString()}
                                                onChange={setFieldValue}
                                                touched={touched.traveler_type as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.traveler_type ? errors.traveler_type : ""}
                                    </Typography>

                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Group Preference'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            {
                                                !isTab ?
                                                    <CustomRadioGroup
                                                        fieldName="group_preference"
                                                        options={mode === 'edit' ? fileterOption(values.group_preference, optionsGroupPreference) : optionsGroupPreference}
                                                        disabled={mode === 'edit'}
                                                        error={errors.group_preference as string}
                                                        value={values.group_preference}
                                                        onChange={setFieldValue}
                                                        touched={touched.group_preference as boolean}
                                                    />
                                                    : <CustomGroupPreference
                                                        values={values}
                                                        setFieldValue={setFieldValue}
                                                        errors={errors}
                                                        touched={touched}

                                                    />
                                            }
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.group_preference ? errors.group_preference : ""}
                                    </Typography>
                                    {
                                        !isTab &&
                                            values.group_preference === 'private' ? (
                                            <Grid container style={{ marginTop: '24.39px' }}>
                                                <Grid item md={3} sm={3} xs={5}>
                                                    {" "}
                                                </Grid>
                                                <Grid item md={9} sm={9} xs={7}>
                                                    <Grid container spacing={3}>

                                                        <Grid item md={6} xs={12}>
                                                            <CustomTextField
                                                                fieldTitle={'Minimum Seats'}
                                                                placeholder={'Enter value'}
                                                                type='number'
                                                                isDisable={mode === "edit"}
                                                                value={values.minimum_seats as string}
                                                                fieldName="minimum_seats"
                                                                onChange={setFieldValue}
                                                                error={errors.minimum_seats as string}
                                                                touched={touched.minimum_seats as boolean}
                                                            />

                                                        </Grid>

                                                        <Grid item md={6} xs={12}>
                                                            <CustomTextField
                                                                fieldTitle={'Maximum Seats'}
                                                                placeholder={'Enter value'}
                                                                type='number'
                                                                isDisable={mode === "edit"}
                                                                value={values.maximum_seats as string}
                                                                fieldName="maximum_seats"
                                                                onChange={setFieldValue}
                                                                error={errors.maximum_seats as string}
                                                                touched={touched.maximum_seats as boolean}
                                                            />

                                                        </Grid>

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : !isTab && values.group_preference === 'public' ? (
                                            <Grid container style={{ marginTop: '24px' }}>
                                                <Grid item md={3} xs={12}>
                                                    {" "}
                                                </Grid>
                                                <Grid item md={9} xs={12}>
                                                    <Grid item md={6} xs={12}>
                                                        <CustomTextField
                                                            fieldTitle={'Maximum Seats'}
                                                            placeholder={'Enter value'}
                                                            type='number'
                                                            isDisable={mode === "edit"}
                                                            value={values.maximum_seats as string}
                                                            fieldName="maximum_seats"
                                                            onChange={setFieldValue}
                                                            error={errors.maximum_seats as string}
                                                            touched={touched.maximum_seats as boolean}
                                                        />

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ) : null
                                    }


                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Dress Code'}
                                                placeholder={'Write something...'}
                                                value={values.dress_code}
                                                fieldName="dress_code"
                                                onChange={setFieldValue}
                                                error={errors.dress_code as string}
                                                touched={touched.dress_code as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Language'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomCheckboxGroup
                                                fieldName="language"
                                                options={mode === "edit" ? fileterCheckboxOption(values.language, optionsCheckBox) : optionsCheckBox}
                                                disabled={mode === 'edit'}
                                                error={errors.language as string}
                                                selectedValues={values.language}
                                                onChange={setFieldValue}
                                                touched={touched.language as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.language ? errors.language : ""}
                                    </Typography>


                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Kids Friendly'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="kids_friendly"
                                                options={mode === 'edit' ? fileterOption(values.kids_friendly, optionsYesNo) : optionsYesNo}
                                                disabled={mode === 'edit'}
                                                error={errors.kids_friendly as string}
                                                value={values.kids_friendly}
                                                onChange={setFieldValue}
                                                touched={touched.kids_friendly as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.kids_friendly ? errors.kids_friendly : ""}
                                    </Typography>

                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Female only'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="female_only"
                                                options={mode === 'edit' ? fileterOption(values.female_only, optionsYesNo) : optionsYesNo}
                                                disabled={mode === 'edit'}
                                                error={errors.female_only as string}
                                                value={values.female_only}
                                                onChange={setFieldValue}
                                                touched={touched.female_only as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.female_only ? errors.female_only : ""}
                                    </Typography>


                                    <Grid container className={classes.distanceBtwnRdo} spacing={2}>
                                        <Grid item md={3} sm={3} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Wheelchair '} <span style={{whiteSpace: 'nowrap'}}>access<span style={{ color: 'red' }}> *</span></span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={9} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="wheelchair_access"
                                                options={mode === 'edit' ? fileterOption(values.wheelchair_access, optionsYesNo) : optionsYesNo}
                                                disabled={mode === 'edit'}
                                                error={errors.wheelchair_access as string}
                                                value={values.wheelchair_access}
                                                onChange={setFieldValue}
                                                touched={touched.wheelchair_access as boolean}
                                            />
                                        </Grid>

                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.wheelchair_access ? errors.wheelchair_access : ""}
                                    </Typography>

                                    <Divider className={classes.distanceBtwnRdo} />


                                </Box>
                                <Box
                                    className={classes.distanceBtnDBtn}
                                    style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column-reverse' : "row", gap: '42px' }}>
                                    <Button
                                        className={classes.cancelBtn}
                                        onClick={() => cancelTrip()}
                                    >
                                        Cancel
                                    </Button>
                                    <Box
                                        className={classes.BtwnBtn}
                                    >
                                        <Button
                                            className={classes.bckBtn}
                                            onClick={() => backStep3CET(values)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            className={classes.nxtBtn}
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>)
                    }}
                </Formik>
            </ThemeProvider>
        </div>
    )
}

export default Step3CET;