import React from "react";

// Customizable Area Start
import { Typography } from "@material-ui/core";
import { invalidError } from "./assets";
const { profileNotApprovedDesc, profileNotApproved } = require('./config.js')
import './CustomerLoginScreen.css';

// Customizable Area End

export default class ReviewPageBlock extends React.Component {
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', alignItems: 'center',
        margin: 'auto',
      }} className="page-under-review">
        <img src={invalidError} width={'120px'} height={'120px'} alt="success" />
        <Typography style={{
          fontFamily: 'Inter',
          fontSize: '32px',
          color: '#212623',
          fontWeight: 500,
          marginTop: '16px',
          lineHeight: '41.6px',
          textAlign: 'center'
        }}>
          {profileNotApproved}
        </Typography>
        <Typography style={{
          fontWeight: 400,
          fontSize: '16px',
          marginTop: '12px',
          fontFamily: 'Inter',
          textAlign: 'center',
          lineHeight: '24px',
          color: 'rgba(33, 38, 35, 0.75)'
        }}>
          {profileNotApprovedDesc}
        </Typography>
      </div >
      // Customizable Area End
    );
  }
}