import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { FormikErrors, FormikTouched } from "formik";
const sendEmailOtpAPiEndPoint = "bx_block_forgot_password/otps";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  VerifyEmailNote: { msg: string, type: string } | null;
  countdown: number,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  submitButtonColor: any = configJSON.submitButtonColor;
  timer: any;
  sendEmailOtpCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      isFromForgotPassword: false,
      countdown: 60,
      VerifyEmailNote: null
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    this.submitOtp = this.submitOtp.bind(this);
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.handleAPIResponse(message);
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.startCountdown();
  }

  async handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (!apiRequestCallId || !responseJson) {
      return;
    }

    if (apiRequestCallId === this.otpAuthApiCallId) {
      const { errors = [], meta: { token = null } = {} } = responseJson
      if (errors.length && errors[0].otp) {
        this.setState({ VerifyEmailNote: { msg: errors[0].otp, type: 'error' } })
        return
      } else if (token) {
        const token = responseJson.meta.token
        const msg: Message = new Message(
          getName(MessageEnum.NavigationNewPasswordMessage)
        );

        msg.addData(
          getName(MessageEnum.AuthTokenDataMessage),
          token
        );

        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

        this.send(msg);
        return
      }
      this.setState({
        VerifyEmailNote: { msg: errorReponse, type: 'error' }
      })
    } else if (apiRequestCallId === this.sendEmailOtpCallId) {
      this.handleResendOtpCodeRespone(responseJson, errorReponse)
    }
  }

  handleResendOtpCodeRespone = (responseJson: any, errorReponse: any) => {
    const { errors = [], meta: { token = null } = {} } = responseJson
    if (errors.length && errors[0].otp) {
      this.setState({ VerifyEmailNote: errors[0].otp })
      return
    } else if (responseJson && responseJson.meta && responseJson.meta.token) {
      const token = responseJson.meta.token
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMobilePhoneOTPMessage)
      );
      msg.addData(getName(MessageEnum.AuthTokenDataMessage), token);
      this.setState({ otpAuthToken: token })
      this.setState({})
      this.setState({
        countdown: 60,
        VerifyEmailNote: { msg: 'We sent you the OTP to your email address', type: 'success' }
      }, () => this.startCountdown())
      return
    }
    this.setState({
      VerifyEmailNote: { msg: errorReponse, type: 'error' }
    })
  }

  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.setState({
        VerifyEmailNote: { msg: 'Please enter the OTP you received', type: 'error' }
      })
      return;
    }

    if(this.state.otp.length !== 4){
      this.setState({
        VerifyEmailNote: { msg: 'Please enter the 4 digit OTP', type: 'error' }
      })
      return
    } else { 
      this.setState({
        VerifyEmailNote: null
      })
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_forgot_password/otps/verify`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : ""
      };

      const httpBody = {
        data: {
          attributes: data,
        }
      };

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  resendOtpCOde = async () => {
    if (this.state.countdown === 0) {

      const attrs = {
        email: this.state.userAccountID
      };

      const httpBody = {
        data: {
          attributes: attrs,
        }
      };

      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.sendEmailOtpCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        sendEmailOtpAPiEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiVerifyOtpMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
  }

  startCountdown = () => {
    this.timer = setInterval(() => {
      this.setState((prevState) => ({ countdown: prevState.countdown - 1 }), () => {
        if (this.state.countdown === 0) {
          clearInterval(this.timer as number | undefined);
          if (this.state.otp.length === 0) {
            this.setState({ VerifyEmailNote: { msg: "Please enter the OTP you received, if not please resend", type: 'warning' } });
          }
        }
      })
    }, 1000);
  }

  handleOtpChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    touched: FormikTouched<any>,
    setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>
  ) => {
    const input = event.target.value;
    const numericInput = input.replace(/\D/g, "").substring(0, 4);
    setFieldValue('otpCode', numericInput)
    this.setState({ otp: numericInput });
    touched.otpCode = true;
  };

  handlePaste = (
    event: React.ClipboardEvent<HTMLDivElement>,
    setFieldValue: (field: string, value: any) => Promise<void | FormikErrors<any>>
  ) => {
    event.preventDefault();
    const clipboardData = event.clipboardData || window.Clipboard;
    const pastedData = clipboardData.getData("text");
    const numericPastedData = pastedData.replace(/\D/g, "");
    setFieldValue('otpCode', numericPastedData.slice(0, 4))
    this.setState({ otp: numericPastedData.slice(0, 4) });
  };

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp()
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text })
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric"
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

  // Customizable Area End
}
