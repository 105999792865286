import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from "moment";
import { EventI } from "./Interface.web";
import ApiRequest from "../../../components/src/ApiRequest";
import { getAuthToken } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedTime: number;
  selectedDate: string;
  timeSlots: object[];
  serviceProviderId: string;
  serviceProviderSchedule: any;
  details: any;
  token: any;
  openADateEvents: boolean;
  allEvents: EventI[];
  singleDayEvents: EventI[];
  selectedDay: string;
  loader: boolean;
  selectedMonth: string[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
  
  // Customizable Area Start
  serviceProviderDetailApiId:any;
  serviceProviderScheduleApiId:any;
  apiCallIdAllEvents: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      selectedTime: 0,
      selectedDate: moment().format("YYYY-MM-DD"),
      timeSlots: [],
      serviceProviderId: "",
      serviceProviderSchedule: "",
      details: {},
      token: null,
      openADateEvents: false,
      allEvents: [],
      singleDayEvents: [],
      selectedDay: '',
      loader: false,
      selectedMonth: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage)){
      const responseJson = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({
        selectedMonth: responseJson?.queryParams?.datesOfTrip ?? this.state.selectedDate,
    });
  }
    const apiRequestCallIds = {
      [this.apiCallIdAllEvents]: this.allEventsApiResponse,

      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    this.getAllEvents()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  onSelectDate = (selectedDateStr: string) => {
    const {details} = this.state;
    this.setState({
      selectedDate: selectedDateStr,
      timeSlots: [],
      selectedTime: 0
    });
    this.getServiceProviderAvailability(selectedDateStr, details);
  };

  calendarProps = {
    minDate: moment(),
    onSelectDate: (selectedDate: string) => this.onSelectDate(selectedDate)
  };

  async getServiceProviderAvailability(selectedDateStr: any, profileData: any){
    const token = this.state.token || '';
    if(profileData && profileData.id && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: profileData.id,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    } else if(this.state.serviceProviderId && selectedDateStr){
      this.getServiceProviderDetails({
        setApiCallId: 'serviceProviderScheduleApiId',
        serviceProviderId: this.state.serviceProviderId,
        availableDate: moment(selectedDateStr).format("YYYY/MM/DD"),
        token
      })
    }
  }

  async getServiceProviderDetails(dataObj: any) {
    const { setApiCallId, serviceProviderId, availableDate, token } = dataObj;

    const header = {
      "Content-Type": configJSON.applicationJsonApiContentType,
      token,
    };
    
    this.apiCall({
      setApiCallId,
      header,
      method: configJSON.getApiMethodType,
      endPoint: `${configJSON.serviceProviderAPiEndPoint}?availability_date=${availableDate}&service_provider_id=${serviceProviderId}`,
      body: null
    });
    return true;
  }

  apiCall = async (data: any) => {

    const { setApiCallId, header, endPoint, method, body } = data;
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if(setApiCallId === 'serviceProviderDetailApiId'){
      this.serviceProviderDetailApiId = requestMessage.messageId;
    }else if(setApiCallId === 'serviceProviderScheduleApiId'){
      this.serviceProviderScheduleApiId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Request API

  getAllEvents = async () => {
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      Authorization: `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.allEventsEndpoint,
      method: "GET",
    });
    this.apiCallIdAllEvents = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({loader: true})
  }

  // Response API

  allEventsApiResponse = (responseJson: any, errorResponse: any) => {
    const allEventsDataArray = responseJson?.data;
  
    if (allEventsDataArray) {
      const allEventsList: EventI[] = [];
  
      allEventsDataArray.forEach((event: any) => {
        const id = event.id;
        const attributes = event.attributes;
        const title = attributes?.title;
  
        // Extract trip_schedules and create events for each schedule
        const tripSchedules = attributes?.trip_schedules?.data;
  
        if (tripSchedules) {
          tripSchedules.forEach((schedule: any) => {
            const start = new Date(schedule.attributes?.scheduled_date);
            const end = new Date(schedule.attributes?.scheduled_date);
  
            // You might want to set end date differently if needed
  
            const eventObj: EventI = { id, title, start, end };
            allEventsList.push(eventObj);
          });
        }
      });
  
      this.setState({
        loader: false,
        allEvents: allEventsList,
      });
    }
    this.setState({loader: false})
  };
  


  openSeeMoreEvents = () => {
    this.setState({
      openADateEvents: true,
    })
  }
  handleClose = () => {
    this.setState({
      openADateEvents: false,
    })
  }
  handleDrillDown = (date: Date, view: string) => {
    const monthYearString = date.toLocaleDateString("en-US", {
      day: "2-digit",
      year: "numeric",
      month: "short",
  });


    // Filter events that match the selected date
    const selectedDateEvents = this.state.allEvents.filter((event) =>
      moment(date).isSame(event.start, 'day')
    );
    this.setState({
      singleDayEvents: selectedDateEvents,
      selectedDay: monthYearString
    }, () => this.openSeeMoreEvents())
  }

  navigateToTripDetails = (id: string) => {
    this.props.navigation.navigate("SingleTripDetails", {id:id})
  }

  navigateToListView = () => {
    this.props.navigation.navigate("TripList")
  }

  navigateToCreateTripFC = () => {
    this.props.navigation.navigate("CreateTrip")
  }
  // Customizable Area End
}