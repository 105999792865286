import { FormikErrors, FormikTouched } from 'formik';
import React, { useEffect, useState } from 'react';
import { getStorageData } from '../../framework/src/Utilities';

export const handleScrollToTop = () => {
  const scrollStep = -window.scrollY / (500 / 15);
  const scrollInterval = setInterval(() => {
    if (window.scrollY !== 0) {
      window.scrollBy(0, scrollStep);
    } else {
      clearInterval(scrollInterval);
    }
  }, 15);
};

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update window width
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // Attach event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowWidth;
}

export const isErrorInField = (errors: FormikErrors<any>, touched: FormikTouched<any>, name: string) => {
  return Boolean(errors[name]) && Boolean(touched[name])
}

export const helperTextValue = (errors: FormikErrors<any>, touched: FormikTouched<any>, name: string) => {
  return touched[name] ? errors[name] : ""
}

export const borderColor = (errors: FormikErrors<any>, touched: FormikTouched<any>, name: string) => {
  return errors[name] && touched[name] ? 'red' : '#C3CAD9'
}

export function checkIfFilesAreCorrectType(files?: any): boolean {
  let valid = true;
  if (files && typeof files !== 'string') {
    if (!['image/jpg', 'image/jpeg', 'image/png'].includes(files.type)) {
      valid = false;
    }
  }
  return valid;
}

export function checkIfFilesAreTooBig(files?: any): boolean {
  if (!files || typeof files === 'string') return true;
  return files && files.size <= 5 * 1024 * 1024;
}

export function formatDate(date: Date): React.ReactElement {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric', month: 'short', day: 'numeric'
  });
  const day = new Date(date).getDate();
  const suffix = getDaySuffix(day);

  const [month, _, year] = formattedDate.split(' ');
  return <>{day}<sup>{suffix} </sup> {month} {year}</>;
}

export const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th'
  }

  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export const checkCondition = (isTrue: boolean) => {
  return isTrue ? 'Yes' : 'No'
}

export function convertDateFormat(inputDate: Date) {
  const day = inputDate.getDate();
  const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
  const year = inputDate.getFullYear();

  return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`;
}

export const objectUrl = (values: Blob | MediaSource) => {
  return URL.createObjectURL(values)
}

export const ternaryCondition = (condition: boolean, trueStmt: any, falseStmt: any) => {
  if (condition) {
    return trueStmt
  } else {
    return falseStmt
  }
}

export function convertToTitleCase(input: string[]) {
  return input
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(', ');
}

export function convertStringToTitleCase(inputString: string) {
  if (typeof inputString !== 'string') {
    // If input is not a string, return it as is or handle it accordingly
    return inputString;
  }
  return inputString
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function capitalizeWords(inputString: string) {
  return inputString ? inputString.replace(/\b\w/g, char => char.toUpperCase()) : '';
}

export const formatMonthDateYear = (date: string) => {
  const dateObject = new Date(date);
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return formattedDate
}

export function capitalizeFirstLetter(str: string) {
  if (!str || typeof str !== 'string') {
    return str;
  }

  let string = str.split('_').join(' ')
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getAuthToken = async () => {
  const rememberMe = await getStorageData("rememberMe");
  if(rememberMe) {
    return await getStorageData("authToken");
  } else {
    return sessionStorage.getItem("authToken");
  }
}

export const getStatusTextColor = (status: string) => {
  let colorCode='';
  if(status === "completed" 
  || status === "confirmed") colorCode = 'black';
  else if(status === "cancelled") colorCode = '#E84D4D';
  else if(status === "removed") colorCode = '#C87F2C';
  else colorCode = '#4A4A4A';
  return colorCode;
}
export const getStatusBgColor = (status: string) => {
  let bgColor = "";
  if(status === "cancelled") bgColor = '#F8D3D3';
  else if(status === "removed") bgColor = '#FFF0BD';
  else if(status === "completed"
  || status === "confirmed") bgColor = '#D3D3D3';
  else bgColor = '#E9E9E9'
  return bgColor;
}

export const formatNullishValue = (value: string | null): string => {
  return (value === null || value === undefined || value === '') ? "" : value;
}

export const formatEmptyValue = (value: string | null | undefined): string => {
  return (value === null || value === undefined || value === '') ? "No upcoming trip found" : value;
}

export const dummyText = "JusADummyText"

export function getRangePage(current_pageStr: number, per_pageStr: number, total_items: number) {
  const current_page = Number(current_pageStr);
  const per_page = Number(per_pageStr);

  const start = (current_page - 1) * per_page + 1;
  const end = Math.min(start + per_page - 1, total_items);

  return `${start} - ${end}`;
}

const formatSlashDate = (dateString: string): string => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const getRelativeDateLabel = (dateString: string): string => {
  const [day, month, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isSameDay = (d1: Date, d2: Date): boolean =>
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();

  if (isSameDay(date, today)) {
    return 'Today';
  }

  if (isSameDay(date, yesterday)) {
    return 'Yesterday';
  }

  return formatSlashDate(dateString);
};

export const convertToAmPm = (timeString: string): string => {
  const [hourString, minute, second] = timeString.split(':');
  let hour = parseInt(hourString, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';

  if (hour > 12) {
    hour -= 12;
  } else if (hour === 0) {
    hour = 12;
  }

  return `${hour}:${minute} ${ampm}`;
};

export const getTimeDifference = (createdAt: string): string => {
  const currentTime = new Date();
  const pastTime = new Date(createdAt);
  const timeDiffInMs = currentTime.getTime() - pastTime.getTime();

  const minutes = Math.floor(timeDiffInMs / (1000 * 60));
  const hours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
  const days = Math.floor(timeDiffInMs / (1000 * 60 * 60 * 24));

  if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else {
    const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short' };
    return pastTime.toLocaleDateString('en-US', options);
  }
};




