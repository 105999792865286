
import {
    TextField, Typography,
    Box, Select, MenuItem,
    Button, Divider, Grid
} from "@material-ui/core";

import React, { useState } from "react";
import { primaryColor } from "./theme";
import { makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { KeyboardArrowDown, Star } from '@material-ui/icons'
import { noBookings, searchIcon } from "../../blocks/ordermanagement/src/assets";
import { deleteIcon, editIcon } from "../../blocks/ProductDescription/src/assets";
import { bookingIcon, noImage, noTripCreated, noTripMatched } from "../../blocks/catalogue/src/assets";
import { Pagination } from '@material-ui/lab';
import { TripListCard } from "../../blocks/catalogue/src/CatalogueController";
import { CountryData } from "../../blocks/email-account-registration/src/Interface.web";
import FilterSelect from "./FilterSelect";
import "./customScrollbar.css"
import { Categories } from "../../blocks/ProductDescription/src/Interface.web";
import Loader from "./Loader.web";
import LazyImage from "./LazyImage";


interface TripListProps {
    isSearched: boolean;
    navigation: any;
    tripList: TripListCard[];
    loader: boolean;
    page_options: {
        totalPages: number,
        totalItems: number,
        page: number,
        limit: string,
    }
    countryList: CountryData[];
    handleFilterChange: (filterName: string, value: string) => void;
    handleSearchChange: (value: string) => void;
    handleDeleteModal: (id: string) => void;
    handlePaginationChange: (e: any, value: number) => void;
    tripTypeList: Categories[]
}

const tripType = [
    // { value: 'Any', label: 'Any' },
{ value: '0', label: 'Culture' },
{ value: '1', label: 'Nature' },
{ value: '2', label: 'Adventure' },
{ value: '3', label: 'Experience' },]

const sortBy = [
    { value: 'AtoZ', label: 'From A to Z' },
    { value: 'ZtoA', label: 'From Z to A' },
    { value: 'highToLow', label: 'Rating (high to low)' },
    { value: 'lowToHigh', label: 'Rating (low to high)' },]




const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "24px",
        // [theme.breakpoints.down("sm")]: {
        //     flexDirection: "column",
        // },
    },
    button: {
        fontFamily: "Inter",
        fontWeight: 500,
        fontSize: "16px",
        // height: "56px",
        width: "125px",
        padding: "8px",
        textTransform: "initial",
        borderRadius: "8px",
    },
    primaryButton: {
        backgroundColor: "#FFF6ED",
        color: primaryColor,
        maxWidth: '153px',
        "&:hover": {
            backgroundColor: "#FFF6ED",
            color: primaryColor,
        },
    },
    secondaryButton: {
        backgroundColor: "transparent",
        maxWidth: '125px',
        color: "#212426",
        border: `1px solid rgba(33, 36, 38, 0.20)`,
    },
    customBorderRight: {
        // borderRight: 'none'
    },
    titleText: {
        fontFamily: "Inter",
        fontSize: "16px",
        color: "#212426",
        fontWeight: 600,
        fontStyle: "normal",
        lineHeight: "150%",
    },
    fieldTitleText: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 400,
        color: "rgba(33, 36, 38, 0.90)",
        fontStyle: "normal",
        lineHeight: "150%",
    },
    fieldValueText: {
        color: "#212426",
        fontFamily: "Inter",
        fontSize: "14px",
        fontWeight: 500,
        fontStyle: "normal",
        lineHeight: "150%",
    },

    input: {
        color: "rgba(33, 36, 38, 0.90);",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "0.1px",
    },
    tripLabel: {
        color: "#92929D",
        lineHeight: "normal",
        letterSpacing: "0.1px",
        marginRight: "12px",
        whiteSpace: "nowrap",
        width: "145px",
    },
    inputBlack: {
        color: "#212426E5",
        fontFamily: "Inter",
        fontSize: "16px",
        fontWeight: 500,
    },
    menuRoot: {
        display: "flex",
        padding: "8px 0px",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        gap: "4px",
        alignSelf: "stretch",
    },
    cardContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(376px, 1fr))",
        gridGap: "32px",
    },
    card: {
        border: `1px solid #C3CAD9`,
        borderRadius: "16px",
        maxHeight: "100%",
        maxWidth: '476px',
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    image: {
        borderRadius: "8px",
        height: "153px",
        width: "153px",
        objectFit: 'cover',
        backgroundColor: 'white',
        marginBottom: "16px",
        cursor: 'pointer'
    },
    details: {
        flex: "1",
        display: "flex",
        flexDirection: "row",
    },
    title: {
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2, // Adjust the number of lines as needed
        textOverflow: 'ellipsis',
        whiteSpace: 'normal', // 'normal' is the default value
        cursor: 'pointer',
        width: '216px',
      },
    titleFontStyle: {
        color: "#212426",
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%"
    },
    fieldFontStyle: {
        color: "rgba(33, 36, 38, 0.90)",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        whiteSpace: 'nowrap',
        lineHeight: "150%"
    },
    valueFontStyle: {
        color: "#212426",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%"
    },
    underlineText: {
        borderBottom: '1px solid rgba(33, 36, 38, 0.90)', // Adjust the color and style as needed
        display: 'inline', // This is important to keep it inline with the text
    },
    subDetails: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr", // Two equal columns
        gap: "49px", // Adjust the gap between fields and values
        alignItems: "center", // Center content vertically in each grid cell
    },
    buttonContainerCard: {
        // marginTop: "24px",
        display: "flex",
        justifyContent: "space-between",
    },
    paginationItem: {
        "& .Mui-selected, & .Mui-selected:hover": {
            backgroundColor: "transparent", // Change this to the desired background color
            color: primaryColor, // Change this to the desired text color
        }
    },
    paginationItems: {
        top: '39%',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        paddingLeft: '32px',
        letterSpacing: '0.1px',
        position: 'absolute' as const,
        color: 'rgba(33, 36, 38, 0.80)',
    },
    paginationRoot: {
        margin: 'auto',
        '& li:first-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& li:last-child': {
            borderRadius: '8px',
            border: '1px solid rgba(33, 36, 38, 0.20)',
            padding: '4px 4.9px'
        },
        '& .MuiPaginationItem-root': {
            color: 'rgba(33, 36, 38, 0.50)',
            textAlign: 'center',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '24px',
            letterSpacing: '0.1px',
            display: 'flex',
            width: '39.206px',
            height: '32px',
            justifyContent: 'center',
            alignItems: 'center',
            '&.Mui-selected': {
                color: '#FE7F2D',
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        }
    },
    noBookingText: {
        color: 'rgba(33, 36, 38, 0.90)',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '120%',
    },
    dropdownContainer: {
        display: 'flex',
        gridColumnGap: '15px',
    },
    searchAndDropDownContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '15px'
    },
    "@media (max-width: 1280px)": {
        dropdownContainer: {
            gridColumnGap:'10px'
        },
        searchAndDropDownContainer: {
            flexDirection: "column", // Change to a column layout on smaller screens
            rowGap: "16px", // Adjust the spacing
        },
    },
    '@media (max-width: 768px)': {
        dropdownContainer: {
            flexDirection: 'column', // Change to a column layout on smaller screens
            rowGap: '16px', // Adjust the spacing
        },
    },
    '@media (max-width: 420px)': {
        card: {
            border: `1px solid #C3CAD9`,
            borderRadius: "16px",
            maxHeight: "100%",
            maxWidth: '280px',
            padding: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        details: {
            flex: "1",
            display: "flex",
            flexDirection: "column",
        },
        image: {
            borderRadius: "8px",
            height: "100%",
            width: "100%",
            objectFit: 'contain',
            backgroundColor: 'white',
            marginBottom: "16px",
            cursor: 'pointer'
        },
        button: {
            fontFamily: "Inter",
            fontWeight: 500,
            fontSize: "14px",
            // height: "56px",
            width: "100px",
            padding: "4px",
            textTransform: "initial",
            // borderRadius: "4px",
        },
        customBorderRight: {
            borderRight: '1px solid rgba(33, 36, 38, 0.20) !important',
            borderRadius: '0px !important',
        },
        secondaryButton: {
            backgroundColor: "transparent",
            maxWidth: '125px',
            color: "#212426",
            border: `none`,
        },
    }
}));


const TripCard = (props: {
    navigationTo: (path: string, id: string) => void;
    handleDeleteModal: (id: string) => void;
    isMobile: boolean;
    id: string;
    primary_image_url: string;
    title: string;
    city: string;
    average_rating: number;
    total_rating: number;
    booking_count: number;
    isImageLoaded: boolean;
    orderId :string

}) => {
    const classes = useStyles();

    // Extract props
    const {
        navigationTo,
        handleDeleteModal,
        id,
        isMobile,
        primary_image_url,
        title,
        city,
        average_rating,
        total_rating,
        booking_count,
        isImageLoaded,
        orderId
    } = props;
    
    return (
        <Box>
            <div className={classes.card}>
                <div className={classes.details}>
                    <div style={{ marginRight: "12px" }}>
                        {
                             <LazyImage onClick={() => navigationTo("SingleTripDetails", id)} src={primary_image_url} alt={title} className={classes.image} />
                        }
                    </div>
                    <div>
                        <Typography onClick={() => navigationTo("SingleTripDetails", id)} variant="h6" className={`${classes.title} ${classes.titleFontStyle}`}>
                            {title.length > 72 ? `${title.slice(0, 72)}...` : title}
                        </Typography>
                        <div className={classes.subDetails} style={{marginTop: '8px'}}>
                            <Typography className={classes.fieldFontStyle}>City </Typography>
                            <Typography className={classes.valueFontStyle}>{city}</Typography>
                        </div>
                        <div className={classes.subDetails}>
                            <Typography className={classes.fieldFontStyle}>Ratings </Typography>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' , cursor:'pointer'}} onClick={()=> navigationTo("ServiceProviderReviews" , "")}>
                                <Star style={{ color: primaryColor, height: '14px', marginBottom: '2.2px' }} />
                                <Typography className={`${classes.valueFontStyle} ${classes.underlineText}`}> 
                                    {` ${average_rating} (${total_rating})`}
                                </Typography>
                            </div>

                        </div>
                        <div className={classes.subDetails}>
                            <Typography className={classes.fieldFontStyle}>Bookings </Typography>
                            <Typography className={classes.valueFontStyle}>{booking_count}</Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.buttonContainerCard}>
                    <Button
                        className={`${classes.button} ${classes.primaryButton} ${classes.customBorderRight}`}
                        startIcon={!isMobile ? <img src={bookingIcon} alt="Booking" /> : null}
                        onClick={() => navigationTo("BookingDetails", orderId)}
                    >
                        Bookings
                    </Button>
                    <Button
                        className={`${classes.button} ${classes.secondaryButton} ${classes.customBorderRight}`}
                        startIcon={ !isMobile ? <img src={editIcon} alt="Edit" /> : null}
                        onClick={() => navigationTo("EditTrip", id)}
                    >
                        Edit
                    </Button>
                    <Button
                        className={`${classes.button} ${classes.secondaryButton}`}
                        startIcon={!isMobile ? <img src={deleteIcon} alt="Delete" /> : null}
                        onClick={() => handleDeleteModal(id)}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Box>
    );
}





const TripListComponent = ({
    navigation,
    tripList,
    tripTypeList,
    loader,
    page_options,
    isSearched,
    countryList,
    handleFilterChange,
    handleSearchChange,
    handleDeleteModal,
    handlePaginationChange
}: TripListProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 600;
    const isTabs: boolean = windowWidth < 1700;
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState('')
    const [isImageLoaded, setIsImageLoaded] = useState(true); // Initialize with true, assuming the image is initially loaded

    const handleImageLoad = () => {
        setIsImageLoaded(true); // Image loaded successfully
        console.log('loaded succesfully')
    };

    const handleImageError = () => {
        setIsImageLoaded(false); // Image failed to load
    };



    const navigationTo = (path: string, id: string) => {
        navigation.navigate(path, {
            id: id,
        })
    }


    const countryListData = [...countryList.map(data => ({
        value: data.id,
        label: data.name
    }))];

    const startRange = (page_options.page - 1) * Number(page_options.limit) + 1;
    const endRange = Math.min(page_options.page * Number(page_options.limit), page_options.totalItems);

    const rangeString = `${startRange} - ${endRange}`;




    return (

        <div style={{
            backgroundColor: '#FFF',
            borderRadius: '16px',
            boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)'
        }}>
            <Loader loading={loader} />

            <Box padding={"25px 32px 32px 29px"}>
                <Box className={classes.searchAndDropDownContainer}>
                    <TextField
                        id="search"
                        value={searchQuery}
                        placeholder="Search for something"
                        onChange={(event) => {
                            setSearchQuery(event.target.value);
                            handleSearchChange(event.target.value)
                        }}
                        InputProps={{
                            startAdornment: (
                                <img src={searchIcon} alt='search' style={{ width: '24px', height: '24px' }} />
                            ),
                            style: {
                                gap: '8px',
                                height: '48px',
                                padding: "12px",
                                display: 'flex',
                                width: isTabs ? '100%' : '376px',
                                borderRadius: '8px',
                                border: `1px solid`,
                                alignItems: 'center',
                                borderColor: '#C3CAD9',
                                alignContent: 'flex-start',
                                backgroundColor: "#ffffff",
                                color: "rgba(33, 36, 38, 0.90)",
                            },
                            classes: { input: classes.input },
                            disableUnderline: true,
                        }}
                    />
                    <Box className={classes.dropdownContainer}>
                        <FilterSelect
                            field="Country"
                            options={countryListData }
                            filterName="countryId"
                            handleFilterChange={handleFilterChange }
                        />
                        <FilterSelect
                            field="Trip type"
                            options={ tripTypeList}
                            filterName="tripType"
                            handleFilterChange={ handleFilterChange}
                        />
                        <FilterSelect
                            field="Sort by"
                            options={sortBy }
                            filterName="sortBy"
                            handleFilterChange={handleFilterChange }
                        />
                    </Box>
                </Box>
                <Divider style={{ margin: "32px 0", }} />
                {
                    tripList.length > 0 ?
                        <Box className={classes.cardContainer}>
                            {
                                tripList.map(data => {
                                    let orderId = data && data?.order_id&& data.order_id[0]||""                             
                                return(
                                    
                                    <Box key={data.id}>
                                        <img
                                            src={data.primary_image_url}
                                            alt="Image"
                                            onLoad={handleImageLoad}
                                            onError={handleImageError}
                                            style={{ display: 'none' }} 
                                        />
                                        <TripCard
                                            navigationTo={navigationTo}
                                            id={data.id}
                                            isMobile={isMobile}
                                            primary_image_url={data.primary_image_url}
                                            title={data.title}
                                            city={data.city}
                                            average_rating={data.average_rating}
                                            total_rating={data.total_rating}
                                            booking_count={data.booking_count}
                                            isImageLoaded={true}
                                            handleDeleteModal={handleDeleteModal}
                                            orderId = {orderId}
                                        />
                                    </Box>
                                )})
                            }
                        </Box>
                        : isSearched ?
                            <Box display={'flex'} alignItems={'center'} gridRowGap={'42px'}
                                flexDirection={'column'} marginBottom={'168px'} marginTop={'160px'}>
                                <img src={noTripMatched} alt='No Bookings' />
                                <Typography className={classes.noBookingText}>
                                    {"No trips are matching your search criteria"}
                                </Typography>
                            </Box>
                            : <Box display={'flex'} alignItems={'center'} gridRowGap={'42px'}
                                flexDirection={'column'} marginBottom={'168px'} marginTop={'160px'}>
                                <img src={noTripCreated} alt='No Bookings' />
                                <Typography className={classes.noBookingText}>
                                    {"No trips are created yet"}
                                </Typography>
                            </Box>
                }
                {page_options.totalItems > 12 &&
                    <>
                        <Divider style={{ margin: "32px 0", }} />
                        <Box position={"relative"}>
                            <Box display={"flex"} padding={"32px"}>
                                <Pagination
                                    count={page_options.totalPages}
                                    page={Number(page_options.page)}
                                    onChange={handlePaginationChange}
                                    // defaultPage={1}
                                    classes={{
                                        root: classes.paginationRoot,
                                        ul: classes.paginationItem
                                    }}
                                />
                            </Box>
                            <Typography className={classes.paginationItems}>
                                {rangeString} of {page_options.totalItems} items
                            </Typography>
                        </Box>
                    </>}
            </Box>

        </div>


    )
}

export default TripListComponent;