export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profileImage = require("../assets/profileImage.png");
export const isVefifyCheck = require("../assets/greenCheck.png");
export const saudiArabic = require("../assets/saudiArabic.png");
export const congratsTick = require("../assets/congratsTick.png");
export const close = require("../assets/close.png");
export const cameraIcon = require("../assets/add_a_photo.png");
export const downArrow = require("../assets/arrow_drop_down.png");
export const successImg = require("../assets/success.png");
export const visibilityOff = require("../assets/visibility_off.png");
export const calendarImg = require("../assets/calendar_month.png");
export const uploadCloudImg = require("../assets/uploadCloud.png");
export const arrow = require("../assets/arrow.svg");
export const tooltipIcon = require("../assets/tooltip.svg");
