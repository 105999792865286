import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { invalidError } from '../../blocks/email-account-login/src/assets';
import { successImg } from '../../blocks/email-account-registration/src/assets';
import { BootstrapButton } from '../../blocks/user-profile-basic/src/UserProfileCustomer.web';

interface CustomProps {
    isSuccess: boolean;
    title: string;
    decription: string;
    buttonText?: string;
    buttonLink?: string;
}

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 530,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px 20px',
        borderRadius: '16px',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        gap: 12
    },
    successIcon: {
        color: theme.palette.success.main,
        fontSize: 32,
    },
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: 32,
    },
}));

const CustomPage: React.FC<CustomProps> = (props) => {
    const { isSuccess, title, decription, buttonText, buttonLink } = props
    const classes = useStyles();

    return (
        <>
            <img src={isSuccess ? successImg : invalidError}
                width={'120px'} height={'120px'} alt="success" />
            <Typography style={{
                marginTop: '42px',
                textAlign: 'center',
                color: '#212426',
                fontFamily: 'Inter',
                fontSize: '32px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '130%'
            }}>
                {title}
            </Typography>
            <Typography style={{
                fontWeight: 400,
                fontSize: '16px',
                marginTop: '12px',
                fontFamily: 'Inter',
                textAlign: 'center',
                lineHeight: '24px',
                color: 'rgba(33, 38, 35, 0.75)'
            }}>
                {decription}
            </Typography>
            {buttonText && <BootstrapButton style={{ marginTop: '32px' }}>
                {buttonText}
            </BootstrapButton>}
        </>
    );
}

export default CustomPage;
