import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { primaryColor } from './theme';
import { makeStyles } from '@material-ui/core/styles';

export interface OptionRadioFilter {
  label: string;
  noOfTrips: number;
  value: string;
}

interface CustomRadioFilterProps {
  options: OptionRadioFilter[];
  title: string;
  handleRadioChange: (name: string, value: string) => void;
  selectedValue: string;
  stateName: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionPadding: {
    boxShadow: 'none',
  },
  accordionContentMargin: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0'
    }
  },

}));

const CustomRadioFilter: React.FC<CustomRadioFilterProps> = ({ options, title, selectedValue, handleRadioChange, stateName }) => {
  const classes = useStyles();
  // const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const handleOptionChange = (option: string) => {
    // setSelectedOption(option);
    handleRadioChange(stateName, option)
  };

  const toggleAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  return (
    <div>
      <Box maxWidth={"300px"}>
        <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
          <AccordionSummary className={classes.accordionContentMargin}>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
              <Typography>{title}</Typography>
              <IconButton>
                {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
              </IconButton>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {options.map((option) => (
                <div key={option.label} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Radio
                        style={{
                          color: selectedValue === option.value ? primaryColor : '#212426',
                        }}
                        checked={selectedValue === option.value}
                        onChange={() => handleOptionChange(option.value)}
                      />
                    }
                    label={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Inter' }}>
                        <div>
                          {option.label}
                        </div>
                      </div>
                    }
                  />
                  <div>
                    <Typography>{option.noOfTrips}</Typography>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>
    </div>
  );
};

export default CustomRadioFilter;
