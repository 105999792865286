Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.httpGetMethod = 'GET'
exports.advancedsearchApiContentType = 'application/json'
exports.getAdvancedSearchApiEndPoint = 'advanced_search/search'
exports.errorTitle = 'Error'
exports.topTravelBuddies = 'account_block/accounts/top_buddies'
exports.topTravelAgencies = 'account_block/accounts/top_agencies'
exports.serviceProviderDetails = 'account_block/accounts/service_provider'
exports.getCountryListEndpoint = 'account_block/country_code_and_flags'
exports.getCurrencyListEndpoint = "bx_block_admin/currencies"
exports.tripTypeEndpoint = "bx_block_productdescription/trip_types";
exports.travelerTypeEndpoint = "bx_block_productdescription/traveler_types";
exports.vibeListEndpoint = "bx_block_productdescription/vibes";
exports.timeEndpoint = "bx_block_productdescription/periods";
exports.advancedSearchEndpoint = "bx_block_advanced_search/trips";
exports.addReviewEndpoint = "bx_block_reviews/reviews";
exports.getMyChatsMessageApiEndpoint = 'bx_block_notifications/chat_boxes/:current_user?participent2=';
exports.sendMyChatsMessageApiEndpoint = 'bx_block_notifications/chat_boxes/:current_user/messages?participent2=';
// Customizable Area End
