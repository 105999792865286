// Customizable Area Start
import React from "react";
import {
    Typography,
    Box
} from "@material-ui/core";

import { createTheme, ThemeProvider, Theme, withStyles } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#FE7F2D',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: '20px 0px',
            fontSize: 12, // Custom font size for subtitle1
            fontWeight: 400, // Custom font weight for subtitle1
        },
        // Add more custom typography styles here as needed
    },
});

import ProductDescriptionController, {
    Props,
} from "./ProductDescriptionController";
import Step1CET from "../../../components/src/Step1CET";
import Step2CET from "../../../components/src/Step2CET";
import Step3CET from "../../../components/src/Step3CET";
import Step4CET from "../../../components/src/Step4CET";
import CustomModal from "../../../components/src/CustumModal";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import CancelTripModal from "../../../components/src/CancelTripModal";
import Loader from "../../../components/src/Loader.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";



export default class CreateTrip extends ProductDescriptionController {

    constructor(props: Props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        const {
            activeStep,
            step1CETValues,
            step2CETValues,
            step3CETValues,
            step4CETValues,
            openModal,
            modalMsg,
            isSuccess,
            responseStatusModal,
            countryList,
            cityList,
            currencyList,
            mode,
            cancelTripCreationModal,
            loader,
            tripType,
            travelerType,
            vibeList,
            time
        } = this.state;

        
        return (
            <ThemeProvider theme={theme}>
                <div
                    className={classes.mainContainer}
                >
                    <div className={classes.marginStyles}>
                    <Box className={classes.breadCrumbStyle}>
                        <Breadcrumbs list={[
                            {
                                title: 'Dashboard',
                                link: '/Dashboard'
                            },
                            {
                                title: 'My Trips',
                                link: '/TripList'
                            },
                            {
                                title: mode === 'create' ? 'Create Trip' : 'Edit Trip'
                            }
                        ]} />
                    </Box>
                    <Typography
                        className={classes.mainTitle}
                    >
                        My trips
                    </Typography>
                    <Box>
                        {
                            activeStep === 0 &&
                            <Step1CET
                                initialState={step1CETValues}
                                submitStep1CET={this.submitStep1CET}
                                cancelTrip={this.openCancelTripCreationModal}
                                countries={countryList}
                                cities={cityList}
                                getCityRequest={this.getCityListAPIRequest}
                                mode={mode}
                                tripType={tripType}
                                vibeList={vibeList}
                                timeList={time}
                            />
                        }
                    </Box>
                    <Box>
                        {
                            activeStep === 1 &&
                            <Step2CET
                                initialState={step2CETValues}
                                submitStep2CET={this.submitStep2CET}
                                cancelTrip={this.openCancelTripCreationModal}
                                backStep2CET={this.backStep2CET}
                                mode={mode}
                            />
                        }
                    </Box>
                    <Box>
                        {
                            activeStep === 2 &&
                            <Step3CET
                                initialState={step3CETValues}
                                submitStep3CET={this.submitStep3CET}
                                backStep3CET={this.backStep3CET}
                                cancelTrip={this.openCancelTripCreationModal}
                                mode={mode}
                                travelerType={travelerType}
                            />
                        }
                    </Box>
                    <Box>
                        {
                            activeStep === 3 &&
                            <Step4CET
                                initialState={step4CETValues}
                                submitStep4CET={this.submitStep4CET}
                                backStep4CET={this.backStep4CET}
                                cancelTrip={this.openCancelTripCreationModal}
                                currencyList={currencyList}
                                mode={mode}
                            />
                        }
                    </Box>
                    <CustomModal
                        open={openModal}
                        onClose={this.onCloseModal}
                        message={modalMsg}
                        mode={mode}
                        dateCancellationReason={this.handleDateCancellationReason}
                        editTripRequest={this.createTripRequest}
                        modifiedDate={step1CETValues?.modified_trip_calendar}
                        addReason={this.addReason}
                        updateTrip={this.createTripRequest}
                    />
                    <ResponseStatusModal
                        open={responseStatusModal}
                        isSuccess={isSuccess}
                        message={modalMsg}
                        onClose={this.onCloseAndNavigate}
                    />
                    <CancelTripModal
                    open={cancelTripCreationModal}
                    onClose={this.closeCancelTripCreationModal}
                    handleCancelTripCreation={this.handleCancelTripCreation}
                    />
                    <Loader
                        loading={loader}
                    />
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}

export const createTripStyles = (theme: Theme) => ({
    
    mainContainer: {
        backgroundColor: '#F6FBFB',
        marginBottom: '90px'
    },
    mainTitle: {
        fontFamily: 'Inter',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        margin: "0px auto 32px", // Center the div horizontally
    },
    breadCrumbStyle: {
        display: 'inline'
    },
    marginStyles: {
        margin: 'none'
    },
    [theme.breakpoints.down(1280)]: {
        breadToTrip: {
            marginBottom: '48px'
        },
        marginStyles: {
            margin: '0 48px'
        },
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "46px auto 27px", // Center the div horizontally
        },
        mainContainer: {
            backgroundColor: '#F6FBFB',
            marginBottom: '72px'
        },
    },
    [theme.breakpoints.down(768)]: {
        breadToTrip: {
            marginBottom: '32px'
        },
        marginStyles: {
            margin: '0 16px'
        },
        mainTitle: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "29px auto 16px", // Center the div horizontally
        },
        mainContainer: {
            backgroundColor: '#F6FBFB',
            marginBottom: '48px'
        },
    },
    [theme.breakpoints.up(1280)]: {
        breadCrumbStyle: {
            display: 'none'
        }
    }
})
const CreateTripWeb = withStyles(createTripStyles)(CreateTrip)
export { CreateTripWeb }
// Customizable Area End
