import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Divider, Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import { primaryColor } from './theme';
import { makeStyles } from '@material-ui/core/styles'

interface Option {
  name: string;
  icon: React.ReactElement;
  number: number;
  value: string;
}

interface ServiceRatingProps {
  serviceRatingChange: (value: string) => void;
  selectedValue: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionPadding: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      padding: '0'
    }
  },
  accordionContentMargin: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0'
    }
  },
  accordionDetails: {
    padding: '0',
    '& .MuiAccordionDetails-root': {
      padding: '0 !important'
    }
  }

}));


const ServiceRatingFilter: React.FC<ServiceRatingProps> = ({serviceRatingChange, selectedValue}) => {
  const classes = useStyles();
  // const [selectedOptions, setSelectedOptions] = useState<string>('');
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const handleOptionChange = (option: string) => {
    // const updatedValues = selectedOptions.includes(option)
    //   ? selectedOptions.filter((value) => value !== option)
    //   : [...selectedOptions, option];

    // setSelectedOptions(option);
    serviceRatingChange(option)
};


  const toggleAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };
  return (
    <div>
     <Box maxWidth={"300px"}>
     <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
        <AccordionSummary className={classes.accordionContentMargin}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
            <Typography>Service Rating</Typography>
            <IconButton>
              {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {options.map((option) => (
                <div key={option.number} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        style={{
                          color: selectedValue.includes(option.value) ? primaryColor : '#212426',
                        }}
                        checked={selectedValue.includes(option.value)}
                        onChange={() => handleOptionChange(option.value)}
                      />
                    }
                    label={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontFamily: 'Inter' }}>
                        <div>
                          {Array.from({ length: option.number }).map((_, index) => (
                            <React.Fragment key={index}>{option.icon}</React.Fragment>
                          ))}
                          {option.name}
                        </div>
                      </div>
                    }
                  />
                  <div>
                    <Typography>{'231'}</Typography>
                  </div>
                </div>
              ))}
            </div>
        </AccordionDetails>
      </Accordion>
     </Box>
    </div>
  );
};

const options: Option[] = [
  { name: ' & up', icon: <StarIcon style={{ color: primaryColor, fontSize: '20px' }} />, number: 4, value: "4" },
  { name: ' & up', icon: <StarIcon style={{ color: primaryColor, fontSize: '20px' }} />, number: 3, value: "3" },
  { name: ' & up', icon: <StarIcon style={{ color: primaryColor, fontSize: '20px' }} />, number: 2, value: "2" },
  { name: ' & up', icon: <StarIcon style={{ color: primaryColor, fontSize: '20px' }} />, number: 1, value: "1" },
  // Add more options as needed
];

export default ServiceRatingFilter;
