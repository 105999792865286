import React, { useState } from "react";
import { makeStyles } from '@material-ui/core/styles'
import { useWindowWidth } from "./utils";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Box,
  IconButton
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericTextField from "./CurrencyTextField";
import { ICurrency } from "../../blocks/ProductDescription/src/Interface.web";
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

interface IPriceFilterProps {
  onChange: (val: string, name: string) => void
  priceFrom: string
  priceTo: string
  currencyList: ICurrency[]
  currency: string
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionPadding: {
    boxShadow: 'none',
  },
  accordionContentMargin: {
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0'
    }
  },

}));
const TripPriceFilter: React.FC<IPriceFilterProps> = ({
  onChange,
  priceFrom,
  priceTo,
  currency,
  currencyList
}) => {
  const classes = useStyles();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth < 768;
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };
  return (
    <Box maxWidth="300px">
      <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
            <Typography>Trip Price ({currency})</Typography>
            <IconButton>
              {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
            </IconButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%">
            <Box display="Flex" flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
              <Typography>From</Typography>
              <GenericTextField
                currency={currency}
                currencyList={currencyList}
                value={priceFrom}
                priceFieldName="priceFrom"
                onChange={onChange}
                error={"errors.price_per_person as string"}
                touch={false}
                fullwidth={false}
                inputStyles={{ height: "40px", maxWidth: "180px" }}
                inputType="text"
              />
            </Box>
            <Box display="Flex" flexDirection="row" justifyContent="space-between" width="100%" alignItems="center" marginTop="10px">
              <Typography>To</Typography>
              <GenericTextField
                currency={currency}
                currencyList={currencyList}
                value={priceTo}
                priceFieldName="priceTo"
                onChange={onChange}
                error={"errors.price_per_person as string"}
                touch={false}
                fullwidth={false}
                inputStyles={{ height: "40px", maxWidth: "180px" }}
                inputType="text"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}


export default TripPriceFilter