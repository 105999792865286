Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "bx_block_order_management/orders";
exports.countsAPiEndPoint = "bx_block_order_management/bookings_count";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.showTripByIDEndPoint = 'bx_block_productdescription/trips';
exports.advancedSearchEndpoint = "bx_block_advanced_search/trips";
exports.tripsByTypeEndpoint = 'bx_block_productdescription/trips_by_type'
exports.getBookingTypeEndpoint = 'bx_block_order_management/orders'
exports.cancelAPiEndPoint = "bx_block_order_management/cancel_order";
exports.addReviewEndpoint = "bx_block_productdescription/reviews";
exports.addTocartEndPoint = "bx_block_order_management/orders"

exports.verifiedText = 'Verified';
exports.showMoreText = 'Show more...';
exports.ratingText = 'rating';
exports.checkAvailability = 'Check availability';
exports.writeReviewBtn = 'Write a Review';

exports.noBookingsText = 'No one has made booking yet';

// Customizable Area End