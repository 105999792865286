
import React from 'react';
import {
    Box,
    Grid,
    Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { Categories } from '../../blocks/ProductDescription/src/Interface.web';
import { useWindowWidth } from './utils';
import CustomRadioGroup from './CustomRadioGroup';
import CustomSelectField from './CustomSelectField';
import SingleDatePicker from './SingleDatePicker';
import CustomTextField from './CustomTextField';
import CountrySelect from './CountrySelect';
import CitySelect from './CitySelect';
import CustomCheckboxGroup from './CustomCheckBoxGroup';
import { CombinedItinerary } from '../../blocks/customform/src/Interface.we';
import { FormikErrors, FormikTouched } from 'formik';
import { CityData, CountryData } from '../../blocks/email-account-registration/src/Interface.web';

interface CFItineraryProps {
    values: CombinedItinerary;
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<CombinedItinerary>;
    touched: FormikTouched<CombinedItinerary>;
    countryList: CountryData[],
    cityList: CityData[],
    vibeList: Categories[],
    getCityRequest: (id: string) => void;
}

const helpList: Categories[] = [
    {
        label: 'I need to plan my trip',
        value: 'plan_my_trip'
    },
    {
        label: 'I need to book an activity',
        value: 'book_an_activity'
    },
    {
        label: 'I want to meet a travel buddy',
        value: 'meet_a_travel_buddy'
    },
    {
        label: "I don't know yet",
        value: 'do_not_know'
    },
]


const useStyles = makeStyles((theme) => ({
    itineraryFieldHeaderPI : {
        color: 'rgba(33, 36, 38, 0.80)',
        fontSize: '18px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '133%',
        fontWeight: 500,
    },
    distanceBtwnSFPI: {
        marginTop: '48px'
    },
    distanceBtwnOFPI: {
        marginTop: '32px'
    },
    [theme.breakpoints.down(768)]: {
        distanceBtwnOFPI: {
            marginTop: '32px'
        },
    }
}));

const CFItineraryInformation: React.FC<CFItineraryProps> = ({
   values,
   setFieldValue,
   errors,
   touched,
   countryList,
   cityList,
   vibeList,
   getCityRequest
}) => {
    const classes = useStyles();
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    return (
        <Box>
            <Grid container spacing={3} justifyContent="flex-start">
                <Grid item sm={4} xs={12}>
                    <Typography className={classes.itineraryFieldHeaderPI}>
                        1. How can we help you? <span style={{ color: '#F00' }}>*</span>
                        {
                            errors.type_of_help && touched.type_of_help &&
                            <span style={{ fontSize: '12px', fontWeight: 400, color: '#FF0000' }}><br /> {errors.type_of_help}</span>
                        }
                    </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CustomRadioGroup
                        fieldName="type_of_help"
                        options={helpList}
                        error={errors.type_of_help as string}
                        value={values.type_of_help}
                        onChange={setFieldValue}
                        touched={touched.type_of_help as boolean}
                        isPlanItinerary={true}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnSFPI}>
                <Grid item sm={4} xs={12}>
                    <Typography className={classes.itineraryFieldHeaderPI}>1.	Do you have a date? <span style={{ color: '#F00' }}>*</span></Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CustomSelectField
                        fieldName={'have_date'}
                        fieldValues={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
                        selectedValue={values.have_date}
                        fieldTouched={touched.have_date as boolean}
                        fieldError={errors.have_date as string}
                        setFieldValue={setFieldValue}
                        planItineraryPlaceholderText={true}
                    />
                </Grid>
            </Grid>
            {
                values.have_date === "true" ?
                    <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnOFPI}>
                        <Grid item sm={4} xs={12}>
                            {""}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <SingleDatePicker
                                fieldTitle={'From'}
                                fieldName={'from_date'}
                                selectedDate={values.from_date as string}
                                startDate={values.from_date as string}
                                onChange={setFieldValue}
                                error={errors.from_date as string}
                                touched={touched.from_date as boolean}
                                placeholder={'From'}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <SingleDatePicker
                                fieldTitle={'To'}
                                fieldName={'to_date'}
                                selectedDate={values.to_date as string}
                                startDate={values.from_date as string}
                                onChange={setFieldValue}
                                error={errors.to_date as string}
                                touched={touched.to_date as boolean}
                                placeholder={'To'}
                            />
                        </Grid>
                    </Grid>
                    : null
            }
            <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnSFPI}>
                <Grid item sm={4} xs={12}>
                    <Typography className={classes.itineraryFieldHeaderPI}>1.	How many persons <span style={{ color: '#F00' }}>*</span></Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CustomTextField
                        fieldTitle={''}
                        placeholder={'Enter number'}
                        type='number'
                        value={values.persons}
                        fieldName="persons"
                        onChange={setFieldValue}
                        error={errors.persons as string}
                        touched={touched.persons as boolean}
                        isApplyDiscount={true}
                        neitherOptionalOrRequired={true}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnSFPI}>
                <Grid item sm={4} xs={12}>
                    <Typography className={classes.itineraryFieldHeaderPI}>1.	Do you know where you're going? <span style={{ color: '#F00' }}>*</span></Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CustomSelectField
                        fieldName={'know_destination'}
                        fieldValues={[{ label: "Yes", value: "true" }, { label: "Not yet", value: "false" }]}
                        selectedValue={values.know_destination}
                        fieldTouched={touched.know_destination as boolean}
                        fieldError={errors.know_destination as string}
                        setFieldValue={setFieldValue}
                        planItineraryPlaceholderText={true}
                    />
                </Grid>
            </Grid>
            {
                values.know_destination === "true" ?
                    <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnOFPI}>
                        <Grid item sm={4} xs={12}>
                            {""}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <CountrySelect
                                countries={countryList}
                                countryTouched={touched.country as boolean}
                                selectedCountry={values.country as string}
                                setFieldValue={setFieldValue}
                                countryError={errors.country as string}
                                getCityListRequest={getCityRequest}
                                isDashboard={true}
                            />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <CitySelect
                                cities={cityList}
                                cityTouched={touched.city as boolean}
                                selectedCity={values.city as string}
                                setFieldValue={setFieldValue}
                                cityError={errors.city as string}
                                isDashboard={true}
                            />
                        </Grid>
                    </Grid>
                    : null
            }
            <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnSFPI}>
                <Grid item sm={4} xs={12}>
                    <Typography className={classes.itineraryFieldHeaderPI}>1.	Do you have a budget? <span style={{ color: '#F00' }}>*</span></Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                    <CustomSelectField
                        fieldName={'have_buget'}
                        fieldValues={[{ label: "Approximately", value: "approximately" }, { label: "No", value: "no" }, { label: "Open Budget", value: "open_budget" }]}
                        selectedValue={values.have_buget}
                        fieldTouched={touched.have_buget as boolean}
                        fieldError={errors.have_buget as string}
                        setFieldValue={setFieldValue}
                        planItineraryPlaceholderText={true}
                    />
                </Grid>
            </Grid>
            {
                values.have_buget === "approximately" ?
                    <Grid container spacing={3} justifyContent="flex-start" className={classes.distanceBtwnOFPI}>
                        <Grid item sm={4} xs={12}>
                            {""}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            <CustomTextField
                                fieldTitle={'Enter amount'}
                                placeholder={''}
                                type='number'
                                endAdorment={'SAR'}
                                value={values.budget_amount as string}
                                fieldName="budget_amount"
                                onChange={setFieldValue}
                                error={errors.budget_amount as string}
                                touched={touched.budget_amount as boolean}
                                isApplyDiscount={true}
                            />
                        </Grid>
                    </Grid>
                    : null
            }
            <Grid container spacing={3} className={classes.distanceBtwnSFPI}>
                <Grid item sm={4} xs={5}>
                    <Typography
                        className={classes.itineraryFieldHeaderPI}
                    >
                        {'1. What would you like to do?'} <span style={{ color: 'red' }}> *</span>
                        {
                            errors.vibes && touched.vibes ?
                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#FF0000' }}><br /> {errors.vibes}</span>
                                :
                                <span style={{ fontSize: '14px', fontWeight: 400, whiteSpace: 'nowrap' }}><br /> {"Select at least 1"}</span>
                        }
                    </Typography>
                </Grid>
                <Grid item sm={8} xs={7}>
                    <CustomCheckboxGroup
                        fieldName="vibes"
                        options={vibeList}
                        error={errors.vibes as string}
                        selectedValues={values.vibes}
                        onChange={setFieldValue}
                        touched={touched.vibes as boolean}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default CFItineraryInformation;
