// Customizable Area Start
import React from "react";
import {
    Typography,
    Box,
} from "@material-ui/core";

import { createTheme, ThemeProvider, Theme, withStyles } from '@material-ui/core/styles';
import CustomModal from "../../../components/src/CustumModal";

const theme = createTheme({
    palette: {
        primary: {
            main: '#FE7F2D',
            contrastText: '#fff',
        },
    },
    typography: {
        fontFamily: 'Inter, sans-serif',
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightBold: 600,
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: '20px 0px',
            fontSize: 12, // Custom font size for subtitle1
            fontWeight: 400, // Custom font weight for subtitle1
        },
        // Add more custom typography styles here as needed
    },
});

import ProductDescriptionController, {
    Props,
} from "./ProductDescriptionController";
import TripDetailsComponent from "../../../components/src/TripDetailsComponent";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";




export default class TripDetails extends ProductDescriptionController {

    constructor(props: Props) {
        super(props);
    }

    render() {

        const { classes } = this.props;

        const {
            step1CETValues,
            step2CETValues,
            step3CETValues,
            step4CETValues,
            openModal,
            modalMsg,
            sureDelete,
            responseStatusModal,
            isSuccess,
            tripType,
            travelerType,
            time
        } = this.state;

        return (
            <ThemeProvider theme={theme}>
                <div
                    style={{
                        backgroundColor: '#F6FBFB',
                        marginBottom: '90px'
                    }}
                >
                    <Typography
                        className={classes.mainTitleStyle}
                    >
                        Trip details
                    </Typography>
                    <Box>
                        <TripDetailsComponent
                            step1CETValues={step1CETValues}
                            step2CETValues={step2CETValues}
                            step3CETValues={step3CETValues}
                            step4CETValues={step4CETValues}
                            tripTypeList={tripType}
                            travelerTypeList={travelerType}
                            timeList={time}
                            navigation={this.props.navigation}
                            openModal={this.openModal}
                            gotoTripCalendar={this.gotoTripCalendarPage}
                        />
                    </Box>
                    <CustomModal
                        open={openModal}
                        onClose={this.onCloseModal}
                        message={modalMsg}
                        mode="deleteTrip"
                        deleteTripApiRequest={this.deleteTripAPIRequest}
                        sureDelete={sureDelete}
                        handleProceedwithDeleteTripReason={this.handleProceedwithDeleteTripReason}
                        addReason={this.addReason}
                    />

                     <ResponseStatusModal
                        open={responseStatusModal}
                        isSuccess={isSuccess}
                        message={modalMsg}
                        onClose={this.onCloseModal}
                    />
                </div>
            </ThemeProvider>
        );
    }
}
export const singleTripStyles = (theme: Theme) => ({
    mainTitleStyle: {
        fontFamily: 'Inter',
        fontSize: '32px',
        fontStyle: 'normal',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#212426',
        margin: "22px auto 32px", // Center the div horizontally
    },
    breadCrumbRStyle: {
        display: 'inline'
    },
    marginRStyles: {
        margin: 'none'
    },
    [theme.breakpoints.down(1280)]: {
        marginRStyles: {
            margin: '0 48px'
        },
        mainTitleStyle: {
            fontFamily: 'Inter',
            fontSize: '28px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.down(768)]: {
        marginRStyles: {
            margin: '0 16px'
        },
        mainTitleStyle: {
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            lineHeight: 'normal',
            fontWeight: 600,
            color: '#212426',
            margin: "22px auto 32px", // Center the div horizontally
        },
    },
    [theme.breakpoints.up(1280)]: {
        breadCrumbRStyle: {
            display: 'none'
        }
    }
})
const SingleTripWeb = withStyles(singleTripStyles)(TripDetails)
export { SingleTripWeb }
// Customizable Area End
