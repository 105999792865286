import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";


const ApiRequest = (values: any) => {
	const { header, endPoint, payload, method } = values;

	const requestMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
	);

	requestMessage.addData(
		getName(MessageEnum.RestAPIResponceEndPointMessage),
		endPoint
	);

	requestMessage.addData(
		getName(MessageEnum.RestAPIRequestHeaderMessage),
		JSON.stringify(header)
	);

	payload &&
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			payload
		);

	requestMessage.addData(
		getName(MessageEnum.RestAPIRequestMethodMessage),
		method
	);
	return requestMessage;
};

export default ApiRequest;
