// Customizable Area Start
import React, { useContext } from "react";
import { UserProfileI } from "./AuthProviderController.web";
export interface AuthContextType {
  currentUser: UserProfileI;
  handleResetProfile: () => void;
  handleLogOut: () => void;
  cartItems :any
}
const defaultContext: AuthContextType = {
  currentUser: {
    id: 0,
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: "",
    gender: "",
    full_phone_number: "",
    facebook_link: "",
    instagram_link: "",
    interest_type: "",
    nationality: "",
    country_of_residence: "",
    alpha_code: "",
    profile_image: "",
    joining_date: "",
    phone_number: {
      country_code: "",
      number: ""
    },
    account_type: ""
  },
  handleResetProfile: () => {},
  handleLogOut: () => {},
  cartItems : []
};

export const AuthContext = React.createContext<AuthContextType>(defaultContext);

export function useAuth() {
  return useContext(AuthContext);
}

// Customizable Area End
