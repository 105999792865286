import React from "react";
import {
  imgImageLoader,
  imgErrorImage,
} from "../../blocks/customform/src/assets";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}
const LazyImage = ({ src, ...props }: ImageProps) => {
  const [imgSrc, setSrc] = React.useState(imgImageLoader || src);

  const onLoad = React.useCallback(() => {
    if (src) {
      setSrc(src);
    }
  }, [src]);

  const onError = React.useCallback(() => {
    setSrc(src || imgErrorImage);
  }, [imgErrorImage, imgImageLoader]);

  React.useEffect(() => {
    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);
    img.addEventListener("error", onError);
    return () => {
      img.removeEventListener("load", onLoad);
      img.removeEventListener("error", onError);
    };
  }, [src, onLoad]);


  return (
      <img
        {...props}
        // @ts-ignore
        loading="lazy" 
        src={imgSrc}
        
      />
  );
};

export default LazyImage;

