Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.getCountryListEndpoint = 'account_block/country_code_and_flags';
exports.getTripListEndpoint = 'bx_block_productdescription/trips';
exports.tripTypeListEndpoint = "bx_block_productdescription/trip_types";
// Customizable Area End