import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest";
import { 
  BuddyEntityDetailsValues, BusinessDetailsValues, 
  CityData, 
  CountryData, EntityDetailsValues, PersonalDetailsValues
} from "./Interface.web";
import { handleScrollToTop } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  activeStep: number;
  openVerifyModal: boolean;
  otp: string;
  isResendClick: boolean;
  enableResendCode: boolean;
  resendCount: number;
  resetTimer: string;
  timer:{
    minutes: string;
    seconds: string;
  },
  email: string;
  mobile: string;
  authToken: string;
  otpErrorMsg: string;
  phoneErrorMsg: string;
  emailErrorMsg: string;
  whichFieldVarifying: string;
  fromWichButtonSendingOtp: string;
  isEmailVarified: boolean;
  isPhoneVarified: boolean;
  modalForEmail: boolean;
  successScreen: boolean;
  countryList: CountryData[];
  cityList: CityData[];
  errorMsg: string;
  personalDetailsValues: PersonalDetailsValues;
  entityDetailsValues: EntityDetailsValues;
  businessDetailsValues: BusinessDetailsValues;
  buddyEntityDetailsValues: BuddyEntityDetailsValues;
  personalDetailsResponse: any;
  entityDetailsResponse: any;
  businessDetailsResponse: any;
  loading: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TravelAgencyRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetCountryList: string = '';
  apiCallIdGetCityList: string = '';
  apiCallIdSendEmailOrSmsOtp: string = '';
  apiCallIdVerifyEmailOrSmsOtp: string = '';
  apiCallIdTravelAgencyRegistration: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
    activeStep: 0,
    openVerifyModal: false,
    otp: '',
    isResendClick: false,
    enableResendCode: false,
    resendCount: 0,
    resetTimer: '',
    timer: {
      minutes: '1',
      seconds: '00'
    },
    email: '',
    mobile: '',
    authToken: '',
    otpErrorMsg: '',
    phoneErrorMsg: '',
    emailErrorMsg: '',
    whichFieldVarifying: '',
    fromWichButtonSendingOtp: '',
    isEmailVarified: false,
    isPhoneVarified: false,
    modalForEmail: false,
    successScreen: false,
      countryList: [],
      cityList: [],
      errorMsg: '',
      personalDetailsValues: {
        fname: '',
        lname: '',
        email: '',
        phoneNumber: '',
        onlyPhone: '',
        password: '',
        facebook_link: '',
        instagram_link: '',
        website_link: '',
        service_provider_type: '',
      },
      entityDetailsValues: {
        agency_logo: null,
        agency_display_name: '',
        about_me: '',
        company_legal_name: '',
        id_number: '',
        commercialPdf: null,
        permitPdf: { name: '' },
        certificatePdf: { name: '' },
        vat_number: ''
      },
      businessDetailsValues: {
        bankName: '',
        accountName: '',
        accountNumber: '',
        iban: '',
        streetName: '',
        country: null,
        city: null,
        zipCode: '',
        agreeTermsAndCondition: false
      },
      buddyEntityDetailsValues: {
        buddy_profile_picture: null,
        buddy_name: '',
        about_me:'',
        id_number: '',
        vat_number: '',
        commercialPdf: null,
        tourismPdf: null,
        permitPdf: null,
      },
      personalDetailsResponse: {},
      entityDetailsResponse: {},
      businessDetailsResponse: {},
      loading: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdGetCountryList]: this.getCountryListApiResponse,
      [this.apiCallIdSendEmailOrSmsOtp]: this.getSendEmailOrSmsResponse,
      [this.apiCallIdVerifyEmailOrSmsOtp]: this.getVarifyEmailOrSmsResponse,
      [this.apiCallIdTravelAgencyRegistration]: this.getTravelAgencyRegistrationResponse,
      [this.apiCallIdGetCityList]: this.getCityListApiResponse,
     
      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
  }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getCountryListRequest()
}

// API Request Functions

  getCountryListRequest = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
  });
  this.apiCallIdGetCountryList = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
  } 

  getCityListRequest = (country: string) => {
    
    const countryId = country
    ? (this.state.countryList.find((countryData: CountryData) => countryData?.name === country)?.id || null)
    : null;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
  };
  const requestMessage = ApiRequest({
      header: header,
      endPoint: `account_block/country/${countryId}/cities`,
      method: "GET",
  });
  this.apiCallIdGetCityList = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
  } 


  sendEmailOrSmsOtpRequest = (field: string, button: string) => {

    this.setState({
      isResendClick: true,
      fromWichButtonSendingOtp: button,
      otp: '',
      otpErrorMsg: '',
      resetTimer: new Date().getTime().toString(),
    })

    const { email, mobile } = this.state;

    let requestBody = {};

    if(field === 'email'){
      this.setState({whichFieldVarifying: 'email'})
      requestBody = {
        data: {
          attributes: {
            email: email
          }
        }
      };

    }else{
      this.setState({whichFieldVarifying: 'mobile'})
      requestBody = {
        data: {
          attributes: {
            full_phone_number: mobile.replace(/\s/g, "")
          }
        }
      };
    }

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };


    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.sendEmailOtpApiEndPoint,
      payload: JSON.stringify(requestBody),
      method: "POST",
    });
    this.apiCallIdSendEmailOrSmsOtp = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  verifyEmailOrSmsOtpRequest = () => {

    const { authToken, otp } = this.state;

     const requestBody = {
        data: {
          attributes: {
            token: authToken,
            otp_code: otp,
          }
        }
      };

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken
    };


    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.emailOtpVerifyApiEndPoint,
      payload: JSON.stringify(requestBody),
      method: "POST",
    });
    this.apiCallIdVerifyEmailOrSmsOtp = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  traveleAgencyRegistrationRequest = () => {

    this.setState({
      loading: true
    })

    const {
      email, fname, facebook_link, instagram_link,
      lname, phoneNumber, password, service_provider_type,
      website_link
    } = this.state.personalDetailsValues;

    const {
      agency_logo, agency_display_name, company_legal_name, about_me,
      id_number, certificatePdf, commercialPdf, permitPdf, vat_number
    } = this.state.entityDetailsValues;

    const {
      accountName, accountNumber, bankName, iban, streetName,
      city, zipCode, country
    } = this.state.businessDetailsValues;

    const {
      id_number: buddy_id_number, about_me: buddy_about_me,
      buddy_profile_picture, buddy_name, commercialPdf: buddy_commercialPdf, 
       tourismPdf, vat_number: buddy_vat_number
    } = this.state.buddyEntityDetailsValues

    const formdata = new FormData();
    formdata.append("first_name", fname);
    formdata.append("last_name", lname);
    formdata.append("full_phone_number", phoneNumber.replace(/\s/g, ''));
    formdata.append("email", email);
    formdata.append("password", password);
    formdata.append("service_provider", service_provider_type)
    facebook_link && formdata.append("facebook_link", facebook_link);
    instagram_link && formdata.append("instagram_link", instagram_link);
    website_link && formdata.append("website_link", website_link);
    formdata.append("language","english");
    formdata.append("country_id", this.getCountryId(country) as string);
    if( service_provider_type == 'agency' ){
    formdata.append("entity_details[agency_logo]", agency_logo);
    formdata.append("entity_details[id_number]", id_number);
    formdata.append("entity_details[display_name]", agency_display_name);
    formdata.append("entity_details[company_legal_name]", company_legal_name);
    formdata.append("entity_details[about_me]", about_me)
    formdata.append("entity_details[commerical_registration_document]", commercialPdf);
    permitPdf?.name && formdata.append("entity_details[permit_document]", permitPdf);
    certificatePdf?.name && formdata.append("entity_details[certificate_file]", certificatePdf);
    vat_number && formdata.append("entity_details[vat_number]", vat_number);
    } else {
      formdata.append("entity_details[id_number]", buddy_id_number);
      formdata.append("entity_details[display_name]", buddy_name);
      buddy_about_me && formdata.append("entity_details[about_me]", buddy_about_me);
      buddy_profile_picture?.name && formdata.append("profile_image", buddy_profile_picture);
      buddy_commercialPdf?.name && formdata.append("entity_details[commerical_registration_document]", buddy_commercialPdf);
      tourismPdf?.name && formdata.append("entity_details[tourism_certificate]", tourismPdf);
      vat_number && formdata.append("entity_details[vat_number]", buddy_vat_number);
    }
    formdata.append("business_details[bank_name]", bankName);
    formdata.append("business_details[account_number]", accountNumber);
    formdata.append("business_details[iban]", iban);
    formdata.append("business_details[street_name]", streetName);
    formdata.append("business_details[account_name]", accountName);
    formdata.append("business_details[city_id]", this.getCityId(city) as string);
    formdata.append("business_details[zipcode]", zipCode);


    


  const header = {
    // "Content-Type": configJSON.validationApiContentType,
  };

  const requestMessage = ApiRequest({
    header: header,
    endPoint: configJSON.travelersRegistrationEndpoint,
    payload: formdata,
    method: "POST",
  });
  this.apiCallIdTravelAgencyRegistration = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
};


getCountryId = (country: string | null) => {
  return country
    ? (this.state.countryList.find(
        (countryData: CountryData) => countryData?.name === country
      )?.id || null)
    : null;
};

getCityId = (city: string | null) => {
  return city
    ? (this.state.cityList.find(
        (cityData: CityData) => cityData?.name === city
      )?.id || null)
    : null;
};


  // API Response Functions

  getCountryListApiResponse = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );
  
      this.setState({
        countryList: countryList,
      });
    }
  }

  getCityListApiResponse = (responseJson: any, errorReponse: any) => {
    const citiesDataArray = responseJson.cities_data.data;
    if (citiesDataArray) {
      const cityList = citiesDataArray.map(
        (city: any) => {
          const attributes = city.attributes;
          attributes.id = city.id;
          return attributes;
        }
      );
  
      this.setState({
        cityList: cityList,
      });
    }
  }

  getSendEmailOrSmsResponse = (responseJson: any, errorResponse: any) => {

    if(responseJson?.status === 500){
      this.setState({
        errorMsg: 'Sorry, something went wrong!'
      })
      return;
    }

    if (responseJson?.data || responseJson?.meta  && !responseJson?.errors && !errorResponse) {
      const { data = {}, meta = {} } = responseJson;
      const token = meta?.token;
      if (data?.type === 'email_otp') {
        this.setState({ 
          authToken: token,
          timer: {
            minutes: '1',
            seconds: '00'
          },
          resetTimer: new Date().getTime().toString(),
          enableResendCode: false,
          modalForEmail: true
        }, () => this.handleOpenVerifyModal());
      } else {
        this.setState({ 
          authToken: token,
          timer: {
            minutes: '1',
            seconds: '00'
          },
          resetTimer: new Date().getTime().toString(),
          enableResendCode: false,
          modalForEmail: false
        }, () => this.handleOpenVerifyModal());
      }
    } else {
      const { errorMsg, phoneMsg, emailMsg } = this.extractSendOtpErrorMessages(responseJson, errorResponse);
      this.setState({
        errorMsg: errorMsg,
        phoneErrorMsg: phoneMsg,
        emailErrorMsg: emailMsg,
      });

    }

  }

  extractSendOtpErrorMessages = (responseJson: any, errorResponse: any) => {
    let errorMsg = "";
    let phoneMsg = "";
    let emailMsg = "";
    const {whichFieldVarifying} = this.state;
  
    if (typeof errorResponse === "string") {
      errorMsg = errorResponse;
    } else if (responseJson?.errors) {
      const error = responseJson.errors[0];
      if (error.otp) {
        if(whichFieldVarifying === 'email'){
          emailMsg = error.otp;
        }else{
          phoneMsg = error.otp;
        }
      } else if (error?.full_phone_number && error?.full_phone_number?.length > 0) {
        phoneMsg = error.full_phone_number[0];
      } else if (error?.email && error?.email?.length > 0) {
        emailMsg = error.email;
      }
    }
  
    return { errorMsg, phoneMsg, emailMsg };
  };

  getVarifyEmailOrSmsResponse = (responseJson: any, errorResponse: any) => {

    if(responseJson?.status === 500){
      this.setState({
        errorMsg: 'Sorry, something went wrong!'
      })
      return;
    }

    if(responseJson?.messages && !responseJson?.errors){
      const message = responseJson?.messages[0]?.otp;
      if(message){
        if(this.state.whichFieldVarifying === 'email'){
          this.setState({isEmailVarified: true}, () => this.closeOpenVerifyModal())
        }else if(this.state.whichFieldVarifying === 'mobile'){
          this.setState({isPhoneVarified: true}, () => this.closeOpenVerifyModal())
        }
      }

    } else {
      const { errorMsg, otpErrorMsg } = this.extractVerifyOtpErrorMessages(responseJson, errorResponse);

      this.setState({
        errorMsg: errorMsg,
        otpErrorMsg: otpErrorMsg,
      });
    }
  }

  extractVerifyOtpErrorMessages = (responseJson: any, errorResponse: any) => {
    let errorMsg = "";
    let otpErrorMsg = "";
  
    if (typeof errorResponse === "string") {
      errorMsg = errorResponse;
    } else if (responseJson?.errors && responseJson?.errors.length > 0) {
      const firstError = responseJson.errors[0];
      if (firstError.otp) {
        otpErrorMsg = firstError.otp;
      }
    }
  
    return { errorMsg, otpErrorMsg };
  };

  getTravelAgencyRegistrationResponse = (responseJson: any, errorReponse: any) => {

    if(responseJson?.status === 500){
      this.setState({
        loading: false,
        errorMsg: 'Sorry, something went wrong!'
      })
      return;
    }




   if(responseJson.messages && !responseJson.errors){
    this.setState(prev => ({
      ...prev,
      loading: false,
      activeStep: prev.activeStep + 1,
      successScreen: true,
      errorMsg: '',
     }))
   }else{
    this.setState({
      loading: false,
      errorMsg: 'Sorry, something went wrong!'
    })
   }
  }


  // Internal Functions

  handleNext = () => {
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1
    }));
  };

  handleEntityBack = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      entityDetailsValues: values
    }));
  };

  handleBusinessBack = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      businessDetailsValues: values
    }));
  };

  handleBuddyEntityBack = (values: any) => {
     // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep - 1,
      buddyEntityDetailsValues: values
    }));
  };

  handleOpenVerifyModal = () => {
    const { fromWichButtonSendingOtp } = this.state;
    if(fromWichButtonSendingOtp == 'verify'){
      this.setState({
        openVerifyModal: true,
        isResendClick: false,
      })
    }else{
      this.setState({
        isResendClick: true,
      })
    }
  }

  submitPersonalDetails = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      personalDetailsValues: values,
    }));
  }

  submitEntityDetails = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      entityDetailsValues: values,
    }));
  }

  submitBusinessDetails = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()

    this.setState(prev => ({
      ...prev,
      businessDetailsValues: values,
    }), () => this.traveleAgencyRegistrationRequest());
  }

  submitBuddyEntityDetails = (values: any) => {
    // Scroll to the top of the page
    handleScrollToTop()
    this.setState(prev => ({
      ...prev,
      activeStep: prev.activeStep + 1,
      buddyEntityDetailsValues: values,
    }));
  }

  closeOpenVerifyModal = () => {
    this.setState({
      openVerifyModal: false,
      otp: '',
      isResendClick: false,
      modalForEmail: false,
      enableResendCode: false,
      otpErrorMsg: '',
    })
  }

  handleValueChange = (field: string, value: string) => {
    if(field === 'mobile'){
      this.setState({
        phoneErrorMsg: '',
        isPhoneVarified: false
      })
    }else if(field === 'email'){
      this.setState({
        emailErrorMsg: '',
        isEmailVarified: false
      })
    }
    this.setState((prev) => ({
      ...prev,
      [field]: value
    }))
  }

  

  handleSignUp = () => {
    this.traveleAgencyRegistrationRequest();
    this.setState({
      successScreen: true,
    })
  }

  handleResendCode = () => {
    this.setState({
      enableResendCode: true,
    })
  }

  handleShowSuccessPage = () => {
    this.setState({
      successScreen: true
    })
  }

 


  // Customizable Area End
}
