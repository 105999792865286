import React, { useState, useEffect } from "react";
import { Typography, Box, Modal, Button } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import Timer from "./Timer";
import OTPInput from "react-otp-input";
import { Close } from "@material-ui/icons";
import { primaryColor } from "./theme";
import { useWindowWidth } from "./utils";
interface VerifyModalProps {
    open: boolean;
    closeOpenVerifyModal: () => void;
    otp: string;
    isResendClick: boolean;
    handleOtpChange: (field: string, value: string) => void;
    verifyEmailOrSmsOtpRequest: () => void;
    resendCount: number;
    otpErrorMsg: string;
    enableResendCode: boolean;
    handleResendCode: () => void;
    sendOtpRequest: (field: string, button: string) => void;
    timer: {
        minutes: string;
        seconds: string;
    },
    resetTimer: string;
    email: string;
    mobile: string;
    modalForEmail: boolean;
}

const VerifyModal = ({ 
    open, otp, isResendClick, otpErrorMsg, handleOtpChange, verifyEmailOrSmsOtpRequest, 
    handleResendCode, sendOtpRequest, resendCount, enableResendCode, 
    timer, resetTimer, email, closeOpenVerifyModal, mobile, modalForEmail 
}: VerifyModalProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            modalStyle: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isMobile ? '365px' : '465px',
                height: "auto",
                backgroundColor: '#ffffff',
                borderRadius: '16px',
                boxShadow: '24px',
            },
            modalContent: {
                position: 'relative',
                padding: '36px 40px', // Your desired padding value
                maxWidth: '100%',
                maxHeight: '100%',
                overflow: 'auto',
            },
            closeButton: {
                position: 'absolute',
                top: '23px', // Adjust as needed
                right: '23px', // Adjust as needed
                cursor: 'pointer',
            },
        })
    );

    const classes = useStyles();

    const [isTimerZero, setIsTimerZero] = useState(false);

    const handleTimerZero = (second: number) => {
        if (second === 0) {
            setIsTimerZero(true);
        } else {
            setIsTimerZero(false);
        }
    };

    useEffect(() => {
        setIsTimerZero(false)
    }, [open])

    let name, value, fieldForSendOtp: string;
    if (modalForEmail) {
        name = 'email address';
        value = email;
        fieldForSendOtp = 'email'
    } else {
        name = 'Mobile number';
        value = mobile;
        fieldForSendOtp = 'mobile'
    }


    return (
        <Box>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.modalStyle}>


                    <Box className={classes.modalContent}>
                        <Box className={classes.closeButton} style={{ display: "flex", justifyContent: 'flex-end', cursor: 'pointer' }} onClick={closeOpenVerifyModal}>
                            <Close style={{ fontSize: '16px' }} />
                        </Box>
                        <Box style={{ textAlign: 'center', width: '100%', marginBottom: "20px", }}>
                            <Typography style={{ fontSize: isMobile ? '23px' : '24px', fontFamily: "Inter", fontWeight: 500, color: 'rgba(33, 36, 38, 0.80)' }}>Verify your {name}</Typography>
                            <Typography style={{ 
                                color: '#21242680', fontFamily: "Inter", 
                                fontSize: '16px', fontWeight: 400, marginTop: '8px',
                                padding: isResendClick || isTimerZero
                                         ? '0px 20px' : '0px'
                                }}>
                                {
                                    isResendClick ? (
                                        `We sent you the OTP to your ${name}`
                                    ) : isTimerZero ? (
                                        `Please enter the OTP you received, if not
                                       please resend`
                                    ) :

                                        (
                                            <>
                                                Verification code sent to you on{' '}
                                                <span style={{ color: '#212426A0', fontWeight: 500 }}>{value}</span>
                                            </>
                                        )
                                }
                            </Typography>
                            {
                                otpErrorMsg &&
                                <Typography style={{
                                    color: '#FF0000', fontFamily: "Inter",
                                    fontSize: '14px', fontWeight: 400, marginTop: '8px',
                                }}>
                                    {
                                     `* ${otpErrorMsg}`   
                                    }
                                </Typography>
                            }
                        </Box>
                        <Box>
                            <Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <Box>
                                        <OTPInput
                                            onChange={(otp) => handleOtpChange("otp", otp)}
                                            value={otp}
                                            numInputs={4}
                                            inputStyle={{
                                                height: "53px",
                                                width: "44px",
                                                margin: 10,
                                                backgroundColor: "#fff",
                                                border: "1px solid #D1D1D1",
                                                borderRadius: 9,
                                                fontWeight: 500,
                                                fontSize: "18px",
                                                fontFamily: "Inter"
                                            }}
                                            shouldAutoFocus={true}
                                            // isInputNum={true}
                                            inputType={'number'}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </Box>
                                    {
                                        resendCount < 3
                                            ? <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: isMobile ? '86%' : '66%' }}>
                                                <Box mx={1} color="blue" component="span" style={{ fontFamily: "Inter" }}>
                                                    <Timer
                                                        initialMinute={timer.minutes}
                                                        initialSeconds={timer.seconds}
                                                        codeHandler={handleResendCode}
                                                        resendCode={resetTimer}
                                                        handleTimerZero={handleTimerZero}
                                                    />
                                                </Box>
                                                <Button
                                                    style={{
                                                        color: enableResendCode ? "#233D4D" : "rgba(33, 36, 38, 0.40)",
                                                        cursor: 'pointer',
                                                        textTransform: 'inherit',
                                                        fontFamily: "Inter",
                                                        fontSize: '14px',
                                                        fontWeight: 500
                                                    }}
                                                    disabled={
                                                        enableResendCode ? false : true
                                                    }
                                                    onClick={() => sendOtpRequest(fieldForSendOtp, 'resend')}
                                                >
                                                    Resend Code
                                                </Button>

                                            </Box>
                                            : <Typography variant="body2" style={{ color: "gray", padding: 10 }}>No attempts left to resend code.</Typography>
                                    }
                                </Box>
                                <Box width="100%">
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            marginTop: '48px',
                                            backgroundColor: primaryColor,
                                            padding: '14px 20px',
                                            color: "white",
                                            borderRadius: '42px',
                                            textTransform: "none",
                                            border: `1px solid ${primaryColor}`,
                                            fontFamily: "Inter",
                                            fontSize: '16px',
                                            fontWeight: 600
                                        }}
                                        disabled={
                                            otp.trim().length === 4 ? false : true
                                        }
                                          onClick={() => verifyEmailOrSmsOtpRequest()}
                                        type="submit"
                                    >
                                        Verify
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </Box>
    )
}
export default VerifyModal;


