Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProductDescription";
exports.labelBodyText = "ProductDescription Body";
exports.getCountryListEndpoint = 'account_block/country_code_and_flags';
exports.getCurrencyListEndpoint = "bx_block_admin/currencies"
exports.createTripEndpoint = 'bx_block_productdescription/trips';
exports.showTripEndpoint = 'bx_block_productdescription/trips';
exports.tripTypeEndpoint = "bx_block_productdescription/trip_types";
exports.travelerTypeEndpoint = "bx_block_productdescription/traveler_types";
exports.vibeListEndpoint = "bx_block_productdescription/vibes";
exports.timeEndpoint = "bx_block_productdescription/periods";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End