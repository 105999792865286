import React from 'react'
import { successImg } from "../../blocks/email-account-registration/src/assets"
import { Typography, Button } from "@material-ui/core"
import { primaryColor } from "./theme"

const SuccessFulSignUp = ({navigation}: any) => {

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '61.3vh' }}>
                <img src={successImg} width={'164px'} height={'137px'} alt="success" />
                <Typography style={{fontFamily: 'Inter', fontSize: '32px', color: 'rgba(33, 38, 35, 0.90)', fontWeight: 500}}>Successfully applied!</Typography>
                <Typography style={{fontFamily: 'Inter', fontSize: '16px', color: 'rgba(33, 38, 35, 0.65)', fontWeight: 400, marginTop: '12px'}}>It will usually take 48 hours for profile to get approved.</Typography>
                <Typography style={{fontFamily: 'Inter', fontSize: '16px', color: 'rgba(33, 38, 35, 0.65)'}}>You will be notified once profile get approved.</Typography>
                <Button
                    onClick={() => navigation?.navigate("LandingPage")}
                    style={{
                        marginTop: '32px',
                        height: '56px',
                        border: `1px solid ${primaryColor}`,
                        backgroundColor: primaryColor,
                        color: '#ffffff',
                        textTransform: 'initial',
                        borderRadius: '51px',
                        padding: '16px 48px',
                        fontSize: '16px',
                        fontFamily: "Inter",
                        fontWeight: 500
                    }}
                >
                    Go back to home</Button>
            </div>
        </>
    )
}

export default SuccessFulSignUp;