import React, { useEffect, useState } from 'react';
import {
    TextField,
    Button,
    Box,
    IconButton,
    makeStyles,
} from '@material-ui/core';
import { deleteIcon } from '../../blocks/ProductDescription/src/assets';
import { primaryColor } from './theme';
import { v4 as uuidv4 } from 'uuid';
import { useWindowWidth } from './utils';
import { Gap } from '../../blocks/ProductDescription/src/Interface.web';


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
    },
    listInput: {
        backgroundColor: '#ffffff',
        color: 'rgba(33, 36, 38, 0.90)',
        // padding: '16px 20px',
        height: '56px',
        border: `none`,
        borderBottom: '1px solid rgba(33, 36, 38, 0.20)',
        // borderColor: '#C3CAD9', // Conditionally set border color
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
    },
    [theme.breakpoints.down(1024)]: {
        listInput: {
            backgroundColor: '#ffffff',
            color: 'rgba(33, 36, 38, 0.90)',
            // padding: '16px 20px',
            height: '48px',
            border: `none`,
            borderBottom: '1px solid rgba(33, 36, 38, 0.20)',
            // borderColor: '#C3CAD9', // Conditionally set border color
            fontFamily: 'Inter',
            fontSize: '16px',
            fontWeight: 500,
        },
    },
    [theme.breakpoints.down(768)]: {
        listInput: {
            backgroundColor: '#ffffff',
            color: 'rgba(33, 36, 38, 0.90)',
            // padding: '16px 20px',
            height: '44px',
            border: `none`,
            borderBottom: '1px solid rgba(33, 36, 38, 0.20)',
            // borderColor: '#C3CAD9', // Conditionally set border color
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: 500,
        },
    },

}));



interface CustomItineraryProps {
    value: Gap[];
    onChange: (fieldName: string, value: any) => void;
}

const CustomItinerary: React.FC<CustomItineraryProps> = ({
    value,
    onChange
}) => {
    const classes = useStyles();
    const [gaps, setGaps] = useState<Gap[]>(value);
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    useEffect(() => {
        setGaps(value)
    }, [value])

    const handleAddPlace = () => {
        const newSerial = uuidv4();
        setGaps([...gaps, { serial: newSerial, value: '', id: '', _destroy: false, _locallyCreated: true, }]);
        onChange('itinerary', [...gaps, { serial: newSerial, value: '', id: '', _destroy: false,_locallyCreated: true, }])
    };

    const handleRemovePlace = (serial: string) => {
        const updatedGaps = gaps.reduce((acc, gap) => {
            if (gap.serial === serial) {
                // If the item is locally created, don't include it in the updated state
                if (!gap._locallyCreated) {
                    acc.push({
                        ...gap,
                        _destroy: true,
                    });
                }
            } else {
                acc.push(gap);
            }
            return acc;
        }, [] as Gap[]);
        
        setGaps(updatedGaps);
        onChange('itinerary', updatedGaps);
    };

    const handleChangeValue = (serial: string, value: string) => {
        const updatedGaps = gaps.map((gap) =>
            gap.serial === serial ? { ...gap, value } : gap
        );
        setGaps(updatedGaps);
        onChange('itinerary', updatedGaps)
    };

    console.log("gaps", gaps)

    return (
        <div className={classes.container}>
            {gaps?.filter(gap => !gap._destroy)?.map((gap: Gap, index) => {
                if(gap._destroy) {
                    return null;
                }
                return (
                    <Box key={gap.serial} display="flex" alignItems="center">
                        {/* <Typography variant="body1">{gap.serial}.</Typography> */}
                        <TextField
                            placeholder={`${index + 1}.`}
                            variant="standard"
                            autoComplete="off"
                            InputProps={{
                                classes: {
                                    input: classes.listInput
                                },
                                disableUnderline: true,
                            }}
                            value={gap.value}
                            onChange={(e) => handleChangeValue(gap.serial, e.target.value)}
                        />
    
                        <IconButton
                            style={{
                                border: 'none'
                            }}
                            onClick={() => handleRemovePlace(gap.serial)}
                        >
                            <img src={deleteIcon} style={{ height: "100%", width: "100%" }} alt="" />
                        </IconButton>
                    </Box>
                )
            })}
            {
                gaps.filter((gap) => !gap._destroy).length <= 9 &&
                <Button
                    style={{
                        border: 'none',
                        textTransform: 'initial',
                        marginTop: '3px',
                        fontFamily: 'Inter',
                        fontWeight: 600,
                        fontSize: isMobile ? '14px' : '16px',
                        color: primaryColor
                    }}
                    onClick={handleAddPlace}
                >
                    + Add Place
                </Button>
            }
        </div>
    );
};

export default CustomItinerary;
