export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const fallImg = require("../assets/fall.png");
export const summerImg = require("../assets/summer.png");
export const springImg = require("../assets/spring.png");
export const winterImg = require("../assets/winter.png");
export const vibesArts = require("../assets/arts.png");
export const vibesBeach = require("../assets/beach.png");
export const vibesDance = require("../assets/dance.png");
export const vibesHeal = require("../assets/heal.png");
export const vibesIndoor = require("../assets/indoor.png");
export const vibesOutdoor = require("../assets/outdoor.png");
export const vibesRelexing = require("../assets/relaxing.png");
export const vibesSocial = require("../assets/social.png");
export const vibesTravel = require("../assets/travel.png");
export const vibesTryNew = require("../assets/trynew.png");
export const energyExtreme = require("../assets/extremist.png");
export const energySoChilled = require("../assets/sochilled.png");
export const energyInBetween = require("../assets/inbetween.png");
export const energyFlexible = require("../assets/flexiable.png");
export const typeNight = require("../assets/nightowl.png");
export const typeMorning = require("../assets/morning.png");
export const typeAlien = require("../assets/alien.png");
export const imgImageLoader = require("../assets/loading.gif");
export const imgErrorImage = require("../assets/errorImage.png")
