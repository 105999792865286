import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Card
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { tour1 } from "../../landingpage/src/assets";
import { withStyles, Theme } from "@material-ui/core/styles";
import CustomPage from "../../../components/src/customSuccessErrorPage";
// Customizable Area End

import SummaryCardController, {
  Props,
  configJSON,
} from "./SummaryCardController";

export default class SummaryCard extends SummaryCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  details = {
    'Service Date': 'Thursday, 17 Aug',
    'Service Price': '1440 SAR',
    'Service provider name': 'The Travel Bug ( Travel Agency)'
  }

  bookingDetails = (classes: any) => {
    return Object.entries(this.details).map(([label, value], index) => {
      return <Box key={index} className={classes.flexStyle32}>
        <Typography className={classes.label} style={{ width: '35%' }}>{label}</Typography>
        <Typography className={classes.value} style={{ width: '65%' }}>{value}</Typography>
      </Box>
    })
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={webStyle.mainWrapper}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          width={'74%'}
          margin={'auto auto 72px'}
          alignItems={'center'}
        >
          <CustomPage
            isSuccess={true}
            title='Woohoo! Your Order is Confirmed'
            decription="Yorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis adipiscing elit. Nunc"
            buttonText="View your orders"
          />
        </Box>
        <Card className={this.props.classes.bookingDetailsCard}>
          <Box className={this.props.classes.tripDetails}>
            <img src={tour1} alt={'Trip Image'}
              style={{ borderRadius: '16px', width: '174px', height: '174.5px' }} />
            <Box>
              <Typography className={this.props.classes.tripTitle} gutterBottom>
                Summer adventure trip: Private tour of step well, Red sea mall & Jeddaah corniche by car
              </Typography>
              <Typography className={this.props.classes.tripDescription}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.
              </Typography>
            </Box>
          </Box>

          <Typography className={`${this.props.classes.title} ${this.props.classes.marginBottom24}`}>
            {configJSON.bookingTitle}
          </Typography>

          {this.bookingDetails(this.props.classes)}

          <Typography className={`${this.props.classes.title} ${this.props.classes.marginBottom16}`}>
            Cancellation policy / No Show
          </Typography>

          <Typography className={this.props.classes.description}>
            A 50% refund will be provided for cancellation made between  3 to 6 days before the trip. However, if you cancel within 2 days or less of the trip start date, no refund will be available.
          </Typography>

        </Card>
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center' as const,
    margin: '88px auto 120px',
    maxWidth: '59.3%',
    width: '100%'
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};

const styles = (theme: Theme) => ({
  bookingDetailsCard: {
    padding: '32px',
    borderRadius: '16px',
    border: '0.5px solid rgba(33, 36, 38, 0.20)',
    background: '#FFF',
    boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
  },
  tripDetails: {
    display: 'flex',
    gap: '32px',
    marginBottom: '48px'
  },
  tripTitle: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
  },
  tripDescription: {
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '160%',
  },
  flexStyle32: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    marginBottom: '24px',
    justifyContent: 'flex-start'
  },
  value: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '130%',
    letterSpacing: 0,
  },
  title: {
    color: 'rgba(33, 36, 38, 0.90)',
    fontFamily: 'Inter',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '130%',
  },
  description: {
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '180%',
    opacity: 0.75
  },
  label: {
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '130%'
  },
  marginBottom24: {
    marginBottom: '24px'
  },
  marginBottom16: {
    marginBottom: '16px'
  }
})

const SummaryCardWeb = withStyles(styles)(SummaryCard)
export { SummaryCardWeb }
// Customizable Area End
