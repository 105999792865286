import React, { useState } from 'react';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import { primaryColor } from './theme';

interface Props {
    onSave: (rating: number) => void;
}

const EmptyStar: React.FC<Props> = ({ onSave }) => {
    const [rating, setRating] = useState<number>(0);

    const handleStarClick = (starIndex: number) => {
        const newRating = starIndex + 1;
        setRating(newRating);
        onSave(newRating);
    };

    return (
        <div style={{ display: 'flex' }}>
            {[...Array(5)].map((_, index) => (
                <StarButton
                    key={index}
                    filled={index < rating}
                    onClick={() => handleStarClick(index)}
                />
            ))}
        </div>
    );
};

interface StarButtonProps {
    filled: boolean;
    onClick: () => void;
}

const StarButton: React.FC<StarButtonProps> = ({ filled, onClick }) => {
    return (
        <>
            {filled ?
                <StarIcon
                    onClick={onClick}
                    style={{ cursor: 'pointer', color: primaryColor }}
                />
                :
                <StarBorderIcon
                    onClick={onClick}
                    style={{ cursor: 'pointer', color: primaryColor }}
                />
            }
        </>
    );
};

export default EmptyStar;
