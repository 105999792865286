import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  useMediaQuery,
  Typography,
  Box,
  makeStyles
} from '@material-ui/core';
import { primaryColor } from './theme';
import { useWindowWidth } from './utils';

const useStyles = makeStyles((theme) => ({
  radioLabel: {
    display: 'flex',
    alignItems: 'center', // Center the label vertically with the radio button
    margin: '8px 0', // Adjust the margin as needed
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    color: '#212426',
    lineHeight: '150%',

  },
  labelText: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%'
  },
  disableStyle: {
    opacity: 0.8
  },
  [theme.breakpoints.down(768)]: {
    labelText: {
      color: '#212426',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '150%'
    }
  }
}));

interface CheckboxOption {
  label: string;
  value: string;
}

interface CustomCheckboxGroupProps {
  fieldName: string;
  options: CheckboxOption[];
  disabled?: boolean;
  error: string;
  touched: boolean;
  selectedValues: string[];
  onChange: (name: string, value: string[]) => void;
}

const CustomCheckboxGroup: React.FC<CustomCheckboxGroupProps> = ({
  fieldName,
  options,
  disabled = false,
  error,
  touched,
  selectedValues,
  onChange,
}) => {
  const classes = useStyles()
  const isMobile = useMediaQuery('(max-width:1023px)');
  const windowWidth = useWindowWidth()
  const isTab = windowWidth > 1023 && windowWidth < 1401;
  const optionLength = options.length;

  const handleCheckboxChange = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];

    onChange(fieldName, updatedValues); // Send the updated array of selected values
  };

  return (
    <FormControl component="fieldset">
      <div style={{ display: 'flex', flexDirection: optionLength === 2 && !isMobile ? 'row' : 'column' }}>
        {options.map((option) => (
          <FormControlLabel
            style={{
              fontFamily: 'Inter', fontSize: '16px', fontWeight: 500, minWidth: optionLength === 2 ? isTab ? '150px' : '160px' : 'none', color: '#212426',
              lineHeight: '150%'
            }}
            key={option.value}
            control={
              <Checkbox
                disabled={disabled}
                classes={{
                  disabled: classes.disableStyle
                }}
                size='small'
                style={{
                  color: selectedValues.includes(option.value) ? primaryColor : '#212426',
                  alignSelf: 'flex-start'
                }}
                checked={selectedValues.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
            }
            label={
              <Typography
                className={classes.labelText}
              >
                {option.label}
              </Typography>
            }
          />
        ))}
      </div>
      {/* {error && touched && (
        <FormHelperText
          style={{
            color: '#f44336',
            fontSize: '14px', // Changed fontSize to 14px
            fontFamily: 'Inter',
            fontWeight: 400, // Changed fontWeight to 400
          }}
        >
          {error}
        </FormHelperText>
      )} */}
    </FormControl>
  );
};

export default CustomCheckboxGroup;
