import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { Box, TextField, List, ListItem, ListItemText } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { KeyboardArrowDown, KeyboardArrowRight, Search } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { CountryData } from '../../blocks/email-account-registration/src/Interface.web';
import { capitalizeFirstLetter } from './utils';

interface CountryFilterProps {
    country: string;
    countries: CountryData[];
    handleCountryChange: (stateName: string, value: string) => void;
    getCityListRequest: (countryName: string) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    accordionPadding: {
        boxShadow: 'none',
    },
    accordionContentMargin: {
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '0'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    suggestionList: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
    },
}));

const CountryFilter: React.FC<CountryFilterProps> = ({ country: searchText, countries, getCityListRequest, handleCountryChange }) => {
    const classes = useStyles();
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
    // const [searchText, setSearchText] = useState('');
    const [filteredCountries, setFilteredCountries] = useState<CountryData[]>([]);
    // const [selectedCountry, setSelectedCountry] = useState<CountryData | null>(null);
    const toggleAccordion = () => {
        setIsAccordionExpanded(!isAccordionExpanded);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value.trim();
        // setSearchText(text.charAt(0).toUpperCase() + text.slice(1));
        handleCountryChange("country", capitalizeFirstLetter(text))
        const filtered = countries.filter(country => country.name.toLowerCase().includes(text.toLowerCase()));
        setFilteredCountries(filtered);
    };

    const handleCountrySelect = (country: CountryData) => {
        console.log("yes I am calling", country)
        // setSelectedCountry(country);
        // setSearchText(country.name);
        handleCountryChange("country", capitalizeFirstLetter(country.name))
        handleCountryChange("countryId", country?.id)
        getCityListRequest(country.name);
        setFilteredCountries([]);
    };

    const handleOnBlur = () => {
        setTimeout(() => {
            setFilteredCountries([])
        }, 1000)
    }

    const isNoMatch = searchText !== '' && filteredCountries.length === 0 && countries.every(country => !country.name.toLowerCase().includes(searchText.toLowerCase()));

    return (
        <div>
            <Box maxWidth={"300px"}>
                <Accordion className={classes.accordionPadding} expanded={isAccordionExpanded} onChange={toggleAccordion}>
                    <AccordionSummary className={classes.accordionContentMargin}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', fontFamily: 'Inter' }}>
                            <Typography>Country</Typography>
                            <IconButton>
                                {isAccordionExpanded ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                            </IconButton>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.container}>
                            <TextField
                                fullWidth
                                // variant="outlined"
                                placeholder="Search Country"
                                value={searchText}
                                onChange={handleSearchChange}
                                onBlur={() => handleOnBlur()}
                                onFocus={() => setIsAccordionExpanded(true)}
                                InputProps={{
                                    startAdornment: (
                                        <IconButton disabled>
                                            <Search />
                                        </IconButton>
                                    ),
                                    style: { border: '1px solid #ccc', borderRadius: '4px' },
                                    disableUnderline: true,
                                }}
                            />
                            <List className={classes.suggestionList} style={{ display: (searchText !== '' && filteredCountries.length > 0) ? 'block' : 'none' }}>
                                {filteredCountries.map(country => (
                                    <ListItem button key={country.name} onClick={() => handleCountrySelect(country)}>
                                        <ListItemText primary={country.name} />
                                    </ListItem>
                                ))}
                                {isNoMatch && (
                                    <ListItem>
                                        <ListItemText primary="No country matches your search." />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
};

export default CountryFilter;
