export const culture = require("../assets/culture.svg");
export const nature = require("../assets/nature.svg");
export const adventure = require("../assets/adventure.svg");
export const expierience = require("../assets/expierience.svg");
export const mostVisited = require("../assets/mostVisited.svg");
export const newAdded = require("../assets/newAdded.svg");
export const topPicks = require("../assets/topPicks.svg");
export const buddy = require("../assets/buddy.png");
export const buddy1 = require("../assets/buddy1.png");
export const itinerary = require("../assets/itinerary.png")
export const itineraryDash = require("../assets/itineraryDash.png")
export const transparentBorder = require("../assets/transpBorder.png")
export const transparentBorder360 = require("../assets/transparentBorder360.svg")
export const transparentBorder768 = require("../assets/transparentBorder768.svg")
export const greyBorder = require("../assets/greyBorder.png")
export const circle = require("../assets/circle.png")
export const beatenPath = require("../assets/beatenPath.png")
export const zehndi = require("../assets/zehndi.png")
export const userBuddy = require("../assets/userBuddy.png")
export const travelBug = require("../assets/travelBug.png")
export const tour1 = require("../assets/tour1.png")
export const tour2 = require("../assets/tour2.png")
export const tour3 = require("../assets/tour3.png")
export const agencyLogo = require("../assets/agencyLogo.png")
export const landingPageGuest = require("../assets/landingPageGuest.png")
