import React from "react";

// Customizable Area Start
import {
    Typography, Box, TextField,
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import { searchIcon } from "./assets";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import TripBookingListContainer from "../../../components/src/TripBookingListContainer.web";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
        MuiButtonBase: {
            root: {
                '&.MuiPaginationItem-root': {
                    color: 'rgba(33, 36, 38, 0.50)',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    display: 'flex',
                    width: '39.206px',
                    height: '32px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    '&.Mui-selected': {
                        color: '#FE7F2D',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent'
                        }
                    }
                }
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

// Customizable Area End

import BookingListController, {
    Props
} from "./BookingListController.web";

export default class TripBookingsList extends BookingListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            searchByCustomer,
            tripBookingList,
            tripBookingTitle
        } = this.state
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box style={{
                    position: 'absolute',
                    top: '43px',

                }}>
                    <Breadcrumbs list={[
                        {
                            title: 'My Bookings',
                            link: '/MyBookings'
                        },
                        {
                            title: `Bookings Report`,
                            link: '/BookingReport'
                        },
                        {
                            title: 'Trip booking list'
                        }
                    ]} />
                </Box>
                <Typography style={{
                    fontWeight: 600,
                    fontSize: '32px',
                    lineHeight: 'normal',
                    letterSpacing: '0.1px',
                    marginBottom: '20px'
                }}>
                    Trip Booking List
                </Typography>
                <div style={{
                    backgroundColor: '#FFF',
                    borderRadius: '16px',
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.08)',
                    marginBottom: '16vh'
                }}>
                    <Box padding={"33px"}>
                        <Box marginBottom={'32px'} display={'flex'} flexWrap={'wrap'}
                            justifyContent={'space-between'} gridGap={'28px'} alignItems={'center'}>
                            <Typography style={{
                                fontWeight: 600,
                                fontSize: '32px',
                                lineHeight: 'normal',
                                letterSpacing: '0.1px',
                            }}>
                                {tripBookingTitle}
                            </Typography>
                            <TextField
                                data-test-id="search"
                                value={searchByCustomer}
                                placeholder="Search for something"
                                onChange={(event) => this.handleSearchTripBookingCustomer(event.target.value)}
                                inputProps={{
                                    className: classes.searchInputTBL
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <img src={searchIcon} alt='search' style={{ width: '24px', height: '24px' }} />
                                    ),
                                    style: {
                                        gap: '8px',
                                        height: '48px',
                                        padding: "15px 12px 14px",
                                        display: 'flex',
                                        width: '20vw',
                                        borderRadius: '8px',
                                        border: `1px solid`,
                                        alignItems: 'center',
                                        borderColor: '#C3CAD9',
                                        alignContent: 'flex-start',
                                        backgroundColor: "#ffffff",
                                        color: "rgba(33, 36, 38, 0.90)",
                                    },
                                    classes: { input: classes.inputTBL },
                                    disableUnderline: true,
                                }}
                            />
                        </Box>
                        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} justifyContent="space-between" gridGap="20px">
                            {
                                Object.keys(tripBookingList).map((tripKey) => {
                                    const trip = tripBookingList[tripKey]
                                    return (
                                        <Box flex={1}>
                                            <TripBookingListContainer tripDates={trip.dates} status={trip.main_status} />
                                        </Box>
                                    )
                                })
                            }
                            
                        </Box>
                    </Box>
                </div>
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = (theme: Theme) => ({
    searchInputTBL: {
        '&::placeholder': {
            color: '#92929D',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 400,
            fontFamily: 'Inter',
            lineHeight: 'normal'
        }
    },
    inputTBL: {
        color: 'rgba(33, 36, 38, 0.90);',
        fontStyle: 'normal',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        letterSpacing: '0.1px',
        lineHeight: 'normal',
    },
})

const TripBookingsListWeb = withStyles(styles)(TripBookingsList)
export { TripBookingsListWeb }
// Customizable Area End
