import React from "react";

// Customizable Area Start

import {
    Typography, Box, TextField,
    Card, Button,
    Tabs, Tab,
    CardContent, Checkbox,
    Divider,
    Dialog,    
    DialogContent 
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import { Formik, Form} from "formik";
import { primaryColor } from "../../../components/src/theme";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import {
    signUptheme, verifyEmailModal
} from "../../email-account-registration/src/EmailAccountRegistration.web";
import {
    dummyText,
    formatNullishValue
} from "../../../components/src/utils";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";

const ModifiedData = (
    values: Record<string, any>,
    initialValues: Record<string, any>
) => {
    let modifiedValuesData: any = {};

    if (values) {
        Object.keys(values).forEach((key) => {
            const newValue = values[key];
            const oldValue = initialValues[key];
            if (key === 'mobile_number' || key === 'selectedCountry') {
                modifiedValuesData[key] = (newValue !== undefined) ? newValue : oldValue;
            } else if (newValue !== initialValues[key]) {
                modifiedValuesData[key] = newValue;
            }
        });
    }

    return modifiedValuesData;
};

const tabList = [
    { label: 'Personal Details', value: '0' },
    { label: 'Entity Details', value: 'Entity Details' },
    { label: 'Business Details', value: 'Business Details' },
]

export const ButtonStyle = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: primaryColor,
        color: '#ffffff',
        minWidth: '160px',
        width: '100%',
        height: '56px',
        maxWidth: '228px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '49px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: primaryColor,
        },
        '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.6
        }
    },
})(Button);

// Customizable Area End

import Settings5Controller, {
    Props
} from "./Settings5Controller";
import PersonalDetailsForm from "../../../components/src/PersonalDetails";
import TravelBuddyEntityDetails from "../../../components/src/TravelBuddySteps/TravelBuddyEntityDetails.web"
import EntityDetails from "../../../components/src/EntityDetails";
import BusinessDetails from "../../../components/src/BusinessDetails";
import { editPen } from "./assets";

export default class MyProfile extends Settings5Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     formatDate(createdAt :any) {
        if (createdAt) {
            const formattedDate = new Date(createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
            return formattedDate;
        } else {
            return null;
        }
    }

     conditionShort = (conditon: any, trueStatetment: any, falseStatetment: any) => {
        return conditon ? trueStatetment : falseStatetment
    }

    
   
    
 
     card = (classes:any , formattedDate : any , formattedrenewalDate:any , subscriptionPrice :any )=>(
        
        <React.Fragment>
            <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ fontWeight: 'bold', color: 'black', fontSize: '20px' }} gutterBottom data-testid="subscription-heading">
                        My Subscription
                    </Typography>
                    <Button onClick={() => this.subscriptionPlan()} data-testid = "subscriptionPlanBtn" variant="outlined" size="small" style={{ color: '#FE7F2D', borderRadius: '30px', border: '1px solid #FE7F2D' }}>Monthly plan</Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', gridGap: '16px', marginTop: '16px' }}>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                            Starts On
                        </Typography>
                        <Typography variant="body2" component="div">
                            {formattedDate}
                        </Typography>
                    </Box>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                            Renewal date
                        </Typography>
                        <Typography variant="body2" component="div">
                        {formattedrenewalDate}
                        </Typography>
                    </Box>
                    <Box className={classes.cardBoxes}>
                        <Typography className={classes.cardkeys} variant="body2" component="div">
                            Amount
                        </Typography>
                        <Typography variant="body2" component="div">
                            {subscriptionPrice} SAR
                        </Typography>
                    </Box>
                </Box>

            </CardContent>

           { !this.state.ischecked &&
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" style={{ background: '#FE7F2D', color: '#fff', borderRadius: '30px' }}>Upgrade Plan</Button>
                <Button size="small" style={{ color: '#FE7F2D', borderBottom: '1px solid' }}>
                    Historical Invoices
                </Button>
            </Box>}
            
            <Divider style={{ borderBottom: '1px dashed grey', marginTop: '20px' }} />
            <Box sx={{ padding: '15px' }}>
                <Typography variant="h6">Auto-Renewal</Typography>
                <Typography variant="body2" style={{ marginTop: '15px' }}> Control your subscription renewal preferences.Enable or dispable your auto renewal bases on your preference</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '10px' }}>
                    <Checkbox style={{color:'FE7F2D'}}  checked={this.state?.ischecked}
                    data-testid = "autorenewalcheckbox" onChange={this.updateAutoRenewalApiRequest} />
                    Turn on subscription auto renewal
                </Box>

            </Box>
            {this.state.ischecked &&
                <>
                    <Card className={classes.carddetails} data-testid = "saved-card-container">
                        <Typography data-testid = "saved-card-heading">Saved Card</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>***** **** **** {(this.context?.currentUser?.card_details?.[0]?.card_number)?.slice(-4)}</Typography>
                            <Edit style={{ fontSize: '20px', cursor:'pointer'}} onClick={this.handleEditClick} data-testid = "saved-card-editbtn"/>

                        </Box>
                    </Card>
                    <Divider style={{ borderBottom: '1px dashed grey', marginTop: '15px' }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                        <Button variant="contained" style={{ background: '#FE7F2D', color: '#fff', borderRadius: '30px' }}>Renew manually</Button>
                        <Button size="small" style={{ color: '#FE7F2D', borderBottom: '1px solid' }}>
                            Historical Invoices
                        </Button>
                    </Box>
                </>
            }
        </React.Fragment>
      );    

    // Customizable Area End

    render() {
        // Customizable Area Start
     const formattedDate = this.formatDate(this.state?.isSubscription?.created_at)
     const formattedrenewalDate = this.formatDate(this.state?.isSubscription?.renewal_date)
     const subscriptionPrice = this.state?.isSubscription?.subscription_price
             const { classes } = this.props
        const { isEditProfile, isEmailVerified,
            countdown, data: {
                first_name = '',
                last_name = '',
                email = '',
                Facebook = "",
                Instagram = "",
                Website = "",
                phone_number: {
                    country_code = '',
                    number = ''
                } = {},
                profile_image = null,
            } = {},
            responseModal, responseMsg, isSuccessIcon
        } = this.state


        const cuurentuserPersonal = this.context?.currentUser
        const currentuserentity = cuurentuserPersonal?.entity_detail?.data?.attributes
        const currentuserBusiness = cuurentuserPersonal?.business_detail?.data?.attributes
        const initialPersonalDetails = {
            fname: cuurentuserPersonal?.first_name,
            lname: cuurentuserPersonal?.last_name,
            email: cuurentuserPersonal?.email,
            phoneNumber: cuurentuserPersonal?.phone_number?.number ,
            password: dummyText,
            facebook_link: cuurentuserPersonal?.facebook_link,
            instagram_link: cuurentuserPersonal?.instagram_link,
            website_link: cuurentuserPersonal?.website_link,
            service_provider_type: "buddy",
            onlyPhone: cuurentuserPersonal?.phone_number?.number,
            country_code: cuurentuserPersonal?.phone_number?.country_code,
        }
        const initialEntityDetailsAgency = {
            agency_logo: cuurentuserPersonal?.profile_image,
            agency_display_name: currentuserentity?.display_name,
            about_me: currentuserentity?.about_me,
            company_legal_name: currentuserentity?.company_legal_name,
            id_number: currentuserentity?.id_number,
            commercialPdf: currentuserentity?.commerical_registration_document,
            permitPdf: currentuserentity?.permit_document,
            certificatePdf: currentuserentity?.certificate_file,
            vat_number: currentuserentity?.vat_number,
        }

        const initialEntityDetailsBuddy = {
            buddy_profile_picture: cuurentuserPersonal?.profile_image,
            buddy_name: currentuserentity?.display_name,
            about_me: currentuserentity?.about_me,
            id_number: currentuserentity?.id_number,
            commercialPdf: currentuserentity?.commerical_registration_document,
            tourismPdf: currentuserentity?.certificate_file,
            permitPdf: currentuserentity?.freelance_permit,
            vat_number: currentuserentity?.vat_number,
        }

        const initialBusinessDetails = {
            bankName: currentuserBusiness?.bank_name,
            accountName: currentuserBusiness?.account_name,
            accountNumber: currentuserBusiness?.account_number,
            iban: currentuserBusiness?.iban,
            streetName: currentuserBusiness?.street_name,
            country: cuurentuserPersonal?.country?.data?.attributes?.name,
            city: cuurentuserPersonal?.city?.data?.attributes?.name,
            zipCode: currentuserBusiness?.zipcode,
            agreeTermsAndCondition: true
        }

        const countTime = `${Math.floor(countdown / 60)}:${(countdown % 60).toString().padStart(2, '0')}`;

        const detailsBlock = (list: Record<string, any>, className: string) => {
            return <Box display={'flex'} gridRowGap={'32px'} flexDirection={'column'} className={className} width={'100%'}>
                {Object.entries(list).map(([label, value], index) => {
                    const isBold = label === 'Bank Details' || label === 'National Address' || label === 'Legal Document';
                    let button = null;
                    const documentLabels = ['Commercial Registration Document', 'Certificate File', 'Permit Document', 'Commercial Registartion', 'Tourism Certificate', 'Freelance Permit'];
                    const linkLabels = ['Facebook', 'Instagram', 'WhatsApp'];
                    const isLink = linkLabels.includes(label);
                    const isDocuments = documentLabels.includes(label);
                    
                if (documentLabels.includes(label)) {
                   button = <Button onClick={() => this.handleViewFile(value, isDocuments)} variant="text" className={classes.editdetailstyles}>View file</Button>;
                   }
                    return (

                        <Box key={index} sx={{ display: 'flex' }}>
                            <Box sx={{ width: '35%' }}>
                                <Typography className={`${classes.lightLabelTextstyle} ${this.conditionShort(isBold, classes.boldLabel, '')}`}>
                                    {label}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography className={classes.valueTextstyle}>
                                    <span onClick={() => this.handleViewFile(value, isLink)} className={`${classes.valueTextstylespan} ${isLink ? classes.link : ''}`}>
                                        {this.conditionShort(typeof value === 'object', JSON.stringify(value), value)}
                                    </span>
                                    {button}
                                </Typography>
                            </Box>
                        </Box>


                    );
                })}

            </Box>;
        };
        
        const userBlockDetail = () => {
            const user = this?.context?.currentUser
            let detailsToDisplay: any;
            const mobile_number = user?.phone_number?.country_code+user?.phone_number?.number;
            switch (this.state.tab) {

                case '0':
                    detailsToDisplay = {
                        'First Name': user?.first_name,
                        'Last Name': user?.last_name,
                        'Email Address': user?.email,
                        'Mobile Number': formatNullishValue(mobile_number),
                        'Facebook': formatNullishValue(user?.facebook_link),
                        'Instagram': formatNullishValue(user?.instagram_link),
                        'WhatsApp': formatNullishValue(user?.website_link)
                    };
                    break;

                case 'Entity Details':

                    /* istanbul ignore next */
                    if (user?.account_type === 'buddy') {
                        detailsToDisplay = {
                            'Buddy Display Name': user?.entity_detail?.data?.attributes?.display_name,
                            'About Me': formatNullishValue(user?.entity_detail?.data?.attributes?.about_me),
                            'Id Number': user?.entity_detail?.data?.attributes?.id_number,
                            'Legal Document': '',
                            'Commercial Registration': user?.entity_detail?.data?.attributes?.commerical_registration_document,
                            'Tourism Certificate': user?.entity_detail?.data?.attributes?.tourism_certificate,
                            'Freelance Permit': user?.entity_detail?.data?.attributes?.freelance_permit,
                            'VAT Number': formatNullishValue(user?.entity_detail?.data?.attributes?.vat_number)
                        };
                    } else {
                        /* istanbul ignore next */
                        detailsToDisplay = {
                            'Agency display Name': user?.entity_detail?.data?.attributes?.display_name,
                            'About me': formatNullishValue(user?.entity_detail?.data?.attributes?.about_me),
                            'Company Legal Name': user?.entity_detail?.data?.attributes?.company_legal_name,
                            'Id Number': user?.entity_detail?.data?.attributes?.id_number,
                            'Commercial Registration Document': user?.entity_detail?.data?.attributes?.commerical_registration_document,
                            'Permit Document': user?.entity_detail?.data?.attributes?.permit_document,
                            'Certificate File': user?.entity_detail?.data?.attributes?.certificate_file,
                            'VAT Number': formatNullishValue(user?.entity_detail?.data?.attributes?.vat_number)
                        };
                    }
                    break;
                case 'Business Details':
                    detailsToDisplay = {
                        'Bank Details': '',
                        'Bank Name': user?.business_detail?.data?.attributes?.bank_name,
                        'Account Name': user?.business_detail?.data?.attributes?.account_name,
                        'Accoutn Number': user?.business_detail?.data?.attributes?.account_number,
                        'IBAN': user?.business_detail?.data?.attributes?.iban,
                        'National Address': '',
                        'Country': user?.country?.data?.attributes?.name,
                        'City': user?.city?.data?.attributes?.name,
                        'Street Name': user?.business_detail?.data?.attributes?.street_name,
                        'Zip Code': user?.business_detail.data?.attributes?.zipcode
                    };
                    break;
            }
            const agencyLogo = this.context?.currentUser?.entity_detail?.data?.attributes?.agency_logo
            const buddyImg = this.context?.currentUser;
            return <Box margin={'0 71px 0 0'} width={'100%'}
                className={classes.userDetails}>
                {this.state.tab === 'Entity Details' && (
                    <Box className={classes.logoContainer} sx={{ borderRight: '1px dashed grey', padding: '0px 35px' }}>
                        <Typography style={{ whiteSpace: 'nowrap' }}>{user?.account_type} Logo</Typography>
                        <Box sx={{ border: '1px solid grey', borderRadius: '50%', overflow: 'hidden', width: '80px', height: '80px' }}>
                            <img src={buddyImg?.profile_image} alt="Company logo" className={classes.agencyImg} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                        </Box>
                    </Box>
                )}
                <Box width={'100%'}>
                    {detailsBlock(detailsToDisplay, classes.detailsLeft)}
                </Box>
            </Box>
        }


        const editRenewal = (classes: any) => {

            const cardDetails = this.context?.currentUser?.card_details[0]
            const initialCardValues = {
                card_detail: {
                    card_number: cardDetails?.card_number,
                    cardholder_name: cardDetails?.cardholder_name,
                    expiration_date: (cardDetails?.expiration_date),
                    cvv: (cardDetails?.cvv)
                }
            };

            return (<Dialog open={true} onClose={() => { }} maxWidth="xs" >
                <Box style={{ padding: '20px', borderRadius: '30px' }}>
                    <Box
                        display="flex"
                        flexDirection='column'
                        justifyContent="flex-start"
                        p={2}
                        style={{ padding: '20px' }}
                        data-testid="edit-model-container"
                    >
                        <Typography data-testid="edit-model-heading" variant="body1" style={{ fontSize: '20px', fontWeight: 'bold' }}>Link your card</Typography>
                        <Typography variant="body2">Link your card to update auto renewal</Typography>
                    </Box>
                    <Box>
                        <DialogContent>



                            <Formik
                                initialValues={initialCardValues}
                                onSubmit={this.updateCardDetailsApiRequest}
                            >
                                {({ values, setFieldValue, errors, touched, setTouched }) => (
                                    <Form>
                                        <Box style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                                            <Box>
                                                <Typography>Card number</Typography>
                                                <TextField
                                                    autoFocus
                                                    id="cardnumber"
                                                    name="card_number"
                                                    placeholder="Enter card number"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(e) => {
                                                        setFieldValue("card_detail['card_number']", e.target.value);
                                                    }}
                                                    value={values.card_detail.card_number}
                                                    // value={values?.card_detail?.card_number}
                                                />
                                            </Box>
                                            <Box>
                                                <Typography>Card holder name</Typography>
                                                <TextField
                                                    autoFocus
                                                    id="cardHolderName"
                                                    name="cardholder_name"
                                                    placeholder="Enter card holder name"
                                                    type="text"
                                                    fullWidth
                                                    variant="outlined"
                                                    required
                                                    onChange={(e) => {
                                                        setFieldValue("card_detail.cardholder_name", e.target.value);
                                                    }}
                                                    value={values?.card_detail?.cardholder_name}
                                                />
                                            </Box>
                                            <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                                <Box>
                                                    <Typography>Expiration</Typography>
                                                    <TextField
                                                        autoFocus
                                                        id="expiration"
                                                        name="expiration_date"
                                                        placeholder="mm/yy"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            setFieldValue("card_detail.expiration_date", e.target.value);
                                                        }}
                                                        value={values?.card_detail?.expiration_date}
                                                    />
                                                </Box>
                                                <Box>
                                                    <Typography>Security code</Typography>
                                                    <TextField
                                                        autoFocus
                                                        id="cvv"
                                                        name="cvv"
                                                        placeholder="Enter security code"
                                                        type="text"
                                                        fullWidth
                                                        variant="outlined"
                                                        required
                                                        onChange={(e) => {
                                                            setFieldValue("card_detail.cvv", e.target.value);
                                                        }}
                                                        value={values?.card_detail?.cvv}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Button variant="outlined" data-testid="carddetailssave" style={{ padding: '10px 60px', borderRadius: '20px', color: '#fff', background: '#FE7F2D' }} type="submit" >Save</Button>
                                                <Button variant="outlined" data-testid="cancleBtn" style={{ padding: '10px 55px', borderRadius: '20px', color: '#FE7F2D' }} onClick={this.handleClose}>Cancel</Button>
                                            </Box>
                                        </Box>
                                    </Form>
                                )}
                            </Formik>

                        </DialogContent>
                    </Box>
                </Box>
            </Dialog>)

        }


       
        const renderForm = (tab: any, email: any, initialValue: any) => {
            switch (tab) {
                case '0':
                    return (
                        <PersonalDetailsForm
                            sendEmailOrSmsOtpRequest={()=>{}}
                            handleValueChange={this.handleValueChange}
                            email={email}
                            mobile={this.state.mobile}
                            countryData={this.state?.countryList}
                            initialState={initialPersonalDetails}
                            submitPersonalDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            errorMsg={this.state.errorMsg}
                            isEmailVarified={true}
                            isPhoneVarified={true}
                            phoneErrorMsg={""}
                            emailErrorMsg={""}
                            isEditClicked={isEditProfile}
                            handleBack={this.handleToggleEditBtn}

                        />
                    );
                case 'Entity Details':
                    if (this.context?.currentUser?.account_type === 'agency') {
                        return <EntityDetails
                            agency_logo=""
                            initialState={initialEntityDetailsAgency}
                            submitEntityDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            handleBack={this.handleClose}
                            isEditClicked={isEditProfile}
                        />;
                    } else {
                        return <TravelBuddyEntityDetails
                            initialState={initialEntityDetailsBuddy}
                            submitEntityDetails={() => { }}
                            traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                            handleBack={this.handleToggleEditBtn}
                            isEditClicked={isEditProfile}
                        />;
                    }
                case 'Business Details':
                    return <BusinessDetails
                        countries={this.state.countryList}
                        cities={this.state.cityList}
                        getCityListRequest={this.getCityListRequest}
                        initialState={initialBusinessDetails}
                        traveleAgencyEditRequest={this.traveleAgencyEditRequest}
                        submitBusinessDetails={() => { }}
                        handleBack={this.handleToggleEditBtn}
                        errorMsg={""}
                        isEditClicked={isEditProfile} />;
                
            }
        }
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme}>
                <Box className={classes.container}>
                    <ResponseStatusModal
                        open={responseModal}
                        message={responseMsg}
                        isSuccess={isSuccessIcon}
                        onClose={this.handleCloseResponseModal}
                    />
                    <Breadcrumbs list={[
                        {
                            title: 'Home',
                            link: '/Dashboard'
                        },
                        {
                            title: 'My Profile'
                        }
                    ]} />
                    <Typography className={classes.dividerStyle}>
                        My Profile
                    </Typography>
                    <Box className={`${this.conditionShort(isEditProfile, classes.editProfileInfo, classes.userProfileInfo)}`}>
                        <Card className={classes.cardWrapper}>
                            <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} marginBottom={ this.conditionShort(isEditProfile, '48px', '32px')}>
                                <Box display={'flex'} justifyContent={'space-between'} alignItems={'flex-start'} gridGap={'28px'}>
                                    <Tabs
                                        id="tabs"
                                        value={this.state.tab}
                                        data-testid="tabs"
                                        classes={{
                                            flexContainer: classes.flexContainer,
                                            root: classes.rootTabs
                                        }}
                                        TabIndicatorProps={{
                                            style: { backgroundColor: '#FE7F2D', }
                                        }}
                                        onChange={(event: any, value: string) =>
                                            this.handleChange(value)
                                        }
                                    >
                                        {tabList.map(({ label, value }, index) => {
                                            return <Tab
                                                key={index}
                                                classes={{
                                                    selected: classes.selectedTab,
                                                    wrapper: classes.tabItem,
                                                    textColorInherit: classes.textColor,
                                                    root: classes.rootTab,
                                                }}
                                                label={label} value={value}
                                            />
                                        })}
                                    </Tabs>

                                </Box>
                                <Box flex={4} display={'flex'} justifyContent={'flex-end'}>
                                    {!isEditProfile &&
                                        <Typography className={classes.editdetailstyle} data-testid='editDetailsBtn' onClick={this.handleToggleEditBtn}>
                                            <img src={editPen} alt='Edit' className={classes.userImage} />  Edit Details
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                            <Box display={'flex'} width={'100%'}>
                                
                            
                                {
                                    this.conditionShort(isEditProfile ,renderForm(this.state.tab, email, initialPersonalDetails),userBlockDetail() )
                                }
                                
                            </Box>

                        </Card>
                        {this.state?.isSubscription && !isEditProfile &&
                            <Box>
                                <Card className={classes.userProfileCard}>{this.card(classes, formattedDate, formattedrenewalDate, subscriptionPrice)}</Card>
                            </Box>
                        }
                    </Box>
                    {verifyEmailModal({ formattedTime: countTime, instance: this })}
                    <ResponseStatusModal
                        open={this.state.openStatusModal}
                        isSuccess={this.state.isSuccessIcon}
                        message={this.state.modalMsg}
                        onClose={this.closeStatusModal}
                    />
                </Box >
                {this.state?.isEditClickd && editRenewal(classes)}
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ProfileStyles = (theme: Theme) => ({
    container: {
        margin: '0px 0 20px',
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },
    rootTab: {
        display: 'flex',
        padding: '4px 4px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '24px',
    },
    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '20px',
        }
    },
    agencyImg: {
        objectFit: 'cover',
        borderRadius: '50px'
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none' as const
    },
    carddetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        justifyContent: 'flex-start',
        padding: '20px 30px',
        borderRadius: '30px',
        background: '#FFF2D7'
    },
    cardWrapper: {
        padding: '48px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
    },
    userProfileInfo: {
        width: '80vw',
        display: 'flex',
        flexDirection: 'row',
        gap: '20px'
    },
    userProfileCard: {
        width: '20vw',
        borderRadius: '16px',
        background: '#FFDBAC',
        padding: '15px'

    },
    userProfilesecondCard:{
        marginTop : '16px' ,
     padding : '30px',
     borderRadius : '16px'
    },
   
    cardkeys : {
      width:'100px'
    },
    editProfileInfo: {
        maxWidth: '900px',
    },
    userDetails: {
        display: 'flex',
        gap: '20px'
    },
    heading: {
        // color: '#212426',
        // fontSize: '20px',
        fontWeight: 500,
        // lineHeight: '100%',
    },
    editdetailstyle: {
        color: primaryColor,
        fontWeight: 500,
        display: 'flex',
        gap: '8px',
        alignItems: 'center',
        cursor: 'pointer'
    },
    cardbtns: {
        padding: '10px 60px',
        background: '#FE7F2D',
        color: '#fff',
        borderRadius: '20px'
    },
    textfields: {
        height: '44px'
    },
    editdetailstyles: {
        color: '#FE7F2D',
        textTransform: 'initial',
        fontWeight: '600',
        marginLeft: '30px',
        whiteSpace: 'nowrap'

    },
    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '0px 30px 40px 0px'
    },
    lightLabelTextstyle: {
        fontWeight: 500,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '340px',
        minWidth: 'fit-content'


    },
    cardBoxes:{
      display:'flex',
      gap:'20px'
    },

    boldLabel: {
        fontWeight: 'bold'
    },

    valueTextstyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        width: '320px',
        height: '24px',
        display: 'flex',
        marginLeft: '40px'
    },
    valueTextstylespan: {

        color: 'rgba(33, 36, 38, 0.90)',
        width: '270px',
        fontWeight: 500,
        height: '24px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        display: 'block'

    },

    userImage: {
        height: '20px',
        width: '20px'
    },
    required: {
        color: '#F00'
    },
    dividerStyle: {
        margin: '18px 0 35px',
        color: '#212426',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '100%',
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
    link: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer'
    }
})

const MyProfileWeb = withStyles(ProfileStyles as object)(MyProfile)
export { MyProfileWeb }
// Customizable Area End
