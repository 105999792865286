import React from "react";

// Customizable Area Start
import {
  Container, Box, Button,
  Typography, TextField, Grid
} from "@material-ui/core";
import { Formik } from "formik";
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { forgotPasswordImg } from "../../forgot-password/src/assets";
import { primaryColor } from "../../../components/src/theme";
import { borderColor, helperTextValue, isErrorInField } from "../../../components/src/utils";
import { styles as commonStyles } from "../../forgot-password/src/ForgotPassword.web";

const styles = (theme: Theme) => ({
  ...commonStyles(),
  outlineBtn: {
    maxWidth: '168px',
    width: '100%',
    height: '56px',
    flexShrink: 0,
    borderRadius: '34px',
    border: ` 1px solid ${primaryColor}`,
    background: '#FFF',
    color: primaryColor,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    [theme.breakpoints.down(400)]: {
      maxWidth: '100%'
    },
  },
  errorToast: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    padding: '8px 16px',
    borderRadius: '8px',
    fontStyle: 'normal',
    marginBottom: '23px',
    display: 'inline-block',
  },
  errorBorder: {
    content: "",
    width: '5px',
    height: '100%',
    top: 0,
    left: 0,
    borderRadius: '8px 0 0 8px!important',
  },
  success: {
    color: '#51AE41',
    background: '#F5FFF4'
  },
  warning: {
    color: '#FE7F2D',
    background: '#FFF9F6'
  },
  error: {
    color: '#FF0000',
    background: '#FFF2F2'
  },
  successbg: {
    background: '#51AE41'
  },
  warningbg: {
    background: '#FE7F2D'
  },
  errorbg: {
    background: '#FF0000'
  },
  timerCount: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    opacity: 0.5
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column',
      rowGap: '24px'
    },
  }
});

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        '&.Mui-disabled': {
          color: '#FFF'
        },
      },
    },
    MuiTypography: {
      body1: {
        letterSpacing: 0,
        fontFamily: 'Inter, sans-serif',
      }
    },
    MuiInputBase: {
      input: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        letterSpacing: '16px',
        lineHeight: '150%',
        padding: 0,
        height: 'auto',
        '&::placeholder': {
          letterSpacing: 0
        }
      },
      root: {
        color: "black",
        padding: "15px 24px",
        borderRadius: '8px',
        border: `1px solid`,
        backgroundColor: "#ffffff",
        fontFamily: "Inter, sans-serif",
      }
    },
    MuiFormHelperText: {
      root: {
        color: '#F00',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        '&.Mui-error': {
          color: '#F00',
        }
      }
    },
  },
});

const verifyEmail = "Verify your email"
const verifyEmailDesc = "Please enter the verification code you received, in case you didn't receive please resend."

// Customizable Area End

import OTPInputAuthController, { Props } from "./OTPInputAuthController";

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    const formattedTime = `${Math.floor(this.state.countdown / 60)}:${(this.state.countdown % 60).toString().padStart(2, '0')}`;

    const BootstrapButton = withStyles((theme) => ({
      root: {
        textTransform: 'none',
        backgroundColor: primaryColor,
        color: '#ffffff',
        maxWidth: '168px',
        width: '100%',
        height: '56px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '34px',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '150%',
        fontFamily: [
          'Inter',
          'sans-serif',
        ].join(','),
        border: `1px solid ${primaryColor}`,
        '&:hover': {
          backgroundColor: primaryColor
        },
        [theme.breakpoints.down(400)]: {
          maxWidth: '100%'
        },
      },
    }))(Button);

    // Customizable Area End
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container
          maxWidth="lg"
          className={classes.main}
        >
          <Grid container justifyContent="space-between" spacing={5}
            style={{ margin: '0 auto', maxWidth: '1200px' }}
          >
            <Grid item xs={10} sm={9} md={6} lg={4}
              className={classes.formBlock} style={{ flexDirection: 'column' }}
            >
              <Typography className={classes.title} >
                {verifyEmail}
              </Typography>
              <Typography className={classes.description}>
                {verifyEmailDesc}
              </Typography>

              {this.state.VerifyEmailNote && <Typography component={'div'}>
                <p className={`${classes.errorToast} ${classes[this.state.VerifyEmailNote.type]}`} style={{ position: `relative`, wordBreak: 'break-word' }}>
                  <span className={`${classes.errorBorder} ${classes[this.state.VerifyEmailNote.type + "bg"]}`} style={{ position: 'absolute' }}></span>
                  {this.state.VerifyEmailNote.msg}
                </p>
              </Typography>}

              <Formik
                initialValues={{ otpCode: '' }}
                onSubmit={this.submitOtp}
              >
                {({ values, touched, errors, handleSubmit, setFieldValue }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Box style={{ marginBottom: '41px' }}>
                        <Typography className={classes.textlabel}>
                          Enter verification code
                        </Typography>

                        <Box style={{ display: 'flex', gap: '10px', width: '100%', marginBottom: '16px', padding: 0 }}>
                          <TextField
                            type="tel"
                            name="otpCode"
                            variant="standard"
                            inputProps={{
                              pattern: "[0-9]*",
                              inputMode: "numeric",
                            }}
                            value={this.state.otp}
                            style={{ width: "100%" }}
                            onPaste={(e) => this.handlePaste(e, setFieldValue)}
                            placeholder={"Enter verification code"}
                            onChange={(e) => this.handleOtpChange(e, touched, setFieldValue)}
                            error={isErrorInField(errors, touched, 'otpCode')}
                            helperText={helperTextValue(errors, touched, 'otpCode')}
                            InputProps={{
                              endAdornment: (
                                <Box component="span">
                                  <span className={classes.timerCount}>
                                    {formattedTime}
                                  </span>
                                </Box>
                              ),
                              style: {
                                borderColor: borderColor(errors, touched, 'otpCode'),
                                color: 'rgba(33, 36, 38, 0.90)'
                              },
                              disableUnderline: true,
                            }}
                          />
                        </Box>

                        <Box style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                          <Button
                            style={{
                              color: primaryColor,
                              textTransform: 'inherit',
                              fontFamily: "Inter",
                              fontSize: '14px',
                              fontWeight: 500,
                              padding: 0,
                              ...this.state.countdown === 0
                                ? {
                                  cursor: 'pointer',
                                  opacity: 1
                                }
                                : {
                                  cursor: 'not-allowed',
                                  opacity: 0.5
                                }
                            }}
                            disabled={!Boolean(this.state.countdown === 0)}
                            onClick={this.resendOtpCOde}
                            data-testid="resendCodebtn"
                          >
                            Resend Code
                          </Button>
                        </Box>
                      </Box>

                      <Box className={classes.buttonContainer}>
                        <BootstrapButton type="submit">
                          Confirm
                        </BootstrapButton>
                        <Button className={classes.outlineBtn} type="button"
                          style={{ textTransform: 'none', textAlign: 'center' }}
                          onClick={() => this.props.navigation.goBack()}
                        >
                          Back
                        </Button>
                      </Box>
                    </form>
                  )
                }}
              </Formik>
            </Grid>
            <Grid item lg={7} className="login-image-block hidden-sm">
              <img src={forgotPasswordImg} alt="Images" />
            </Grid>
          </Grid>
        </Container >
      </ThemeProvider >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const OTPInputAuthWeb = withStyles(styles)(OTPInputAuth)
export { OTPInputAuthWeb }
// Customizable Area End
