import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Box, TextField, Modal, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import {
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";
import { primaryColor } from "./theme";

const useStyles = makeStyles((theme) => ({
  modal: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '16px 20px',
    borderRadius: '16px',
    
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up(768)]: {
      top: '25%',
      width: 530, // Width for tablet and larger screens
    },
    [theme.breakpoints.down(768)]: {
      top: '30%',
      width: '80%', // Width for mobile screens
    },
  },
  headingSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    flex: 1, // Take up remaining space
    border: `1px solid rgba(33, 36, 38, 0.20)`,
    padding: '16px 20px',
    borderRadius: '10px',
    [theme.breakpoints.up(768)]: {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down(768)]: {
      marginRight: theme.spacing(0),
    },
  },
  button: {
    fontFamily: "Inter", 
    textTransform: "initial", 
    backgroundColor: primaryColor,
    borderRadius: '10px',
    color: "#fff",
    '&:hover': {
      backgroundColor: primaryColor,
      color: '#ffffff',
    },
    [theme.breakpoints.up(768)]: {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down(768)]: {
      width: '100%', // Full width for mobile screens
      marginTop: theme.spacing(2), // Add some space between text field and button
      marginRight: theme.spacing(0),

    },
  },
  shareIcons: {
    display: "flex",
    justifyContent: "flex-start",
    gap: '20px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  flexDiv: {
    display: 'flex',
    [theme.breakpoints.up(768)]: {
     flexDirection: 'row'
    },
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column'
    },
  }
}));

interface ShareTripModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShareTripModal = ({ isOpen, onClose }: ShareTripModalProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const [copied, setCopied] = useState(false);
  const [tripLink, setTripLink] = useState('');

  const handleClose = () => {
    setCopied(false);
    onClose();
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(tripLink);
    setCopied(true);
  };

  useEffect(() => {
    // Capture the full URL
    const fullUrl = window.location.href;
    setTripLink(fullUrl)

  }, []);

  return (
    <Modal open={isOpen}>
      <Box className={classes.modal}>
        <Box className={classes.headingSec}>
          <h2 style={{fontFamily: "Inter"}}>Share Trip</h2>
          <Close style={{cursor: 'pointer'}} onClick={() => handleClose()} />
        </Box>
        <Divider style={{margin: '10px 0px'}} />
        <p style={{fontFamily: "Inter"}}>Share this link via</p>
        <div className={classes.shareIcons}>
          <FacebookShareButton
            url={tripLink}
            // @ts-ignore
            quote={"Title or jo bhi aapko likhna ho"}
            hashtag={"#portfolio..."}
          >
            <FacebookIcon size={40} round={true} />
          </FacebookShareButton>

          <WhatsappShareButton
            url={tripLink}
            // @ts-ignore
            quote={"Title or jo bhi aapko likhna ho"}
            hashtag={"#portfolio..."}
          >
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>

          <TwitterShareButton
            url={tripLink}
            // @ts-ignore
            quote={"Title or jo bhi aapko likhna ho"}
            hashtag={"#portfolio..."}
          >
            <TwitterIcon size={40} round={true} />
          </TwitterShareButton>
        </div>

        <p style={{fontFamily: "Inter"}}>Or copy the link</p>
        <Box className={classes.flexDiv}>
          <TextField
            className={classes.textField}
            value={tripLink}
            InputProps={{ readOnly: true, disableUnderline: true, }}
          />
          <Button
            className={classes.button}
            onClick={copyToClipboard}
          >
            {copied ? "Copied!" : "Copy Link"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ShareTripModal;
