import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getAuthToken, handleScrollToTop } from "../../../components/src/utils";
import ApiRequest from "../../../components/src/ApiRequest";
import { ProviderReviewList } from "./Interface.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  reviewModal?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  title: string;
  description: string;
  account_id: number;
  anonymous: boolean;
  ReviewsList: [];
  activeCreatedAt: string;
  isVisible: boolean;
  accountReview: any;
  providerData: ProviderReviewList;
  overallRating: number;
  reivewModal: boolean;
  selectedStars: number[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReviewsController extends BlockComponent<Props, S, SS> {
  reviewsApiCallId: any;
  deleteReviewApiCallId: any;
  addReviewApiCallId: any;
  updateReviewApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      title: "",
      description: "",
      account_id: 1,
      anonymous: false,
      token: "",
      ReviewsList: [],
      accountReview: null,
      activeCreatedAt: "",
      isVisible: false,
      providerData: {
        data: [],
        page_options: {
          total_pages: 0,
          per_page: 5,
          current_page: 1,
          total_items: 0
        },
        average_rating: 0,
        total_number_of_review: 0,
        star_rating: [
          {
            "1_star_rating": 0
          },
          {
            "2_star_rating": 0
          },
          {
            "3_star_rating": 0
          },
          {
            "4_star_rating": 0
          },
          {
            "5_star_rating": 0
          }
        ]
      },
      selectedStars: [],
      reivewModal: props.reviewModal ?? false,
      overallRating: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getReviewListApiRequest()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallIds = {
      [this.apiCallIdGetReviewList]: this.getReviewListApiResponse,
      


      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCallIdGetReviewList: string = ""
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ title: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    }
  };

  txtTitleProps = {
    onChangeText: (text: string) => {
      this.setState({ title: text });

      //@ts-ignore
      this.txtTitleProps.value = text;
    }
  };
  submitProps = {
    onPress : () => {
      this.addQueryApi();
    }
  }
 
  txtDescriptionProps = {
    onChangeText: (text: string) => {
      this.setState({ description: text });
      //@ts-ignore
      this.txtDescriptionProps.value = text;
    }
  };

  addQuery = () => {
    this.props.navigation.navigate("AddReview");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      title: item.attributes.title,
      account_id: item.account_id,
      description: item.attributes.description,
      accountReview: null,
      anonymous: item.attributes.anonymous,
      isVisible: !this.state.isVisible
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  updateQueryApi = () => {
    console.log("update");
    let data = {
      data: {
        attributes: {
          title: this.state.title,
          description: this.state.description,
          account_id: this.state.account_id,
          anonymous: this.state.anonymous
        }
      }
    };
    
    const header = {
      "Content-Type": configJSON.ReviewsApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateReviewApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReviewsAPiEndPoint+"/"+this.state.account_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  addQueryApi = () => {
      let data = {
        data: {
          attributes: {
            title: this.state.title,
            description: this.state.description,
            account_id: this.state.account_id,
            anonymous: this.state.anonymous
          }
        }
      };
      
      const header = {
        "Content-Type": configJSON.ReviewsApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addReviewApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getReviewsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      //@ts-ignore
      this.state.ReviewsList.push({attributes: data.attributes});
  };

  getReviewsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.ReviewsApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reviewsApiCallId = requestMessage.messageId;

    console.log("reviews list", this.state.ReviewsList);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReviewsAPiEndPoint + "?account_id="+ this.state.account_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Request functions
  getReviewListApiRequest = async () => {
    const { current_page, per_page } = this.state.providerData.page_options;
    const authToken = await getAuthToken();
    const { selectedStars } = this.state;
    
    // Construct query parameters
    const queryParams = `page=${current_page}&limit=${per_page}&rating=${selectedStars}`;

    const header = {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": configJSON.ReviewsApiContentType,
    };

    const requestMessage = ApiRequest({
        header: header,
        endPoint: `${configJSON.getReviewsAPiEndPoint}?${queryParams}`,
        method: "GET",
    });

    this.apiCallIdGetReviewList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  // Request functions
  getReviewListApiResponse = (responseJson: ProviderReviewList, errorResponse: any) => {
    handleScrollToTop()
    if (responseJson?.data && responseJson.data.length > 0) {
        const providerData: ProviderReviewList = {
            data: responseJson.data,
            page_options: responseJson.page_options,
            average_rating: parseFloat(responseJson.average_rating.toFixed(2)),
            total_number_of_review: responseJson.total_number_of_review,
            star_rating: responseJson.star_rating
        };

        this.setState({
            providerData: providerData
        });
    }
}

  // Internal functions
  handleRating = (rating: number) => {
    this.setState({
      overallRating: rating,
    })
  }
  handleOpenModal = () => {
    this.setState({
      reivewModal: true,
    })
  }
  handleCloseModal = () => {
    this.setState({
      reivewModal: false,
    })
  }
  handlePaginationChange = (e: any, value: number) => {
    const pageNumber = Number(value);
    this.setState((prev) => {
      return {
        ...prev,
        providerData: {
          ...prev.providerData,
          page_options: {
            ...prev.providerData.page_options,
            current_page: pageNumber,
          }
        }
      }
    },() =>  this.getReviewListApiRequest())
  }
  handleSelectedStar = (rating: number) => {
    const {selectedStars} = this.state;
    const isSelected = selectedStars.includes(rating);
        let updatedSelection: number[] = [];

        if (isSelected) {
            updatedSelection = selectedStars.filter((selectedRating) => selectedRating !== rating);
        } else {
            updatedSelection = [...selectedStars, rating];
        }

        this.setState((prev) => {
          return {
            ...prev,
            selectedStars: updatedSelection,
            providerData: {
              ...prev.providerData,
              data: [],
              page_options: {
                ...prev.providerData.page_options,
                total_pages: 0
              }
            }
          }
        }, () => this.getReviewListApiRequest())
  }
  // Customizable Area End
}
