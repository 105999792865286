import React from "react";

import {
    // Customizable Area Start
    Box,
    Typography,
    Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import LazyImage from "../../../components/src/LazyImage";


const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

// Customizable Area End

import CustomformController, {
    Props,
    configJSON,
} from "./CustomformController";
import { primaryColor } from "../../../components/src/theme";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import { energyExtreme, energyFlexible, energyInBetween, energySoChilled, fallImg, springImg, summerImg, typeAlien, typeMorning, typeNight, vibesArts, vibesBeach, vibesDance, vibesHeal, vibesIndoor, vibesOutdoor, vibesRelexing, vibesSocial, vibesTravel, vibesTryNew, winterImg } from "./assets";
import { IbannerImages } from "./Interface.we";
import Loader from "../../../components/src/Loader.web";

export default class PersonalizeExp extends CustomformController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    seasonCollection = [
        {
            image: winterImg,
            title: 'Winter',
            selected: false,
        },
        {
            image: springImg,
            title: 'Spring',
            selected: false,
        },
        {
            image: summerImg,
            title: 'Summer',
            selected: false,
        },
        {
            image: fallImg,
            title: 'Fall',
            selected: false,
        },
    ];

    vibesCollection = [
        {
            image: vibesRelexing,
            title: 'Relaxing & chilling',
            selected: false,
        },
        {
            image: vibesSocial,
            title: 'Socializing',
            selected: false,
        },
        {
            image: vibesOutdoor,
            title: 'Outdoors',
            selected: false,
        },
        {
            image: vibesDance,
            title: 'Dance & party',
            selected: false,
        },
        {
            image: vibesBeach,
            title: 'Beaching',
            selected: false,
        },
        {
            image: vibesHeal,
            title: 'Healing & meditation',
            selected: false,
        },
        {
            image: vibesTravel,
            title: 'Travel & explore',
            selected: false,
        },
        {
            image: vibesIndoor,
            title: 'Indoors',
            selected: false,
        },
        {
            image: vibesArts,
            title: 'Arts & crafts',
            selected: false,
        },
        {
            image: vibesTryNew,
            title: 'Trying new things',
            selected: false,
        },
    ]

    energyCollection = [
        {
            image: energyExtreme,
            title: 'Extremist',
            selected: false,
        },
        {
            image: energySoChilled,
            title: 'So chilled',
            selected: false,
        },
        {
            image: energyInBetween,
            title: 'In-between',
            selected: false,
        },
        {
            image: energyFlexible,
            title: 'Flexible',
            selected: false,
        },
    ];

    typeCollection = [
        {
            image: typeNight,
            title: 'A night owl',
            selected: false,
        },
        {
            image: typeMorning,
            title: 'Morning butterfly',
            selected: false,
        },
        {
            image: typeAlien,
            title: 'An alien (time is irrelevant)',
            selected: false,
        },
    ];
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            season, energy, sleepingPreference,
            personalPreferenceVibes,
            selectedCategorySeason,
            selectedCategoryEnergy,
            selectedCategoryVibes,
            selectedCategorySleeping,
            loading
        } = this.state;


        const PersonlizeExpCard = (list: IbannerImages[],
            data: { title: string, field: string, class: any },
            handleSelectImage: (id: string) => void,
            maxWidth: string) => {
            return <Box className={data.class}>
                <Typography className={classes.headingPE}>{data.title} <span className={classes.selectionTextPE}>{data.field === "mInfo" ? '(Choose one or more)' : '(Choose only one)'}</span></Typography>
                <Box className={classes.imageListPE}>
                    {list.map((item, index) => {
                        return (
                            <Box key={index}>
                                <div 
                                onClick={() => handleSelectImage(item.id)}
                                className={`${classes.imageContainerPE} ${item?.selected ? classes.selectedImagePE : ''}`}
                                    style={{
                                        flexBasis: maxWidth,
                                        flexGrow: 1,
                                    }}>
                                    <LazyImage
                                        className={`${classes.responsiveImgPE} ${classes.imageStylePE}`}
                                        style={{ width: maxWidth, height: "224px"}}
                                        src={item?.imageUrl}
                                        alt="Image 1"
                                    />
                                    <Typography variant="body2" className={classes.textPE}>
                                        {item?.title}
                                    </Typography>
                                </div>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Box className={classes.mainContainerPE}>
                    <Loader loading={loading}/>
                    <Box>
                        <Typography className={classes.textHeadingPE}>{configJSON.headingText}
                            <br /> <span className={classes.selectionTextPE}>{configJSON.selectionText}</span>
                        </Typography>
                    </Box>
                    {PersonlizeExpCard(season,
                        { title: 'My favorite season ', field: "season", class: classes.headingToTopSecPE }, this.handleCategorySeasonSelection, '288px')}
                    {PersonlizeExpCard(personalPreferenceVibes,
                        { title: 'I’m Into ', field: "mInfo", class: classes.btwnSecPE }, this.handleCategoryVibesSelection, '224px')}
                    {PersonlizeExpCard(energy,
                        { title: 'My Energy Levels ', field: "energy", class: classes.btwnSecPE }, this.handleCategoryEnergySelection, '288px')}
                    {PersonlizeExpCard(sleepingPreference,
                        { title: 'I am ', field: "am", class: classes.btwnSecPE }, this.handleCategorySleepingSelection, '395px')}
                    <Box className={classes.btnCntnrPE}>
                        <Button
                            disabled={
                                !selectedCategoryEnergy ||
                                !selectedCategorySeason ||
                                !selectedCategorySleeping ||
                                selectedCategoryVibes.length === 0
                              }
                            onClick={this.handleSubmitMyInterestData} className={classes.nextButtonPE}>Next</Button>
                    </Box>
                </Box>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const stylesPersonalizeExp = (theme: Theme) => ({
    mainContainerPE: {
        margin: '81px 96px auto 96px'
    },
    textHeadingPE: {
        color: '#212426',
        lineHeight: '115%',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '48px',
    },
    headingToTopSecPE: {
        marginTop: '60px',
    },
    btwnSecPE: {
        marginTop: '96px',
    },
    headingPE: {
        color: '#212426',
        fontSize: '24px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '135%',
        fontWeight: 700,
    },
    selectionTextPE: {
        color: '#212426',
        fontSize: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        lineHeight: '150%',
        fontWeight: 400,
    },
    imageListPE: {
        display: 'flex',
        // justifyContent: 'space-between',
        flexWrap: 'wrap' as const,
        gap: '32px',
        marginTop: '31px',
    },
    imageListItem: { margin: 'auto' },
    selectedImagePE: {
        border: `4px solid ${primaryColor} !important`,
        borderRadius: '20px'
    },
    imageContainerPE: {
        position: 'relative' as const,
        width: '100%',
        height: 'auto',
        justifyContent: 'center',
        display: 'flex',
        boxSizing: 'border-box' as const,
        border: '4px solid transparent',
    },
    responsiveImgPE: {
        height: '494px'
    },
    imageStylePE: {
        width: '100%',
        height: 'auto',
        borderRadius: '16px',
        objectFit: 'cover' as const,
    },
    textPE: {
        position: 'absolute' as const,
        left: '50%',
        bottom: 0,
        transform: 'translateX(-50%)',
        width: '100%',
        padding: '32px 0',
        color: '#FFF',
        fontFamily: 'Inter',
        textAlign: 'center' as const,
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '140%',
        fontStyle: 'normal',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
        borderRadius: '16px'
    },
    btnCntnrPE: {
        width: '100%',
        margin: '72px auto 119px auto',
        textAlign: 'center' as const,
    },
    nextButtonPE: {
        width: '177px',
        borderRadius: '27px',
        height: '54px',
        border: '1px solid #FE7F2D',
        textTransform: 'initial' as const,
        color: '#fff',
        background: '#FE7F2D',
        textAlign: 'center' as const,
        fontSize: '16px',
        fontFamily: 'Inter',
        fontStyle: 'normal' as const,
        lineHeight: '150%',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    [theme.breakpoints.between(360.05, 768.05)]: {
        textPE: {
            fontSize: '18px'
        },
        imageContainerPE: {
            border: '3px solid transparent',
        },
        selectedImagePE: {
            border: `3px solid ${primaryColor} !important`,
            borderRadius: '16px'
        },
        textHeadingPE: {
            fontSize: '32px',
        },
        mainContainerPE: {
            margin: '20px'
        },
        headingPE: { fontSize: '24px' },
        imageListPE: {
            marginTop: '48px',
            gap: '32px',
            flexWrap: 'wrap'
        },
        responsiveImgPE: {
            objectFit: 'cover',
            borderRadius: '16px',
            width: '320px',
            height: '414px',
        },
    },
    [theme.breakpoints.down(360.05)]: {
        textPE: {
            fontSize: '18px'
        },
        imageContainerPE: {
            border: '3px solid transparent',
        },
        selectedImagePE: {
            border: `3px solid ${primaryColor} !important`,
            borderRadius: '13px'
        },
        textHeadingPE: {
            fontSize: '32px',
        },
        mainContainerPE: {
            margin: '20px'
        },
        headingPE: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '100%',
            '&.sliderHeading': {
                fontSize: '18px'
            }
        },
        imageListPE: {
            marginTop: '24px',
            gap: '16px',
            flexWrap: 'wrap'
        },
        responsiveImgPE: {
            width: '156px',
            minWidth: 'auto',
            height: '208px',
            objectFit: 'cover'
        },
        imageStylePE: {
            borderRadius: '8px'
        },
    }
});

const PersonalizeExpWeb = withStyles(stylesPersonalizeExp)(PersonalizeExp)
export { PersonalizeExpWeb }
// Customizable Area End
