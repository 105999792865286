
import {
    Grid, Typography, Box, Button, Divider, createTheme, ThemeProvider
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import Check from "@material-ui/icons/Check"
import React, { useState, useEffect } from "react";
import { parsePhoneNumber } from 'libphonenumber-js'
import PhoneInput from "./PhoneInput";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { primaryColor } from "./theme";
import { CityData, CountryData, PersonalDetailsValues } from "../../blocks/email-account-registration/src/Interface.web";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useWindowWidth } from "./utils";
import { IStep2CET } from "../../blocks/ProductDescription/src/Interface.web";
import CustomRadioGroup from "./CustomRadioGroup";
import CustomTextField from "./CustomTextField";
import CountrySelect from "./CountrySelect";
import CitySelect from "./CitySelect";
import CustomCheckboxGroup from "./CustomCheckBoxGroup";
import CustomPhotoUpload from "./CustomPhotoUpload";
import GenericCalendarList from "./GenericCalendarList";
import CustomItinerary from "./CustomItinerary";
import { fileterOption } from "./Step1CET";
interface Step2CETProps {
    initialState: IStep2CET;
    submitStep2CET: (values: IStep2CET) => void;
    backStep2CET: (values: IStep2CET) => void;
    cancelTrip: () => void;
    mode: 'create' | 'edit';
}


const validationSchema = Yup.object().shape({
    guided_tour: Yup.string().required("* Please select whether your service include guided tour."),
    transportation: Yup.string().required("* Please select whether your service include transportation."),
    itinerary: Yup.string().notRequired(),
    facilities: Yup.string().notRequired(),
    what_to_bring: Yup.string().notRequired(),
    include: Yup.string().notRequired(),
    does_not_include: Yup.string().notRequired(),
    not_allowed: Yup.string().notRequired(),
    more_information: Yup.string().notRequired()
})



const optionsGuidedTour = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },

];

const optionsTransportation = [
    { label: 'Provided', value: 'true' },
    { label: 'Not Provided', value: 'false' },

];

const optionsCheckBox = [
    { label: 'Relaxed', value: 'relaxed' },
    { label: 'Wellness', value: 'wellness' },
    { label: 'Dance and chill', value: 'dance_and_chill' },
    { label: 'Art and creativity', value: 'arts_and_creativity' },
    { label: 'Hiking', value: 'hiking' },
    { label: 'Extreme adventures', value: 'extreme_adventures' },
    { label: 'Sund and Sea', value: 'sun_and_sea' },
    { label: 'Exploring', value: 'exploring' },
    { label: 'Trying something different', value: 'trying_something_different' },
    { label: 'Sports activities', value: 'sports_activities' },
    { label: 'Events', value: 'events' },

];

const optionsTime = [
    { label: 'Full day', value: 'full_day' },
    { label: 'Day trip', value: 'day_trip' },
    { label: 'Night trip', value: 'night_trip' },
];


const countries: CountryData[] = [
    {
        id: '1',
        alpha_code: 'AF',
        name: 'Afganistan',
        flag_emoji: 'test',
        country_code: 'test',
        phone_number_length: [10]
    },
    {
        id: '2',
        alpha_code: 'BN',
        name: 'Bangladesh',
        flag_emoji: 'test',
        country_code: 'test',
        phone_number_length: [10]
    }

]

const cities: CityData[] = [
    {
        id: '1',
        name: 'Dhaka'
    },
    {
        id: '2',
        name: 'Kabul'
    }
]

// Create a custom theme
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024, // Change the default md width to 1024
            lg: 1280,
            xl: 1920,
        },
    },
});

const Step2CET = ({
    initialState,
    submitStep2CET,
    backStep2CET,
    cancelTrip,
    mode
}: Step2CETProps) => {
    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const useStyles = makeStyles((theme) => ({
        customFont: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 400,
        },
        buttonSelectStyle: {
            fontFamily: 'Inter', // Replace with your desired font family
            fontWeight: 500,
            color: 'rgba(33, 36, 38, 0.90)',
            fontSize: '16px',
            marginTop: '-22px'
        },
        toastModal: {
            borderRadius: '8px',
            display: 'inline-flex',
            height: '45px',
            alignItems: 'center',
            gap: '12px',
            flexShrink: 0,
            backgroundColor: '#FFF2F2',
            borderLeft: '8px solid #FF0000',
            padding: '8px 16px 8px 12px',
            margin: '30px 0'
        },
        formHelperText: {
            color: 'rgb(244, 67, 54)',
            fontSize: '12px',
            marginLeft: isMobile ? '0px' : '20px',
            marginTop: isMobile ? '8px' : '24px'
        },
        headingText: {
            fontSize: '32px',
            fontFamily: 'Inter',
            fontWeight: 600,
            fontStyle: 'normal',
            color: '#212426',
            lineHeight: 'normal'
        },
        stepText: {
            fontSize: '16px',
            fontFamily: 'Inter',
            fontWeight: 600,
            color: "#FE7F2D",
            lineHeight: '150%',
            marginBottom: '2px'
        },
        fieldTitleText: {
            fontFamily: 'Inter',
            fontSize: '18px',
            color: 'rgba(33, 36, 38, 0.90)',
            marginTop: '8px',
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal'
        },
        whiteContainer: {
            padding: "47px 71px 71px 71px",
            borderRadius: '20px',
        },
        cancelBtn: {
            color: primaryColor,
            textTransform: 'initial',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            lineHeight: '150%',
            fontStyle: 'normal',
            paddingLeft: 0,
        },
        nxtBtn: {
            backgroundColor: primaryColor,
            color: '#ffffff',
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: primaryColor,
                color: '#ffffff',
            }
        },
        bckBtn: {
            backgroundColor: 'transparent',
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            textTransform: 'initial',
            borderRadius: '68px',
            padding: '10px 50px',
            fontSize: '16px',
            fontFamily: "Inter",
            fontWeight: 500,
            width: '189px',
            height: '56px',
            '&:hover': {
                backgroundColor: 'transparent',
                color: primaryColor,
            }
        },
        distanceBtwnTxt: {
            marginTop: '32px'
        },
        distanceBtwnRdo: {
            marginTop: '48px'
        },
        distanceBtnDBtn: {
            marginTop: '48px'
        },
        optionalTxt: {
            fontWeight: 400,
            color: 'rgba(33, 36, 38, 0.65)',
            fontSize: '16px'
        },
        BtwnBtn: {
            display: 'flex',
            gap: '30px',
            justifyContent: "center"
        },
        [theme.breakpoints.down(1024)]: {
            whiteContainer: {
                padding: "36px 47px 31px 47px",
                borderRadius: '20px',
                margin: '48px'
            },
            headingText: {
                fontSize: '26px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '16px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                textTransform: 'initial',
                borderRadius: '34px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '132px',
                height: '44px'
            },
            distanceBtwnTxt: {
                marginTop: '24px'
            },
            distanceBtwnRdo: {
                marginTop: '40px'
            },
            distanceBtnDBtn: {
                marginTop: '32px'
            },
            optionalTxt: {
                fontWeight: 400,
                color: 'rgba(33, 36, 38, 0.65)',
                fontSize: '14px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '16px',
                justifyContent: "center"
            },

        },
        [theme.breakpoints.down(768)]: {
            whiteContainer: {
                padding: "15px 15px 17px 15px",
                borderRadius: '12px',
                margin: '16px'
            },
            headingText: {
                fontSize: '18px',
                fontFamily: 'Inter',
                fontWeight: 600,
                fontStyle: 'normal',
                color: '#212426',
                lineHeight: 'normal'
            },
            stepText: {
                fontSize: '12px',
                fontFamily: 'Inter',
                fontWeight: 500,
                color: "#FE7F2D",
                lineHeight: '150%',
            },
            fieldTitleText: {
                fontFamily: 'Inter',
                fontSize: '14px',
                color: 'rgba(33, 36, 38, 0.90)',
                marginTop: '8px',
                fontWeight: 500,
                lineHeight: '150%',
                fontStyle: 'normal'
            },
            cancelBtn: {
                color: primaryColor,
                textTransform: 'initial',
                fontSize: '14px',
                fontFamily: "Inter",
                fontWeight: 500,
                paddingLeft: 0,
            },
            nxtBtn: {
                backgroundColor: primaryColor,
                color: '#ffffff',
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            bckBtn: {
                backgroundColor: 'transparent',
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                textTransform: 'initial',
                borderRadius: '68px',
                padding: '10px 48px',
                fontSize: '16px',
                fontFamily: "Inter",
                fontWeight: 500,
                width: '140px',
                height: '44px'
            },
            distanceBtwnTxt: {
                marginTop: '16px'
            },
            distanceBtwnRdo: {
                marginTop: '24px'
            },
            distanceBtnDBtn: {
                marginTop: '24px'
            },
            optionalTxt: {
                fontWeight: 400,
                color: 'rgba(33, 36, 38, 0.65)',
                fontSize: '12px'
            },
            BtwnBtn: {
                display: 'flex',
                gap: '15px',
                justifyContent: "space-between" 
            },

        },
    }));
    const classes = useStyles();

    return (
        <div
            className={classes.whiteContainer}
            style={{
                maxWidth: "1082px",
                minWidth: "328px",
                backgroundColor: '#fff',
                border: '0.5px solid rgba(33, 36, 38, 0.20)',
                boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
                margin: isMobile ? '0 auto' : '0'
            }}
        >
            <ThemeProvider theme={theme}>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography className={classes.headingText}>{mode === "create" ? 'Plan new trip' : 'Edit trip details'}</Typography>
                    <Typography className={classes.stepText}>Step 2 - 4</Typography>
                </Box>
                <Formik
                    enableReinitialize={true}
                    initialValues={initialState}
                    test-id="submitButtonId"
                    onSubmit={submitStep2CET}
                    validationSchema={validationSchema}
                >
                    {({ values, setFieldValue, errors, touched }) => {
                        return (
                            <Form style={{marginBottom: '0px'}}>
                                <Box>
                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Guided '} <span style={{whiteSpace: 'nowrap'}}>Tour<span style={{ color: 'red' }}> *</span></span>
                                            </Typography>

                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="guided_tour"
                                                options={mode === 'edit' ? fileterOption(values.guided_tour, optionsGuidedTour) : optionsGuidedTour}
                                                disabled={mode === 'edit'}
                                                error={errors.guided_tour as string}
                                                value={values.guided_tour}
                                                onChange={setFieldValue}
                                                touched={touched.guided_tour as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.guided_tour ? errors.guided_tour : ""}
                                    </Typography>

                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                            >
                                                {'Itinerary'}
                                                <span className={classes.optionalTxt}> <br />(Optional)</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={5} sm={8} xs={7}>
                                            <CustomItinerary
                                                value={values.itinerary}
                                                onChange={setFieldValue}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Facilities'}
                                                placeholder={'Write something...'}
                                                value={values.facilities}
                                                fieldName="facilities"
                                                onChange={setFieldValue}
                                                error={errors.facilities as string}
                                                touched={touched.facilities as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'What to Bring'}
                                                placeholder={'Write something...'}
                                                value={values.what_to_bring}
                                                fieldName="what_to_bring"
                                                onChange={setFieldValue}
                                                error={errors.what_to_bring as string}
                                                touched={touched.what_to_bring as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnRdo}>
                                        <Grid item md={3} sm={4} xs={5}>
                                            <Typography
                                                className={classes.fieldTitleText}
                                                style={{whiteSpace: 'nowrap'}}
                                            >
                                                {'Transportation'} <span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item md={9} sm={8} xs={7}>
                                            <CustomRadioGroup
                                                fieldName="transportation"
                                                options={mode === 'edit' ? fileterOption(values.transportation, optionsTransportation) : optionsTransportation}
                                                disabled={mode === 'edit'}
                                                error={errors.transportation as string}
                                                value={values.transportation}
                                                onChange={setFieldValue}
                                                touched={touched.transportation as boolean}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="caption" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '-2px', color: '#FF0000' }}>
                                        {touched.transportation ? errors.transportation : ""}
                                    </Typography>

                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={'Include'}
                                                placeholder={'Write something...'}
                                                value={values.include}
                                                fieldName="include"
                                                onChange={setFieldValue}
                                                error={errors.include as string}
                                                touched={touched.include as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={"Doesn't Include"}
                                                placeholder={'Write something...'}
                                                value={values.does_not_include}
                                                fieldName="does_not_include"
                                                onChange={setFieldValue}
                                                error={errors.does_not_include as string}
                                                touched={touched.does_not_include as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={"Not Allowed"}
                                                placeholder={'Write something...'}
                                                value={values.not_allowed}
                                                fieldName="not_allowed"
                                                onChange={setFieldValue}
                                                error={errors.not_allowed as string}
                                                touched={touched.not_allowed as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container className={classes.distanceBtwnTxt}>
                                        <Grid item md={12} xs={12}>
                                            <CustomTextField
                                                fieldTitle={"More Information"}
                                                placeholder={'Write something...'}
                                                value={values.more_information}
                                                fieldName="more_information"
                                                onChange={setFieldValue}
                                                error={errors.more_information as string}
                                                touched={touched.more_information as boolean}
                                                multiline={true}
                                                maxRows={7}
                                                minRows={4}
                                                optional={true}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider className={classes.distanceBtwnRdo} />

                                </Box>
                                <Box
                                    className={classes.distanceBtnDBtn}
                                    style={{ display: 'flex', justifyContent: 'space-between', flexDirection: isMobile ? 'column-reverse' : "row", gap: '42px' }}>
                                    <Button
                                        className={classes.cancelBtn}
                                        onClick={() => cancelTrip()}
                                    >
                                        Cancel
                                    </Button>
                                    <Box
                                       className={classes.BtwnBtn}
                                    >
                                        <Button
                                            className={classes.bckBtn}
                                            onClick={() => backStep2CET(values)}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            className={classes.nxtBtn}
                                            type="submit"
                                        >
                                            Next
                                        </Button>
                                    </Box>
                                </Box>
                            </Form>)
                    }}
                </Formik>
            </ThemeProvider>
        </div>
    )
}

export default Step2CET;