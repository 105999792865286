import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Box, Button } from '@material-ui/core';
import { primaryColor } from './theme';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fontStyleField: {
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '150%',
            color: '#9D9D9D',
            marginBottom: '8px'
        },
        fontStyleValue: {
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '150%',
            // color: '#7C7C7C'
        },
        card: {
            marginBottom: theme.spacing(2),
            border: '1px solid #C3CAD9',
            borderRadius: '10px',
        },
        content: {
            padding: theme.spacing(2),
        },
        leftColumn: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
        rightColumn: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
        },
        rightColumnItem: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1),
            },
        },
        fieldContainer: {
            marginBottom: theme.spacing(1),
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '15px'
        },
        viewBtn: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '26px',
            fontStyle: 'normal',
            fontFamily: 'Inter',
            whiteSpace: 'nowrap',
            borderRadius: '20px',
            border: `1px solid ${primaryColor}`,
            color: primaryColor,
            textTransform: 'initial',
            width: '80px',
            height: '30px'
          },
    })
);

interface CardProps {
    tripName: string;
    tripType: string;
    country: string;
    customerName: string;
    tripDate: string;
    numOfPeople: number;
    amount: number;
    tripId: number;
    handleNavigation: (id: number) => void;
}

const ResponsiveBookingReportCard: React.FC<CardProps> = ({
    tripName,
    tripType,
    country,
    customerName,
    tripDate,
    numOfPeople,
    amount,
    tripId,
    handleNavigation
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.fontStyleValue} gutterBottom>{tripName}</Typography>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Country:</span> {country}</Typography>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Trip Type:</span> {tripType}</Typography>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Customer Name:</span> {customerName}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Date:</span> {tripDate}</Typography>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Number of People:</span> {numOfPeople}</Typography>
                        <Typography className={classes.fontStyleValue} gutterBottom><span className={classes.fontStyleField}>Amount:</span> ${amount}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="flex-end">
                        <Button
                        onClick={() => handleNavigation(tripId)}
                        className={classes.viewBtn}>
                        {'View'}
                      </Button>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ResponsiveBookingReportCard;
