import React from 'react';
import {
    Box,
    Typography,
    useMediaQuery,
    makeStyles,
} from '@material-ui/core';
import AddPhoto from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    dropzoneContainer: {
        position: 'relative',
        // border: '1px solid #c3cad9',
        borderRadius: '18px',
        backgroundColor: '#F6F6F6',
        height: '137px',
        width: '137px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'poiter',
        // marginBottom: '16px'
    },
    closeIcon: {
        position: 'absolute',
        top: 0,
        right: 0,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderRadius: '50%',
        padding: '2px',
        boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
    },
    labelStyle: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    errorStyle: { 
        fontFamily: "Inter", 
        fontWeight: 400, 
        color: '#FF0000',
        marginTop: '7px'
    },
    errorStyleMainPhoto: { 
        fontFamily: "Inter", 
        fontWeight: 400, 
        color: '#FF0000',
        marginTop: '7px'
    },
    plusBtnStyle: { 
        height: '36px', 
        width: '36px', 
        color: '#6b6d6f' 
    },
    photoStyle: { 
        width: '100%', 
        height: '100%', 
        borderRadius: '18px' 
    },
    [theme.breakpoints.down(1024)]: {
        dropzoneContainer: {
            position: 'relative',
            // border: '1px solid #c3cad9',
            borderRadius: '17px',
            backgroundColor: '#F6F6F6',
            height: '132px',
            width: '132px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'poiter',
            // marginBottom: '16px'
        },
        photoStyle: { 
            width: '100%', 
            height: '100%', 
            borderRadius: '17px' 
        },
        plusBtnStyle: { 
            height: '31px', 
            width: '31px', 
            color: '#6b6d6f' 
        }, 
    },
    [theme.breakpoints.down(768)]: {
        dropzoneContainer: {
            position: 'relative',
            // border: '1px solid #c3cad9',
            borderRadius: '9.6px',
            backgroundColor: '#F6F6F6',
            height: '72px',
            width: '72px',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'poiter',
            // marginBottom: '16px'
        },
        photoStyle: { 
            width: '100%', 
            height: '100%', 
            borderRadius: '9.6px' 
        },
        closeIcon: {
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer',
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '1px',
            boxShadow: '0 0 3px rgba(0, 0, 0, 0.5)',
            fontSize: '15px'
        },
        plusBtnStyle: { 
            height: '20px', 
            width: '20px', 
            color: '#6b6d6f' 
        },
        errorStyleMainPhoto: { 
            fontFamily: "Inter", 
            fontWeight: 400, 
            color: '#FF0000',
            marginTop: '4px',
            width: '60%'
        },
    }
   

}));

interface PhotoUploadProps {
    onMainPhotoChange: (fieldName: string, file: any[]) => void;
    photos: any;
    multiple: boolean;
    paramName: string;
    error?: string;
    touch?: boolean;
    mode: 'create' | 'edit';
}

const getFileSizeInMB = (size: number) => {
    return size / 1024 / 1024;

}

const CustomPhotoUpload: React.FC<PhotoUploadProps> = ({
    onMainPhotoChange,
    photos,
    multiple,
    paramName,
    error,
    touch,
    mode
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');


    const shouldUseObjectURL = (photos.length > 0 && photos[0] && typeof photos[0] !== "string") || false;


    const handleOnChangePhoto = (event: any) => {
        const file = event.target.files;
        const new_files = Array.from(file);
        if (file && multiple) {

            onMainPhotoChange(paramName, [...(photos ?? []), ...new_files] as File[]);

        } else if (file && !multiple) {
            onMainPhotoChange(paramName, new_files as File[])
        }

        event.target.value = null;
        event.target.files = null;

    }

    const removePhoto = (name: string) => {
        const indexToRemove = photos?.findIndex((photo: any) => photo.name === name);

        if (indexToRemove !== undefined && indexToRemove !== -1) {
            const newPhotos = [...photos as File[]];
            newPhotos.splice(indexToRemove, 1); // Remove the first occurrence of the photo with the specified name
            onMainPhotoChange(paramName, newPhotos);
        }
    }


    // console.log("main photos", photos[0])
    // console.log("shouldUseObjectURL", shouldUseObjectURL)
    // console.log("photos length", photos.length)
    // console.log("photos name", photos[0]?.name)
    // console.log("type of photos", typeof photos[0])


    return (
        <div
            style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                gap: '16px',
            }}
        >

            {multiple && photos?.map((photo: any, index: number) => {
                const fileSize = getFileSizeInMB(photo.size);
                return (
                    <Box key={photo.name + index} style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                            className={classes.dropzoneContainer}
                            style={{
                                border: fileSize > 5 ? `1px solid #f44336` : `1px solid #c3cad9`
                            }}
                        >
                            <CloseIcon
                                className={classes.closeIcon}
                                onClick={() => removePhoto(photo.name)}
                            />
                            {
                                <img
                                    src={shouldUseObjectURL ? URL.createObjectURL(photo) : photo} // Conditional rendering
                                    alt=""
                                    className={classes.photoStyle}
                                />
                            }
                        </Box>
                        <Typography variant="caption" className={classes.errorStyle}>
                            {getFileSizeInMB(photo?.size) > 5 ? "  More than 5MB" : ""}
                        </Typography>
                    </Box>
                )
            })}
            {
                !multiple && photos && photos?.length > 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box
                            className={classes.dropzoneContainer}
                            style={{
                                border: getFileSizeInMB(photos[0]?.size) > 5 ? `1px solid #f44336` : `1px solid #c3cad9`,
                                cursor: 'pointer'
                            }}
                        >
                            <label htmlFor={"uploader" + paramName} className={classes.labelStyle}>
                                <img src={shouldUseObjectURL ? URL.createObjectURL(photos[0][0]) : photos[0]} className={classes.photoStyle} />
                            </label>

                        </Box>
                        <Typography variant="caption" className={classes.errorStyleMainPhoto}>
                            {getFileSizeInMB(photos[0]?.size) > 5 ? "* Please choose a file less than 5 MB" : ""}
                        </Typography>
                    </div>
                )
                    :
                    !multiple && (!photos) || multiple && (photos && photos.length < 10) ?
                        (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Box
                                    className={classes.dropzoneContainer}
                                    style={{
                                        border: touch && error ? `1px solid #f44336` : `1px solid #c3cad9`,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <label htmlFor={"uploader" + paramName} className={classes.labelStyle}>
                                        <AddPhoto className={classes.plusBtnStyle} />
                                    </label>
                                </Box>
                                <Typography variant="caption" className={classes.errorStyleMainPhoto}>
                                    {touch ? error : ""}
                                </Typography>
                            </div>
                        ) : null
            }
            <input
                type="file"
                name=""
                id={"uploader" + paramName}
                style={{ visibility: 'hidden' }}
                onChange={handleOnChangePhoto}
                multiple={false}
                accept='image/jpg, image/jpeg, image/png'
            />

        </div>
    );
};

export default CustomPhotoUpload;
