import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    useMediaQuery,
    Typography,
    makeStyles
} from '@material-ui/core';
import { primaryColor } from './theme';

const useStyles = makeStyles((theme) => ({
    radioLabel: {
        display: 'flex',
        alignItems: 'center', // Center the label vertically with the radio button
        margin: '8px 0', // Adjust the margin as needed
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 500,
        color: '#212426',
        lineHeight: '150%',

    },
    labelText: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%'
    },
    disableStyle: {
        opacity: 0.8
    },
    [theme.breakpoints.down(768)]: {
        labelText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '150%'
        }
    }
}));

interface RadioOption {
    label: string;
    value: string;
}

interface CustomRadioGroupProps {
    fieldName: string;
    options: RadioOption[];
    error: string;
    touched: boolean;
    value: string;
    disabled?: boolean;
    onChange: (name: string, value: string) => void;
    isPlanItinerary?: boolean;
}

const CustomRadioGroup: React.FC<CustomRadioGroupProps> = ({
    fieldName,
    options,
    error,
    touched,
    disabled = false,
    value,
    onChange,
    isPlanItinerary,
}) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:1023px)');
    const isMedium = useMediaQuery('(max-width:1400px)')
    return (
        <FormControl component="fieldset">
            <RadioGroup
                row={!isMobile && !isPlanItinerary}
                aria-label={fieldName}
                name={fieldName}
                value={value}
                onChange={(e) => onChange(fieldName, e.target.value)}
            >
                {options.map((option) => (
                    <FormControlLabel
                        key={option.value}
                        classes={{ label: classes.radioLabel }}
                        style={{ minWidth: isMedium ? '150px' : '160px' }}
                        value={option.value}
                        control={<Radio
                            disabled={disabled}
                            classes={{
                                disabled: classes.disableStyle
                            }}
                            size='small'
                            style={{
                                color: option.value === value ? primaryColor : '#212426',
                            }} />}
                        label={
                            <Typography
                                className={classes.labelText}
                            >
                                {option.label}
                            </Typography>
                        }
                    />
                ))}
            </RadioGroup>
            {/* {
                error && touched && (
                    <FormHelperText style={{ color: '#f44336', fontSize: '12px', fontFamily: "Inter", fontWeight: 400 }}>{error}</FormHelperText>
                )
            } */}
        </FormControl >
    );
};

export default CustomRadioGroup;
