import React from "react";

// Customizable Area Start
import {
    Box, Grid, InputBase, Chip,
    Typography, Button, Avatar, Tooltip
} from "@material-ui/core";
import Slider from "react-slick";
import { withStyles, Theme } from "@material-ui/core/styles";
import {
    KeyboardArrowLeft, Search, KeyboardArrowRight
} from '@material-ui/icons';
import { Rating } from "@material-ui/lab"
import {
    culture, nature, expierience, adventure, buddy, landingPageGuest, tour1,
    transparentBorder, itinerary, itineraryDash, topPicks, mostVisited, newAdded,
    transparentBorder360, transparentBorder768
} from "./assets";
import { primaryColor } from "../../../components/src/theme";
import { capitalizeWords, formatDate, formatEmptyValue } from "../../../components/src/utils";
import CarouselDisplay, { settings } from "../../carouseldisplay/src/CarouselDisplay.web";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";
import LazyImage from "../../../components/src/LazyImage";

/* istanbul ignore next */
const conditionShort = (conditon: any, trueStatetment: any, falseStatetment: any) => {
    return conditon ? trueStatetment : falseStatetment
}

/* istanbul ignore next */
export const tripSlider = (classes: any, list: Record<string, any>[], title: string, navigation: any, gotoAdvancedSearchPage: (type: string, params: string, value: string) => void, style: any = {}, className: any = {}) => {
 
    return (isCartTrip =true) => {
        const tripList = [...list].sort((a, b) => isCartTrip ? b.attributes.total_rating - a.attributes.total_rating : b.attributes.rating - a.attributes.rating);
        return <Box style={style} className={className}>
        <Box className={classes.sliderHeadingBlock}>
            <Typography className={classes.tripTypeheading}>{title}</Typography>
            <Typography
            onClick={() => gotoAdvancedSearchPage(title, 'tripType', title)}
            className={classes.viewMoreLink}>Explore more</Typography>
        </Box>
        <Typography className={classes.smallDescription}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
        {
            conditionShort( tripList.length <= 0 ,
                <Typography style={{marginTop: '24px'}} className={classes.smallDescription}>
        No data found.
       </Typography> ,           
      <CarouselDisplay
      slideNo={ conditionShort (list.length > 5, 4 ,list.length) }
      id="CarouselDisplay"
      navigation={navigation}
      className={`${classes.tripSlider} ${classes.slickSlider} `}
      >
            {tripList.map((trip, index) => {
                const { title, agency_logo, agency_name, rating, provider_type,
                    price, discounted_price, currency, city, country, trip_main_picture = tour1, total_rating,primary_image_url
                } = trip.attributes
                
                return (
                    <div data-test-id="checkid" key={index} className={classes.innerTripSlideBlock}>
                        <div data-test-id="navigateid" onClick={() => navigation?.navigate('TripDetails', {id: trip.id})} className={classes.imageVignette}>
                            <LazyImage src={conditionShort(isCartTrip,trip_main_picture,primary_image_url)}  alt="Image" className={`${classes.image} ${classes.fixedImg} `} />
                            <Box className={classes.agencyDetails}>
                                <Avatar alt="AgencyLogo" src={agency_logo} className={classes.badge} />
                                <Typography className={classes.font14} style={{
                                    color: '#FFF',
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '150%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%'
                                }} component={'pre'}>
                                    <Tooltip title={`${agency_name} (${capitalizeWords(provider_type)})`} arrow>
                                        <Button classes={{ label: classes.serviceName }} style={{ padding: 0 }}>
                                            {agency_name}
                                        </Button>
                                    </Tooltip> ({capitalizeWords(provider_type)})
                                </Typography>
                                <Chip
                                    label={
                                        <>
                                         {conditionShort( Number.isInteger(rating || total_rating),(rating || total_rating),(rating || total_rating || 0).toFixed(1))}
                                            <Rating value={rating||total_rating * 0.2} precision={0.1}
                                                readOnly size="medium" max={1}
                                                style={{ color: "#FE7F2D", margin: 'auto 0' }}
                                                classes={{
                                                    icon: classes.ratingIcon
                                                }}
                                                />
                                        </>
                                    }
                                    classes={{ label: classes.chipLabel }}
                                    className={classes.chip}
                                    />
                            </Box>
                        </div>
                        <Typography data-test-id="navigateid2" onClick={() => navigation?.navigate('TripDetails', {id: trip.id})} className={classes.tripName}>
                            {title}
                        </Typography>
                        <Typography className={classes.tripLocation}>
                            {country}, {city}
                        </Typography>
                        <Typography className={classes.price}>
                            {discounted_price
                                ? <>{discounted_price} {currency} <s className={classes.strikeThrough}>{price} {currency}</s></>
                                : <>{price} {currency}</>
                            }
                        </Typography>
                    </div>
                )
            })}
      </CarouselDisplay>)
        }
    </Box >
}
}

export const carouselStyle = (theme: Theme) => ({
    sliderHeadingBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
    },
    ratingIcon: {
        '& svg': {
            height: '16px',
            width: '16px',
        }
    },
    heading: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '36px',
    },
    tripTypeheading: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: 'normal',
        [theme.breakpoints.between(600, 768)]: {
            fontSize: '32px',
            lineHeight: '36px'
        },
        [theme.breakpoints.down(600)]: {
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '100%',
        }

    },
    viewMoreLink: {
        color: '#FE7F2D',
        textAlign: 'right' as 'right',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        cursor: 'pointer',
        [theme.breakpoints.down(600)]: {
                fontSize: '14px'
        }
    },
    profileSlider: {
        maxHeight: '280px',
        '& img': {
            height: '234px'
        },
        '& .slick-list': {
            margin: '0 0 0 -16px',
            maxHeight: 'inherit'
        },
    },
    slickSlider: {
        marginTop: '48px',
        height: '100%',
        '& .slick-list': {
            maxHeight: 'inherit',
            height:'100%',
            '& .slick-slide': {
                width: 'auto !important',
                margin: '0 16px',
                height: '324px'
            }
        },
        '& .slick-arrow': {
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            filter: 'drop-shadow(0px 6.588235378265381px 31.05882453918457px rgba(0, 0, 0, 0.07)) drop-shadow(0px 1.8823529481887817px 18.823530197143555px rgba(0, 0, 0, 0.04)) drop-shadow(0px 1.8823529481887817px 3.7647058963775635px rgba(0, 0, 0, 0.08))',
            background: '#fff',
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1',
            top: '113px',
            '& svg': {
                color: '#1F55DE',
            },
            '&:before': {
                display: 'none',
            }
        },
        '& .slick-prev': {
            left: '-20px'
        },
        '& .slick-next': {
            right: '-20px'
        },
        '& .slick-prev.slick-disabled': {
            cursor: 'initial',
            backgroundColor: 'rgba(192, 190, 190, 0.75)',
            '& svg': {
                color: 'rgba(75, 75, 76, 0.75)'
            }
        },
        '& .slick-next.slick-disabled': {
            cursor: 'initial',
            backgroundColor: 'rgba(192, 190, 190, 0.75)',
            '& svg': {
                color: 'rgba(75, 75, 76, 0.75)'
            }
        },
    },
    innerSlideBlock: {
        maxHeight: '234px',
        maxWidth: '280px',
        position: 'relative' as 'relative'
    },
    fixedImg: {
        width: '300px !important',
        height: '226px !important'
    },
    badge: {
        '&.MuiAvatar-root': { width: '24px', height: '24px', }
    },
    chip: {
        background: 'white',
        maxWidth: '58px',
        maxHeight: '27px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#F5950B',
        borderRadius: '17px'
    },
    starIcon: {
        color: '#F5950B',
    },
    chipLabel: {
        color: '#F5950B',
        textAlign: 'right' as 'right',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: '0.301px',
        display: 'flex',
        alignItems: 'center',
        padding: '4px 16px'
    },
    serviceName: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        display: 'block',
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '106.5px',
        textTransform: 'none' as const,
        fontSize: '14px',
        justifyContent: 'flex-start'
    },
    tripName: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        letterSpacing: 0,
        marginTop: '16px',
        whiteSpace: 'nowrap' as const,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer'
    },
    tripLocation: {
        color: 'rgba(33, 36, 38, 0.85)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: 0,
        marginTop: '8px',
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical' as const,
        WebkitLineClamp: 1,
        overflow: 'hidden'
    },
    price: {
        display: 'flex',
        gap: '12px',
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        alignItems: 'center',
        letterSpacing: 0,
        marginTop: '16px',
        [theme.breakpoints.down(1254)]:{
            lineHeight : '5px'
        }
    },
    strikeThrough: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 300,
        lineHeight: 'normal',
        textDecorationLine: 'line-through',
    },
    imageVignette: {
        position: 'relative' as 'relative',
        cursor: 'pointer',
        '&:before': {
            content: "''",
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            top: 'auto',
            background: 'linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8))',
            pointerEvents: 'none',
            zIndex: 1,
            height: '40%',
            borderRadius: '0 0 16px 16px',
        }
    },
    smallDescription: {
        color: 'rgba(33, 36, 38, 0.75)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.301px',
        marginTop: '8px',
        [theme.breakpoints.between(600, 768)]: {
                marginTop: '12px'
        },
        [theme.breakpoints.down(600)]: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '13px',
                lineHeight: '21px',
                display: '-webkit-box',
                width: '199px',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 1,
                marginTop: '6px'
        }
    },
    tripSlider: {
        maxHeight: '349px',
        [theme.breakpoints.between(768, 1254)]: {
            maxHeight: '324px',
        },
        [theme.breakpoints.down(768)]: {
            maxHeight: '222px',
        '& .slick-list': {
            margin: '0 -16px 0 0',
            width: '344px'
        },
        },
        '& .slick-list': {
            margin: '0 -16px',
        },
    },
    innerTripSlideBlock: {
        maxHeight: '226px',
        maxWidth: '300px',
        position: 'relative' as 'relative',
        [theme.breakpoints.up(768)]: {
            maxHeight: '213px',
        },
        [theme.breakpoints.up(1024)]: {
            maxHeight: '300px',
        },
        [theme.breakpoints.up(1440)]: {
            maxHeight: '378px',
        },
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '16px',
        // objectFit: 'fill' as 'fill',
    },
    agencyDetails: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
        gap: '8px',
        position: 'absolute' as const,
        bottom: 0,
        height: '49px',
        width: '100%',
        zIndex: 1
    }
})

export const styles = (theme: Theme) => ({
    landingPageWrapper: { margin: '1px 0 120px' },
    container: {
        margin: '0 auto',
        maxWidth: '90%',
        width: '100%'
    },
    imageContainer: {
        position: 'relative' as 'relative',
        height: 'auto',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    responsiveImg: {
        height: '494px'
    },
    font14: {
        fontSize: '14px',
    },
    text: {
        position: 'absolute' as 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '32px 0',
        width: '100%',
        color: '#FFF',
        textAlign: 'center' as 'center',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)',
        borderRadius: '16px'
    },
    imageList: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '72px',
        gap: '40px'
    },
    imageListItem: { margin: 'auto' },
    planTripBlock: {
        borderRadius: '16px',
        backgroundColor: '#FFFAEA',
        background: `url(${itineraryDash}) lightgray 50% / cover no-repeat`,
        backgroundPosition: "100.4% center",
        backgroundSize: '12px'
    },
    innerPlanBlock: {
        display: 'flex',
        padding: '0 72px',
        flexDirection: 'row' as 'row',
        maxHeight: '290px',
        alignContent: 'end',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    search: {
        borderRadius: '53px',
        border: '0.5px solid rgba(33, 36, 38, 0.50)',
        background: '#FFF',
        maxWidth: '700px',
        height: '64px',
        position: 'relative' as 'relative',
        backgroundColor: '#FFF',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
    },
    searchIcon: {
        right: '12px',
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute' as 'absolute',
        // pointerEvents: 'none' as 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FE7F2D',
        textTransform: 'none' as 'none',
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        borderRadius: '25px',
        width: '113px',
        maxHeight: '40px',
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    inputRoot: { paddingLeft: '32px' },
    guestImage: {
        display: 'flex',
        height: '745px',
        background: `url(${landingPageGuest}) lightgray 50% / cover no-repeat`,
    },
    guestDescription: {
        color: "#FFF",
        textAlign: "center" as "center",
        fontFamily: "Inter",
        fontSize: "69px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "110%",
        width: '522px',
    },
    guestTitle: {
        color: "#FFF",
        fontFamily: "Inter",
        fontSize: "28px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        letterSpacing: "1.12px",
    },
    guestContainer: {
        maxWidth: '632px',
        width: '100%',
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        gap: '8px'
    },
    userDetailWrapper: {
        maxWidth: '90%',
        width: '100%',
        padding: '48px 0',
        margin: 'auto',
        position: 'relative' as 'relative',
    },
    leftContainer: {
        '&:before': {
            width: 1,
            top: '50%',
            left: '50%',
            content: "''",
            height: '75.5%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            background: `url(${transparentBorder}) transparent 50% / cover no-repeat`
        }
    },
    userDetailBlock: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    userName: {
        color: '#FE7F2D',
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '100%',
        marginBottom: '47px'
    },
    userImage: {
        width: '221px',
        height: '221px',
        borderRadius: '50%',
    },
    profileItem: {
        marginBottom: theme.spacing(1),
    },
    label: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        marginBottom: '8px',
        letterSpacing: 0
    },
    labelvalue: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        marginBottom: '24px',
        letterSpacing: 0,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as const,
        '&.left': {
            maxWidth: '108px',
            height: '24px'
        },
        '&.right': {
            maxWidth: '164px',
        }
    },
    tripTitle: {
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '28px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '100%',
        marginBottom: '48px',
        maxWidth: '89%',
        width: '100%',
    },
    tripLabel: {
        color: 'rgba(255, 255, 255, 0.80)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%',
        marginBottom: '8px'
    },
    tripValue: {
        color: '#FFF',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        marginBottom: '24px'
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        justifyContent: 'center',
        alignItems: 'end'
    },
    details: {
        display: 'flex',
        gap: '37px',
    },
    tripContainer: {
        border: '0.5px solid #FFF',
        background: '#4CA7C4',
        maxWidth: '89%',
        maxHeight: '272px',
        width: '100%',
        height: '100%',
        position: "relative" as "relative",
        '&:after': {
            width: 24,
            height: 24,
            left: "50%",
            top: "-12px",
            content: '""',
            borderRadius: "50%",
            position: "absolute",
            backgroundColor: "#F0FAFB",
            transform: 'translate(-50%)',
            zIndex: 1
        },
        '&:before': {
            content: '""',
            width: 24,
            height: 24,
            left: "50%",
            bottom: "-12px",
            borderRadius: "50%",
            position: "absolute",
            transform: 'translate(-50%)',
            backgroundColor: "#F0FAFB",
            zIndex: 1
        },
    },
    tripLeft: {
        padding: '32px 0 0 48px',
        position: 'relative' as 'relative',
        '&:after': {
            top: '50%',
            left: "100%",
            content: '""',
            height: "100%",
            position: "absolute",
            transform: "translate(-50%,-50%)",
            borderLeft: "2px dashed rgba(255, 255, 255, 0.70)",
        }
    },
    tripRight: {
        padding: '32px 0 0 48px'
    },
    searchBar: {
        color: 'rgba(33, 36, 38, 0.50)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    sliderText: {
        marginTop: '16px',
        color: '#212426',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '150%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        '&.travelAgency': {
            textAlign: 'center'
        }
    },
    planTitle: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '33px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '120%',
        letterSpacing: 0,
        marginBottom: '16px'
    },
    marginTop96: { marginTop: '96px' },
    marginTop88: { marginTop: '88px' },
    marginTop56: { marginTop: '56px' },
    marginTop32: { marginTop: '32px' },
    marginTop120: { marginTop: '120px' },
    forGuest: { marginTop: '120px' },
    forUser: { marginTop: '120px' },
    collection: { marginTop: '128px' },
    planDescription: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: 0,
        marginBottom: '32px'
    },
    ...carouselStyle(theme),
    [theme.breakpoints.down(768.05)]: {
        sliderText: {
            '&.travelAgency': {
                textAlign: 'start'
            }
        }
    },
    [theme.breakpoints.between(600.05, 768.05)]: {
        container: { maxWidth: '672px', },
        forUser: { marginTop: '80px' },
        forGuest: { marginTop: '80px' },
        collection: { marginTop: '96px' },
        marginTop120: { marginTop: '80px' },
        marginTop96: { marginTop: '80px' },
        marginTop88: { marginTop: '80px' },
        guestImage: { height: '952px' },
        userName: {
            fontSize: '24px',
            marginBottom: '48px'
        },
        userImage: {
            width: '203px',
            height: '203px',
            border: '0.769px solid #D9D9D9'
        },
        userDetailWrapper: {
            maxWidth: '672px',
            gap: '96px',
        },
        leftContainer: {
            '&:before': {
                position: 'absolute',
                height: '1px',
                content: "''",
                transform: 'translate(-50%, -50%)',
                top: '50%',
                width: '100%',
                background: `url(${transparentBorder768}) transparent 50% / cover no-repeat`
            }
        },
        userDetailBlock: {
            justifyContent: 'flex-start',
            gap: '90px'
        },
        details: { gap: '68px' },
        planTitle: {
            fontSize: '32px',
            lineHeight: '130%'
        },
        tripTitle: {
            fontSize: '24px',
            marginBottom: '43px',
            maxWidth: '100%'
        },
        tripContainer: {
            maxWidth: '100%',
            height: '216px'
        },
        tripLeft: {
            padding: '32px 48px 0 48px',
            '&:after': {
                height: '87%'
            }
        },
        tripRight: { padding: '32px 48px 0 48px' },
        heading: { fontSize: '36px' },
        
        imageList: {
            marginTop: '48px',
            gap: '32px',
            flexWrap: 'wrap'
        },
        responsiveImg: {
            objectFit: 'cover',
            borderRadius: '16px',
            width: '320px',
            height: '414px',
        },
        innerSlideBlock: {
            maxWidth: '234px'
        },
        innerPlanBlock: {
            padding: '48px 3px 48px 48px',
            maxHeight: '360px',
        },
        planDescription: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3
        },
        guestContainer: { maxWidth: '558px' },
        guestTitle: { letterSpacing: '0.96px', fontSize: '24px' },
        guestDescription: { fontSize: '64px' },
    },
    [theme.breakpoints.down(600.05)]: {
        container: {
            maxWidth: '328px',
        },
        guestContainer: {
            maxWidth: '328px',
            gap: 0
        },
        guestImage: {
            height: '484px'
        },
        userDetailBlock: {
            gap: '17px',
            flexWrap: 'nowrap'
        },
        guestTitle: {
            fontSize: "16px",
            letterSpacing: "0.64px",
            marginBottom: "6px"
        },
        guestDescription: {
            fontSize: '40px',
            width: '328px'
        },
        userDetailWrapper: {
            padding: '24px 0 32px',
            maxWidth: '328px',
            gap: '48px',
        },
        leftContainer: {
            '&:before': {
                position: 'absolute',
                height: '1px',
                content: "''",
                transform: 'translate(-50%, -50%)',
                top: '50%',
                width: '100%',
                background: `url(${transparentBorder360}) transparent 50% / cover no-repeat`
            }
        },
        userName: {
            fontSize: '18px',
            marginBottom: '20px'
        },
        userImage: {
            width: '70px',
            height: '70px',
            border: '0.265px solid #D9D9D9'
        },
        details: {
            gap: '24px'
        },
        label: {
            fontSize: '12px',
            marginBottom: '4px'
        },
        labelvalue: {
            fontSize: '14px',
            marginBottom: '16px',
            '&.left': {
                maxWidth: '94px',
                height: '21px'
            },
            '&.right': {
                maxWidth: '123px',
            }
        },
        tripTitle: {
            fontSize: '18px',
            maxWidth: '100%',
            marginBottom: '23px'
        },
        tripContainer: {
            maxWidth: '100%',
            height: '155px'
        },
        tripLeft: {
            padding: '16px 16px 0',
            '&:after': {
                height: '81%'
            }
        },
        tripRight: {
            padding: '16px'
        },
        tripLabel: {
            fontSize: '12px',
            marginBottom: '4px'
        },
        tripValue: {
            fontSize: '14px',
            marginBottom: '16px'
        },
        search: {
            height: '56px'
        },
        inputRoot: {
            padding: '18px 24px 19px'
        },
        searchBar: {
            fontSize: '16px',
            padding: 0
        },
        searchIcon: {
            padding: 0,
            fontSize: 0,
            width: '40px',
            height: '40px',
            minWidth: 'auto',
            '& svg': {
                width: '16px'
            }
        },
        marginTop96: {
            marginTop: '48px'
        },
        marginTop88: {
            marginTop: '48px'
        },
        marginTop56: {
            marginTop: '48px'
        },
        marginTop120: {
            marginTop: '48px'
        },
        forUser: {
            marginTop: '56px'
        },
        forGuest: {
            marginTop: '40px'
        },
        collection: {
            marginTop: '64px'
        },
        imageList: {
            marginTop: '24px',
            gap: '16px',
            flexWrap: 'wrap'
        },
        innerBlockRight: {
            display: 'none'
        },
        innerPlanBlock: {
            padding: '16px'
        },
        planTitle: {
            fontSize: '20px',
            lineHeight: '140%',
            marginBottom: '6px'
        },
        planDescription: {
            marginBottom: '24px',
            fontSize: '14px'
        },
        landingPageWrapper: {
            margin: '0 0 97px'
        },
        text: {
            padding: '16px 0',
            fontSize: '16px',
            borderRadius: '8px'
        },
        heading: {
            fontSize: '20px',
            fontWeight: 600,
            lineHeight: '100%',
            '&.sliderHeading': {
                fontSize: '18px'
            }
        },        
        image: {
            borderRadius: '8px'
        },
        serviceName: {
            fontSize: '12px',
            maxWidth: '80px'
        },
        responsiveImg: {
            width: '156px',
            minWidth: 'auto',
            height: '208px',
            objectFit: 'cover'
        },
        fixedImg: {
            width: '156px !important',
            height: '120px !important'
        },
        
        slickSlider: {
            marginTop: '24px',
            '& .slick-list': {
                '& .slick-slide': {
                    margin: '0 16px 0 0',
                    height: '222px'
                }
            },
            '& .slick-arrow': {
                width: '32px',
                height: '32px',
                top: '60px'
            },
            '& .slick-prev': {
                left: '-8px'
            },
            '& .slick-next': {
                right: '-8px'
            }
        },
        innerTripSlideBlock: {
            maxWidth: '156px'
        },
        agencyDetails: {
            padding: '0 8px',
            height: '19.5px',
            gap: '4px',
            bottom: '10.5px'
        },
        badge: {
            '&.MuiAvatar-root': {
                width: '19.5px', height: '19.5px',
                border: '0.417px solid rgba(33, 36, 38, 0.20)'
            }
        },
        innerSlideBlock: {
            maxWidth: '120px'
        },
        font14: {
            fontSize: '0',
        },
        chip: {
            width: '30px',
            height: '16px',
            padding: '2px 6px',
            borderRadius: '20px',
        },
        chipLabel: {
            fontSize: '11px',
            padding: 0
        },
        ratingIcon: {
            '& svg': {
                height: '10px',
                width: '10px',
            }
        },
        tripName: {
            marginTop: '8px',
            fontSize: '14px',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: '22px',
            whiteSpace: 'wrap'
        },
        tripLocation: {
            marginTop: '4px',
            fontSize: '12px'
        },
        price: {
            marginTop: '12px',
            fontSize: '16px',
            lineHeight: '19px',
            alignItems: 'flex-end'
        },
        strikeThrough: {
            marginBottom: '1px'
        },
        profileImg: {
            width: '120px !important',
            height: '120px !important'
        },
        sliderText: {
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '150%',
            marginTop: '8px'
        },
        profileSlider: {
            maxHeight: '149px',
            '& img': {
                height: '120px'
            },
            '& .slick-list': {
                width: '328px',
                // margin: '0 -8px',
            },
        }
    },
})

const BootstrapButton = withStyles({
    root: {
        textTransform: "none",
        backgroundColor: primaryColor,
        color: "#ffffff",
        width: "100%",
        textAlign: "center",
        borderRadius: "44px",
        fontSize: "19px",
        fontWeight: 400,
        lineHeight: "normal",
        height: '54px',
        maxWidth: '177px',
        fontFamily: ["Inter", "sans-serif"].join(","),
        border: `1px solid ${primaryColor}`,
        "&:hover": {
            backgroundColor: primaryColor
        },
        '@media (max-width:360px)': {
            maxWidth: '100%',
            padding: '9px',
            height: 'auto',
            fontSize: '16px',
            lineHeight: '24px'
        }
    }
})(Button);

// Customizable Area End

import LandingPageController, {
    Props
} from "./LandingPageController";

export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    data = [
        {
            image: culture,
            title: 'Culture'
        },
        {
            image: nature,
            title: 'Nature'
        },
        {
            image: adventure,
            title: 'Adventure'
        },
        {
            image: expierience,
            title: 'Experience'
        },
    ]

    featuredCollection = [
        {
            image: topPicks,
            title: 'Top Picks'
        },
        {
            image: mostVisited,
            title: 'Most Visited'
        },
        {
            image: newAdded,
            title: 'Newly Added'
        },
    ]

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { loggedin, travelAgencyList, travelBuddyList, cultureTrips,
            responeStatsModal, isSuccessIcon, modalMsg,   upcomingTrip , confirmationNumber,

            natureTrips, adventureTrips, experienceTrips, userProfileData: {
                first_name,
                last_name,
                gender,
                nationality,
                country_of_residence,
                profile_image,
                joining_date,
            } = {} } = this.state
                        

            const tripDetails = {
                upcominTripAgentName: upcomingTrip && upcomingTrip.data && upcomingTrip.data.attributes && upcomingTrip.data.attributes.servise_provier_details && upcomingTrip.data.attributes.servise_provier_details.name,
                tripTitle: upcomingTrip && upcomingTrip.data && upcomingTrip.data.attributes && upcomingTrip.data.attributes.title,
                tripDate: (() => {
                    const tripDate = upcomingTrip && upcomingTrip.data && upcomingTrip.data.attributes && upcomingTrip.data.attributes.trip_schedules && upcomingTrip.data.attributes.trip_schedules.data && upcomingTrip.data.attributes.trip_schedules.data[0] && upcomingTrip.data.attributes.trip_schedules.data[0].attributes && upcomingTrip.data.attributes.trip_schedules.data[0].attributes.scheduled_date;
                    if (tripDate) {
                        const dateObj = new Date(tripDate);
                        const options = { weekday: 'long' as const, month: 'long' as const, day: 'numeric' as const };
                        return dateObj.toLocaleDateString('en-US', options);
                    }
                    return null; // or handle the case when tripDate is undefined
                })()
            };
            
            
     
        const userDetailsBlock = () => {
            return (loggedin
                ? <div style={webStyle.userDetailContainer}>
                    <Grid container className={classes.userDetailWrapper}>
                        <Grid item xs={12} md={6} className={classes.leftContainer}>
                            <Typography variant="h5" className={classes.userName}>
                                Beena Adventurer
                            </Typography>
                            <Grid container className={classes.userDetailBlock}>
                                <Grid item md={5}>
                                    <Avatar className={classes.userImage} alt="User Avatar" src={profile_image} />
                                </Grid>

                                <Grid item md={7} className={classes.details}>
                                    <div>
                                        <Typography variant="subtitle1" className={classes.label}>First Name</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} left`}
                                            style={{ overflow: 'hidden' }}>{first_name}</Typography>
                                        <Typography variant="subtitle1" className={classes.label}>Joining Date</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} left`}>
                                            {joining_date ? formatDate(joining_date) : joining_date}
                                        </Typography>
                                        <Typography variant="subtitle1" className={classes.label}>Nationality</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} left`} style={{ marginBottom: 0 }}>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="subtitle1" className={classes.label}>Last Name</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} right`}
                                            style={{ overflow: 'hidden' }}>{last_name}</Typography>
                                        <Typography variant="subtitle1" className={classes.label}>Gender</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} right`}>{gender}</Typography>
                                        <Typography variant="subtitle1" className={classes.label}>Country of Residence</Typography>
                                        <Typography variant="body1" className={`${classes.labelvalue} right`} style={{ marginBottom: 0 }}>
                                            {country_of_residence}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.rightContainer}>
                            <Typography variant="h5" className={classes.tripTitle}>
                                Upcoming Trips
                            </Typography>
                            <Grid container className={classes.tripContainer}>
                                <Grid item xs={6} className={classes.tripLeft}>
                                    <Typography variant="subtitle1" className={classes.tripLabel}>Beena Agent</Typography>
                                    <Typography variant="body1" className={classes.tripValue}>{formatEmptyValue(tripDetails.upcominTripAgentName)}</Typography>
                                    <Typography variant="subtitle1" className={classes.tripLabel}>Boarding Day & Time</Typography>
                                    <Typography variant="body1" className={classes.tripValue}>{formatEmptyValue(tripDetails.tripDate)}</Typography>
                                </Grid>

                                <Grid item xs={6} className={classes.tripRight}>
                                    <Typography variant="subtitle1" className={classes.tripLabel}>Confirmation Number</Typography>
                                    <Typography variant="body1" className={classes.tripValue}>{formatEmptyValue(confirmationNumber)}</Typography>
                                    <Typography variant="subtitle1" className={classes.tripLabel}>Journey To</Typography>
                                    <Typography variant="body1" className={classes.tripValue}>{formatEmptyValue(tripDetails.tripTitle)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                : <div className={classes.guestImage}>
                    <div className={classes.guestContainer}>
                        <Typography className={classes.guestTitle}>
                            SAWWAH
                        </Typography>
                        <Typography className={classes.guestDescription}>
                            Walk this Way for Adventures
                        </Typography>
                        {searchBlock(classes.marginTop32)}
                    </div>
                </div>
            )
        }

        const profileSilder = (title: string, list: Record<string, any>[], additionalClass: string = '') => {
            return <Box className={classes.marginTop120}>
                <Box className={classes.sliderHeadingBlock}>
                    <Typography className={`${classes.heading} sliderHeading`}>{title}</Typography>
                    <Typography 
                    onClick={() => this.goToServiceProviderPageList(additionalClass, '', '')}
                    className={classes.viewMoreLink}>Explore more</Typography>
                </Box>

                <Slider {...settings(6)} className={`${classes.profileSlider} ${classes.slickSlider} `}
                    nextArrow={<Box><KeyboardArrowRight /></Box>}
                    prevArrow={<Box><KeyboardArrowLeft /></Box>}
                >
                    {list.map((user, index) => {
                        const { name, image } = user.attributes
                        const {id, type} = user;
                        return (
                            <div data-test-id="checkid" key={index} className={classes.innerSlideBlock}>
                                <LazyImage src={image} alt="Image" className={`${classes.image} ${classes.profileImg}`}
                                    style={{cursor: 'pointer'}}
                                    data-test-id="navigateToSPDPage1"
                                    onClick={() => this.navigateToProviderDetailsPage(type, id)}
                                />
                                <Typography className={`${classes.sliderText}`}
                                    data-test-id="navigateToSPDPage2"
                                    style={{cursor: 'pointer'}}
                                    onClick={() => this.navigateToProviderDetailsPage(type, id)}
                                >
                                    {name}
                                </Typography>
                            </div>
                        )
                    })}
                </Slider>
            </Box>
        }

        const imageListComponent = (list: { image: string, title: string }[],
            data: { title: string, class: any },
            maxWidth: string) => {
            return <Box className={data.class}>
                <Typography className={classes.heading}>{data.title}</Typography>
                <Box className={classes.imageList}>
                    {list.map((item, index) => {
                        return (
                            <Box className={classes.imageListItem} key={index}>
                                <div className={classes.imageContainer}
                                onClick={() => this.goToAdvancedSearchPage("tripType", item.title)}
                                style={{cursor: 'pointer'}}
                                >
                                    <LazyImage
                                        className={`${classes.responsiveImg} ${classes.image}`}
                                        style={{ maxWidth: maxWidth }}
                                        src={item.image}
                                        alt="Image 1"
                                    />
                                    <Typography variant="body2" className={classes.text}>
                                        {item.title}
                                    </Typography>
                                </div>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
        }

        const searchBlock = (style: '') => {
            return <div className={`${classes.search} ${style}`}>
                <InputBase
                    placeholder="Where to?"
                    data-test-id="searchInputField"
                    value={this.state.searchString}
                    onChange={(event) => this.handleSearchString(event.target.value)}
                    onKeyDown={(event) => this.handleEnterPress(event, "searchString", this.state.searchString)}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ className: classes.searchBar }}
                />
                <Button 
                data-test-id="pressSearchBtn"
                disabled={!this.state.searchString.trim()} className={classes.searchIcon} onClick={() => this.goToAdvancedSearchPage("searchString", this.state.searchString)}>
                    <Search /> Search
                </Button>
            </div>
        }

        const itineraryBlock = (navigateToItinarary: () => void) => {
            return (
                <Box className={`${classes.planTripBlock} ${classes.marginTop96}`}>
                    <Grid container className={classes.innerPlanBlock}>
                        <Grid item xs={12} sm={7} md={8} style={webStyle.innerBlockLeft}>
                            <Typography className={classes.planTitle}>
                                Do you want to plan for your itinerary?
                            </Typography>
                            <Typography className={classes.planDescription}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam eiusmod tempor incididunt ut labore
                            </Typography>
                            <BootstrapButton data-test-id="navigateToPlanItinerary" onClick={() => navigateToItinarary()}>Start here</BootstrapButton>
                        </Grid>
                        <Grid item xs={12} sm={5} md={3} className={classes.innerBlockRight}>
                            <LazyImage src={itinerary} alt={'itinerary Image'} />
                        </Grid>
                    </Grid>
                </Box>
            )
        }

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box className={classes.landingPageWrapper}>
                {userDetailsBlock()}

                <div className={classes.container} >

                    {loggedin && searchBlock(classes.marginTop56)}

                    {imageListComponent(this.data, loggedin
                        ? { title: 'For you', class: classes.forUser }
                        : { title: 'Where are you up to?', class: classes.forGuest }, '375px')}

                    {profileSilder('Top Travel Buddies', travelBuddyList, 'travelBuddy')}
                    {profileSilder('Top Travel Agencies', travelAgencyList, 'travelAgency')}

                    {itineraryBlock(this.navigateToItenarary)}

                    {tripSlider(classes, cultureTrips, 'Culture', this.props.navigation, this.goToServiceProviderPageList, {}, classes.marginTop96)()}
                    {tripSlider(classes, natureTrips, 'Nature', this.props.navigation, this.goToServiceProviderPageList, {}, classes.marginTop88)()}
                    {tripSlider(classes, adventureTrips, 'Adventure', this.props.navigation, this.goToServiceProviderPageList, {}, classes.marginTop88)()}
                    {tripSlider(classes, experienceTrips, 'Experience', this.props.navigation, this.goToServiceProviderPageList, {}, classes.marginTop88)()}

                    {imageListComponent(this.featuredCollection,
                        { title: 'Explore featured collection ', class: classes.collection }, '510px')}
                </div >
                <ResponseStatusModal
                    open={responeStatsModal}
                    isSuccess={isSuccessIcon}
                    message={modalMsg}
                    onClose={this.closeResponseModalLP}
                />
            </Box >
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const LandingPageWeb = withStyles(styles)(LandingPage);
export { LandingPageWeb };

const webStyle = {
    userDetailContainer: {
        background: '#F0FAFB'
    },
    innerBlockLeft: {
        display: 'flex',
        flexDirection: 'column' as 'column',
        alignItems: 'flex-start'
    },
};
// Customizable Area End
