import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Button,
    Typography,
    TextField,
    Grid
} from "@material-ui/core";
import * as Yup from "yup";
import { Formik } from "formik";
import { withStyles } from "@material-ui/core/styles";
export const {
    verifyEmailBtn,
    forgotPasswordTitle,
    emailAddressPlaceholder,
    forgotPasswordDescription
} = require("./config");
import { forgotPasswordImg } from "./assets";
import { primaryColor } from "../../../components/src/theme";
import {
    borderColor,
    helperTextValue,
    isErrorInField
} from "../../../components/src/utils";

export const styles = () => ({
    main: {
        padding: "53px 0 50px",
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between"
    },
    formBlock: {
        margin: "0 auto",
        display: "flex",
        justifyContent: "center"
    },
    title: {
        fontWeight: 500,
        color: "#212426",
        fontSize: "40px",
        lineHeight: "120%",
        fontStyle: "normal",
        marginBottom: "16px",
        fontFamily: "Inter, sans-serif"
    },
    description: {
        fontWeight: 400,
        letterSpacing: 0,
        fontSize: "16px",
        lineHeight: "150%",
        fontStyle: "normal",
        marginBottom: "32px",
        fontFamily: "Inter, sans-serif",
        color: "rgba(33, 36, 38, 0.65)"
    },
    textlabel: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "150%",
        marginBottom: "10px",
        color: "rgb(33 36 38 / 65%)",
        fontFamily: "Inter, sans-serif"
    },
    input: {
        padding: 0,
        letterSpacing: 0,
        height: "auto",
        lineHeight: "24px",
        fontSize: "16px",
        fontWeight: 500
    },
    inputField: {
        color: "black",
        padding: "15px 24px",
        borderRadius: "8px",
        border: `1px solid`,
        backgroundColor: "#ffffff",
        fontFamily: "Inter, sans-serif"
    },
    errorBlock: {
        color: "#FF0000",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "150%",
        padding: "8px 16px",
        borderRadius: "8px",
        fontStyle: "normal",
        marginBottom: "23px",
        background: "#FFF2F2",
        display: "inline-block"
    },
    errorBefore: {
        content: "",
        background: "red",
        width: "5px",
        height: "100%",
        top: 0,
        left: 0,
        borderRadius: "8px 0 0 8px!important"
    },
    root: {
        "&.Mui-error": {
            color: "#F00"
        }
    },
    validateError: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
        marginTop: "4px",
        letterSpacing: 0
    },
    successNoteText: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        textAlign: 'center' as const,
        marginBottom: 0
    }
});
// Customizable Area End

import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController";

export default class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start

        const validationSchema = Yup.object().shape({
            email: Yup.string()
                .email("* Please enter a valid email")
                .required("* Please enter a valid email")
        });

        const { classes } = this.props;

        const BootstrapButton = withStyles({
            root: {
                textTransform: "none",
                backgroundColor: primaryColor,
                color: "#ffffff",
                width: "100%",
                height: "56px",
                flexShrink: 0,
                textAlign: "center",
                borderRadius: "44px",
                fontSize: "16px",
                fontWeight: 500,
                lineHeight: "150%",
                fontFamily: ["Inter", "sans-serif"].join(","),
                border: `1px solid ${primaryColor}`,
                "&:hover": {
                    backgroundColor: primaryColor
                }
            }
        })(Button);

        // Customizable Area End
        return (
            // Customizable Area Start
            <Container maxWidth="lg" className={classes.main}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={5}
                    style={{ margin: "0 auto", maxWidth: "1200px" }}
                >
                    <Grid
                        item
                        xs={10}
                        sm={9}
                        md={6}
                        lg={4}
                        className={classes.formBlock}
                        style={{ flexDirection: "column" }}
                    >
                        <Typography className={classes.title}>
                            {forgotPasswordTitle}
                        </Typography>
                        <Typography className={classes.description}>
                            {forgotPasswordDescription}
                        </Typography>

                        {this.state.VerifyEmailNote && (
                            <Typography component={"div"}>
                                <p
                                    className={classes.errorBlock}
                                    style={{ position: `relative`, wordBreak: "break-all" }}
                                >
                                    <span
                                        className={classes.errorBefore}
                                        style={{ position: "absolute" }}
                                    />
                                    {this.state.VerifyEmailNote}
                                </p>
                            </Typography>
                        )}

                        <Formik
                            initialValues={{ email: "" }}
                            validationSchema={validationSchema}
                            onSubmit={values => {
                                this.sendEmailOtp(values);
                            }}
                        >
                            {({ values, touched, errors, handleSubmit, setFieldValue }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Box style={{ marginBottom: "48px" }}>
                                            <Typography className={classes.textlabel}>
                                                {emailAddressPlaceholder}
                                            </Typography>
                                            <Box
                                                style={{ display: "flex", gap: "10px", width: "100%" }}
                                            >
                                                <TextField
                                                    name="email"
                                                    variant="standard"
                                                    style={{ width: "100%" }}
                                                    placeholder={emailAddressPlaceholder}
                                                    onChange={e => {
                                                        setFieldValue("email", e.target.value);
                                                        touched.email = true;
                                                    }}
                                                    error={isErrorInField(errors, touched, "email")}
                                                    helperText={helperTextValue(
                                                        errors,
                                                        touched,
                                                        "email"
                                                    )}
                                                    FormHelperTextProps={{
                                                        className: classes.validateError,
                                                        classes: {
                                                            root: classes.root
                                                        }
                                                    }}
                                                    value={values.email}
                                                    InputProps={{
                                                        className: classes.inputField,
                                                        classes: {
                                                            input: classes.input
                                                        },
                                                        style: {
                                                            borderColor: borderColor(errors, touched, "email")
                                                        },
                                                        disableUnderline: true
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box>
                                            <BootstrapButton type="submit">
                                                {verifyEmailBtn}
                                            </BootstrapButton>
                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    </Grid>
                    <Grid item lg={7} className="login-image-block hidden-sm">
                        <img src={forgotPasswordImg} alt="Images" />
                    </Grid>
                </Grid>
            </Container>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const ForgotPasswordWeb = withStyles(styles)(ForgotPassword);
export { ForgotPasswordWeb };
// Customizable Area End
