import React from "react";

import {
    // Customizable Area Start
    Box,
    Modal,
    Typography,
    Button
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import { Formik, Form } from 'formik';
import * as Yup from "yup";

const theme = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        }
    },
    typography: {
        body1: {
            color: '#212426',
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 400,
            letterSpacing: 0,
            fontSize: '16px',
            lineHeight: '150%'
        }
    }
});

const validationSchema = Yup.object().shape({
    current_password: Yup.string().min(5, "* Password must be at least 5 characters").required("* Incorrect password, please enter your correct password."),
    new_password: Yup.string().min(5, "* Password must be at least 5 characters").required("* Please enter a valid password."),
    confirm_password: Yup.string().min(5, "* Password must be at least 5 characters")
    .oneOf([Yup.ref("new_password"), null], "* Password do not match")
    .required("* Please enter a valid password")
})
// Customizable Area End

import Settings5Controller, {
    Props,
    configJSON,
} from "./Settings5Controller";
import CustomTextField from "../../../components/src/CustomTextField";
import { primaryColor } from "../../../components/src/theme";
import ResponseStatusModal from "../../../components/src/ResponseStatusModal";

export default class ChangePassword extends Settings5Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            localModal,
            current_password,
            new_password,
            confirm_password,
            showPasswordOld,
            showPasswordNew,
            showPasswordConfirm,
            responseModalMsg,
            responseStatsModal,
            isSuccessIcon
        } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <Modal open={localModal}>
                    <Box className={classes.paperMainWrapper}>
                        <Box className={classes.modalEditContent}>
                            <Box className={classes.textAndCloseStyle}>
                                <Typography className={classes.headingTxt}>Change Password</Typography>
                                <Box onClick={this.handleCloseAllModal} className={classes.closeButton}><Close style={{ fontSize: '20px' }} /></Box>
                            </Box>
                            <Typography className={`${classes.distanceBtwn} ${classes.bodyTxt}`}>{configJSON.ChangePasswordText}</Typography>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ current_password, new_password, confirm_password }}
                                test-id="submitButtonId"
                                onSubmit={this.changePasswordAPIRequest}
                                validationSchema={validationSchema}
                            >
                                {({ values, setFieldValue, errors, touched }) => {
                                    return (
                                        <Form style={{marginBottom: '0px'}}>
                                            <Box className={`${classes.distanceBtwn}`}>
                                                <CustomTextField
                                                    fieldTitle={'Current Password'}
                                                    placeholder={'Enter current password'}
                                                    value={values.current_password}
                                                    fieldName="current_password"
                                                    onChange={setFieldValue}
                                                    error={errors.current_password as string}
                                                    touched={touched.current_password as boolean}
                                                    type= {showPasswordOld ? "text" : "password"}
                                                    isChangePassword={true}
                                                    showPassword={showPasswordOld}
                                                    handleShowPassword={this.handleShowPasswordOld}
                                                    isApplyDiscount={true}
                                                />
                                            </Box>
                                            <Box className={`${classes.distanceBtwn}`}>
                                                <CustomTextField
                                                    data-testid="new_password"
                                                    fieldTitle={'New Password'}
                                                    placeholder={'Enter new password'}
                                                    value={values.new_password}
                                                    fieldName="new_password"
                                                    onChange={setFieldValue}
                                                    error={errors.new_password as string}
                                                    touched={touched.new_password as boolean}
                                                    type= {showPasswordNew ? "text" : "password"}
                                                    isChangePassword={true}
                                                    showPassword={showPasswordNew}
                                                    handleShowPassword={this.handleShowPasswordNew}
                                                    isApplyDiscount={true}
                                                />
                                            </Box>
                                            <Box className={`${classes.distanceBtwn}`}>
                                                <CustomTextField
                                                    data-testid="confirm_password"
                                                    fieldTitle={'Confirm Password'}
                                                    placeholder={'Enter new password again'}
                                                    value={values.confirm_password}
                                                    fieldName="confirm_password"
                                                    onChange={setFieldValue}
                                                    error={errors.confirm_password as string}
                                                    touched={touched.confirm_password as boolean}
                                                    type= {showPasswordConfirm ? "text" : "password"}
                                                    isChangePassword={true}
                                                    showPassword={showPasswordConfirm}
                                                    handleShowPassword={this.handleShowPasswordConfirm}
                                                    isApplyDiscount={true}
                                                />
                                            </Box>
                                            <Box
                                                className={`${classes.textAndCloseStyle} ${classes.distanceBtnFld}`}
                                            >
                                                <Button
                                                    className={classes.cancelBtnStyle}
                                                    onClick={this.handleCloseAllModal}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className={classes.submitBtnStyle}
                                                    type="submit"
                                                >
                                                    Change Password
                                                </Button>
                                            </Box>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </Box>
                    </Box>
                </Modal>
                <ResponseStatusModal
                        open={responseStatsModal}
                        isSuccess={isSuccessIcon}
                        message={responseModalMsg}
                        onClose={this.closeResponseModal}
                    />
            </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    paperMainWrapper: {
        position: 'absolute' as const,
        width: '411px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.18)',
        border: '1px solid #C3CAD9',
        // padding: '16px 20px',
        borderRadius: '32px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'initial' as const,
    },
    modalEditContent: {
        padding: '32px 32px 40px 32px',
        maxWidth: '100%',
        maxHeight: '100%',
        overflow: 'auto',
    },
    textAndCloseStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px'
    },
    headingTxt: {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '121%',
        fontStyle: 'normal',
        color: '#212426',
    },
    bodyTxt: {
        color: 'rgba(33, 36, 38, 0.80)',
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '150%'
    },
    closeButton: {
        cursor: 'pointer',
    },
    cancelBtnStyle: {
        backgroundColor: 'transparent',
        color: primaryColor,
        border: `1px solid ${primaryColor}`,
        textTransform: 'initial' as const,
        borderRadius: '49px',
        padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 500,
        lineHeight: '150%',
        width: '117px',
        height: '48px',
    },
    submitBtnStyle: {
        backgroundColor: primaryColor,
        color: '#ffffff',
        textTransform: 'initial' as const,
        borderRadius: '49px',
        padding: '10px 50px',
        fontSize: '16px',
        fontFamily: "Inter",
        fontWeight: 400,
        width: '214px',
        height: '48px',
        lineHeight: '150%',
        whiteSpace: 'nowrap' as const,
        '&:hover': {
            backgroundColor: primaryColor,
            color: '#ffffff',
        }
    },
    distanceBtwn: {
        marginTop: '24px'
    },
    distanceBtnFld: {
        marginTop: '32px'
    },
    [theme.breakpoints.down('md')]: {
        paperMainWrapper: {
            position: 'absolute' as const,
            width: '468px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '12px 16px',
            borderRadius: '16px',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial' as const,
        },
        modalEditContent: {
            padding: '32px 32px 40px 32px',
            maxWidth: '100%',
            maxHeight: '100%',
            overflow: 'auto',
        },
    },
    [theme.breakpoints.down('sm')]: {
        paperMainWrapper: {
            position: 'absolute' as const,
            width: '370px',
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '8px 12px',
            borderRadius: '16px',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'initial' as const,
        },
        modalEditContent: {
            padding: '1rem 1.2rem',
            maxWidth: '100%',
            maxHeight: '50vh',
            overflowY: 'scroll',
        },
    }
});

const ChangePasswordWeb = withStyles(styles)(ChangePassword)
export { ChangePasswordWeb }
// Customizable Area End
