import React from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from '@material-ui/core/styles'
import { ICurrency } from '../../blocks/ProductDescription/src/Interface.web';
import { useWindowWidth } from './utils';

interface CurrencyTextFieldProps {
    value: string,
    priceFieldName: string;
    onChange: (fieldName: string, value: string) => void;
    currency: string;
    currencyList: ICurrency[];
    error: string;
    touch: boolean;
    fullwidth?: boolean;
    inputStyles?: any;
    inputType?: string;
}

const useStyles = makeStyles((theme) => ({
    inputBlack: {
        color: "#212426E5",
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 500
    },
    inputGray: {
        color: "rgb(33 36 38 / 65%)",
        fontFamily: "Inter",
        fontSize: '16px',
        fontWeight: 500
    },
    customFont: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        '&.MuiFormHelperText-root.Mui-error': {
            color: '#FF0000',
          }
    },
    [theme.breakpoints.down(768)]: {
        inputBlack: {
            color: "#212426E5",
            fontFamily: "Inter",
            fontSize: '14px',
            fontWeight: 500
        },
        inputGray: {
            color: "rgb(33 36 38 / 65%)",
            fontFamily: "Inter",
            fontSize: '14px',
            fontWeight: 500
        },
    }
}));

const CurrencyTextField: React.FC<CurrencyTextFieldProps> = ({
    value,
    priceFieldName,
    onChange,
    currency,
    currencyList,
    error,
    touch,
    fullwidth= true,
    inputStyles,
    inputType="number"
}) => {
    const classes = useStyles();
    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;
    return (
        <FormControl fullWidth={fullwidth}>
            <TextField
                value={value}
                placeholder='price'
                type={inputType}
                onChange={(e) => onChange(priceFieldName, e.target.value)}
                error={Boolean(error) && touch} // Show error if touched is true
                helperText={touch ? error : ''} // Show error message if touched is true
                FormHelperTextProps={{
                    className: classes.customFont,
                }}
                InputProps={{
                    style: {
                        backgroundColor: '#ffffff',
                        color: 'rgba(33, 36, 38, 0.90)',
                        padding: '16px 20px',
                        height: isMobile ? '48px' : '56px',
                        border: `1px solid`,
                        borderColor: error && touch ? 'red' : '#C3CAD9', // Conditionally set border color
                        borderRadius: '8px',
                        fontFamily: 'Inter',
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: 500,
                        ...(inputStyles)
                    },
                    disableUnderline: true,
                    endAdornment: (<div>
                        <Select
                            fullWidth
                            variant="standard"
                            IconComponent={KeyboardArrowDownIcon}
                            disableUnderline={true}
                            displayEmpty
                            inputProps={{
                                className:
                                    currency === null
                                        ? classes.inputGray
                                        : classes.inputBlack,
                                style: {
                                    backgroundColor: "#ffffff",
                                    border: `none`,
                                    fontFamily: "Inter",
                                },

                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                },
                                getContentAnchorEl: null,
                                PaperProps: {
                                    style: {
                                        maxHeight: "250px",
                                    },
                                },
                            }}
                            value={currency ? currency : ""}
                            onChange={(e) => onChange('currency', e.target.value as string)}
                        >
                            {
                                currencyList?.map((currency: ICurrency) => (
                                    <MenuItem key={currency.currency_id} value={currency.currency_code} style={{ fontFamily: "Inter", fontSize: isMobile ? '14px' : '16px', fontWeight: 500 }}>{currency.currency_code}</MenuItem>
                                ))
                            }
                          
                        </Select>
                    </div>),
                }}
            />
        </FormControl>
    );
};

export default CurrencyTextField;
