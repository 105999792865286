import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from "./theme";

interface Column {
  id: string;
  label: string;
  maxWidth?: number;
}

interface BookingReportTableProps {
  orders: any[];
  handleNavigation: (id: number) => void;
}

const useStyles = makeStyles((theme) => ({
  tableCell: {
    borderBottom: 'none',
    backgroundColor: '#EAF7FF',
    color: 'rgba(33, 36, 38, 0.40)',
    textAlign: 'left',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    padding: '12px 16px 11px',
    '&.firstCell': {
      borderRadius: '8px 0 0 8px',
      textAlign: 'left',
      paddingLeft: '32px',
    },
    '&.lastCell': {
      borderRadius: '0 8px 8px 0',
      textAlign: 'end',
      paddingRight: '32px',
    }
  },
  tableRowCell: {
    borderBottom: 'none',
    color: 'rgba(33, 36, 38, 0.80)',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    whiteSpace: 'nowrap',
    padding: '15px 16px',
  },
  centeredCell: {
    textAlign: 'center',
  },
  flexContainers: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '8px', // Adjust the gap here
    maxWidth: '200px',
    textOverflow: 'ellipsis'
  },
  userName: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '26px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap',
    letterSpacing: '0.1px',
    width: '100%',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(33, 36, 38, 0.80)',
  },
  viewBtn: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '26px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    whiteSpace: 'nowrap',
    borderRadius: '20px',
    border: `1px solid ${primaryColor}`,
    color: primaryColor,
    textTransform: 'initial',
    width: '80px',
    height: '30px'
  },
  tableBody: {
    '& tr:first-child td': {
      paddingTop: '19px !important'
    },
    '& td': {
      textAlign: 'center', // Center align all TableCell elements
    },
  },
}));

export const BookingReportTable: React.FC<BookingReportTableProps> = ({
  orders,
  handleNavigation,
}) => {
  const classes = useStyles()
  const columns: Column[] = [
    { id: "trip_name", label: "Trip Name", maxWidth: 200 },
    { id: "trip_type", label: "Trip Type", maxWidth: 150 },
    { id: "country", label: "Country", maxWidth: 150 },
    { id: "customer_name", label: "Customer Name", maxWidth: 200 },
    { id: "trip_date", label: "Trip Date", maxWidth: 100 },
    { id: "no_of_people", label: "No. OF People", maxWidth: 120 },
    { id: "amount", label: "Amount", maxWidth: 80 },
    { id: "", label: "", maxWidth: 80 }
  ];

  return (
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {columns.map((column, index, list) => (
              <TableCell
                className={`${classes.tableCell} ${index === 0 ? 'firstCell' : ''} ${index === list.length - 1 ? 'lastCell' : ''}`}
                style={{ maxWidth: column.maxWidth, textAlign: (index === 5 || index === 6) ? 'center' : 'left' as const }}
                key={column.id}
                align="center"
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {orders && orders.length > 0 ? (
            orders.map((row, index) => (
              <TableRow
                hover
                key={index}
              >
                {columns.map((column, index) => (
                  <TableCell
                    key={index}
                    className={`${classes.tableRowCell}`}
                  >
                    {column.id === "trip_name" && (
                      <div className={classes.flexContainers}>
                        <div className={classes.userName}>
                          {row.trip_name}
                        </div>
                      </div>
                    )}
                    {column.id === "trip_type" && (
                      <div className={classes.flexContainers}>
                        <div className={classes.userName}>
                          {row.trip_type}
                        </div>
                      </div>
                    )}
                    {column.id === "country" && (
                      <div className={classes.flexContainers}>
                        <div className={classes.userName}>
                          {row.country}
                        </div>
                      </div>
                    )}
                    {column.id === "customer_name" && (
                      <div className={classes.flexContainers}>
                        <div className={classes.userName}>
                          {row.customer_name}
                        </div>
                      </div>
                    )}
                    {column.id === "trip_date" && (
                      <div className={classes.flexContainers}>
                        <div className={classes.userName}>
                          {row.trip_date}
                        </div>
                      </div>
                    )}
                    {column.id === "no_of_people" && (
                      <div className={classes.flexContainers}>
                        <div
                          className={classes.userName}
                          style={{ textAlign: 'center' }}
                        >
                          {row?.no_of_people}
                        </div>
                      </div>
                    )}
                    {column.id === "amount" && (
                      <div className={classes.flexContainers}>
                        <div
                          className={classes.userName}
                          style={{ textAlign: 'center' }}
                        >
                          {row.amount}
                        </div>
                      </div>
                    )}
                    {column.id === "" && (
                      <Button
                        onClick={() => handleNavigation(row.navigation_id)}
                        className={classes.viewBtn}>
                        {'View'}
                      </Button>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                style={{ borderBottom: "none" }}
                align="center"
                colSpan={columns.length}
              >
                You have no available bookings
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
