import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest";
import { getAuthToken } from "../../../components/src/utils";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  cardNumber: string;
  cardHolderName: string;
  expiration: string;
  securityCode: string;
  paymentToken: string;
  trip_id: string;
  trip_schedule_id: string;
  quantity: string;
  paymentStatus: boolean;
  paymentResponse: any;
  responseModal: boolean;
  subcriptionPayment: any,
  ischecked:any;
  token: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SubscriptionPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdGetPaymentToken: string = "";
  apiCallIdsubmitPayment: string = "";
  getSubscriptionPlanAPICallId: string = "";
  updateAutoRenewalapiCallId : any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      cardNumber: '',
      cardHolderName: '',
      expiration: '',
      securityCode: '',
      paymentToken: '',
      trip_id: '',
      trip_schedule_id: '',
      quantity: '',
      paymentStatus: false,
      paymentResponse: {},
      responseModal: false,
      subcriptionPayment: [],
      ischecked : false,
      token: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   /* istanbul ignore next */
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
    }

    // Customizable Area Start
    const apiRequestCallIds = {
      [this.apiCallIdGetPaymentToken]: this.getPaymentTokenResponse,
      [this.apiCallIdsubmitPayment]: this.getSubmitPaymentResponse,
      [this.getSubscriptionPlanAPICallId]: this.getSubscriptionPaymentResponse,
      [this.updateAutoRenewalapiCallId]: this.handleAutoRenewalResponse,
    
      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }

    // Customizable Area End
  }

  
 

  // Customizable Area Start
  // life-cycle method
  async componentDidMount() {
    const authToken = await getAuthToken();
    /* istanbul ignore next */
    this.setState({token : authToken}, () => {
      this.getSubscriptionPlan()
    })

  }

  handleAutoRenewalResponse(responseJson: any, errorReponse: any){
  console.log(responseJson ,errorReponse )
  }

  updateAutoRenewalApiRequest = async ()=>{
    const account_id = 3 
    const header = {
     Authorization: `Bearer ${this.state.token}`,
    }
    const requestMessage = ApiRequest({
     header: header,
     endPoint: configJSON.automaticRenewalEndpointFirst+account_id+configJSON.automaticRenewalEndpointSec,
     method: "PATCH",
   });
   this.updateAutoRenewalapiCallId = requestMessage.messageId;
   runEngine.sendMessage(requestMessage.id, requestMessage);
   this.setState({ischecked:!this.state?.ischecked})
 }

  getSubscriptionPlan = async () => {
    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "Authorization": `Bearer ${this.state.token}`
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscriptionPlanEndPoint
    );

    this.getSubscriptionPlanAPICallId = requestMessage.messageId;

   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getSubscriptionPaymentResponse = (responseJson: any, errorResponse: any) => {
    this.setState({ subcriptionPayment: responseJson?.data?.attributes })
  }

// Request function
getPaymentTokenRequest = () => {
  const {cardNumber, expiration, securityCode} = this.state;
  const [expiryMonth, expiryYear] = expiration.split('/');

  // Convert the strings to numbers
  const numericMonth = parseInt(expiryMonth, 10);
  const numericYear = parseInt(expiryYear, 10);

  // Take the last two digits of the year and make it a full year
  const fullYear = new Date().getFullYear();
  const fourDigitYear = parseInt((fullYear + '').slice(0, 2) + numericYear, 10);

  // Remove spaces from the input value
  const unformattedCardNumber = cardNumber.replace(/\s/g, '');

  const payload = { 
    "type": "card", 
    "number": unformattedCardNumber, 
    "expiry_month": numericMonth, 
    "expiry_year": fourDigitYear, 
    "phone": {}, 
    "preferred_scheme": "", 
    "requestSource": "JS" ,
    "cvv": securityCode

  }
  const authToken = "pk_sbox_gw262xxhmwiv3hi2eglhwnxbjet";
    const header = {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.paymentTokenEndpoint,
      payload: JSON.stringify(payload),
      method: "POST",
    });
    this.apiCallIdGetPaymentToken = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

submitPaymentRequest = async () => {
  const header = {
    Authorization: `Bearer ${this.state.token}`,
  };
  const endpoint = `${configJSON.paymentAPiEndPoint}`;
  
  const formdata = new FormData();

  // Helper function to append a field and value to formdata if the value exists
  const appendFieldIfValueExists = (field: string, value: any) => {
    if (value) {
      formdata.append(field, value);
    }
   
  };
  // Append fields with values if they exist
  appendFieldIfValueExists('subscription_billing[active]', this.state.subcriptionPayment.active);
  appendFieldIfValueExists('subscription_billing[subscription_price]', this.state.subcriptionPayment?.subscription_price);
  appendFieldIfValueExists('subscription_billing[subscription_type]', this.state.subcriptionPayment?.subscription_type);
  appendFieldIfValueExists('subscription_billing[card_detail_id]', this.state.subcriptionPayment?.id);
  appendFieldIfValueExists('subscription_billing[currency]', this.state.subcriptionPayment?.currency);


  const requestMessage = ApiRequest({
    header,
    endPoint: endpoint,
    payload: formdata,
    method: 'POST',
  });
  this.apiCallIdsubmitPayment = requestMessage.messageId;
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

// Response function

getPaymentTokenResponse = (responseJson: any, errorReponse: any) => {
  if(responseJson.token) {
    this.setState({paymentToken: responseJson.token}, () => this.submitPaymentRequest())
  } else {
    this.setState({
      responseModal: true,
    })
  }
}

paymentForm = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionDetailForm"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
}

 /* istanbul ignore next */
formBackButton = () => {
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "SubscriptionPlan"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}



getSubmitPaymentResponse = (responseJson: any, errorResponse: any) => {
  
  this.setState({ 
    paymentStatus: !responseJson?.data, 
    paymentResponse: responseJson?.meta
  }, () => this.gotoPaymentStatusPage());
  console.log(this.state.paymentStatus, this.state.paymentResponse, "paymentResponse")
  
}

// Internal function
  /* istanbul ignore next */

gotoPaymentStatusPage = () => {
  const payload = {
    responseJson: this.state.paymentResponse,
    paymentStatus: this.state.paymentStatus
  }
  const message: Message = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  message.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "PaymentStatus"
  );
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  const raiseMessage: Message = new Message(
    getName(MessageEnum.NavigationPayLoadMessage)
  );
  raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
    payload,
  });

  message.addData(
    getName(MessageEnum.NavigationRaiseMessage),
    raiseMessage
  );
  this.send(message);
}
handleCardNumberChange = (event: any) => {
  const inputCardNumber = event.target.value;

  this.setState({
    cardNumber: inputCardNumber
  });
};

handleCardHolderNameChange = (event: any) => {
  const inputCardHolderName = event.target.value;
  this.setState({
    cardHolderName: inputCardHolderName
  });
};


handleSecurityChange = (event: any) => {
  const inputSecurityCard = event.target.value;
  this.setState({
    securityCode: inputSecurityCard
  });
};
handleExpirationChange = (event: any) => {
  const inputExpiration = event.target.value;
  this.setState({
    expiration: this.formatExpiration(inputExpiration)
  });
};

formatExpiration = (inputExpiration: string) => {
  // Remove non-numeric characters
  const numericValue = inputExpiration.replace(/\D/g, '');

  // Take only the first 4 characters (MMYY)
  const formattedValue = numericValue.slice(0, 4);

  // Extract month and year
  const month = formattedValue.slice(0, 2);
  const year = formattedValue.slice(2, 4);

  // Concatenate and return in MM/YY format
  return formattedValue.length >= 3 ? `${month}/${year}` : formattedValue;
};


  // Customizable Area End
}
