Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Enter Email Address";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email Address";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.contentTypeFormDataDetail = "multipart/form-data";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.customerAccountsAPiEndPoint = "account_block/accounts/customers";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.sendFormDataContentType = 'multipart/form-data';
exports.getCountryListEndpoint = 'account_block/country_code_and_flags';

exports.validationApiMethodType = "GET";

exports.sendEmailOtpApiEndPoint = "account_block/otps";
exports.emailOtpVerifyApiEndPoint = "account_block/otps/verify";
exports.travelersRegistrationEndpoint = "account_block/accounts/service_providers";
// Customizable Area End
