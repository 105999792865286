import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest";
import { ICurrency } from "../../ProductDescription/src/Interface.web";
import { capitalizeFirstLetter, convertToAmPm, getAuthToken, getRelativeDateLabel, handleScrollToTop } from "../../../components/src/utils";
import { CityData, CountryData } from "../../email-account-registration/src/Interface.web";
import { OptionCheckboxFilter } from "../../../components/src/CustomCheckboxFilter";
import { PaginationI, ServiceProviderI } from "./Interface.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { IChatListItem, IProcessedMessage } from "../../ContentManagement/src/ChatController";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  firstNameSearchText: string;
  lastNameSearchText: string;
  advancedsearchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;
  providerType: string;
  serviceProviderDetails: ServiceProviderI;
  providerId: string;
  serviceProviderList: Record<string, any>[];
  searchString: string;
  currencyList: ICurrency[],
  countryList: CountryData[];
  cityList: CityData[];
  tripType: OptionCheckboxFilter[];
  travelerType: OptionCheckboxFilter[];
  vibeList: OptionCheckboxFilter[];
  periodList: OptionCheckboxFilter[];
  groupPreferenceFilterOptions: OptionCheckboxFilter[];
  tripDesignerFilterOptions: OptionCheckboxFilter[];
  tripList: Record<string, any>[];
  pagination: PaginationI;
  loader: boolean;
  serviceRating: string;
  groupPreference: string;
  guidedTour: string;
  kidsFriendly: string;
  femalesOnly: string;
  currency: string;
  priceFrom: string;
  priceTo: string;
  dateFrom: string;
  dateTo: string;
  country: string;
  countryId: string;
  city: string;
  cityId: string;
  singleTripType: string;
  orderBy: 'price' | 'rating',
  ordering: 'asc' | 'desc',
  most_visited: string;
  most_booked: string;
  newly_added: string;
  tab: string;
  openReviewModal: boolean;
  reviews: Record<string, any>[];
  responseStatusModal: boolean,
  responseMsg: string,
  isSuccessResponse: boolean,
  chatBoxModal: boolean,
  chatBoxAllMessages: IProcessedMessage,
  sendMessageData: string;
  receiverInfo: any;
  isMenuClicked : boolean;
  isMobile : boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advancedsearchApiCallId: any;
  apiCallIdBuddyList: string = "";
  apiCallIdAgencyList: string = "";
  apiCallIdCountryList: string = "";
  apiCallIdCityList: string = "";
  apiCallIdTravelerType: string = "";
  apiCallIdTripType: string = "";
  apiCallIdVibeList: string = "";
  apiCallIdTime: string = "";
  apiCallIdCurrencyList: string = "";
  apiCallIdAdvancedSearchTripList: string = "";
  apiCallIdServiceProviderDetails: string = "";
  apiCallIdAddReview: string = "";
  apiCallIdCreateChatMessage: string = "";
  apiCallIdGetChatBoxMessage: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      firstNameSearchText: "",
      lastNameSearchText: "",
      advancedsearchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,
      providerType: "",
      providerId: "",
      serviceProviderDetails: {
        id: '',
        display_name: '',
        profile_photo: '',
        average_rating: 0,
        rating_count: 0,
        facebook_link: null,
        instagram_link: null,
        website_link: null,
      },
      serviceProviderList: [],
      searchString: "",
      currencyList: [{currency_code: "SAR", currency_id: "133"},{currency_code: "USD", currency_id: "123"}],
      countryList: [],
      cityList: [],
      tripType: [],
      travelerType: [],
      vibeList: [],
      periodList: [],
      tripList: [],
      loader: false,
      pagination: {
        total_pages: 0,
        per_page: 18,
        current_page: 1,
        total_items: 0
      },
      serviceRating: '',
      groupPreference: '',
      guidedTour: '',
      kidsFriendly: '',
      femalesOnly: '',
      currency: 'USD',
      priceFrom: '',
      priceTo: '',
      dateFrom: '',
      dateTo: '',
      countryId: '',
      cityId: '',
      groupPreferenceFilterOptions: [
        {
          label: "Public",
          value: "1",
          noOfTrips: 300,
          selected: false,
        },
        {
          label: "Private",
          value: "0",
          noOfTrips: 255,
          selected: false,
        },
      
      ],
      tripDesignerFilterOptions: [
        {
          label: "Travel Agency",
          value: "4",
          noOfTrips: 300,
          selected: false,
        },
        {
          label: "Travel Buddy",
          value: "2",
          noOfTrips: 255,
          selected: false,
        },
      
      ],
      singleTripType: '',
      orderBy: "rating",
      ordering: "desc",
      country: '',
      city: '',
      most_visited: '',
      most_booked: '',
      newly_added: '',
      tab: '0',
      openReviewModal: false,
      reviews: [],
      responseStatusModal: false,
      responseMsg: "",
      isSuccessResponse: false,
      chatBoxModal: false,
      chatBoxAllMessages: {},
      sendMessageData: '',
      receiverInfo: {},
      isMenuClicked : false,
      isMobile : false
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.checkIfMobile()
    const { pathname } = window.location;
    if(pathname.startsWith("/TravelAgencyList")) {
      this.setState({providerType: 'agency'}, () => this.getTravelAgencyList())
    } else if(pathname.startsWith("/TravelBuddyList")){
      this.setState({providerType: 'buddy'}, () => this.getTravelBuddyList())
    } else if(pathname.startsWith("/ServiceProviderDetails/")) {
      const type = this.props.navigation.getParam("type");
      const provider_id = this.props.navigation.getParam("id");
      this.setState({providerType: type, providerId: provider_id}, () => {
        this.getCountryListApiASRequest();
        this.getCurrencyListApiASRequest();
        this.getTravelerTypeApiASRequest();
        this.getTripTypeApiASRequest();
        this.getVibeListApiASRequest();
        this.getTimeApiASRequest();
        this.getServiceProviderDetailsRequest()
        this.getChatBoxMessageRequest()
      })
    } else if(pathname.startsWith("/AdvancedSearch")) {
      this.getCountryListApiASRequest();
      this.getCurrencyListApiASRequest();
      this.getTravelerTypeApiASRequest();
      this.getTripTypeApiASRequest();
      this.getVibeListApiASRequest();
      this.getTimeApiASRequest();
      this.getAdvancedSearchTripListAPIRequest();
    }
    window.addEventListener('resize', this.checkIfMobile);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage)){
      const responseJson = message.getData(getName(MessageEnum.SessionResponseData));
      this.setState({
        searchString: responseJson?.queryParams?.searchString ?? this.state.searchString,
        singleTripType: responseJson?.queryParams?.tripType ?? this.state.singleTripType
    }, () => this.getAdvancedSearchTripListAPIRequest());
    
    }
    const apiRequestCallIds = {
      [this.apiCallIdAgencyList]: this.handleAgencyListResponse,
      [this.apiCallIdBuddyList]: this.handleBuddyListResponse,
      [this.apiCallIdCurrencyList]: this.currencyListApiASResponse,
      [this.apiCallIdCountryList]: this.countryListApiASResponse,
      [this.apiCallIdCityList]: this.cityListApiASResponse,
      [this.apiCallIdTripType]: this.tripTypeApiASResponse,
      [this.apiCallIdTravelerType]: this.travelerTypeApiASResponse,
      [this.apiCallIdVibeList]: this.vibeListApiASResponse,
      [this.apiCallIdTime]: this.timeApiASResponse,
      [this.apiCallIdAdvancedSearchTripList]: this.handleTripListResponse,
      [this.apiCallIdServiceProviderDetails]: this.handleProviderDetailsResponse,
      [this.apiCallIdAddReview]: this.addReviewResponse,
      [this.apiCallIdGetChatBoxMessage]: this.handleChatBoxMessageApiResponse,
      [this.apiCallIdCreateChatMessage]: this.handleSendChatMessageResponse,
      
      // Add more API call IDs and handlers as needed
    };

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
      apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  txtInputFirstNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setFirstNameText(text);
    }
  };

  txtInputLastNameSearchTextProps = {
    onChangeText: (text: string) => {
      this.setLastNameText(text);
    }
  };

  setFirstNameText = (firstName: string) => {
    this.setState({ firstNameSearchText: firstName });
  };

  setLastNameText = (firstName: string) => {
    this.setState({ lastNameSearchText: firstName });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible
    });
  };

  getAdvancedSearchList = (token: string) => {
    if (
      this.state.firstNameSearchText.length === 0 &&
      this.state.lastNameSearchText.length === 0
    ) {
      return;
    }

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    let attrs = null;

    if (
      this.state.firstNameSearchText.length > 0 &&
      this.state.lastNameSearchText.length > 0
    ) {
      attrs = {
        first_name: this.state.firstNameSearchText,
        last_name: this.state.lastNameSearchText
      };
    } else if (this.state.firstNameSearchText.length > 0) {
      attrs = {
        first_name: this.state.firstNameSearchText
      };
    } else if (this.state.lastNameSearchText.length > 0) {
      attrs = {
        last_name: this.state.lastNameSearchText
      };
    }

    this.advancedsearchApiCallId = requestMessage.messageId;

    //@ts-ignore
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAdvancedSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Request functions
  getTravelAgencyList = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.topTravelAgencies,
      method: "GET",
    });
    this.apiCallIdAgencyList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTravelBuddyList = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.topTravelBuddies,
      method: "GET",
    });
    this.apiCallIdBuddyList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getServiceProviderDetailsRequest = async () => {
    const {
      travelerType, serviceRating, guidedTour, kidsFriendly,
      providerId, pagination, periodList, vibeList,
      tripType, countryId, cityId, groupPreferenceFilterOptions,
      femalesOnly, priceFrom, priceTo, dateFrom, dateTo,
      orderBy, ordering, most_booked, most_visited, newly_added,
      tripDesignerFilterOptions
    } = this.state;

    const getIdArray = (items: { selected: boolean; value: string }[]): string[] => {
      return items
        .filter(item => item.selected)
        .map(item => item.value);
    }

    const tripTypeIdArray: string[] = getIdArray(tripType);
    const vibesIdArray: string[] = getIdArray(vibeList);
    const periodIdArray: string[] = getIdArray(periodList);
    const preferenceIdArray: string[] = getIdArray(groupPreferenceFilterOptions);
    const travelerIdArray: string[] = getIdArray(travelerType);
    const tripDesignerIdArray: string[] = getIdArray(tripDesignerFilterOptions)

    

    let queryParamsForSP: Record<string, string> = {}

    // Function to add a query parameter if the array has values
    const addQueryParamIfNotEmptyForSP = (paramName: string, paramArray: string[]) => {
      if (paramArray.length > 0) {
        queryParamsForSP[paramName] = paramArray.join(',');
      }
    };

    // Add period_id query parameter if periodIdArray has values
    addQueryParamIfNotEmptyForSP('trip_type_id', tripTypeIdArray);
    addQueryParamIfNotEmptyForSP('vibe_ids[]', vibesIdArray);
    addQueryParamIfNotEmptyForSP('period_id', periodIdArray);
    addQueryParamIfNotEmptyForSP('preference', preferenceIdArray);
    addQueryParamIfNotEmptyForSP('traveler_type_id', travelerIdArray);
    addQueryParamIfNotEmptyForSP('role_id', tripDesignerIdArray)

    // Function to add a query parameter if the value is not empty
    const addQueryParamIfNotEmptyValueForSP = (paramName: string, paramValue: string | undefined) => {
      if (paramValue) {
        queryParamsForSP[paramName] = paramValue;
      }
    };

    // Add other query parameters
    addQueryParamIfNotEmptyValueForSP('rating', serviceRating);
    addQueryParamIfNotEmptyValueForSP('id', providerId);
    addQueryParamIfNotEmptyValueForSP('guided_tour', guidedTour);
    addQueryParamIfNotEmptyValueForSP('female_only', femalesOnly);
    addQueryParamIfNotEmptyValueForSP('price_per_person_from', priceFrom);
    addQueryParamIfNotEmptyValueForSP('kids_friendly', kidsFriendly);
    addQueryParamIfNotEmptyValueForSP('price_per_person_to', priceTo);
    addQueryParamIfNotEmptyValueForSP('from', dateFrom);
    addQueryParamIfNotEmptyValueForSP('limit', pagination.per_page.toString())
    addQueryParamIfNotEmptyValueForSP('to', dateTo);
    addQueryParamIfNotEmptyValueForSP('country_id', countryId);
    addQueryParamIfNotEmptyValueForSP('order_by', orderBy);
    addQueryParamIfNotEmptyValueForSP('ordering', ordering);
    addQueryParamIfNotEmptyValueForSP('city_id', cityId);
    addQueryParamIfNotEmptyValueForSP('page', pagination.current_page.toString());
    addQueryParamIfNotEmptyValueForSP('most_visited', most_visited);
    addQueryParamIfNotEmptyValueForSP('most_booked', most_booked);
    addQueryParamIfNotEmptyValueForSP('newly_added', newly_added);



    const header: HeadersInit = {

    };
    const queryString = new URLSearchParams(queryParamsForSP).toString();
    const endpoint = `${configJSON.serviceProviderDetails}?${queryString}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      method: 'GET',
    });
    this.apiCallIdServiceProviderDetails = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loader: true })
  }

  // Request functions

  getCountryListApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCountryListEndpoint,
      method: "GET",
    });
    this.apiCallIdCountryList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCityListApiASRequest = (country: string) => {

    const countryId = this.getCountryIdFunc(country)

    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: `account_block/country/${countryId}/cities`,
      method: "GET",
    });
    this.apiCallIdCityList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTripTypeApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.tripTypeEndpoint,
      method: "GET",
    });
    this.apiCallIdTripType = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTravelerTypeApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.travelerTypeEndpoint,
      method: "GET",
    });
    this.apiCallIdTravelerType = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getVibeListApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.vibeListEndpoint,
      method: "GET",
    });
    this.apiCallIdVibeList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTimeApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.timeEndpoint,
      method: "GET",
    });
    this.apiCallIdTime = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCurrencyListApiASRequest = () => {
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getCurrencyListEndpoint,
      method: "GET",
    });
    this.apiCallIdCurrencyList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAdvancedSearchTripListAPIRequest = async () => {
    const {
      pagination, searchString, periodList, vibeList,
      travelerType, serviceRating, guidedTour, kidsFriendly,
      femalesOnly, priceFrom, priceTo, dateFrom, dateTo,
      tripType, countryId, cityId, groupPreferenceFilterOptions,
      orderBy, ordering, most_visited, most_booked, newly_added,
      tripDesignerFilterOptions
    } = this.state;

    const tripTypeIdArray: string[] = tripType
      .filter(type => type.selected)
      .map(type => type.value);

    const periodIdArray: string[] = periodList
      .filter(period => period.selected)
      .map(period => period.value);

    const vibesIdArray: string[] = vibeList
      .filter(vibe => vibe.selected)
      .map(vibe => vibe.value);

    const travelerIdArray: string[] = travelerType
      .filter(traveler => traveler.selected)
      .map(traveler => traveler.value);

    const preferenceIdArray: string[] = groupPreferenceFilterOptions
    .filter(preference => preference.selected)
    .map(preference => preference.value);

    const tripDesignerIdArray: string[] = tripDesignerFilterOptions
    .filter(desiger => desiger.selected)
    .map(designer => designer.value);
    
    const header: HeadersInit = {

    };

    let queryParams: Record<string, string> = {}


    // Function to add a query parameter if the array has values
    const addQueryParamIfNotEmpty = (paramName: string, paramArray: string[]) => {
      if (paramArray.length > 0) {
        queryParams[paramName] = paramArray.join(',');
      }
    };

    // Add period_id query parameter if periodIdArray has values
    addQueryParamIfNotEmpty('trip_type_id', tripTypeIdArray);
    addQueryParamIfNotEmpty('period_id', periodIdArray);
    addQueryParamIfNotEmpty('vibe_ids[]', vibesIdArray);
    addQueryParamIfNotEmpty('traveler_type_id', travelerIdArray);
    addQueryParamIfNotEmpty('preference', preferenceIdArray);
    addQueryParamIfNotEmpty('role_id', tripDesignerIdArray);
    // Function to add a query parameter if the value is not empty
    const addQueryParamIfNotEmptyValue = (paramName: string, paramValue: string | undefined) => {
      if (paramValue) {
        queryParams[paramName] = paramValue;
      }
    };

// Add other query parameters
addQueryParamIfNotEmptyValue('search', searchString);
addQueryParamIfNotEmptyValue('rating', serviceRating);
addQueryParamIfNotEmptyValue('guided_tour', guidedTour);
addQueryParamIfNotEmptyValue('kids_friendly', kidsFriendly);
addQueryParamIfNotEmptyValue('female_only', femalesOnly);
addQueryParamIfNotEmptyValue('price_per_person_from', priceFrom);
addQueryParamIfNotEmptyValue('price_per_person_to', priceTo);
addQueryParamIfNotEmptyValue('from', dateFrom);
addQueryParamIfNotEmptyValue('to', dateTo);
addQueryParamIfNotEmptyValue('country_id', countryId);
addQueryParamIfNotEmptyValue('city_id', cityId);
addQueryParamIfNotEmptyValue('order_by', orderBy);
addQueryParamIfNotEmptyValue('ordering', ordering);
addQueryParamIfNotEmptyValue('page', pagination.current_page.toString());
addQueryParamIfNotEmptyValue('limit', pagination.per_page.toString());
addQueryParamIfNotEmptyValue('most_visited', most_visited);
addQueryParamIfNotEmptyValue('most_booked', most_booked);
addQueryParamIfNotEmptyValue('newly_added', newly_added);






    const queryString = new URLSearchParams(queryParams).toString();
    const endpoint = `${configJSON.advancedSearchEndpoint}?${queryString}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      method: 'GET',
    });
    this.apiCallIdAdvancedSearchTripList = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ loader: true })
  }

  addReviewRequest = async (values: any) => {
    const { rating, title, desc } = values;
    
   const payload = {
      "data": {
          "attributes": {
              "title": title,
              "description": desc,
              "rating": rating,
              "account_id": this.state.providerId
          }
      }
  }

    const authToken = await getAuthToken();
    const header = {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': configJSON.advancedsearchApiContentType,
    };
    const endpoint = `${configJSON.addReviewEndpoint}`;

    const requestMessage = ApiRequest({
      header,
      endPoint: endpoint,
      payload: JSON.stringify(payload),
      method: 'POST',
    });
    this.apiCallIdAddReview = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getChatBoxMessageRequest = async () => {
    const {providerId} = this.state;
    const authToken = await getAuthToken();
    const header = {
      "Content-Type": configJSON.advancedsearchApiContentType,
      "Authorization": `Bearer ${authToken}`,
    };
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.getMyChatsMessageApiEndpoint + `${providerId}`,
      method: "GET",
    });

    this.apiCallIdGetChatBoxMessage = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendChatMessageRequest = async () => {
    const {providerId} = this.state;
    const authToken = await getAuthToken();
    const header = {
      "Authorization": `Bearer ${authToken}`,
    };

    const formData = new FormData();
    if (this.state.sendMessageData !== "") {
      formData.append('message[body]', this.state.sendMessageData);
    }
    const requestMessage = ApiRequest({
      header: header,
      endPoint: configJSON.sendMyChatsMessageApiEndpoint + `${providerId}`,
      payload: formData,
      method: "POST",
    });

    this.apiCallIdCreateChatMessage = requestMessage.messageId;

   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Response fucntions

  handleTripListResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson?.searched_trips?.data) {
      const tripList = responseJson?.searched_trips?.data;
      this.setState({ tripList, pagination: responseJson?.searched_trips?.page_options });
    } else {
      // If the response does not contain data, set tripList to an empty array
      this.setState({ tripList: [] });
    }
    // Always hide the loader once the API call is complete
    this.setState({ loader: false });
  }

  addReviewResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson && responseJson.data && responseJson.data.type === "review") {
        // Handle success response
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: "Review added successfully",
          isSuccessResponse: true,
        })
        // Do something with the review data, such as displaying it to the user
    } else if (responseJson && responseJson.data && responseJson.data.type === "error") {
        // Handle error type response
        const errorMessage = Object.values(responseJson.data.attributes.errors).join(', ');
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: capitalizeFirstLetter(errorMessage),
          isSuccessResponse: false,
        })
        // Display the error message to the user
    } else {
        // Unexpected response format
        this.setState({
          openReviewModal: false,
          responseStatusModal: true,
          responseMsg: "Something went wrong",
          isSuccessResponse: false,
        })
    }
};

  handleProviderDetailsResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson && responseJson?.data) {
      const providerDetails = {
        id: responseJson.data.id,
        display_name: responseJson.data.attributes.display_name,
        profile_photo: responseJson.data.attributes.profile_photo,
        average_rating: responseJson.data.attributes.average_rating,
        rating_count: responseJson.data.attributes.rating_count,
        facebook_link: responseJson.data.attributes.social_media_link.facebook_link,
        instagram_link: responseJson.data.attributes.social_media_link.instagram_link,
        website_link: responseJson.data.attributes.social_media_link.website_link,
      }
      const tripList = responseJson.data.attributes.trips.data;
      const pagination = responseJson.data.attributes.trips.page_options;
      const reviews = responseJson.data.attributes.reviews.data;
      this.setState({ tripList, pagination, reviews, serviceProviderDetails: providerDetails });
    } else {
      // If the response does not contain data, set tripList to an empty array
      this.setState({ tripList: [] });
    }
    // Always hide the loader once the API call is complete
    this.setState({ loader: false });
  }

  handleAgencyListResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data) {
      const data = responseJson.data.map((item: any) => {
        const { agency_logo, agency_display_name } = item.attributes
        return { ...item, attributes: { name: agency_display_name, image: agency_logo } }
      })
      this.setState({ serviceProviderList: data })
    }
  }

  handleBuddyListResponse = (responseJson: any, errorReponse: any) => {
    if (responseJson?.data) {
      const data = responseJson.data.map((item: any) => {
        const { buddy_profile_photo, buddy_display_name } = item.attributes
        return { ...item, attributes: { name: buddy_display_name, image: buddy_profile_photo } }
      })
      this.setState({ serviceProviderList: data })
    }
  }

  countryListApiASResponse = (responseJson: any, errorReponse: any) => {
    const countryDataArray = responseJson.country_data.data;
    if (countryDataArray) {
      const countryList: CountryData[] = countryDataArray.map(
        (country: any) => {
          const attributes = country.attributes;
          attributes.country_code = '+' + attributes.country_code; // Add '+' sign
          attributes.id = country.id;
          return attributes;
        }
      );

      this.setState({
        countryList: countryList,
      });
    }
  }

  cityListApiASResponse = (responseJson: any, errorReponse: any) => {
    const citiesDataArray = responseJson?.cities_data?.data;
    if (citiesDataArray) {
      const cityList = citiesDataArray.map(
        (city: any) => {
          const attributes = city.attributes;
          attributes.id = city.id;
          return attributes;
        }
      );

      this.setState({
        cityList: cityList,
      });
    }
  }

  tripTypeApiASResponse = (responseJson: any, errorReponse: any) => {
    const tripTypeData = responseJson.data;
    if (tripTypeData) {
      const tripTypeList: OptionCheckboxFilter[] = tripTypeData.map(
        (tripType: any) => {
          const value = tripType.id;
          const label = capitalizeFirstLetter(tripType.attributes.name);
          return { label, value, noOfTrips: 232, selected: false };
        }
      );

      this.setState({
        tripType: tripTypeList,
      }, () => {
        if (this.state.singleTripType) {
          const singleTripType = this.state.singleTripType.toLowerCase();
        
          // Define the mapping of singleTripType values to state properties
          const mapping: any = {
            'newly added': 'newly_added',
            'most visited': 'most_visited',
            'top picks': 'most_booked'
          };
        
          // Determine which property to set based on singleTripType
          const propertyName = mapping[singleTripType];
        
          // Map tripType based on selected or not selected
          const changedTripType = this.state.tripType.map(type => ({
            ...type,
            selected: type.label.toLowerCase() === singleTripType
          }));
        
          // Update the state with the newly selected trip type and corresponding property
          this.setState((prevState) => ({
            tripType: changedTripType,
            [propertyName]: true // Setting the corresponding property to true
          } as Pick<S, keyof S>), () => this.getAdvancedSearchTripListAPIRequest());
        } 
      });
    }
};


  travelerTypeApiASResponse = (responseJson: any, errorReponse: any) => {
    const travelerTypeData = responseJson.data;
    if (travelerTypeData) {
      const travelerTypeList: OptionCheckboxFilter[] = travelerTypeData.map(
        (travelerType: any) => {
          const value = travelerType.id;
          const label = capitalizeFirstLetter(travelerType.attributes.name);
          return {label, value, noOfTrips: 224, selected: false,};
        }
      );

      this.setState({
        travelerType: travelerTypeList,
      });
    }
  }

  vibeListApiASResponse = (responseJson: any, errorReponse: any) => {
    const vibeListData = responseJson.data;
    if (vibeListData) {
      const vibeList: OptionCheckboxFilter[] = vibeListData.map(
        (vibe: any) => {
          const value = vibe.id;
          const label = capitalizeFirstLetter(vibe.attributes.title);
          return {label, value, noOfTrips: 234, selected: false,};
        }
      );

      this.setState({
        vibeList: vibeList,
      });
    }
  }

  timeApiASResponse = (responseJson: any, errorReponse: any) => {
    const timeData = responseJson.data;
    if (timeData) {
      const timeList: OptionCheckboxFilter[] = timeData.map(
        (time: any) => {
          const value = time.id;
          const label = capitalizeFirstLetter(time.attributes.name);
          return {label, value, noOfTrips: 123, selected: false,};
        }
      );

      this.setState({
        periodList: timeList,
      });
    }
  }

  currencyListApiASResponse = (responseJson: any, errorReponse: any) => {
    const currencyDataArray = responseJson.data;
    if (currencyDataArray) {
      const currencyList: ICurrency[] = currencyDataArray.map(
        (currency: any) => {
          const attributes = currency.attributes;
          attributes.currency_id = currency.id;
          return attributes;
        }
      );

      this.setState({currencyList: currencyList});
    }
  }

  handleChatBoxMessageApiResponse = async (responseJson: any, errorResponse: any) => {
    const userData = await getStorageData("userData");
    let id = "";
    if (userData) {
      const userObject = JSON.parse(userData);
      id = userObject.id;
    }
    if (responseJson?.chat_box?.data && id) {
      const { chat_box } = responseJson;
      const { attributes } = chat_box.data;
      const { messages } = attributes;
      const numericCurrentUserId = Number(id);


      // finding receiver information
      let receiverInfo: IChatListItem = {userId: '', userName: '', userPhoto: ''};
      const { sender_id, receiver_id, sender_name, receiver_name, sender_image_url, receiver_image_url } = attributes;
      const numericSenderId = Number(sender_id);

      if (numericCurrentUserId === numericSenderId) {
        receiverInfo.userName = receiver_name;
        receiverInfo.userPhoto = receiver_image_url;
        receiverInfo.userId = receiver_id.toString();
      } else {
        receiverInfo.userName = sender_name;
        receiverInfo.userPhoto = sender_image_url;
        receiverInfo.userId = sender_id.toString();
      }

      const processedMessages: IProcessedMessage = {};

      messages?.data.forEach((message: any) => {
        const { attributes: msgAttributes } = message;
        const date = getRelativeDateLabel(msgAttributes.current_date);
        const time = convertToAmPm(msgAttributes.current_time);
        const numericAccountId = Number(msgAttributes.account_id);
        const from = numericAccountId === numericCurrentUserId ? "sender" : "receiver";
        

        if (!processedMessages[date]) {
          processedMessages[date] = {
            date,
            messages: []
          };
        }

        processedMessages[date].messages.push({
          message: msgAttributes.body,
          time,
          from,
        });
      });

      this.setState({
        chatBoxAllMessages: processedMessages,
        receiverInfo,
      })
        ;
    }
  }

  handleSendChatMessageResponse = (responseJson: any, errorResponse: any) => {
    if(responseJson) {
      this.getChatBoxMessageRequest()
      this.setState({
        sendMessageData: '',
      });
    }
  }


  // Internal functions
  handleSorting = (filterName: string, value: string) => {
    const newState: Partial<S> = {}; // Define an empty partial state object
  
    const sortMappings: Record<string, { orderBy: "price" | "rating", ordering: 'asc' | 'desc' }> = {
      'AtoZ': { orderBy: 'price', ordering: 'asc' },
      'ZtoA': { orderBy: 'price', ordering: 'desc' },
      'lowToHigh': { orderBy: 'rating', ordering: 'asc' },
      'highToLow': { orderBy: 'rating', ordering: 'desc' },
    };
  
    if (sortMappings[value]) {
      const { orderBy, ordering } = sortMappings[value];
      newState.orderBy = orderBy;
      newState.ordering = ordering;
    }
  
  
    this.setState(newState as Pick<S, keyof S>, () => {
      this.getAdvancedSearchTripListAPIRequest();
    });
  };
  handleSearchValue = (value: string) => {
    if (value === '') {
      this.getAdvancedSearchTripListAPIRequest();
    }
    this.setState({
      searchString: value,
    })
  }
  handleEnterPressSearch = (event: any) => {
    if (event.key === 'Enter') {
      this.getAdvancedSearchTripListAPIRequest()
    }
  };
  handleResetFilters = () => {
    const { tripType, travelerType, vibeList, periodList } = this.state;
    const updatedTripType = tripType.map((option) => {
      return { ...option, selected: false };
    });
    const updatedTravelerType = travelerType.map((option) => {
      return { ...option, selected: false };
    });
    const updatedVibeList = vibeList.map((option) => {
      return { ...option, selected: false };
    });
    const updatedPeriodList = periodList.map((option) => {
      return { ...option, selected: false };
    });
    this.setState({
      priceFrom: '',
      priceTo: '',
      dateFrom: '',
      dateTo: '',
      serviceRating: '',
      groupPreference: '',
      guidedTour: '',
      kidsFriendly: '',
      femalesOnly: '',
      countryId: '',
      country: '',
      cityId: '',
      city: '',
      tripType: updatedTripType,
      travelerType: updatedTravelerType,
      vibeList: updatedVibeList,
      periodList: updatedPeriodList,
    }, () => this.getAdvancedSearchTripListAPIRequest())
  }
  getCountryIdFunc = (country: string | null) => {
    return country
      ? (this.state.countryList.find(
        (countryData: CountryData) => countryData.name === country
      )?.id ?? null)
      : null;
  };

  getCityIdFunc = (city: string | null) => {
    return city
      ? (this.state.cityList.find(
        (cityData: CityData) => cityData.name === city
      )?.id ?? null)
      : null;
  };

  handleCheckboxSelect = (option: OptionCheckboxFilter[], name: keyof S) => {
    const newState = {
      ...this.state,
      [name]: option,
      tripList: []
    };
    this.setState(newState, () => this.getAdvancedSearchTripListAPIRequest())
  }

  handleSingleFilterValueSelect = (name: string, value: string) => {
    
    this.setState((prev) => {
      return {
        ...prev,
        [name]: value
      }
    },  () => {
      
      let skipCurrencyForEmptyPrice = false;
      if(name === "currency") {
        skipCurrencyForEmptyPrice = !this.state.priceFrom && !this.state.priceTo;
      }
      const skipCall = name === 'dateFrom' || name === 'priceFrom' || name === 'country' || name === 'city'  || skipCurrencyForEmptyPrice;
      

      if (!skipCall) {
        this.setState({ tripList: [] }, () => {
          this.getAdvancedSearchTripListAPIRequest();
        });
      }
    })
  }

  handleServiceRatingChange = (value: string) => {
    // Check if the current value is the same as the previously selected value
    const newValue = this.state.serviceRating === value ? '' : value;
    // Call the API request function after updating the state
    this.setState({serviceRating: newValue, tripList: []}, () => this.getAdvancedSearchTripListAPIRequest());
  }

  handlePagination = (e: any, value: number) => {
    handleScrollToTop()
    const pageNumber = Number(value);
    this.setState(prev => ({
      pagination: {
        ...prev.pagination,
        current_page: pageNumber
      },
      tripList: [],
    }), () => this.getAdvancedSearchTripListAPIRequest())
  }

  navigateToProviderDetails = (type: string, id: string) => {
    const myType = type === "travel_buddy" ? "buddy" : "agency";
    this.props.navigation.navigate("ServiceProviderDetails", {
      type: myType,
      id: id,
    })
  }

  handleTabChange = (value: string) => {
    if (value !== this.state.tab) {
      this.setState({ tab: value });
    }
  }

  handleOpenReviewModal = async () => {
    const userDataString = await getStorageData('userData');
    if(userDataString) {
      this.setState({
        openReviewModal: true,
      })
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
    
  }

  handleCloseReviewModal = () => {
    this.setState({openReviewModal: false})
  }

  handleSendMessage = (message: string) => {
    this.setState({sendMessageData: message})
  }

  openChatBoxModal = async () => {
    const userDataString = await getStorageData('userData');
    if(userDataString) {
      this.setState({
        chatBoxModal: true,
      })
    } else {
      this.props.navigation.navigate("EmailAccountLoginBlock")
    }
  }

  closeChatBoxModal = () => {
    this.setState({chatBoxModal: false})
  }

  closeResponseModal = () => {
    this.setState({
      responseStatusModal: false,
    })
  }

  handleSocialMediaClick = (socialMediaHandle: string | null) => {
    if (socialMediaHandle) {
        window.open(socialMediaHandle, '_blank');
    } else {
        alert("socialMediaHandle is null or empty. Cannot open the link.");
    }
};
handleMenuCLick = (newOpen : boolean) => {
  this.setState({
    isMenuClicked : newOpen
  })
}

checkIfMobile = () => {
  const isMobile = window.innerWidth <= 768;
  this.setState({ isMobile });
}


  // Customizable Area End
}
