Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.sellerDetailsApiContentType = "application/json";
exports.sellersAPIEndPoint = "custom_form/seller_accounts";

exports.sellerDetailsAPIMethodPUT = "PUT";
exports.sellerDetailsAPIMethodPOST = "POST";
exports.sellerDetailsAPIMethodPATCH = "PATCH";
exports.getSellersAPIMethod = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform";
exports.labelBodyText = "customform Body";
exports.errorMessageShopName = "Shop name is mandatory";
exports.errorMessageGstin = "GSTIN is mandatory";

exports.btnExampleTitle = "CLICK ME";

exports.congrats = "Congratulations!";
exports.formSubmitted = "Your form is submitted";
exports.sellerDetails = "Seller Details";
exports.headingText = "Personalize your experience";
exports.selectionText = "Choose one or more from the following";
exports.itineraryHeading = "Plan Itinerary";
exports.itineraryInfo = "Itinerary Information"
exports.itinerarySelectionText = "Share your preferences, interests, and travel style, and let us curate exceptional trips just for you.";
exports.itineraryCustomerHeading = "Customer Information";
exports.getCountryListEndpoint = 'account_block/country_code_and_flags';
exports.getProfileInfoEndpoint = 'account_block/accounts/profile';
exports.vibeListEndpoint = "bx_block_productdescription/vibes";
exports.bannerEndpoint = "bx_block_dashboard/banners";
exports.myInterestEndpoint = "bx_block_custom_form/user_interests";
exports.planItineraryEndpoint = "bx_block_custom_form/itinerary_requests";
exports.periodListEndpoint = "bx_block_productdescription/periods";
// Customizable Area End
