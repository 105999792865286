// Customizable Area Start
import ApiRequest from "../../../components/src/ApiRequest";
import { getAuthToken } from "../../../components/src/utils";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

export interface UserProfileI {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    date_of_birth: string;
    gender: string;
    full_phone_number: string;
    facebook_link: string;
    instagram_link: string;
    interest_type: string;
    nationality: string;
    country_of_residence: string;
    alpha_code: string;
    profile_image: string;
    joining_date: string;
    phone_number: {
        country_code: string;
        number: string;
    };
    account_type:any
}

export interface Props {
    navigation?: any;
    id?: string;
    children?: JSX.Element | JSX.Element[] | string | string[];
}

interface S {
    currentUser: UserProfileI;
    cartItems :any
}

interface SS {
    id: any;
}

export default class AuthProviderController extends BlockComponent<
    Props,
    S,
    SS
> {
    apiCallIdGetCurrentUser: string = "";
    apicallIdGetCartItems: string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];

        this.state = {
            currentUser: {
                id: 0,
            first_name: "",
            last_name: "",
            email: "",
            date_of_birth: "",
            gender: "",
            full_phone_number: "",
            facebook_link: "",
            instagram_link: "",
            interest_type: "",
            nationality: "",
            country_of_residence: "",
            alpha_code: "",
            profile_image: "",
            joining_date: "",
            phone_number: {
                country_code: "",
                number: ""
            },
            account_type:''
            },
            cartItems : []

        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        //<===============response for get current user============>
        const apiRequestCallIds = {
            [this.apiCallIdGetCurrentUser]: this.handleUserProfileResponse,
            [this.apicallIdGetCartItems]:this.handleCartItemResponse

            // Add more API call IDs and handlers as needed
        };

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (apiRequestCallId != null && apiRequestCallIds[apiRequestCallId]) {
            apiRequestCallIds[apiRequestCallId](responseJson, errorResponse);
        }
    }

    async componentDidMount() {
        this.getUserProfileData();
        this.getCartItems();
    }


    // Request fucntions
    getUserProfileData = async () => {
        const authToken = await getAuthToken();
        const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            "Authorization": `Bearer ${authToken}`
        };
        const requestMessage = ApiRequest({
            header: header,
            endPoint: configJSON.customerProfileApi,
            method: "GET",
        });
        this.apiCallIdGetCurrentUser = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    getCartItems = async() =>{
        const authToken = await getAuthToken();
        const header = {
            "Authorization": `Bearer ${authToken}`
        };
        const requestMessage = ApiRequest({
            header: header,
            endPoint: configJSON.getCartItemsApi,
            method: "GET",
        });
        this.apicallIdGetCartItems = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // Response functions

    handleUserProfileResponse = (responseJson: any, errorReponse: any) => {

        if (responseJson?.data?.attributes) {
            if(responseJson?.data?.type === "service_provider") {
                const agencyLogo = responseJson.data.attributes.entity_detail.data.attributes.agency_logo;
                this.setState((prevState) => ({
                    currentUser: {
                       ...responseJson.data.attributes,
                        profile_image: agencyLogo||responseJson.data.attributes?.profile_image,
                        id: responseJson.data.id
                    }
                }))
            } else if(responseJson?.data?.type === "customer") {
                this.setState({
                    currentUser: {
                        ...responseJson.data.attributes,
                        id: responseJson.data.id,
                    },
                })
            }
            
        }

    }
    
    handleCartItemResponse = (responseJson: any, errorReponse: any) =>{
        if(responseJson){
            this.setState({
                cartItems : responseJson.data
            })
        }

    }

    handleResetProfile = () => {
        this.getUserProfileData();
    }

    handleLogOut = () => {
        this.setState({
            currentUser: {
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                date_of_birth: "",
                gender: "",
                full_phone_number: "",
                facebook_link: "",
                instagram_link: "",
                interest_type: "",
                nationality: "",
                country_of_residence: "",
                alpha_code: "",
                profile_image: "",
                joining_date: "",
                phone_number: {
                    country_code: "",
                    number: ""
                },
                account_type:''
            }
        })
    }

    // Internal functions


    // Customizable Area End

}