import React from 'react';
import { withRouter } from "react-router-dom";
import {
    Box, ListItem, List, Link
} from '@material-ui/core';
import { StarBorder, Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import logo from "./sawahLogo.png";
import HomeIcon from "./homeIcon.js"
import NoteIcon from "./noteIcon.js"
import ChatIcon from "./chatIcon.js"
import TrainIcon from "./trainIcon.js"

const useStyles = makeStyles((theme) => ({
    sidebar: {
        width: '100%',
        margin: '24px',
        maxWidth: '292px',
        maxHeight: '1024px',
        padding: '0 24px 20px',
        borderRadius: '16px',
        backgroundColor: '#fff',
    },
    closeButton: {
        position: 'absolute',
        top: '5px', // Adjust as needed
        left: '5px', // Adjust as needed
        cursor: 'pointer',
    },
    sidebarLogo: {
        display: 'flex',
        marginBottom: '27px',
        marginLeft: '5px'
    },
    sidebarLogoLink: {
        display: 'flex',
        cursor: 'pointer'
    },
    sidebarLinkBox: {
        padding: 0,
        gap: '24px',
        display: 'flex',
        flexDirection: 'column',
    },
    sidebarLink: {
        gap: '16px',
        color: '#212426',
        fontWeight: 600,
        display: 'flex',
        padding: '16px',
        cursor: 'pointer',
        borderRadius: '8px',
        alignItems: 'center',
        transition: '0.2s all ease-in-out',
        '&.active': {
            backgroundColor: '#FE7F2D',
            color: 'rgba(255, 255, 255, 0.90)',
            '& svg path': {
                stroke: '#fff'
            }
        }
    },
    [theme.breakpoints.down(950)]: {
        sidebar: {
             display: 'none'
        },
        drawerView: {
            display: 'inline !important'
        }
    },
    [theme.breakpoints.between(360.05, 768.05)]: {
    },
    [theme.breakpoints.down(360.05)]: {
    }
}));

const menuList = [
    {
        title: 'Home',
        icon: <HomeIcon />,
        activePath: ['/Dashboard']
    },
    {
        title: 'My Trips',
        icon: <TrainIcon />,
        activePath: ['/TripList', '/CreateTrip', '/SingleTripDetails', '/EditTrip']
    },
    {
        title: 'My Bookings',
        icon: <NoteIcon />,
        activePath: ['/MyBookings', '/BookingDetails', '/BookingReport']
    },
    {
        title: 'Chats',
        icon: <ChatIcon />,
        activePath: ['/Chats']
    },
    {
        title: 'Reviews',
        icon: <StarBorder height={24} width={24} />,
        activePath: ['/ServiceProviderReviews']
    },
]

const Sidebar = ({ history, isDrawer, handleClose }) => {
    const classes = useStyles();
    const { pathname } = history.location;
    console.log("pathname", pathname)

    return (
        <Box className={`${classes.sidebar} ${isDrawer && classes.drawerView}`}>
            {
                isDrawer &&
                <Box className={classes.closeButton} onClick={() => handleClose(false)}>
                    <Close style={{ fontSize: '20px' }} />
                </Box>
            }
            <Box className={classes.sidebarLogo}>
                <Link onClick={() => history.push('/Dashboard')} className={classes.sidebarLogoLink}>
                    <img src={logo} width="120" height="53" alt='Company Logo' />
                </Link>
            </Box>
            <List className={classes.sidebarLinkBox}>
                {menuList.map((list, index) => {
                    const isActive = list.activePath.some(path => pathname.startsWith(path));
                    return (
                        <ListItem key={index}
                            onClick={() => history.push(list.activePath[0])}
                            className={`${classes.sidebarLink}${isActive ? ' active' : ''}`}
                        >
                            {list.icon}
                            {list.title}
                        </ListItem>
                    )
                })}
            </List>
        </Box>
    );
}

export default withRouter(Sidebar);
