import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import {
    Grid, Typography, Box,
    List, ListItem, Link
} from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import logo from "./sawahLogoBlue.png"
import WhatsApp from "./whatsapp.png"
import instagram from "./instagram.png"
import facebook from "./facebook.png"
import InstaIcon from './instaIcon.js';
import FacebookIcon from './facebookIcon.js';
import { useAuth } from "../../blocks/user-profile-basic/src/AuthContext.web";



const useStyles = makeStyles((theme) =>
    createStyles({
        footer: {
            backgroundColor: '#233D4D',
            width:'100%',
        },
        marginTop: {
            // marginTop: '80px'
        },
        footerInner: {
            margin: '19px 131px 20px 130px',
            [theme.breakpoints.down(360.05)]: {
                margin: '32px 16px 24px',
                flexWrap: 'wrap-reverse',
                gap: '32px',
                justifyContent: 'center'
            },
            [theme.breakpoints.between(360.05, 768.05)]: {
                margin: '29px 49px 46px 36px'
            }
        },
        listItem: {
            color: '#FFF',
            fontFamily: 'Poppins',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '0.257px',
            whiteSpace: 'nowrap'
        },
        footerWrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            maxWidth: '80%',
            margin: '0 auto',
            padding: '16px 15px 19px',
            color: '#fff',
            [theme.breakpoints.down('sm')]: {
                maxWidth: '100%'
            }
        },
        footerLinkBox: {
            display: 'flex',
            alignItems: 'center',
            gap: '56px',
            padding: '0',
        },
        footerLink: {
            color: '#FFF',
            fontFamily: 'Poppins',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
            padding: '0',
            width: 'auto',
            '& a': {
                textDecoration: 'none',
                color: '#fff',
                transition: '0.2s all ease-in-out',
                "&:hover": {
                    color: '#FE7F2D',
                },
            },
        },
        footerSocialBox: {
            display: 'flex',
            gap: '16px',
        },
        footerSocialLink: {
            display: 'flex',
            transition: '0.2s all ease-in-out',
            '&:hover path': {
                fill: '#FE7F2D'
            },
        },
        margingBottom: {
            marginBottom: '8px'
        },
        image: {
            height: '20px',
            width: '20px'
        },
        image18: {
            height: '18px',
            width: '18px'
        },
        footerRight: {
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: "19px",
            [theme.breakpoints.up(768.05)]: {
                gap: '41px'
            },
            [theme.breakpoints.between(360.05, 768.05)]: {
                gap: '0px'
            },
        },
        footerLeft: {
            [theme.breakpoints.down(360.05)]: {
                flexDirection: 'row',
                gap: '12px'
            },
            [theme.breakpoints.up(360.05)]: {
                flexDirection: 'column'
            },
        },
        followUsLink: {
            display: 'flex',
            alignItems: 'center',
            gap: '11px',
            [theme.breakpoints.up(360.05)]: {
                marginTop: '0px',
                justifyContent: 'start',
            },
            [theme.breakpoints.down(360.05)]: {
                margin: 'auto',
                flexWrap: 'wrap',
                width: '74px'
            },
        },
        visibleXs: {
            display: 'none'
        },
        hiddenXs: {
            display: 'flex',
        },
        [theme.breakpoints.between(360.05, 768.05)]: {
            footerWrapper: {
                padding: '16px 48px 15.6px 38px'
            },
            logo: {
                width: '96px',
                height: '74.4px'
            },
            footerLinkBox: {
                gap: '32px'
            },
            footerLink: {
                fontSize: '14px'
            },
            footerSocialBox: {
                gap: '12px'
            },
            footerSocialLink: {
                '& svg': {
                    width: '20px',
                    height: '20px'
                }
            }
        },
        [theme.breakpoints.down(360.05)]: {
            footerWrapper: {
                margin: '24px 16px 24px 8px',
                padding: 0
            },
            logo: {
                width: '80px',
                height: '62px'
            },
            footerLinkBox: {
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'flex-start',
            },
            footerLink: {
                fontSize: '12px',
            },
            visibleXs: {
                display: 'flex',
                gap: '8px',
                marginLeft: '8px',
                marginTop: '-2px',
            },
            hiddenXs: {
                display: 'none'
            },
            footerSocialLink: {
                '& svg': {
                    width: '16px',
                    height: '16px'
                }
            }
        }
    }),
);

const Footer = ({ history, isLoggedIn = false, handleContactus }) => {
    const classes = useStyles();
    const {currentUser} = useAuth();
    const goToTermAndConditionsPage = () => {
        history.push("/TermsAndConditions")
    }
    const goToPrivacyPolicyPage = () => {
        history.push("/PrivacyPolicy")
    }
    const goToAboutusPage = () => {
        history.push("/AboutUs")
    }
    
    const footerLinks = [{
        title: 'Contact Us',
        onClick: handleContactus
    },
    {
        title: 'Privacy Policy',
        // link: '/PrivacyPolicy'
        onClick: goToPrivacyPolicyPage
    },
    {
        title: 'Terms & conditions',
        // link: '/TermsAndConditions',
        onClick: goToTermAndConditionsPage
    },]

    const footerSocialBlock = (className) => {
        return <Box className={`${classes.footerSocialBox} ${className}`}>
            <Link href={currentUser.facebook_link} target="_blank" className={classes.footerSocialLink}>
                <FacebookIcon />
            </Link>
            <Link href={currentUser.instagram_link} target="_blank" className={classes.footerSocialLink}>
                <InstaIcon />
            </Link>
        </Box>
    }

    const handleSocailMediaClick = (url = null) => {
        if(url !== null) {
            window.open(url, '_blank');
        } else {
            window.alert("URL not provided.")
        }
    };

    


    return (isLoggedIn
        ? <Box component="footer" className={classes.footer}>
            <Box className={classes.footerWrapper}>
                <div>
                    <img src={logo} width="120" height="53" alt='Company Logo' className={classes.logo} />
                    {footerSocialBlock(classes.visibleXs)}
                </div>
                <List className={classes.footerLinkBox}>
                    {footerLinks.map((item, index) => {
                        return <ListItem className={classes.footerLink} key={index}>
                            <Link href={item.link} onClick={item.onClick}>
                                {item.title}
                            </Link>
                        </ListItem>
                    })}
                </List>
                {footerSocialBlock(classes.hiddenXs)}
            </Box>
        </Box>
        : <footer className={`${classes.footer} ${classes.marginTop}`}>
            <Grid justifyContent="space-between" className={classes.footerInner} style={{ display: 'flex', width:'auto' }} container>
                <Box style={{ display: 'flex' }} className={classes.footerLeft}>
                    <img src={logo} alt="LOGO" width={'120px'} />
                    <Grid item className={classes.followUsLink}>
                        <Typography className={classes.listItem}>follow us on </Typography>
                        <img src={facebook} className={classes.image18} alt="" style={{ verticalAlign: "top", cursor: 'pointer' }}
                        onClick={() => handleSocailMediaClick("https://www.facebook.com/yourPage")}
                        />
                        <img src={instagram} className={classes.image18} alt="" style={{cursor: 'pointer'}}
                        onClick={() => handleSocailMediaClick("https://www.instagram.com/yourPage")}
                        />
                    </Grid>
                </Box>
                <Box className={classes.footerRight}>
                    <Grid item >
                        <Typography
                            className={`${classes.listItem} ${classes.margingBottom}`}
                            onClick={() => goToAboutusPage()}
                            style={{cursor: 'pointer'}}
                        >
                            About Us
                        </Typography>
                        <Typography 
                        onClick = {() => handleContactus(true)} 
                        style={{cursor: 'pointer'}}
                        className={`${classes.listItem} ${classes.margingBottom}`}>Contact Us</Typography>
                        <Typography className={`${classes.listItem} ${classes.margingBottom}`} 
                        onClick={() => handleSocailMediaClick('https://wa.me/+966558217033')}
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center', gap: "6px", cursor: 'pointer'
                        }}>Contact Us on Whatsapp <img src={WhatsApp} className={classes.image} alt="" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            className={`${classes.listItem} ${classes.margingBottom}`}
                            onClick={() => goToPrivacyPolicyPage()}
                            style={{cursor: 'pointer'}}
                        >
                            Privacy and Policy
                        </Typography>
                        <Typography
                            onClick={() => goToTermAndConditionsPage()}
                            className={`${classes.listItem} ${classes.margingBottom}`}
                            style={{cursor: 'pointer'}}
                        >
                            Terms and Conditions
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </footer>
    )
};

export default withRouter(Footer);
