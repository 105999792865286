import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Card, CardContent, Grid, Typography, Box, useMediaQuery, Avatar } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fontStyleField: {
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '150%',
            color: '#9D9D9D',
            marginBottom: '8px'
        },
        fontStyleValue: {
            fontFamily: 'Inter',
            fontSize: '13px',
            fontWeight: 500,
            lineHeight: '150%',
            // color: '#7C7C7C'
        },
        card: {
            marginBottom: theme.spacing(2),
            border: '1px solid #C3CAD9',
            borderRadius: '10px',
        },
        content: {
            padding: theme.spacing(2),
        },
        leftColumn: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(2),
            },
        },
        rightColumn: {
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
            },
        },
        rightColumnItem: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1),
            },
        },
        fieldContainer: {
            marginBottom: theme.spacing(1),
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '15px'
        },
        avatar: {
            marginRight: theme.spacing(1),
            height: '35px',
            width: '35px',
        },
    })
);

interface CardProps {
    name: string;
    image: string;
    tripTitle: string;
    amount: number;
    date: string;
    to: string;
    bookingStatus: string;
}

const ResponsiveBookingCard: React.FC<CardProps> = ({
    name,
    image,
    tripTitle,
    amount,
    date,
    to,
    bookingStatus,
}) => {
    const classes = useStyles();

    const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

    return (
        <Card className={classes.card}>
            <CardContent className={classes.content}>
                <Grid container spacing={3}>
                    <Grid item xs={isSmallScreen ? 12 : 6} md={6} className={classes.leftColumn}>
                        <Box className={classes.fieldContainer}>
                            <Typography className={classes.fontStyleField}>NAME</Typography>
                            <Box className={classes.flexContainer}>
                                <Avatar alt={'ab'} src={image} className={classes.avatar} />
                                <Typography className={classes.fontStyleValue}>{name}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    {!isSmallScreen && (
                        <Grid item xs={isSmallScreen ? 12 : 6} md={6} className={classes.rightColumn}>
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.fontStyleField}>DATE</Typography>
                                <Typography className={`${classes.fontStyleValue} ${classes.rightColumnItem}`}>
                                    {date}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={isSmallScreen ? 12 : 6} className={classes.leftColumn}>
                        <Box className={classes.fieldContainer}>
                            <Typography className={classes.fontStyleField}>TRIP TITLE</Typography>
                            <Typography className={classes.fontStyleValue}>{tripTitle}</Typography>
                        </Box>

                    </Grid>
                    {!isSmallScreen && (
                        <Grid item xs={isSmallScreen ? 12 : 6} className={classes.rightColumn}>
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.fontStyleField}>TO</Typography>
                                <Typography className={`${classes.fontStyleValue} ${classes.rightColumnItem}`}>
                                    {to}
                                </Typography>
                            </Box>
                        </Grid>
                    )}
                </Grid>
                {isSmallScreen && (
                    <Grid container spacing={3}>
                        <Grid item xs={6} className={classes.rightColumn}>
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.fontStyleField}>TO</Typography>
                                <Typography className={`${classes.fontStyleValue} ${classes.rightColumnItem}`}>
                                    {to}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} className={classes.rightColumn}>
                            <Box className={classes.fieldContainer}>
                                <Typography className={classes.fontStyleField}>DATE</Typography>
                                <Typography className={`${classes.fontStyleValue} ${classes.rightColumnItem}`}>
                                    {date}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Grid container spacing={3}>
                    <Grid item xs={6} className={classes.leftColumn}>
                        <Box className={classes.fieldContainer}>
                            <Typography className={classes.fontStyleField}>AMOUNT</Typography>
                            <Typography className={classes.fontStyleValue}>${amount}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} className={classes.rightColumn}>
                        <Box className={classes.fieldContainer}>
                            <Typography className={classes.fontStyleField}>BOOKING STATUS</Typography>
                            <Typography className={`${classes.fontStyleValue} ${classes.rightColumnItem}`}>
                                {bookingStatus}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ResponsiveBookingCard;