import React from "react";

// Customizable Area Start
import {
    Typography, Box,
    Card, Button,
    CardContent,
    Divider, 
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider
} from "@material-ui/core/styles";
import { primaryColor } from "../../../components/src/theme";
import {
    signUptheme
} from "../../email-account-registration/src/EmailAccountRegistration.web";


export const ButtonStyle = withStyles({
    root: {
        textTransform: 'none',
        backgroundColor: primaryColor,
        color: '#ffffff',
        minWidth: '160px',
        width: '100%',
        height: '56px',
        maxWidth: '228px',
        flexShrink: 0,
        textAlign: 'center',
        borderRadius: '49px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '150%',
        fontFamily: [
            'Inter',
            'sans-serif',
        ].join(','),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&.Mui-disabled': {
            color: '#FFF',
            opacity: 0.6
        }
    },
})(Button);

// Customizable Area End

import SubscriptionPlanController, {
    Props
} from "./SubscriptionPlanController";


export default class SubscriptionPlan extends SubscriptionPlanController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
     

    
      

    // Customizable Area End

    render() {
        // Customizable Area Start
     
             const { classes } = this.props
             
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={signUptheme}>
                <Box className={classes.container}>
                   
                    <Typography className={classes.dividerStyle}>
                        Subscribe to begin your journey
                    </Typography>
                    <Typography>Elevate your journey with an exclusive subscription experience!</Typography>
                    <Box className={`${classes.userProfileInfo}`}>
                            <Box>
                                <Card className={classes.cardWrapper}>
                                <Box>
                                    <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">
                                        
                                    <Typography variant="h6">Trial Pack</Typography>
                                    </Box>
                                    <Divider className={`${classes.divider} dividerHover`} />
                                    <Box sx={{ padding: '15px 0' }}>
                                        <Typography variant="h6" style={{fontWeight: 600}}>Free</Typography>
                                        <Typography variant="body2" style={{ marginTop: '15px' }}> Control your subscription renewal preferences.Enable or dispable your auto renewal bases on your preference</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <Button data-testId={'btnGetStarted'} onClick={this.paymentForm} className={`${classes.buttonStyle} buttonStyleHover`} variant="contained">Get Started</Button>
                                            
                                            </Box>

                                    </Box>
                                    </Box>
                                </Card>
                            </Box>
                        
                            <Box>
                                <Card className={classes.cardWrapper}>
                                <Box>
                                    <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">
                                        
                                    <Typography variant="h6">Annual Pack</Typography>
                                    </CardContent>
                                    <Divider />
                                    <Box sx={{ padding: '15px' }}>
                                        <Typography variant="h6"><span style={{fontWeight: 600}}>999</span> <span style={{fontSize: '13px'}}>SAR/month</span></Typography>
                                        <Typography variant="body2" style={{ marginTop: '15px' }}> Control your subscription renewal preferences.Enable or dispable your auto renewal bases on your preference</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                <Button onClick={this.paymentForm} variant="contained" className={`${classes.buttonStyle} buttonStyleHover`}>Get Started</Button>
                                            
                                            </Box>

                                    </Box>
                                    </Box>

                                </Card>
                            </Box>
                            <Box>
                                <Card className={classes.cardWrapper}>
                                <Box>
                                    <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }} data-testid="subscription-container">
                                        
                                    <Typography variant="h6">Monthly Pack</Typography>
                                    </CardContent>
                                    <Divider />
                                    <Box sx={{ padding: '15px' }}>
                                        <Typography variant="h6"><span style={{fontWeight: 600}}>149</span> <span style={{fontSize: '13px'}}>SAR/month</span></Typography>
                                        <Typography variant="body2" style={{ marginTop: '15px' }}> Control your subscription renewal preferences.Enable or dispable your auto renewal bases on your preference</Typography>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <Button onClick={this.paymentForm} variant="contained" className={`${classes.buttonStyle} buttonStyleHover`}>Get Started</Button>
                                        </Box>
                                    </Box>
                                    </Box>
                                </Card>
                            </Box>

                            

                    </Box>
                    
                </Box >
            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export const ProfileStyles = (theme: Theme) => ({
    container: {
        margin: '0px 0 20px',
    },
    divider: {
        marginTop: '20px'
    },
    buttonStyle: {
        background: '#FE7F2D', 
        color: '#fff', 
        borderRadius: '30px', 
        width: '100%',
        // '&:hover': {
        //     backgroundColor: '#fff',
           
        //     // transition: 'opactiy 0.3s linear',
        // },
    },
      
     

    rootTab: {
        display: 'flex',
        padding: '4px 4px',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '4px',
        minWidth: 'auto',
        minHeight: 'auto'
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: '24px',
    },
    rootTabs: {
        minHeight: '32px',
        height: '100%'
    },

    agencyImg: {
        objectFit: 'cover',
        borderRadius: '50px'
    },
    selectedTab: {
        '& span': {
            color: '#212426',
            fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
            fontFamily: 'Inter',
            fontWeight: 600,
            lineHeight: '20px',
            fontSize: '16px',
            fontStyle: 'normal',
        }
    },
    tabItem: {
        color: 'rgba(0, 0, 0, 0.40)',
        fontFeatureSettings: "'cv11' on, 'cv01' on, 'ss01' on",
        fontFamily: 'Inter',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
        textTransform: 'none' as const
    },


    cardWrapper: {
        padding: '48px',
        borderRadius: '16px',
        border: '0.5px solid rgba(33, 36, 38, 0.20)',
        background: '#FFF',
        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.04)',
        transition: 'transform .2s',
        '&:hover': {
            backgroundColor: '#F0A974',
            transform: 'scale(1.1)',
            boxShadow: '0 24px 36px rgba(0,0,0,0.11)',
            color:'#fff',
            "& .buttonStyleHover": {
                background: '#fff',
                color: 'rgb(254, 127, 45)'
            },
            "& .dividerHover": {
                borderBottom: '0.5px solid #fff'
            }
        }
    },
    carddetails: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 30px',
        borderRadius: '30px',
        background: '#FFF2D7',
        gap: '10px',
        justifyContent: 'flex-start',

    },

    userProfileCard: {
        borderRadius: '16px',
        background: '#FFDBAC',
        padding: '15px'

    },
    userProfileInfo: {
        flexDirection: 'row',
        gap: '20px',
        width: '100%',
        display: 'flex',
        marginTop: '50px'
    },
    userProfilesecondCard:{
        marginTop : '16px' ,
     padding : '30px',
     borderRadius : '16px'
    },
   

    editProfileInfo: {
        maxWidth: '900px',
    },
    userDetails: {
        display: 'flex',
        gap: '20px'
    },
    cardkeys : {
        width:'100px'
      },
    heading: {
        // color: '#212426',
        // fontSize: '20px',
        fontWeight: 500,
        // lineHeight: '100%',
    },

    cardbtns: {
        padding: '10px 60px',
        background: '#FE7F2D',
        color: '#fff',
        borderRadius: '20px'
    },
    editdetailstyle: {
        color: primaryColor,
        fontWeight: 500,
        gap: '8px',
        alignItems: 'center',
        display: 'flex',
        cursor: 'pointer'
    },
    textfields: {
        height: '44px'
    },

    logoContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
        padding: '0px 30px 40px 0px'
    },
    editdetailstyles: {
        color: '#FE7F2D',
        marginLeft: '30px',
        whiteSpace: 'nowrap',
        fontWeight: '500',

    },
    lightLabelTextstyle: {
        fontWeight: 500,
        whiteSpace: 'noWrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '340px',
        minWidth: 'fit-content'


    },


    boldLabel: {
        fontWeight: 'bold'
    },

    valueTextstyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontWeight: 500,
        display: 'flex',
        marginLeft: '40px',
        width: '320px',
        height: '24px',

    },
    cardBoxes:{
        display:'flex',
        gap:'20px'
      },
    valueTextstylespan: {

        color: 'rgba(33, 36, 38, 0.90)',
        width: '270px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' as const,
        display: 'block',
        fontWeight: 500,
        height: '24px',


    },


    required: {
        color: '#F00'
    },
    dividerStyle: {
        margin: '18px 0 10px',
        color: '#212426',
        fontSize: '28px',
        fontWeight: 700,
        lineHeight: '100%',
    },
    userImage: {
        height: '20px',
        width: '20px'
    },
    labelText: {
        fontWeight: 500,
        marginBottom: '10px'
    },
})

const SubscriptionPlanWeb = withStyles(ProfileStyles as object)(SubscriptionPlan)
export { SubscriptionPlanWeb }
// Customizable Area End
