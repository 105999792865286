import React from "react"
import { Breadcrumbs as MuiBreadcrumbs, Typography, Link } from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import { useWindowWidth } from "./utils";

interface BreadcrumbsProps {
    list: Record<string, any>[]
}

const Breadcrumbs = ({ list }: BreadcrumbsProps) => {

    const windowWidth = useWindowWidth();
    const isMobile = windowWidth < 768;

    const webStyle = {
        breadcrumbText: {
            color: '#212426',
            fontFamily: 'Inter',
            fontSize: isMobile ? '12px' : '14px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '150%',
        }
    }

    return (
        <MuiBreadcrumbs separator={<NavigateNext fontSize="small" />}
            aria-label="breadcrumb">
            {
                list.map((item, index, lists) => {
                    return index == lists.length - 1
                        ? <Typography style={webStyle.breadcrumbText} key={index}>
                            {item.title}
                        </Typography>
                        : <Link href={item.link} key={index}
                            style={webStyle.breadcrumbText}>
                            {item.title}
                        </Link>
                })
            }
        </MuiBreadcrumbs>
    )
}

export default Breadcrumbs;


