import React, { useEffect } from "react";
import {
    Select,
    MenuItem,
    Typography,
    FormControl,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { makeStyles } from '@material-ui/core/styles'
import { CountryData } from "../../blocks/email-account-registration/src/Interface.web";
import { useWindowWidth } from "./utils";

interface City {
    name: string;
}

interface CountrySelectProps {
    countries: CountryData[];
    selectedCountry: string | null;
    countryTouched: boolean;
    countryError: string;
    setFieldValue: (field: string, value: any) => void; // Added setFieldValue prop
    getCityListRequest: (cityName: string) => void;
    isDashboard?: boolean
    isDisable?: boolean;
}





const CountrySelect: React.FC<CountrySelectProps> = ({
    countries,
    countryError,
    countryTouched,
    selectedCountry,
    setFieldValue, // Received setFieldValue prop
    getCityListRequest,
    isDashboard = false,
    isDisable = false,
}) => {
    const useStyles = makeStyles((theme) => ({
        inputBlack: {
          color: "#212426",
          fontFamily: "Inter",
          fontSize: '16px',
          fontWeight: 500
        },
        inputGray: {
          color: "#A2A3A4",
          fontFamily: "Inter",
          fontSize: '16px',
          fontWeight: 500
        },
        fieldText: {
            fontFamily: "Inter",
            fontSize: isDashboard ? '18px' : "16px",
            color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
            marginBottom: isDashboard ? '16px' : "8px",
            height: '24px',
            fontWeight: 500
        },
        itemText: {
            fontFamily: "Inter", 
            fontSize: '16px', 
            fontWeight: 500
        },
        [theme.breakpoints.down(1024)]: {
            fieldText: {
                fontFamily: "Inter",
                fontSize: "16px",
                color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
                marginBottom: isDashboard ? '12px' : "8px",
                fontWeight: 500
            },
            itemText: {
                fontFamily: "Inter", 
                fontSize: '14px', 
                fontWeight: 500
            },
            
        },
        [theme.breakpoints.down(768)]: {
            fieldText: {
                fontFamily: "Inter",
                fontSize: "14px",
                color: isDashboard ? 'rgba(33, 36, 38, 0.90)' : "rgb(33 36 38 / 65%)",
                marginBottom: "8px",
                fontWeight: 500
            },
            itemText: {
                fontFamily: "Inter", 
                fontSize: '14px', 
                fontWeight: 500
            },
            inputBlack: {
                color: "#212426E5",
                fontFamily: "Inter",
                fontSize: '14px',
                fontWeight: 500
              },
              inputGray: {
                color: "#A2A3A4",
                fontFamily: "Inter",
                fontSize: '14px',
                fontWeight: 500
              },
        }
      }));
    const classes = useStyles();
    const windowWidth = useWindowWidth();

  const isMobile = windowWidth < 768;

    const handleCountryChange = (event: any) => {
        const countryName = event.target.value as string;
        // if (!countryName) {
        //     setFieldValue("country", null)
        // }
        // const country = countries.find((country: CountryData) => country.name === countryName);
        setFieldValue("country", countryName); // Use setFieldValue to update Formik's field value
        setFieldValue("city", null)
        getCityListRequest(countryName)

    };

    useEffect(() => {
        getCityListRequest(selectedCountry as string)
    },[])


    return (
        <FormControl variant="outlined" fullWidth>
            <Typography
                className={classes.fieldText}
            >
                Country<span style={{ color: 'red' }}> *</span>
            </Typography>
            <div style={{
                border: `1px solid`,
                borderColor: countryError && countryTouched && !selectedCountry ? 'red' : '#C3CAD9',
                borderRadius: '8px',
                padding: '11px 20px',
                height: isMobile ? '48px' : '56px',
                display: 'flex'
            }}>
                <Select
                    fullWidth
                    variant="standard"
                    IconComponent={KeyboardArrowDownIcon}
                    disableUnderline={true}
                    displayEmpty
                    data-testid="countyselect"
                    disabled={isDisable}
                    style={{border: 'none', paddingLeft: '0px'}}
                    inputProps={{
                        className:
                            selectedCountry === ""
                                ? classes.inputGray
                                : classes.inputBlack,
                        style: {
                            backgroundColor: "#ffffff",
                            // color: selectedCountry === null ? "rgb(33 36 38 / 65%)" : "black",
                            border: 'none',
                            fontFamily: "Inter",
                        },

                    }}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                            style: {
                                maxHeight: "250px",
                            },
                        },
                    }}
                    value={selectedCountry ? selectedCountry : ""}
                    onChange={handleCountryChange}
                >
                    <MenuItem value="" disabled className={classes.itemText}>
                        Select country
                    </MenuItem>
                    {countries.map((country: CountryData) => (
                        <MenuItem key={country.name} 
                        value={country.name}
                        className={classes.itemText}
                        >
                            {country.name}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <Typography variant="caption" style={{fontFamily: "Inter", fontWeight: 400, marginTop: '2.5px', color: '#FF0000',}}>
                {countryTouched && !selectedCountry ? countryError : ""}
            </Typography>
        </FormControl>
    );
};

export default CountrySelect;
