
import React from "react";
import { TextField, Typography, Button, Box, InputAdornment, IconButton } from "@material-ui/core";
import { ErrorOutline, Check } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles';
import { primaryColor } from "./theme";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from 'formik'
import { uploadCloudImg } from "../../blocks/email-account-registration/src/assets";
import { EntityDetailsValues } from "../../blocks/email-account-registration/src/Interface.web";
import { useWindowWidth } from "./utils";
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { useAuth } from "../../blocks/user-profile-basic/src/AuthContext.web";

interface EntityDetailsProps {
    agency_logo: string;
    initialState: EntityDetailsValues;
    submitEntityDetails: (values: any) => void;
    // handleBack: (values: any) => void;
    isEditClicked: boolean
    traveleAgencyEditRequest
  : (value: any) => void
  handleBack : (value :any)=> void
    // currentUser :any
}

export function checkIfFilesAreTooBig(files?: any): boolean {
    let valid = true;
    if (files) {
        const size = files.size / 1024 / 1024;
        if (size > 5) {
            valid = false;
        }
    }
    return valid;
}

const logoFiles = ['image/jpg', 'image/jpeg', 'image/png']

function checkIfFilesAreCorrectType(files?: any): boolean {
    let valid = true;
    if (files?.type) {
        if (!logoFiles.includes(files.type)) {
            valid = false;
        }
    }
    return valid;
}

const pdfFiles = ['application/pdf']

function checkIfFileIsPdfType(files?: any): boolean {
    let valid = true;
    if (files?.name) {
        if (!pdfFiles.includes(files.type)) {
            valid = false;
        }
    }
    return valid;
}

const validationSchema = Yup.object().shape({
    agency_logo: Yup.mixed()
        .nullable()
        .required('* Please choose a valid file')
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig)
        .test('is-correct-file', '* Please choose a valid file', checkIfFilesAreCorrectType),
    agency_display_name: Yup.string().required('* Please enter agency display name'),
    about_me: Yup.string().notRequired(),
    company_legal_name: Yup.string().required('* Please enter company legal name'),
    id_number: Yup.string().required('* Please enter ID number'),
    commercialPdf: Yup.mixed()
        .required('* Please choose a valid file')
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig)
        .test('is-correct-file', '* Please choose a valid file', checkIfFileIsPdfType),
    permitPdf: Yup.mixed()
        .nullable()
        .notRequired()
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig)
        .test('is-correct-file', '* Please choose a valid file', checkIfFileIsPdfType),
    certificatePdf: Yup.mixed()
        .nullable()
        .notRequired()
        .test('is-big-file', '* Please choose a file less than 5 MB', checkIfFilesAreTooBig)
        .test('is-correct-file', '* Please choose a valid file', checkIfFileIsPdfType),
    vat_number: Yup.string()
        .notRequired().nullable()
});


const useStyles = makeStyles((theme) => ({
    customFont: {
        fontFamily: 'Inter',
        fontWeight: 400
    },
}));

const EntityDetails = ({ initialState, submitEntityDetails,traveleAgencyEditRequest, isEditClicked = false ,handleBack }: EntityDetailsProps) => {
    const {currentUser} = useAuth()
    const classes = useStyles();

    const windowWidth: number = useWindowWidth();

    // Define your responsive logic based on windowWidth
    const isMobile: boolean = windowWidth < 768;

    const normalLine = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23C3CAD9' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`
    const errorLine = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23FF0000' stroke-width='2' stroke-dasharray='6%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`

    return (
        <div
            style={{
                padding: "20px",
            }}
        >
            {!isEditClicked && <Box>
                <Typography style={{ fontSize: '32px', marginTop: '30px', fontFamily: 'Inter', fontWeight: 500, color: '#212426' }}>Entity Details</Typography>
            </Box>}
            <Formik
                enableReinitialize={true}
                initialValues={initialState}
                test-id="submitButtonId"
                onSubmit={ isEditClicked ? traveleAgencyEditRequest :submitEntityDetails}
                validationSchema={validationSchema}
            >
                {({ values, setFieldValue, errors, touched }) => {
                    const isDefaultImg = values?.agency_logo
                    && typeof values?.agency_logo === 'object'
                    && !errors?.agency_logo
                    return (
                        <Form>
                            {isEditClicked ?
                                <Box sx={{ display: 'flex', gridGap: '10px' }}>
                                    <Box sx={{ display: 'flex', borderRight: '1px dashed', flexDirection: 'column', padding: '0px 40px' }}>

                                        <Box style={{ marginTop: '30px', display: 'flex', gap: '7px', flexWrap: 'wrap' }}>
                                            <Typography
                                                style={{
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    fontWeight: 500
                                                }}
                                            >
                                                Profile Picture
                                            </Typography>

                                        </Box>

                                      
                                        <Box style={{ marginTop: '16px' }}>
    <Box style={{ display: 'flex', gap: '32px', flexDirection: 'column' }}>
        <Box>
            <Typography style={{
                height: '120px', width: '120px', display: "flex", alignItems: 'center',
                borderRadius: '50%', background: 'rgb(247, 247, 247)', justifyContent: 'center',
                border: '1px solid #C3CAD9', padding: '0', overflow: 'hidden', cursor: 'pointer'
            }} component={"label"} htmlFor="profilePic">
                <img style={{ height: '100%', width: '100%', objectFit: 'cover' }} src={values?.agency_logo} alt="Profile Image" />
                <input type="file" id="profilePic" name="buddy_profile_picture" hidden
                    placeholder="Upload your resume (.DOC, .DOCX, .PDF. Max file size 10MB)"
                    onChange={(e) => {
                        const selectedFile = e.target.files && e.target.files[0];
                        if (selectedFile) {
                            setFieldValue('agency_logo', selectedFile);
                        }
                    }} />
            </Typography>
        </Box>

        <Box style={{ display: 'flex', flexDirection: "column", justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', gridGap: '10px' }}>
                <Box>
                    <CloudUploadOutlinedIcon style={{
                        color: primaryColor,
                    }} />
                </Box>
                <Typography style={{
                    color: primaryColor,
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '150%',
                    cursor: "pointer",
                    marginBottom: "2px"
                }} component="label" htmlFor="profilePic">
                    Replace
                </Typography>

            </Box>
            <Typography style={{
                color: '#F00',
                fontSize: '12px',
                fontStyle: 'normal',
                lineHeight: 'normal',
                marginTop: '8px'
            }}>{errors?.agency_logo}</Typography>
        </Box>
    </Box>
</Box>

                                    </Box>

                                    <Box>

                                        <Box style={{ marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500
                                                }}
                                            >
                                                Agency Display Name<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter agency display name"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    fullWidth
                                                    onChange={(e) => {
                                                        setFieldValue("agency_display_name", e.target.value);
                                                        touched.agency_display_name = true;
                                                    }}
                                                    error={
                                                        Boolean(errors.agency_display_name) && touched?.agency_display_name
                                                    }
                                                    helperText={
                                                        touched?.agency_display_name ? errors?.agency_display_name : ""
                                                    }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={values.agency_display_name}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: "#ffffff",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `1px solid`,
                                                            borderColor: errors?.agency_display_name && touched.agency_display_name ? 'red' : '#C3CAD9',
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500
                                                }}
                                            >
                                                About Me (Optional)
                                            </Typography>
                                            <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Write something about you"
                                                    variant="standard"
                                                    name="about_me"
                                                    multiline={true}
                                                    minRows={5}
                                                    maxRows={10}
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onChange={(e) => {
                                                        setFieldValue("about_me", e.target.value);
                                                        touched.about_me = true;
                                                    }}
                                                    error={
                                                        Boolean(errors.about_me) && touched?.about_me
                                                    }
                                                    helperText={
                                                        touched?.about_me ? errors?.about_me : ""
                                                    }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={values.about_me}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: "#ffffff",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            border: `1px solid`,
                                                            borderColor: errors?.about_me && touched.about_me ? 'red' : '#C3CAD9',
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500,
                                                            resize: 'none'
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500
                                                }}
                                            >
                                                Company Legal Name<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter company legal name"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onChange={(e) => {
                                                        setFieldValue("company_legal_name", e.target.value);
                                                        touched.company_legal_name = true;
                                                    }}
                                                    error={
                                                        Boolean(errors?.company_legal_name) && touched.company_legal_name
                                                    }
                                                    helperText={
                                                        touched.company_legal_name ? errors?.company_legal_name : ""
                                                    }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={values?.company_legal_name}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: "#ffffff",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `1px solid`,
                                                            borderColor: errors?.company_legal_name && touched.company_legal_name ? 'red' : '#C3CAD9',
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500
                                                }}
                                            >
                                                ID Number<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter ID number"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onChange={(e) => {
                                                        setFieldValue("id_number", e.target.value);
                                                        touched.id_number = true;
                                                    }}
                                                    error={
                                                        Boolean(errors?.id_number) && touched.id_number
                                                    }
                                                    helperText={
                                                        touched.id_number ? errors?.id_number : ""
                                                    }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={values.id_number}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: "#ffffff",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `1px solid`,
                                                            borderColor: errors?.id_number && touched.id_number ? 'red' : '#C3CAD9',
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Commercial Registration Document<span style={{ color: 'red' }}> *</span>
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        paddingLeft: isMobile ? '34px' : '0px',
                                                        fontWeight: 400
                                                    }}
                                                >
                                                    (Format: PDF) (Max size: 5 MB)
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: isMobile ? 'column' : 'row',
                                                justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                                alignItems: 'flex-end',
                                                gap: '10px',
                                                width: '100%',
                                                marginBottom: errors.commercialPdf ? '1px' : 'none'
                                            }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Commercial Registration Document"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                        const droppedFiles = e.dataTransfer.files?.[0];
                                                        if (droppedFiles) {
                                                            setFieldValue('commercialPdf', droppedFiles);
                                                            touched.commercialPdf = true;
                                                        }
                                                    }}
                                                    error={
                                                        Boolean(errors?.commercialPdf) && touched.commercialPdf as boolean
                                                    }
                                                    // helperText={
                                                    //     touched.commercialPdf ? errors?.commercialPdf as string : ""
                                                    // }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={checkIfFilesAreTooBig(values.commercialPdf) && checkIfFileIsPdfType(values.commercialPdf) ? values?.commercialPdf?.name : ""}
                                                    InputProps={{
                                                        startAdornment: !values.commercialPdf?.name
                                                            || values.commercialPdf?.name && !checkIfFilesAreTooBig(values.commercialPdf) || !checkIfFileIsPdfType(values.commercialPdf) ? (
                                                            <InputAdornment position='start'>
                                                                <img src={uploadCloudImg} />
                                                            </InputAdornment>
                                                        ) : null,
                                                        placeholder: !values.commercialPdf?.name
                                                            || values.commercialPdf?.name && !checkIfFilesAreTooBig(values.commercialPdf) || !checkIfFileIsPdfType(values.commercialPdf) ?
                                                            'Browse or drag and drop file to upload' : "",
                                                        endAdornment: pdfFiles.includes(values.commercialPdf?.type) && !errors?.commercialPdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : values.commercialPdf?.name && errors?.commercialPdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : null,
                                                        style: {
                                                            backgroundColor: "#F7F7F7",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `none`,
                                                            width: '100%',
                                                            backgroundImage: errors?.commercialPdf && touched.commercialPdf ? errorLine : normalLine,
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                                <Button
                                                    component="label"
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        borderRadius: '8px',
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        width: isMobile ? '50%' : '22%',
                                                        height: '56px',
                                                        textTransform: 'initial',
                                                        border: `1px solid ${primaryColor}`,
                                                        color: primaryColor,
                                                        textAlign: 'center',
                                                        // marginBottom: !isMobile && errors.commercialPdf ? '22px' : 'none' 
                                                    }}
                                                >
                                                    Browse file
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {
                                                            const selectedFile = e.target.files?.[0];
                                                            if (selectedFile) {
                                                                setFieldValue('commercialPdf', selectedFile);
                                                                touched.commercialPdf = true;
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                            <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                                {touched.commercialPdf ? errors?.commercialPdf as string : ""}
                                            </Typography>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Permit Document (Optional)
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        paddingLeft: isMobile ? '34px' : '0px',
                                                        fontWeight: 400
                                                    }}
                                                >
                                                    (Format: PDF) (Max size: 5 MB)
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: isMobile ? 'column' : 'row',
                                                justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                                alignItems: 'flex-end',
                                                gap: '10px',
                                                width: '100%',
                                                marginBottom: errors.permitPdf ? '1px' : 'none'
                                            }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Permit Document"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                        const droppedFiles = e.dataTransfer.files?.[0];
                                                        if (droppedFiles) {
                                                            setFieldValue('permitPdf', droppedFiles);
                                                            touched.permitPdf = true;
                                                        }
                                                    }}
                                                    error={
                                                        Boolean(errors?.permitPdf) && touched.permitPdf as boolean
                                                    }
                                                    // helperText={
                                                    //     touched.permitPdf ? errors?.permitPdf as string : ""
                                                    // }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={checkIfFilesAreTooBig(values.permitPdf) && checkIfFileIsPdfType(values.permitPdf) ? values?.permitPdf?.name : ""}
                                                    InputProps={{
                                                        startAdornment: !values.permitPdf?.name
                                                            || values.permitPdf?.name && !checkIfFilesAreTooBig(values.permitPdf) || !checkIfFileIsPdfType(values.permitPdf)
                                                            ? (
                                                                <InputAdornment position='start'>
                                                                    <img src={uploadCloudImg} />
                                                                </InputAdornment>
                                                            ) : null,
                                                        placeholder: !values.permitPdf?.name
                                                            || values.permitPdf?.name && !checkIfFilesAreTooBig(values.permitPdf) || !checkIfFileIsPdfType(values.permitPdf)
                                                            ? 'Browse or drag and drop file to upload' : "",
                                                        endAdornment: values.permitPdf?.name && !errors?.permitPdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : values.permitPdf?.name && errors?.permitPdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : null,
                                                        style: {
                                                            backgroundColor: "#F7F7F7",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `none`,
                                                            width: '100%',
                                                            backgroundImage: errors?.permitPdf && touched.permitPdf ? errorLine : normalLine,
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                                <Button
                                                    component="label"
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        borderRadius: '8px',
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        width: isMobile ? '50%' : '22%',
                                                        height: '56px',
                                                        textTransform: 'initial',
                                                        border: `1px solid ${primaryColor}`,
                                                        color: primaryColor,
                                                        textAlign: 'center',
                                                        // marginBottom: !isMobile && errors.permitPdf ? '22px' : 'none' 
                                                    }}
                                                >
                                                    Browse file
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {
                                                            const selectedFile = e.target.files?.[0];
                                                            if (selectedFile) {
                                                                setFieldValue('permitPdf', selectedFile);
                                                                touched.permitPdf = true;
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                            <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                                {touched.permitPdf ? errors?.permitPdf as string : ""}
                                            </Typography>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "16px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        fontWeight: 500
                                                    }}
                                                >
                                                    Certificate File (Optional)
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        fontFamily: "Inter",
                                                        fontSize: "14px",
                                                        color: "rgb(33 36 38 / 65%)",
                                                        marginBottom: "8px",
                                                        flex: isMobile ? 1 : 'initial',
                                                        paddingLeft: isMobile ? '34px' : '0px',
                                                        fontWeight: 400
                                                    }}
                                                >
                                                    (Format: PDF) (Max size: 5 MB)
                                                </Typography>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: isMobile ? 'column' : 'row',
                                                justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                                alignItems: 'flex-end',
                                                gap: '10px',
                                                width: '100%',
                                                marginBottom: errors.certificatePdf ? '1px' : 'none'
                                            }}>
                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Certificate File"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onDragOver={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                    }}
                                                    onDrop={(e) => {
                                                        e.preventDefault()
                                                        e.stopPropagation();
                                                        const droppedFiles = e.dataTransfer.files?.[0];
                                                        if (droppedFiles) {
                                                            setFieldValue('certificatePdf', droppedFiles);
                                                            touched.certificatePdf = true;
                                                        }
                                                    }}
                                                    error={
                                                        Boolean(errors?.certificatePdf) && touched.certificatePdf as boolean
                                                    }
                                                    // helperText={
                                                    //     touched.certificatePdf ? errors?.certificatePdf as string : ""
                                                    // }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={checkIfFilesAreTooBig(values.certificatePdf) && checkIfFileIsPdfType(values.certificatePdf) ? values?.certificatePdf?.name : ""}
                                                    InputProps={{
                                                        startAdornment: !values.certificatePdf?.name
                                                            || values.certificatePdf?.name && !checkIfFilesAreTooBig(values.certificatePdf) || !checkIfFileIsPdfType(values.certificatePdf) ? (
                                                            <InputAdornment position='start'>
                                                                <img src={uploadCloudImg} />
                                                            </InputAdornment>
                                                        ) : null,
                                                        placeholder: !values.certificatePdf?.name
                                                            || values.certificatePdf?.name && !checkIfFilesAreTooBig(values.certificatePdf) || !checkIfFileIsPdfType(values.certificatePdf)
                                                            ? 'Browse or drag and drop file to upload' : "",
                                                        endAdornment: values.certificatePdf?.name && !errors?.certificatePdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : values.certificatePdf?.name && errors?.certificatePdf ? (
                                                            <InputAdornment position='end'>
                                                                <div style={{ display: 'flex', gap: '5px' }}>
                                                                    <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                    <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                                </div>
                                                            </InputAdornment>
                                                        ) : null,
                                                        style: {
                                                            backgroundColor: "#F7F7F7",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `none`,
                                                            width: '100%',
                                                            backgroundImage: errors?.certificatePdf && touched.certificatePdf ? errorLine : normalLine,
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                        readOnly: true,
                                                    }}
                                                />
                                                <Button
                                                    component="label"
                                                    style={{
                                                        fontFamily: 'Inter',
                                                        borderRadius: '8px',
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        width: isMobile ? '50%' : '22%',
                                                        height: '56px',
                                                        textTransform: 'initial',
                                                        border: `1px solid ${primaryColor}`,
                                                        color: primaryColor,
                                                        textAlign: 'center',
                                                        // marginBottom: !isMobile && errors.certificatePdf ? '22px' : 'none' 
                                                    }}
                                                >
                                                    Browse file
                                                    <input
                                                        type="file"
                                                        hidden
                                                        onChange={(e) => {
                                                            const selectedFile = e.target.files?.[0];
                                                            if (selectedFile) {
                                                                setFieldValue('certificatePdf', selectedFile);
                                                                touched.certificatePdf = true;
                                                            }
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                            <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                                {touched.certificatePdf ? errors?.certificatePdf as string : ""}
                                            </Typography>
                                        </Box>
                                        <Box style={{ marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    height: '24px',
                                                    fontWeight: 500
                                                }}
                                            >
                                                VAT Number (Optional)
                                            </Typography>
                                            <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                                <TextField
                                                    style={{ width: "100%" }}
                                                    placeholder="Enter VAT number"
                                                    variant="standard"
                                                    name="firstName"
                                                    autoComplete="off"
                                                    className="email_input"
                                                    onChange={(e) => {
                                                        setFieldValue("vat_number", e.target.value);
                                                        touched.vat_number = true;
                                                    }}
                                                    error={
                                                        Boolean(errors?.vat_number) && touched.vat_number
                                                    }
                                                    helperText={
                                                        touched.vat_number ? errors?.vat_number : ""
                                                    }
                                                    FormHelperTextProps={{
                                                        className: classes.customFont,
                                                    }}
                                                    value={values.vat_number}
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: "#ffffff",
                                                            color: "rgba(33, 36, 38, 0.90)",
                                                            padding: "16px 20px",
                                                            height: '56px',
                                                            border: `1px solid`,
                                                            borderColor: errors?.vat_number && touched.vat_number ? 'red' : '#C3CAD9',
                                                            borderRadius: '8px',
                                                            fontFamily: "Inter",
                                                            fontSize: '16px',
                                                            fontWeight: 500
                                                        },
                                                        disableUnderline: true,
                                                    }}
                                                />
                                            </Box>
                                        </Box>

                                        <Box style={{ marginTop: '48px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                            {
                                                !isEditClicked ? <Button
                                                    style={{
                                                        backgroundColor: primaryColor,
                                                        color: '#ffffff',
                                                        textTransform: 'initial',
                                                        borderRadius: '34px',
                                                        padding: '10px 50px',
                                                        fontSize: '16px',
                                                        fontFamily: "Inter",
                                                        fontWeight: 500,
                                                        width: '182px',
                                                        height: '56px',
                                                    }}
                                                    type="submit"
                                                >
                                                    Next
                                                </Button> :
                                                    <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                                        <Button
                                                            style={{
                                                                backgroundColor: primaryColor,
                                                                color: '#ffffff',
                                                                textTransform: 'initial',
                                                                borderRadius: '34px',
                                                                padding: '10px 60px',
                                                                fontSize: '16px',
                                                                fontFamily: "Inter",
                                                                fontWeight: 500,
                                                                width: '182px',
                                                                height: '56px',
                                                                whiteSpace: 'nowrap'
                                                            }}
                                                            type="submit"
                                                        >
                                                            Save Changes
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                color: primaryColor,
                                                                textTransform: 'initial',
                                                                borderRadius: '34px',
                                                                padding: '10px 50px',
                                                                fontSize: '16px',
                                                                fontFamily: "Inter",
                                                                fontWeight: 500,
                                                                width: '182px',
                                                                height: '56px',
                                                            }}
                                                            onClick={handleBack}
                                                        
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </Box>
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                                :

                                <Box>
                                    <Box style={{ marginTop: '30px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%', marginTop: '32px' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    fontWeight: 500,
                                                    flex: isMobile ? 1 : 'initial'
                                                }}
                                            >
                                                Agency Logo<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    fontWeight: 400
                                                }}
                                            >
                                                (Format: JPG, JPEG and PNG) (Max size: 5 MB)
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                            alignItems: 'flex-end',
                                            gap: '10px',
                                            width: '100%',
                                            marginBottom: errors.agency_logo ? '1px' : 'none'
                                        }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onDragOver={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                    const droppedFiles = e.dataTransfer.files?.[0];
                                                    if (droppedFiles) {
                                                        setFieldValue('agency_logo', droppedFiles);
                                                        touched.agency_logo = true;
                                                    }
                                                }}
                                                error={
                                                    Boolean(errors.agency_logo) && touched.agency_logo as boolean
                                                }
                                                // helperText={
                                                //    touched.agency_logo ? errors?.agency_logo as string : ""
                                                // }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={checkIfFilesAreTooBig(values.agency_logo) && checkIfFilesAreCorrectType(values.agency_logo) ? values.agency_logo?.name : ""}
                                                InputProps={{
                                                    startAdornment: !values.agency_logo?.name
                                                        || values.agency_logo?.name && !checkIfFilesAreTooBig(values.agency_logo) || !checkIfFilesAreCorrectType(values.agency_logo) ? (
                                                        <InputAdornment position='start'>
                                                            <img src={uploadCloudImg} />
                                                        </InputAdornment>
                                                    ) : null,
                                                    placeholder: !values.agency_logo?.name
                                                        || values.agency_logo?.name && !checkIfFilesAreTooBig(values.agency_logo) || !checkIfFilesAreCorrectType(values.agency_logo)
                                                        ? 'Browse or drag and drop file to upload'
                                                        : "",
                                                    endAdornment: logoFiles.includes(values.agency_logo?.type) && !errors?.agency_logo ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : values.agency_logo?.name && !checkIfFilesAreTooBig(values.agency_logo) && !checkIfFilesAreCorrectType(values.agency_logo) ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : null,
                                                    style: {
                                                        backgroundColor: "#F7F7F7",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `none`,
                                                        width: '100%',
                                                        backgroundImage: errors?.agency_logo && touched.agency_logo ? errorLine : normalLine,
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                    readOnly: true
                                                }}
                                            />
                                            <Button
                                                component="label"
                                                style={{
                                                    fontFamily: 'Inter',
                                                    borderRadius: '8px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    width: isMobile ? '50%' : '22%',
                                                    height: '56px',
                                                    textTransform: 'initial',
                                                    border: `1px solid ${primaryColor}`,
                                                    color: primaryColor,
                                                    textAlign: 'center',
                                                    // marginBottom: !isMobile && errors.agency_logo ? '22px' : 'none' 
                                                }}
                                            >
                                                Browse file
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files?.[0];
                                                        if (selectedFile) {
                                                            setFieldValue('agency_logo', selectedFile);
                                                            touched.agency_logo = true;
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                        <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                            {touched.agency_logo ? errors?.agency_logo as string : ""}
                                        </Typography>
                                    </Box>

                                    <Box style={{ marginTop: '32px' }}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                color: "rgb(33 36 38 / 65%)",
                                                marginBottom: "8px",
                                                height: '24px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Agency Display Name<span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Enter agency display name"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onChange={(e) => {
                                                    setFieldValue("agency_display_name", e.target.value);
                                                    touched.agency_display_name = true;
                                                }}
                                                error={
                                                    Boolean(errors.agency_display_name) && touched?.agency_display_name
                                                }
                                                helperText={
                                                    touched?.agency_display_name ? errors?.agency_display_name : ""
                                                }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={values.agency_display_name}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#ffffff",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `1px solid`,
                                                        borderColor: errors?.agency_display_name && touched.agency_display_name ? 'red' : '#C3CAD9',
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                color: "rgb(33 36 38 / 65%)",
                                                marginBottom: "8px",
                                                height: '24px',
                                                fontWeight: 500
                                            }}
                                        >
                                            About Me (Optional)
                                        </Typography>
                                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Write something about you"
                                                variant="standard"
                                                name="about_me"
                                                multiline={true}
                                                minRows={5}
                                                maxRows={10}
                                                autoComplete="off"
                                                className="email_input"
                                                onChange={(e) => {
                                                    setFieldValue("about_me", e.target.value);
                                                    touched.about_me = true;
                                                }}
                                                error={
                                                    Boolean(errors.about_me) && touched?.about_me
                                                }
                                                helperText={
                                                    touched?.about_me ? errors?.about_me : ""
                                                }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={values.about_me}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#ffffff",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        border: `1px solid`,
                                                        borderColor: errors?.about_me && touched.about_me ? 'red' : '#C3CAD9',
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        resize: 'none'
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                color: "rgb(33 36 38 / 65%)",
                                                marginBottom: "8px",
                                                height: '24px',
                                                fontWeight: 500
                                            }}
                                        >
                                            Company Legal Name<span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Enter company legal name"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onChange={(e) => {
                                                    setFieldValue("company_legal_name", e.target.value);
                                                    touched.company_legal_name = true;
                                                }}
                                                error={
                                                    Boolean(errors?.company_legal_name) && touched.company_legal_name
                                                }
                                                helperText={
                                                    touched.company_legal_name ? errors?.company_legal_name : ""
                                                }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={values?.company_legal_name}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#ffffff",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `1px solid`,
                                                        borderColor: errors?.company_legal_name && touched.company_legal_name ? 'red' : '#C3CAD9',
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                color: "rgb(33 36 38 / 65%)",
                                                marginBottom: "8px",
                                                height: '24px',
                                                fontWeight: 500
                                            }}
                                        >
                                            ID Number<span style={{ color: 'red' }}> *</span>
                                        </Typography>
                                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Enter ID number"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onChange={(e) => {
                                                    setFieldValue("id_number", e.target.value);
                                                    touched.id_number = true;
                                                }}
                                                error={
                                                    Boolean(errors?.id_number) && touched.id_number
                                                }
                                                helperText={
                                                    touched.id_number ? errors?.id_number : ""
                                                }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={values.id_number}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#ffffff",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `1px solid`,
                                                        borderColor: errors?.id_number && touched.id_number ? 'red' : '#C3CAD9',
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    fontWeight: 500
                                                }}
                                            >
                                                Commercial Registration Document<span style={{ color: 'red' }}> *</span>
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    paddingLeft: isMobile ? '34px' : '0px',
                                                    fontWeight: 400
                                                }}
                                            >
                                                (Format: PDF) (Max size: 5 MB)
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                            alignItems: 'flex-end',
                                            gap: '10px',
                                            width: '100%',
                                            marginBottom: errors.commercialPdf ? '1px' : 'none'
                                        }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Commercial Registration Document"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onDragOver={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                    const droppedFiles = e.dataTransfer.files?.[0];
                                                    if (droppedFiles) {
                                                        setFieldValue('commercialPdf', droppedFiles);
                                                        touched.commercialPdf = true;
                                                    }
                                                }}
                                                error={
                                                    Boolean(errors?.commercialPdf) && touched.commercialPdf as boolean
                                                }
                                                // helperText={
                                                //     touched.commercialPdf ? errors?.commercialPdf as string : ""
                                                // }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={checkIfFilesAreTooBig(values.commercialPdf) && checkIfFileIsPdfType(values.commercialPdf) ? values?.commercialPdf?.name : ""}
                                                InputProps={{
                                                    startAdornment: !values.commercialPdf?.name
                                                        || values.commercialPdf?.name && !checkIfFilesAreTooBig(values.commercialPdf) || !checkIfFileIsPdfType(values.commercialPdf) ? (
                                                        <InputAdornment position='start'>
                                                            <img src={uploadCloudImg} />
                                                        </InputAdornment>
                                                    ) : null,
                                                    placeholder: !values.commercialPdf?.name
                                                        || values.commercialPdf?.name && !checkIfFilesAreTooBig(values.commercialPdf) || !checkIfFileIsPdfType(values.commercialPdf) ?
                                                        'Browse or drag and drop file to upload' : "",
                                                    endAdornment: pdfFiles.includes(values.commercialPdf?.type) && !errors?.commercialPdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : values.commercialPdf?.name && errors?.commercialPdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : null,
                                                    style: {
                                                        backgroundColor: "#F7F7F7",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `none`,
                                                        width: '100%',
                                                        backgroundImage: errors?.commercialPdf && touched.commercialPdf ? errorLine : normalLine,
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                }}
                                            />
                                            <Button
                                                component="label"
                                                style={{
                                                    fontFamily: 'Inter',
                                                    borderRadius: '8px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    width: isMobile ? '50%' : '22%',
                                                    height: '56px',
                                                    textTransform: 'initial',
                                                    border: `1px solid ${primaryColor}`,
                                                    color: primaryColor,
                                                    textAlign: 'center',
                                                    // marginBottom: !isMobile && errors.commercialPdf ? '22px' : 'none' 
                                                }}
                                            >
                                                Browse file
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files?.[0];
                                                        if (selectedFile) {
                                                            setFieldValue('commercialPdf', selectedFile);
                                                            touched.commercialPdf = true;
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                        <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                            {touched.commercialPdf ? errors?.commercialPdf as string : ""}
                                        </Typography>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    fontWeight: 500
                                                }}
                                            >
                                                Permit Document (Optional)
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    paddingLeft: isMobile ? '34px' : '0px',
                                                    fontWeight: 400
                                                }}
                                            >
                                                (Format: PDF) (Max size: 5 MB)
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                            alignItems: 'flex-end',
                                            gap: '10px',
                                            width: '100%',
                                            marginBottom: errors.permitPdf ? '1px' : 'none'
                                        }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Permit Document"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onDragOver={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                    const droppedFiles = e.dataTransfer.files?.[0];
                                                    if (droppedFiles) {
                                                        setFieldValue('permitPdf', droppedFiles);
                                                        touched.permitPdf = true;
                                                    }
                                                }}
                                                error={
                                                    Boolean(errors?.permitPdf) && touched.permitPdf as boolean
                                                }
                                                // helperText={
                                                //     touched.permitPdf ? errors?.permitPdf as string : ""
                                                // }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={checkIfFilesAreTooBig(values.permitPdf) && checkIfFileIsPdfType(values.permitPdf) ? values?.permitPdf?.name : ""}
                                                InputProps={{
                                                    startAdornment: !values.permitPdf?.name
                                                        || values.permitPdf?.name && !checkIfFilesAreTooBig(values.permitPdf) || !checkIfFileIsPdfType(values.permitPdf)
                                                        ? (
                                                            <InputAdornment position='start'>
                                                                <img src={uploadCloudImg} />
                                                            </InputAdornment>
                                                        ) : null,
                                                    placeholder: !values.permitPdf?.name
                                                        || values.permitPdf?.name && !checkIfFilesAreTooBig(values.permitPdf) || !checkIfFileIsPdfType(values.permitPdf)
                                                        ? 'Browse or drag and drop file to upload' : "",
                                                    endAdornment: values.permitPdf?.name && !errors?.permitPdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : values.permitPdf?.name && errors?.permitPdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : null,
                                                    style: {
                                                        backgroundColor: "#F7F7F7",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `none`,
                                                        width: '100%',
                                                        backgroundImage: errors?.permitPdf && touched.permitPdf ? errorLine : normalLine,
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                }}
                                            />
                                            <Button
                                                component="label"
                                                style={{
                                                    fontFamily: 'Inter',
                                                    borderRadius: '8px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    width: isMobile ? '50%' : '22%',
                                                    height: '56px',
                                                    textTransform: 'initial',
                                                    border: `1px solid ${primaryColor}`,
                                                    color: primaryColor,
                                                    textAlign: 'center',
                                                    // marginBottom: !isMobile && errors.permitPdf ? '22px' : 'none' 
                                                }}
                                            >
                                                Browse file
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files?.[0];
                                                        if (selectedFile) {
                                                            setFieldValue('permitPdf', selectedFile);
                                                            touched.permitPdf = true;
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                        <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                            {touched.permitPdf ? errors?.permitPdf as string : ""}
                                        </Typography>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Box style={{ display: 'flex', justifyContent: 'space-between', width: isMobile ? '100%' : '80.4%' }}>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "16px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    fontWeight: 500
                                                }}
                                            >
                                                Certificate File (Optional)
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontFamily: "Inter",
                                                    fontSize: "14px",
                                                    color: "rgb(33 36 38 / 65%)",
                                                    marginBottom: "8px",
                                                    flex: isMobile ? 1 : 'initial',
                                                    paddingLeft: isMobile ? '34px' : '0px',
                                                    fontWeight: 400
                                                }}
                                            >
                                                (Format: PDF) (Max size: 5 MB)
                                            </Typography>
                                        </Box>
                                        <Box style={{
                                            display: 'flex',
                                            flexDirection: isMobile ? 'column' : 'row',
                                            justifyContent: isMobile ? 'flex-start' : 'flex-end',
                                            alignItems: 'flex-end',
                                            gap: '10px',
                                            width: '100%',
                                            marginBottom: errors.certificatePdf ? '1px' : 'none'
                                        }}>
                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Certificate File"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onDragOver={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                }}
                                                onDrop={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation();
                                                    const droppedFiles = e.dataTransfer.files?.[0];
                                                    if (droppedFiles) {
                                                        setFieldValue('certificatePdf', droppedFiles);
                                                        touched.certificatePdf = true;
                                                    }
                                                }}
                                                error={
                                                    Boolean(errors?.certificatePdf) && touched.certificatePdf as boolean
                                                }
                                                // helperText={
                                                //     touched.certificatePdf ? errors?.certificatePdf as string : ""
                                                // }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={checkIfFilesAreTooBig(values.certificatePdf) && checkIfFileIsPdfType(values.certificatePdf) ? values?.certificatePdf?.name : ""}
                                                InputProps={{
                                                    startAdornment: !values.certificatePdf?.name
                                                        || values.certificatePdf?.name && !checkIfFilesAreTooBig(values.certificatePdf) || !checkIfFileIsPdfType(values.certificatePdf) ? (
                                                        <InputAdornment position='start'>
                                                            <img src={uploadCloudImg} />
                                                        </InputAdornment>
                                                    ) : null,
                                                    placeholder: !values.certificatePdf?.name
                                                        || values.certificatePdf?.name && !checkIfFilesAreTooBig(values.certificatePdf) || !checkIfFileIsPdfType(values.certificatePdf)
                                                        ? 'Browse or drag and drop file to upload' : "",
                                                    endAdornment: values.certificatePdf?.name && !errors?.certificatePdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <Check style={{ color: '#6F964C', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#6F964C', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Uploaded successfully</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : values.certificatePdf?.name && errors?.certificatePdf ? (
                                                        <InputAdornment position='end'>
                                                            <div style={{ display: 'flex', gap: '5px' }}>
                                                                <ErrorOutline style={{ color: '#FF0000', fontSize: '20px' }} />
                                                                <Typography style={{ color: '#FF0000', fontSize: '14px', fontFamily: "Inter", fontWeight: 400 }}>Upload failed</Typography>
                                                            </div>
                                                        </InputAdornment>
                                                    ) : null,
                                                    style: {
                                                        backgroundColor: "#F7F7F7",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `none`,
                                                        width: '100%',
                                                        backgroundImage: errors?.certificatePdf && touched.certificatePdf ? errorLine : normalLine,
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                    readOnly: true,
                                                }}
                                            />
                                            <Button
                                                component="label"
                                                style={{
                                                    fontFamily: 'Inter',
                                                    borderRadius: '8px',
                                                    fontSize: '16px',
                                                    fontWeight: 500,
                                                    width: isMobile ? '50%' : '22%',
                                                    height: '56px',
                                                    textTransform: 'initial',
                                                    border: `1px solid ${primaryColor}`,
                                                    color: primaryColor,
                                                    textAlign: 'center',
                                                    // marginBottom: !isMobile && errors.certificatePdf ? '22px' : 'none' 
                                                }}
                                            >
                                                Browse file
                                                <input
                                                    type="file"
                                                    hidden
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files?.[0];
                                                        if (selectedFile) {
                                                            setFieldValue('certificatePdf', selectedFile);
                                                            touched.certificatePdf = true;
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                        <Typography variant="caption" color="error" style={{ fontFamily: "Inter", fontWeight: 400, marginTop: '3px' }}>
                                            {touched.certificatePdf ? errors?.certificatePdf as string : ""}
                                        </Typography>
                                    </Box>
                                    <Box style={{ marginTop: '32px' }}>
                                        <Typography
                                            style={{
                                                fontFamily: "Inter",
                                                fontSize: "16px",
                                                color: "rgb(33 36 38 / 65%)",
                                                marginBottom: "8px",
                                                height: '24px',
                                                fontWeight: 500
                                            }}
                                        >
                                            VAT Number (Optional)
                                        </Typography>
                                        <Box style={{ display: 'flex', gap: '10px', width: '100%' }}>

                                            <TextField
                                                style={{ width: "100%" }}
                                                placeholder="Enter VAT number"
                                                variant="standard"
                                                name="firstName"
                                                autoComplete="off"
                                                className="email_input"
                                                onChange={(e) => {
                                                    setFieldValue("vat_number", e.target.value);
                                                    touched.vat_number = true;
                                                }}
                                                error={
                                                    Boolean(errors?.vat_number) && touched.vat_number
                                                }
                                                helperText={
                                                    touched.vat_number ? errors?.vat_number : ""
                                                }
                                                FormHelperTextProps={{
                                                    className: classes.customFont,
                                                }}
                                                value={values.vat_number}
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: "#ffffff",
                                                        color: "rgba(33, 36, 38, 0.90)",
                                                        padding: "16px 20px",
                                                        height: '56px',
                                                        border: `1px solid`,
                                                        borderColor: errors?.vat_number && touched.vat_number ? 'red' : '#C3CAD9',
                                                        borderRadius: '8px',
                                                        fontFamily: "Inter",
                                                        fontSize: '16px',
                                                        fontWeight: 500
                                                    },
                                                    disableUnderline: true,
                                                }}
                                            />
                                        </Box>
                                    </Box>

                                    <Box style={{ marginTop: '48px', display: 'flex', justifyContent: isMobile ? 'center' : 'flex-start' }}>
                                        {
                                            !isEditClicked ? <Button
                                                style={{
                                                    backgroundColor: primaryColor,
                                                    color: '#ffffff',
                                                    textTransform: 'initial',
                                                    borderRadius: '34px',
                                                    padding: '10px 50px',
                                                    fontSize: '16px',
                                                    fontFamily: "Inter",
                                                    fontWeight: 500,
                                                    width: '182px',
                                                    height: '56px',
                                                }}
                                                type="submit"
                                            >
                                                Next
                                            </Button> :
                                                <Box sx={{ display: 'flex', gridGap: '20px' }}>
                                                    <Button
                                                        style={{
                                                            backgroundColor: primaryColor,
                                                            color: '#ffffff',
                                                            textTransform: 'initial',
                                                            borderRadius: '34px',
                                                            padding: '10px 60px',
                                                            fontSize: '16px',
                                                            fontFamily: "Inter",
                                                            fontWeight: 500,
                                                            width: '182px',
                                                            height: '56px',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                        type="submit"
                                                    >
                                                        Save 
                                                    </Button>
                                                  
                                                </Box>
                                        }
                                    </Box>
                                </Box>
                            }
                        </Form>

                    )
                }}
            </Formik>
        </div>
    )
}

export default EntityDetails;
