import React from "react";

// Customizable Area Start
import {
 Avatar, Card, TextField,
  Box, List, Divider, ListItemAvatar,
  Typography, ListItem, ListItemText
} from "@material-ui/core";
import { ThemeProvider, withStyles, Theme } from "@material-ui/core/styles";
import { arrowRightCircle } from "./assets";
import { muiTheme } from "../../ordermanagement/src/MyBookings.web";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


// Customizable Area End

import ChatController, { configJSON, Props } from "./ChatController";


export default class ChatCustomer extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  


  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { chatList, activeTab, receiverInfo, chatAllMessage , isMobile } = this.state
    return (
      <ThemeProvider theme={muiTheme}>
            <Box className={classes.container} style={{ minHeight: 'fit-content' }}>
                <Breadcrumbs list={[
                    {
                        title: 'Home',
                        link: '/LandingPage'
                    },
                    {
                        title: 'My Chats'
                    }
                ]} />
                <Divider  className={classes.divider} />
                <Box className={classes.subContainer}>
                    <Typography className={classes.myChatText}>
                        My Chats
                    </Typography>
                    <Typography  className= {classes.myChathead}>
                        {configJSON.myChatsSubText}
                    </Typography>
                    <Box className= { classes.ChatBoxcontainer}>
                 
                       { (!isMobile || !activeTab ) &&  <Card data-testid="chatList"
                         className={classes.chatCard}>
                            <List className= {classes.list}> 
                                {chatList.map((chat, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {index !== 0 && <Divider style={{ margin: '24px 0' }} />}
                                            <ListItem key={index}
                                              button
                                             className={classes.chatContainer}
                                             onClick={this.handleNavigationCallBack(chat)}
                                             >
                                                <ListItemAvatar>
                                                    <Avatar src={chat.userPhoto} alt={chat.userName} classes={{ root: classes.avatarImg, img: classes.avatarImg }} />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={<>
                                                        <Typography className={classes.username}>{chat.userName}</Typography>
                                                        <Typography className={classes.userMsgTime}>{chat.lastMessageTime}</Typography>
                                                    </>}
                                                    secondary={<>
                                                        <Typography className={`${classes.lastMessage} ${chat.read ? '' : 'isUnread'}`}>
                                                            {chat.lastMessage}</Typography>
                                                        {chat.read && <span className={classes.unreadCountText}>{1}</span>}
                                                    </>}
                                                    classes={{ multiline: classes.chatInfo }}
                                                    primaryTypographyProps={{ display: undefined, classes: { root: classes.primaryRoot } }}
                                                    secondaryTypographyProps={{ display: undefined, noWrap: true, classes: { root: classes.secondaryRoot } }}
                                                />
                                            </ListItem>
                                        </React.Fragment>
                                    )
                                })}
                            </List>
                        </Card>}

                     {  ( !isMobile || activeTab )&&  <Card data-testid="chatList"
                        className= {classes.chatmessageCard}  >
                            <Box style={{
                                display: 'flex',
                                background: '#FFF',
                                padding: '24px 48px',
                                borderBottom: '1px solid rgba(33, 36, 38, 0.10)',
                                gap: '16px'
                            }} >
                              {isMobile && <span onClick={this.handleChatBackIcon} className={classes.backIcon} data-testid='backIcon'><ArrowBackIcon/></span> }
                                {/* @ts-ignore */}
                                <Avatar src={receiverInfo.userPhoto} alt={'userName'} styles={{ img: styles.avatarImg, root: styles.avatarImg }} />
                                <Box display={'flex'} gridGap={'6px'} flexDirection={'column'}>
                                    <Typography className={classes.ActiveUserName}>
                                        {/* @ts-ignore */}
                                        {receiverInfo.userName}
                                    </Typography>
                                    <Typography className={classes.activeStatus}>
                                        Active now
                                    </Typography>
                                </Box>
                            </Box>

                            <div className={classes.msgBoxScroll}>
                            <div className={classes.chatMsgContainer}>
                                {Object.keys(chatAllMessage).length > 0 &&
                                Object.keys(chatAllMessage).map((dateKey) => {
                                  const filteredItem = chatAllMessage[dateKey]
                                    const item = filteredItem.messages;

                                  return (
                                    <React.Fragment key={dateKey}>
                                      <Typography className={classes.messageDate}>
                                        {filteredItem.date}
                                      </Typography>
                                      {
                                        item.map((msg, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className={`${classes.messageContentFC} ${msg.from === 'receiver' ? 'receiver' : 'sender'}`}
                                            >
                                              <div className={`${msg.from === 'receiver' ? classes.receiver : classes.sender} ${classes.messageStyle}`}>
                                                {msg.message}
                                              </div>
                                              <div className={classes.timeStyle} style={{ textAlign: msg.from === 'sender' ? 'end' : 'start' }}>
                                                {msg.time}
                                              </div>
                                            </div>
                                          )
                                        })
                                      }
                                    </React.Fragment>
                                  )
                                })}
                            </div>
                            </div>
                            <Box className={classes.chatFooterFC}>
                                <TextField
                                    fullWidth
                                    placeholder="Write a message..."
                                    type="text"
                                    data-test-id={'writeMessageInput'}
                                    name="message"
                                    variant="standard"
                                    value={this.state.sendMessageData}
                                    onChange={(event) => this.setState({ sendMessageData: event.target.value })}
                                    onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            this.sendChatMessage(activeTab);
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <img 
                                            style={{ cursor: 'pointer' }} 
                                            data-test-id={'sumbitIconBtn'} 
                                            onClick={() => this.sendChatMessage(activeTab)} 
                                            src={arrowRightCircle} alt='submit' 
                                            />
                                        ),
                                        classes: {
                                            input: classes.input,
                                            root: classes.inputRoot
                                        },
                                        disableUnderline: true
                                    }}
                                />
                            </Box>
                        </Card> }


                    </Box>
                </Box>
            </Box>  
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
 
  modalContainer: {
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "row",
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    minWidth: 300,    
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    transform: "translate(-50%, -50%)",
  },
  modalButtonView: {
    marginLeft: 10,
  },
  listItemContainer: {
    width: 300,
    border: "2px solid #ccc",
    borderRadius: 10,
    padding: 15,
    marginBottom: 20,
  },
  mutedChat: {
    backgroundColor: "lightgray"
  },
};

const styles = (theme: Theme) => ({
  username: {
    flex: '1 0 0',
    fontFamily: 'Inter',
    color: '#212426',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '150%',
  },
  avatarImg: {
    width: '54px',
    height: '54px',
  },
  lastMessage: {
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
    flex: '1 0 0',
    '&.isUnread': {
      fontWeight: 500,
      color: 'rgba(33, 36, 38, 0.50)'
    }
  },
  userMsgTime: {
    color: 'rgba(33, 36, 38, 0.40)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '150%',
  },
  chatContainer: {
    display: 'flex',
    gap: '16px',
    cursor: 'pointer'
  },
 
  chatInfo: {
    gap: '6px',
    display: 'flex',
    flexDirection: 'column' as const,
    margin: '0',
  },
  primaryRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '12px'
  },
  unreadCountText: {
    backgroundColor: '#FE7F2D',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    color: '#FFF',
    lineHeight: 1,
    padding: '3px 6px',
  },
  ActiveUserName: {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    color: '#212426',
  },
  secondaryRoot: {
    display: 'flex'
  },
  activeStatus: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    color: 'rgba(33, 36, 38, 0.75)',
  },
  chatFooterFC: {
    background: '#FFF',
    display: 'inline-flex',
    alignItems: 'flex-start',
    height: '120px',
    justifyContent: 'center',
    padding: '24px 48px 56px 48px',
    borderTop: '1px solid rgba(33, 36, 38, 0.10)',
    gap: '491px',
  },
  msgBoxScroll: {
    margin: '0',
    overflowY: 'scroll' as const,
    padding: '0 20px',
    gap: '4px',
    height: '500px'
  },
  chatMsgContainer: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column' as const,
    justifyContent: 'end',
  },
  messageStyle: {
    background: '#FFF',
    display: 'inline-flex',
    flexDirection: 'column' as const,
    border: '0.5px solid rgba(33, 36, 38, 0.10)',
    wordWrap: 'break-word' as const,
    color: '#212426',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '150%',
    gap: '8px',
    padding: '12px 16px',
  },
  receiver: {
    borderRadius: '0px 16px 16px 16px',
  },
  sender: {
    borderRadius: '16px 0px 16px 16px',
  },
  timeStyle: {
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '100%',
    color: 'rgba(33, 36, 38, 0.65)',
  },
  messageContentFC: {
    display: 'flex',
    flexDirection: 'column' as const,
    maxWidth: '47%',
    '&.sender': {
      alignSelf: 'flex-end'
    },
    '&.receiver': {
      alignSelf: 'flex-start'
    },
    marginTop: '8px',
    gap: '8px',
    [theme.breakpoints.down(768)] : {
      maxWidth : '90%'
    }
  },
  messageDate: {
    fontSize: '14px',
    lineHeight: 'normal',
    margin: '0 auto 16px',
    color: 'rgba(33, 36, 38, 0.75)',
  },
  input: {
    padding: 0
  },
  inputRoot: {
    alignItems: 'flex-start'
  },
  container: {
    margin: '49px auto 120px',
    maxWidth: '90%',
    width: '100%' ,
    [theme.breakpoints.down(768)] : {
      marginTop : '20px',
      marginBottom : '15px'
    }
  },
  subContainer: {
    margin: '69px auto 140px',
    maxWidth: '90%',
    width: '100%',
    [theme.breakpoints.down(1024)]:{
      margin: '69px auto 0px',
    },
    [theme.breakpoints.down(768)]:{
      marginTop: '-24px',
      width : '100%'
    }
  },
  chatCard : {
    borderRadius: '20px',
    boxShadow: 'none',
    background: '#FFF',
    padding: '32px 48px',
    width: '39.08%',
    border: '1px solid rgba(33, 36, 38, 0.1)',
    height: '817px',
    overflowY: 'scroll' as const,
    [theme.breakpoints.down(1024)] : {
      width: '80.08%',
    },
    [theme.breakpoints.down(768)] : {
      width: '100%',
      height : 'auto',
      padding : '0 0',
      overflowX : 'scroll'
    }
  },
  chatmessageCard : {
    borderRadius: '20px',
    background: '#FCFAF7',
    boxShadow: 'none',
    height: '817px',
    width: '766px',
    display: 'flex',
    flexDirection: 'column' as const,
    border: '1px solid rgba(33, 36, 38, 0.1)',
    gap: '32px',
    [theme.breakpoints.down(768)] : {
      width : '100%'
    }
  },

  ChatBoxcontainer : {
   display  :'flex',
   gap :'24px',
   [theme.breakpoints.down(768)] : {
    flexDirection : 'column'
   }
  },
  myChatText : {
    fontWeight: 600,
    fontSize: '32px',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
    marginBottom: '8px',
    [theme.breakpoints.down(768)] : {
      fontSize : '20px'
    }
  },
 divider : {
  margin : '48px 0' ,
 [theme.breakpoints.down(768)] : {
  margin : '35px 0'
 }
  },
  backIcon : {
    margin : '5px 0px 0px -30px' ,
    cursor : 'pointer'
  },
  myChathead : {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 'normal',
    letterSpacing: '0.1px',
    marginBottom: '32px',
    [theme.breakpoints.down(768)] : {
      fontSize: '13px',
    }

  }
})

const ChatCustomerWeb = withStyles(styles)(ChatCustomer)
export { ChatCustomerWeb }
// Customizable Area End
