// CustomToolbar.tsx
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { leftArrowIcon, rightArrowIcon } from "../../blocks/ProductDescription/src/assets";
import { useWindowWidth } from "./utils";

interface CustomToolbarProps {
    label: string;
    onNavigate: (action: string) => void;
}

const useStyles = makeStyles((theme) => ({
    todayStyle: {
        color: 'rgba(33, 36, 38, 0.90)',
        fontFamily: 'Inter',
        fontSize: '24px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal'
    },
    buttonStyle: {
        border: 'none',
        backgroundColor: '#F4F4F5',
        padding: '4.571px',
        borderRadius: '8px',
        margin: '0 9.143px',
        width: '18.8px',
        cursor: 'pointer'
    },
    mainContainer: {
        marginBottom: '25px'
    },
    '@media (max-width: 767px)': {
        todayStyle: {
            fontSize: '18px'
        }
    }
}));


const CustomToolbar: React.FC<CustomToolbarProps> = ({
    label,
    onNavigate,
}) => {
    const classes = useStyles();
    const inputDate = new Date(label);
    const windowWidth = useWindowWidth();

    const isMobile = windowWidth < 768;

    const monthYearString = inputDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
    });

    const formattedDate = `${monthYearString.slice(0, 3)}, ${monthYearString.slice(4)}`;


    const handleNavigate = (action: string) => {
        onNavigate(action);
    };


    return (
        <div className={classes.mainContainer}>
            {/* <button onClick={() => handleNavigate("TODAY")} className={classes.todayStyle}>{formattedDate}</button> */}
            {
                isMobile ?
                    <div style={{textAlign: 'center'}}>
                        <button onClick={() => handleNavigate("PREV")} className={classes.buttonStyle}><img src={leftArrowIcon} alt="<" style={{ height: '100%', width: '100%' }} /></button>
                        <span className={classes.todayStyle}>{formattedDate}</span>
                        <button onClick={() => handleNavigate("NEXT")} className={classes.buttonStyle}><img src={rightArrowIcon} alt=">" style={{ height: '100%', width: '100%' }} /></button>

                    </div>
                    :
                    <div>
                        <span className={classes.todayStyle}>{formattedDate}</span>

                        <button onClick={() => handleNavigate("PREV")} className={classes.buttonStyle}><img src={leftArrowIcon} alt="<" style={{ height: '100%', width: '100%' }} /></button>
                        <button onClick={() => handleNavigate("NEXT")} className={classes.buttonStyle}><img src={rightArrowIcon} alt=">" style={{ height: '100%', width: '100%' }} /></button>
                    </div>
            }
        </div>
    );
};

export default CustomToolbar;
