import React from 'react';
import {
    Box,
    Typography,
    makeStyles,
    Modal
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    successIcon: {
        color: theme.palette.success.main,
        fontSize: 32,
    },
    errorIcon: {
        color: theme.palette.error.main,
        fontSize: 32,
    },
    paperResponseMsg: {
        position: 'absolute',
        width: 530,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: '16px 20px',
        borderRadius: '16px',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
        paperResponseMsg: {
            position: 'absolute',
            width: 370,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: '16px 20px',
            borderRadius: '16px',
            top: '25%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
        },
    }
}));

interface ResponseStatusModalProps {
    open: boolean;
    onClose: () => void;
    isSuccess: boolean;
    message: string;
}

const ResponseStatusModal: React.FC<ResponseStatusModalProps> = ({ open, onClose, isSuccess, message }) => {
    const classes = useStyles();

    return (
        <Modal open={open} onClose={onClose}>
            <div className={classes.paperResponseMsg}>
                <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12
                }}>
                    {isSuccess ? (
                        <CheckCircleIcon className={classes.successIcon} />
                    ) : (
                        <CancelIcon className={classes.errorIcon} />
                    )}
                    <Typography style={{
                        fontFamily: 'Inter',
                        fontSize: '16px',
                        fontWeight: 500,
                        color: '#212426'
                    }}>{message}</Typography>
                </Box>
            </div>
        </Modal>
    );
};

export default ResponseStatusModal;
