import React from "react";

// Customizable Area Start
import {
    Box, Divider, Typography
} from "@material-ui/core";
import {
    withStyles, Theme, ThemeProvider, createTheme
} from "@material-ui/core/styles";
import Breadcrumbs from "../../../components/src/Breadcrumbs";
import Loader from "../../../components/src/Loader.web";

const themeTC = createTheme({
    overrides: {
        MuiTypography: {
            gutterBottom: {
                marginBottom: '8px'
            }
        },
    },
    typography: {
        body1: {
            fontFamily: 'Inter',
            fontStyle: "normal",
            color: '#212426',
            fontWeight: 400,
            fontSize: '16px',
            letterSpacing: 0,
            lineHeight: '150%'
        }
    }
});


// Customizable Area End

import ContentManagementController, {
    Props
} from "./ContentManagementController";


export default class TermsAndConditions extends ContentManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const {
            loader,
           termsAndConditionData
        } = this.state;
        
        // Customizable Area End
        return (
            // Customizable Area Start
            <ThemeProvider theme={themeTC}>
                {loader ? (
                    <Box style={{ minHeight: '60vh' }}>
                        <Loader loading={loader} />
                    </Box>
            ) : (
                <Box className={classes.container}>
                    <Breadcrumbs
                        list={[
                            {
                                title: 'Home',
                                link: '/LandingPage'
                            },
                            {
                                title: `Terms and conditions`,
                            },
                        ]}
                    />
                    <Divider style={{ margin: '48px 0' }} />
                    <Box className={classes.innerContainer}>
                        <Typography className={classes.heading}>
                            Terms and Conditions
                        </Typography>
                        {termsAndConditionData.length > 0 ? (
                            termsAndConditionData.map((value, i) => (
                                <Box key={value.id}>
                                    <Typography>{value.title}</Typography>
                                    <Box dangerouslySetInnerHTML={{ __html: value.content }} />
                                </Box>
                            ))
                        ) : (
                            <Typography className={classes.description}>No data found</Typography>
                        )}
                    </Box>
                </Box>
            )}

            </ThemeProvider >
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const styles = (theme: Theme) => ({
    container: {
        margin: '49px auto 120px',
        padding: '0 26px 0 38px',
        width: '100%'
    },
    innerContainer: {
        width: '75.9%',
        margin: '0 auto',
    },
    heading: {
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '120%',
        marginBottom: '39px'
    },
    title: {
        fontSize: '28px',
        fontWeight: 600,
        lineHeight: '130%',
        opacity: 0.9
    },
    description: {
        fontSize: '16px',
        height: '25vh',
        fontWeight: 400,
        lineHeight: '160%',
        opacity: 0.8,
        textAlign: 'center' as const,
    },
})

const TermsAndConditionsWeb = withStyles(styles)(TermsAndConditions)
export { TermsAndConditionsWeb }
// Customizable Area End
