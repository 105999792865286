import React, { useState } from "react";
import {
    TextField,
    Box,
    Typography,
    useMediaQuery,
    makeStyles,
    IconButton,
    Popover,
} from "@material-ui/core";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import DatePicker from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker"
import { calendarImg } from "../../blocks/email-account-registration/src/assets";
import { CustomButton } from "./MultiSelectCalender";
import { useWindowWidth } from "./utils";


interface SingleDatePickerProps {
    fieldTitle: string;
    fieldName: string;
    optional?: boolean;
    selectedDate: Date | string;
    startDate: Date | string;
    onChange: (fieldName: string, date: string) => void;
    error: string;
    touched: boolean;
    placeholder: string;
}

const useStyles = makeStyles((theme) => ({
    selected: {
        backgroundColor: '#FE7F2D',
        color: '#ffffff'
    },
    main: {
        backgroundColor: '#FFF6ED',
        border: '1px solid #FE7F2D',
        borderRadius: '13px'
    },
    datePicker: {
        borderRadius: '8px',
        height: '56px',
        padding: '0',
        "& .MuiInputBase-root": {
            padding: '0',
        },
        "& .MuiInputBase-input": {
            padding: '18px', // Adjust this value for text vertical alignment
        },
    },
    calendarIcon: {
        marginRight: '-10px',
        cursor: "pointer",
    },
    calendarPopover: {
        padding: theme.spacing(2),
    },
    customFont: {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: 400,
        '&.MuiFormHelperText-root.Mui-error': {
            color: '#FF0000',
          }
    },
    [theme.breakpoints.down(768)]: {
        datePicker: {
            borderRadius: '8px',
            height: '48px',
            padding: '0',
            "& .MuiInputBase-root": {
                padding: '0',
            },
            "& .MuiInputBase-input": {
                padding: '18px', // Adjust this value for text vertical alignment
            },
        },
    }
}));

const SingleDatePicker: React.FC<SingleDatePickerProps> = ({
    fieldTitle,
    fieldName,
    optional = false,
    selectedDate,
    startDate,
    onChange,
    error,
    touched,
    placeholder
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const windowWidth = useWindowWidth()
    // const isTab = windowWidth < 1024;
    const isMobile = windowWidth < 768;
    const today = new Date().toISOString().split('T')[0].replace(/-/g, '/');
    // const formattedDate = customDateFormatter(selectedDate);

    const handleCalendarClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCalendarClose = () => {
        setAnchorEl(null);
    };

    const handleDateChange = (date: any) => { 
        if (fieldName === "end_date" && date < startDate) {
            // If the selected date for "end_date" is less than "startDate", don't update "end_date".
            return;
        }
        onChange(fieldName, date);
        handleCalendarClose();
    };

    const customDateFormatter = (): any => {
        if(!selectedDate){
            return <span style={{color: 'rgba(33, 36, 38, 0.50)'}}>{placeholder}</span>
        }
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate: string = new Date(selectedDate)?.toLocaleDateString('en-US', options);

        const [month, dayComma, year] = formattedDate.split(' ');

        const day = dayComma.replace(/,/g, '');


        let suffix = 'th'; // Default to 'th'
        if (day === '1' || day === '21' || day === '31') {
            suffix = 'st';
        } else if (day === '2' || day === '22') {
            suffix = 'nd';
        } else if (day === '3' || day === '23') {
            suffix = 'rd';
        }

        return (
            <span style={{ color: 'rgba(33, 36, 38, 0.90' }}>
                {day.replace(/,$/, '')}
                <sup>{suffix}</sup> {month} {year}
            </span>
        );
      };  

    return (
        <div>
            <Typography
                style={{
                    fontFamily: 'Inter',
                    fontSize: isMobile ? '14px' : '16px',
                    color: 'rgba(33, 36, 38, 0.90)',
                    marginBottom: '16px',
                    height: '24px',
                    fontWeight: 500,
                }}
            >
                {fieldTitle} {optional ? <span style={{ fontWeight: 400 }}>(Optional)</span> : <span style={{ color: 'red' }}> *</span>}
            </Typography>
            <TextField
                style={{ width: '100%' }}
                placeholder={placeholder}
                variant="standard"
                autoComplete="off"
                className={classes.datePicker}
                // value={selectedDate}
                onClick={handleCalendarClick}
                error={Boolean(error) && touched} // Show error if touched is true
                helperText={touched ? error : ''} // Show error message if touched is true
                FormHelperTextProps={{
                    className: classes.customFont,
                }}
                InputProps={{
                    endAdornment: (
                        <IconButton
                            className={classes.calendarIcon}
                            onClick={handleCalendarClick}
                        >
                            <img src={calendarImg} style={{ height: '100%', width: '100%' }} alt="" />
                        </IconButton>
                    ),
                    style: {
                        backgroundColor: '#ffffff',
                        color: 'rgba(33, 36, 38, 0.90)',
                        padding: '16px 20px',
                        height: isMobile ? '48px' : '56px',
                        border: `1px solid`,
                        borderColor: error && touched ? 'red' : '#C3CAD9', // Conditionally set border color
                        borderRadius: '8px',
                        fontFamily: 'Inter',
                        fontSize: isMobile ? '14px' : '16px',
                        fontWeight: 500,
                        justifyContent: 'space-between'
                    },
                    inputComponent: customDateFormatter,
                    disableUnderline: true,
                }}
            />
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCalendarClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Calendar
                    showOtherDays
                    monthYearSeparator=" "
                    highlightToday={false}
                    value={selectedDate}
                    onChange={handleDateChange}
                    className={classes.main}
                    renderButton={<CustomButton />} 
                    minDate={today}
                />
            </Popover>
        </div>
    );
}

export default SingleDatePicker;
