import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid,
    Box,
    Divider,
    makeStyles,
    createTheme, ThemeProvider
} from '@material-ui/core';
import { primaryColor } from './theme';
import CustomTextField from './CustomTextField';
import { IStep4CET } from '../../blocks/ProductDescription/src/Interface.web';
import { FormikErrors, FormikTouched } from 'formik';
import SingleDatePicker from './SingleDatePicker';


interface CustomCheckboxGroupProps {
    values: IStep4CET;
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<IStep4CET>;
    touched: FormikTouched<IStep4CET>;
}


// Create a custom theme
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1024, // Change the default md width to 1024
            lg: 1280,
            xl: 1920,
        },
    },
});



const CustomApplyDiscount: React.FC<CustomCheckboxGroupProps> = ({
    values,
    setFieldValue,
    errors,
    touched
}) => {
    const useStyles = makeStyles((theme) => ({
        labelText: {
            fontFamily: 'Inter',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '150%',
            color: 'rgba(33, 36, 38, 0.90)'
        },
        [theme.breakpoints.down(1024)]: {
            labelText: {
                fontFamily: 'Inter',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '150%',
                color: 'rgba(33, 36, 38, 0.90)'
            },
        },
        [theme.breakpoints.down(768)]: {
            labelText: {
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '150%',
                color: 'rgba(33, 36, 38, 0.90)'
            },
        }
    }));

    const classes = useStyles();

    const handleCheckboxChange = (value: string) => {

        setFieldValue('apply_discount', value); // Send the updated array of selected values
    };

    const toggleCheckbox = (currentValue: string) => {
        handleCheckboxChange(currentValue === values.apply_discount ? '' : currentValue);
    };

    return (
        <ThemeProvider theme={theme}>
            <FormControl component="fieldset" fullWidth>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'rgba(33, 36, 38, 0.90)' }}
                        key={'1'}
                        control={
                            <Checkbox
                                size='small'
                                style={{
                                    color: values.apply_discount === 'define_discount_percent' ? primaryColor : '#212426',
                                }}
                                checked={values.apply_discount === 'define_discount_percent'}
                                onChange={() => toggleCheckbox('define_discount_percent')}
                            />
                        }
                        label={
                            <Typography
                                className={classes.labelText}
                            >
                                Define discount percent (%)
                            </Typography>
                        }
                    />
                </div>
                {
                    values.apply_discount === 'define_discount_percent' && (
                        <Box style={{
                            marginBottom: '48px',
                            marginTop: '25px'
                        }}>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Select Discount (%)'}
                                        placeholder={'Enter value'}
                                        type="number"
                                        value={values.select_discount}
                                        fieldName="select_discount"
                                        onChange={setFieldValue}
                                        error={errors.select_discount as string}
                                        touched={touched.select_discount as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ marginBottom: '48px' }}>
                                <Grid item md={6} xs={12}>
                                    <SingleDatePicker
                                        fieldTitle={'Start date'}
                                        fieldName={'start_date'}
                                        selectedDate={values.start_date}
                                        startDate={values.start_date}
                                        onChange={setFieldValue}
                                        error={errors.start_date as string}
                                        touched={touched.start_date as boolean}
                                        placeholder={'Start date'}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <SingleDatePicker
                                        fieldTitle={'End date'}
                                        fieldName={'end_date'}
                                        selectedDate={values.end_date}
                                        startDate={values.start_date}
                                        onChange={setFieldValue}
                                        error={errors.end_date as string}
                                        touched={touched.end_date as boolean}
                                        placeholder={'End date'}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    )
                }
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'rgba(33, 36, 38, 0.90)' }}
                        key={'1'}
                        control={
                            <Checkbox
                                size='small'
                                style={{
                                    color: values.apply_discount === 'based_on_purchase_quantity' ? primaryColor : '#212426',
                                }}
                                checked={values.apply_discount === 'based_on_purchase_quantity'}
                                onChange={() => toggleCheckbox('based_on_purchase_quantity')}
                            />
                        }
                        label={
                            <Typography
                                className={classes.labelText}
                            >
                                Based on purchase quantity
                            </Typography>
                        }
                    />
                </div>

                {
                    values.apply_discount === 'based_on_purchase_quantity' && (
                        <Box style={{
                            marginBottom: '48px',
                            marginTop: '25px'
                        }}>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Title'}
                                        placeholder={'Write something...'}
                                        value={values.title}
                                        fieldName="title"
                                        onChange={setFieldValue}
                                        error={errors.title as string}
                                        touched={touched.title as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Description'}
                                        placeholder={'Write something...'}
                                        value={values.description}
                                        fieldName="description"
                                        onChange={setFieldValue}
                                        error={errors.description as string}
                                        touched={touched.description as boolean}
                                        optional={true}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Quantity'}
                                        placeholder={'Write something...'}
                                        type='number'
                                        value={values.quantity}
                                        fieldName="quantity"
                                        onChange={setFieldValue}
                                        error={errors.quantity as string}
                                        touched={touched.quantity as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3} style={{ marginBottom: '48px' }}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Discounted price per person'}
                                        placeholder={''}
                                        type='number'
                                        endAdorment={values.currency}
                                        value={values.discounted_price_per_person_purchase_quantity}
                                        fieldName="discounted_price_per_person_purchase_quantity"
                                        onChange={setFieldValue}
                                        error={errors.discounted_price_per_person_purchase_quantity as string}
                                        touched={touched.discounted_price_per_person_purchase_quantity as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                            <Divider />
                        </Box>
                    )
                }

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel
                        style={{ fontFamily: 'Inter', fontSize: '18px', fontWeight: 500, lineHeight: '150%', color: 'rgba(33, 36, 38, 0.90)' }}
                        key={'1'}
                        control={
                            <Checkbox
                                size='small'
                                style={{
                                    color: values.apply_discount === 'early_payment_price' ? primaryColor : '#212426',
                                }}
                                checked={values.apply_discount === 'early_payment_price'}
                                onChange={() => toggleCheckbox('early_payment_price')}
                            />
                        }
                        label={
                            <Typography
                                className={classes.labelText}
                            >
                                Early payment price
                            </Typography>
                        }
                    />
                </div>

                {
                    values.apply_discount === 'early_payment_price' && (
                        <Box style={{ marginTop: '25px', marginBottom: '7px' }}>
                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'Discounted price per person'}
                                        placeholder={''}
                                        type='number'
                                        endAdorment={values.currency}
                                        value={values.discounted_price_per_person_early_payment}
                                        fieldName="discounted_price_per_person_early_payment"
                                        onChange={setFieldValue}
                                        error={errors.discounted_price_per_person_early_payment as string}
                                        touched={touched.discounted_price_per_person_early_payment as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                                <Grid item md={12} xs={12}>
                                    <CustomTextField
                                        fieldTitle={'How many days before the trip'}
                                        placeholder={'Write something...'}
                                        type='number'
                                        value={values.how_many_days_before_the_trip}
                                        fieldName="how_many_days_before_the_trip"
                                        onChange={setFieldValue}
                                        error={errors.how_many_days_before_the_trip as string}
                                        touched={touched.how_many_days_before_the_trip as boolean}
                                        isApplyDiscount={true}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    )
                }

            </FormControl>
        </ThemeProvider>
    );
};

export default CustomApplyDiscount;
