export const emptyMyOrdersIcon = require("../assets/emptyMyOrdersIcon.png");
export const bringBag = require("../assets/bringBag.png");
export const cancelationPolicy = require("../assets/cancelationPolicy.png");
export const details = require("../assets/details.png");
export const facility = require("../assets/facility.png");
export const include = require("../assets/include.png");
export const itinerary = require("../assets/itinerary.png");
export const moreInfo = require("../assets/moreInfo.png");
export const notAllowed = require("../assets/notAllowed.png");
export const notInclude = require("../assets/notInclude.png");
export const reviewCircle = require("../assets/reviewCircle.png");
export const verified = require("../assets/verified.png");
export const verticalBorder = require("../assets/LineGreyBorder.png");
export const calendarOutline = require("../assets/calendar-outline.png");
export const searchIcon = require("../assets/searchIcon.png");
export const noBookings = require("../assets/noBookings.svg");
export const callIcon = require("../assets/callIcon.svg");
export const downloadIcon = require("../assets/downloadIcon.png");
export const editIcon = require("../assets/editIcon.png");
